import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import empty_state from "../../../assets/images/empty_state_cooperatives.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { NumberCard } from "../../../components/cards/numberCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { AddProductModal } from "../../../components/modals/app_modals/addProductModal";
import { AdminAddProductModal } from "../../../components/modals/app_modals/adminAddProductModal";
import { AdminViewProductHistoryModal } from "../../../components/modals/app_modals/adminViewProductHistoryModal";
import { MemberAddProductModal } from "../../../components/modals/app_modals/memberAddProductModal";
import { PaymentModal } from "../../../components/modals/app_modals/paymentModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { EmptyState } from "../../../components/states/emptyState";
import { CooperativeMemberProductTable } from "../../../components/table/app_tables/cooperativeMemberProductTable";
import { ProductHistoryTable } from "../../../components/table/app_tables/productHistoryTable";
import { usePayment } from "../../../customHooks/usePayment";
import { useShares } from "../../../customHooks/useShares";

export const CooperativeShares = ({
  singleCooperative,
  mode,
  isSharesActive,
}) => {
  const defaultQuery = {
    coop: singleCooperative?.id,
    logs: true,
    text: "",
    limit: 10,
    offset: 0,
  };

  const [query, setQuery] = useState(defaultQuery);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [paymentData, setPaymentData] = useState({});

  // eslint-disable-next-line
  const { myCooperativePermissions } = useSelector((state) => {
    return state.app;
  });

  const { verifyPayment } = usePayment();

  const {
    fetchMySharesInformation,
    fetchMySharesHistory,
    fetchCooperativeSharesInformation,
    fetchCooperativeMemberSharesInformation,
  } = useShares();

  const sharesInformation = useQuery(
    ["sharesInformation", { coop: singleCooperative?.id, logs: "false" }],
    fetchMySharesInformation,
    { enabled: false }
  );

  const sharesHistory = useQuery(
    ["sharesHistory", query],
    fetchMySharesHistory,
    { enabled: false }
  );

  const cooperativeSharesInformation = useQuery(
    ["cooperativeSharesInformation", query],
    fetchCooperativeSharesInformation,
    { enabled: false }
  );

  const cooperativeMemberSharesInformation = useQuery(
    ["cooperativeMemberSharesInformation", query],
    fetchCooperativeMemberSharesInformation,
    { enabled: false }
  );

  useEffect(() => {
    mode === "personal" && sharesInformation?.refetch();
    mode === "admin" && cooperativeSharesInformation?.refetch();

    setQuery(defaultQuery);
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    mode === "personal" && sharesHistory?.refetch();
    mode === "admin" && cooperativeMemberSharesInformation?.refetch();
    // eslint-disable-next-line
  }, [query]);

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleUserSharesStart = () => {
    setModal("addSharesModal");
    setModalOpen(true);
  };

  const handleMemberSharesUploadStart = () => {
    setModal("memberAddSharesModal");
    setModalOpen(true);
  };

  return (
    <div className="w-full">
      {/* Card information and action */}
      {(mode === "admin" && cooperativeSharesInformation?.isFetching) ||
      (mode === "personal" &&
        (sharesInformation?.isFetching || sharesInformation?.isRefetching)) ? (
        <PageLoader />
      ) : (
        <div className="w-full flex flex-row flex-wrap gap-4 items-center">
          <NumberCard
            title={
              mode === "personal" ? `Total share value` : "Total share value"
            }
            value={
              mode === "personal"
                ? sharesInformation?.data?.Amount
                : cooperativeSharesInformation?.data?.Balance
            }
            leftChildrenExtra={
              <div className="text-sm">
                Total shares:{" "}
                <span className="font-medium">
                  {mode === "personal"
                    ? sharesInformation?.data?.BalanceUnit
                    : cooperativeSharesInformation?.data?.QuantityBalance}{" "}
                  shares
                </span>
              </div>
            }
            rightChildren={
              mode === "personal" && (
                <div className="w-full flex flex-col gap-[10px]">
                  <Button
                    text="Buy Shares"
                    onClick={handleUserSharesStart}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                    loading={false}
                    disabled={!isSharesActive}
                    icon={"plus"}
                    variation="primary-white"
                  />

                  <Button
                    text="Upload Shares"
                    onClick={handleMemberSharesUploadStart}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                    loading={false}
                    disabled={!isSharesActive}
                    icon={"arrow-up"}
                    variation="secondary-white"
                  />
                </div>
              )
            }
          />
        </div>
      )}

      {/* History  */}

      {!sharesHistory?.isFetching &&
        !cooperativeSharesInformation?.isFetching && (
          <div>
            <div className="my-[24px] text-[16px] font-medium flex flex-wrap gap-2 items-center justify-between">
              <div>
                {mode === "personal" ? "Shares History" : "Members' Shares"}
              </div>

              {mode === "admin" && (
                <div className="w-full md:w-max md:flex-grow flex items-center md:justify-end gap-2 flex-wrap">
                  <FilterComponent
                    searchPlaceholder={"Search member name, email"}
                    hasExtraFilter={false}
                    handleSearch={(search) => {
                      setQuery({
                        ...query,
                        text: search,
                        limit: 10,
                        offset: 0,
                      });
                    }}
                    mainClassName="!w-full md:!max-w-[286px]"
                    className={"!w-full md:!w-[286px] !py-2"}
                    size="small"
                  />

                  <Button
                    onClick={() => {
                      setModal("adminProductHistoryModal");
                      setModalOpen(true);
                    }}
                    text="View History"
                    type="button"
                    className="md:!py-[8px] w-full md:!max-w-[140px] !text-xs"
                    loading={false}
                    disabled={false}
                    icon={"refresh"}
                    variation="primary"
                  />
                </div>
              )}
            </div>
            {sharesHistory?.isFetching ||
            cooperativeMemberSharesInformation?.isFetching ? (
              <div className="mt-[24px]">
                <PageLoader />
              </div>
            ) : (
              <div className="">
                {(mode === "admin" &&
                  !cooperativeMemberSharesInformation?.data?.data?.length) ||
                (mode === "personal" && !sharesHistory?.data?.data?.length) ? (
                  <ContainerCard className="!py-12">
                    <EmptyState
                      title={
                        mode === "admin"
                          ? "No data found"
                          : "You haven't purchased any shares yet"
                      }
                      titleClassName="!text-base"
                      image={<img src={empty_state} alt="empty" />}
                      actionText={mode === "personal" && "+ Buy my first share"}
                      onClick={handleUserSharesStart}
                      actionButtonVariant="secondary"
                      actionDisabled={!isSharesActive}
                    />
                  </ContainerCard>
                ) : (
                  <div>
                    {mode === "personal" ? (
                      <ProductHistoryTable
                        data={sharesHistory?.data}
                        query={query}
                        setQuery={setQuery}
                        type="shares"
                      />
                    ) : (
                      <CooperativeMemberProductTable
                        data={cooperativeMemberSharesInformation?.data}
                        query={query}
                        setQuery={setQuery}
                        onAddProductClick={(data) => {
                          setSelectedData(data);
                          handleModalOpen("adminAddSharesModal");
                        }}
                        type="shares"
                        isProductActive={isSharesActive}
                      />
                    )}
                  </div>
                )}
              </div>
            )}{" "}
          </div>
        )}

      <AdminAddProductModal
        selectedData={selectedData}
        isModalOpen={modalOpen && modal === "adminAddSharesModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("adminAddSharesSuccessModal");
        }}
        type="shares"
      />

      <SuccessModal
        message={"Shares added successfully"}
        subText={
          <div>
            Once approved, it will reflect on{" "}
            <span className="text-pry-green-200">{`${selectedData?.MemberID?.User?.FirstName} ${selectedData?.MemberID?.User?.LastName}'s`}</span>{" "}
            shares.
          </div>
        }
        isModalOpen={modalOpen && modal === "adminAddSharesSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AddProductModal
        isModalOpen={modalOpen && modal === "addSharesModal"}
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
        successCallback={(data) => {
          setPaymentData(data);
          handleModalOpen("paymentModal");
        }}
        type="shares"
        cooperative={singleCooperative}
      />

      <PaymentModal
        isModalOpen={modalOpen && modal === "paymentModal"}
        onModalClose={handleModalClose}
        paymentData={paymentData}
        handleVerification={() => {
          verifyPayment(
            {
              coop: singleCooperative?.id,
              trxref: paymentData?.reference,
              amount: paymentData?.amount,
              unit: paymentData?.unit,
              reference: paymentData?.reference,
              mode: "paystack",
            },
            () => {
              sharesInformation?.refetch();
            },
            () => {
              handleModalClose();
              handleModalOpen("addSharesSuccessModal");
              setPaymentData({});
              setSelectedData({});
              setQuery(defaultQuery);
            },
            "shares"
          );
        }}
      />

      <SuccessModal
        message={"Shares added successfully"}
        subText={
          <div>
            You have successfully added{" "}
            <span className="text-pry-green-200">{paymentData?.amount}</span> to
            your {""}
            <span className="text-pry-green-200">
              {singleCooperative?.CoopName}
            </span>{" "}
            shares.
          </div>
        }
        isModalOpen={modalOpen && modal === "addSharesSuccessModal"}
        onModalClose={handleModalClose}
      />

      <MemberAddProductModal
        cooperative={singleCooperative?.id}
        isModalOpen={modalOpen && modal === "memberAddSharesModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("memberAddSharesSuccessModal");
        }}
        type="shares"
      />

      <SuccessModal
        message={"Shares added successfully"}
        subText={
          <div>
            Your uploaded share purchase will reflect in your account once
            approved.
          </div>
        }
        isModalOpen={modalOpen && modal === "memberAddSharesSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AdminViewProductHistoryModal
        isModalOpen={modalOpen && modal === "adminProductHistoryModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        singleCooperative={singleCooperative}
        type="shares"
      />
    </div>
  );
};

import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLoans } from "../../../customHooks/useLoans";
import { decodeLoanStatus, numberToString } from "../../../helperFunctions";
import { InformationCard } from "../../cards/infoCard";
import { TagCard } from "../../cards/tagCard";
import { MultiSelectApprovalTable } from "../multiSelectApprovalTable";

export const FailedDisbursementsTable = ({ singleCooperative }) => {
  const [query, setQuery] = useState({
    coop: singleCooperative?.id,
    logs: true,
    limit: 10,
    offset: 0,
  });

  const { fetchFailedDisbursementLoans, retryFailedLoan } = useLoans();

  const failedCooperativeLoanDisbursements = useQuery(
    ["failedCooperativeLoanDisbursements", query],
    fetchFailedDisbursementLoans,
    {
      enabled: false,
    }
  );

  return (
    <div>
      {!failedCooperativeLoanDisbursements.isFetching && (
        <InformationCard
          info={
            <span className="flex gap-1 md:gap-10 flex-wrap">
              <span>
                Cooperative balance:{" "}
                <span className="text-info-purple font-bold">
                  {`₦${numberToString(
                    failedCooperativeLoanDisbursements?.data?.cooperativeBalance
                  )}`}
                </span>
              </span>

              <span>
                Total failed disbursements value:{" "}
                <span className="text-info-purple font-bold">
                  {`₦${numberToString(
                    failedCooperativeLoanDisbursements?.data?.totalFailedAmount
                  )}`}
                </span>
              </span>
            </span>
          }
          size="mini"
          variant="purple"
          className={"!items-center mb-8"}
        />
      )}

      <MultiSelectApprovalTable
        singleCooperative={singleCooperative}
        headers={["NAME", "LOAN TYPE", "LOAN AMOUNT", "STATUS", " "]}
        data={
          failedCooperativeLoanDisbursements?.data?.data
            ? failedCooperativeLoanDisbursements?.data?.data?.map(
                (item, idx) => {
                  return {
                    id: item?.id,
                    data: [
                      `${item?.MemberID?.User?.FirstName} ${item?.MemberID?.User?.LastName}`,
                      item?.LoanProduct?.LoanName ?? "N/A",
                      `₦${numberToString(item?.Amount)}`,
                      <TagCard
                        text={decodeLoanStatus(item?.Status)?.text}
                        type={decodeLoanStatus(item?.Status)?.type}
                        className={`!min-w-max`}
                      />,
                    ],
                  };
                }
              )
            : []
        }
        isLoading={failedCooperativeLoanDisbursements.isFetching}
        refetchData={failedCooperativeLoanDisbursements.refetch}
        successCallback={() => {
          failedCooperativeLoanDisbursements?.refetch();
        }}
        approvalFunction={retryFailedLoan}
        totalCount={failedCooperativeLoanDisbursements?.data?.totalCount}
        query={query}
        setQuery={setQuery}
        emptyTitle="There are no failed loan disbursements"
        emptySubTitle="All failed loan disbursements will be displayed here."
      />
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "..";
import { CooperativeCard } from "../../cards/cooperativeCard";

export const JoinCooperativeModal = ({
  cooperative,
  modalOpen,
  onModalClose,
}) => {
  const navigate = useNavigate();

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title=""
      subTitle=""
      childrenClassName="!mt-0"
      modalClassName="!max-h-[448px]"
      hasButtons={true}
      proceedButtonText="Join"
      onProceedClick={() => {
        navigate(`/app/cooperative/${cooperative?.id}/join`);
      }}
      isLoading={false}
      isProceedDisabled={false}
      hasCancelButton
      cancelButtonText="Cancel"
      onCancelClick={onModalClose}
    >
      <CooperativeCard cooperative={cooperative} variant="modal-variant" />
    </ModalComponent>
  );
};

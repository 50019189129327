import React, { useState } from "react";
import { useLoans } from "../../customHooks/useLoans";
import { numberToString } from "../../helperFunctions";
import { doNotification } from "../../helperFunctions/nofitication";
import { Button } from "../buttons/button";
import { Icon } from "../icons/icon";
import { MemberProfilePic } from "../profile/memberPicture";
import { ProfilePicture } from "../profile/profilePicture";
import { ContainerCard } from "./containerCard";

export const LoanInvitationCard = ({
  invitation,
  handleModalOpen,
  setSelected,
  successCallback = () => {},
}) => {
  const [declining, setDeclining] = useState(false);

  const { approveGuarantorRequest } = useLoans();

  return (
    <ContainerCard className="!px-6 md:!px-8 !py-6 !max-w-[526px]">
      <div className="flex items-center gap-2">
        <ProfilePicture className="w-[32px] h-[32px]" />
        <Icon svg="guarantee-icon" width={20} height={20} className="" />
        <MemberProfilePic
          member={invitation?.GuarantorMemberID?.User}
          className="!w-[32px] !h-[32px] !text-[12px]"
        />
      </div>

      <div className="mt-4 text-base">
        <span className="text-pry-green-200 font-medium">
          {`${invitation?.MembershipLoanRequestID?.MemberID?.User?.FirstName}
          ${invitation?.MembershipLoanRequestID?.MemberID?.User?.LastName}`}
        </span>{" "}
        has requested you to stand as guarantor for a loan
      </div>

      <div className="mt-4 text-sm">
        <ul className="list-disc leading-6 list-inside">
          <li>
            Loan duration:{" "}
            <span className="text-pry-green-200 font-medium">
              {invitation?.MembershipLoanRequestID?.Tenure} months
            </span>
          </li>
          <li>
            Loan amount:{" "}
            <span className="text-pry-green-200 font-medium">
              ₦{numberToString(invitation?.MembershipLoanRequestID?.Amount)}
            </span>
          </li>
          <li>
            Amount to guarantee:{" "}
            <span className="text-pry-green-200 font-medium">
              ₦{numberToString(invitation?.AmountGuarantee)}
            </span>
          </li>
        </ul>
      </div>

      <div className="flex flex-row gap-2 mt-4">
        <Button
          text="Decline"
          onClick={() => {
            setDeclining(true);
            approveGuarantorRequest(
              {
                coop: invitation?.MembershipLoanRequestID?.Cooperative,
                isaccept: false,
                loanid: invitation?.MembershipLoanRequestID?.id,
              },
              () => {
                setDeclining(false);
              },
              () => {
                doNotification("Request denied successfully", "success");
                setDeclining(false);
                successCallback();
              }
            );
          }}
          type="button"
          className="md:!py-2"
          loading={declining}
          disabled={declining}
          variation="secondary-light-2"
        />
        <Button
          text="I consent"
          onClick={() => {
            setSelected(invitation);
            handleModalOpen("confirmationModal");
          }}
          type="button"
          className="md:!py-2"
          loading={false}
          disabled={false}
          variation="primary"
        />
      </div>
    </ContainerCard>
  );
};

import React from "react";
import { ModalComponent } from "..";
import { PageLoader } from "../../loaders/pageLoader";

export const MoreDetailsModal = ({
  modalOpen,
  onModalClose = () => {},
  mainData = {},
  title = "",
  extraInfoTitle = "",
  secondaryData = {},
  loading = false,
}) => {
  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title={title}
      topTitle
      subTitle={undefined}
      childrenClassName="!mt-0"
      childrenContainerClassName=""
      modalClassName="!max-h-[480px] md:!max-w-[792px] !overflow-y-scroll"
      hasButtons={false}
      proceedButtonText=""
      proceedButtonClassName=""
      onProceedClick={() => {}}
      proceedButtonVariant="secondary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisable={false}
      buttonsContainerClassName="!justify-center"
      blank
    >
      {loading ? (
        <PageLoader />
      ) : (
        <div
          className="
        !w-full !h-full
        absolute inset-0 -z-[1]
        "
        >
          <div className="flex flex-wrap bg-light-green-bg min-h-full">
            <div
              className="bg-white w-full
            lg:w-6/12 pl-[24px] lg:pl-[40px] pr-[24px] pt-[88px]
            "
            >
              <div className="flex flex-col gap-3">
                {mainData &&
                  Object.keys(mainData)?.map((val, idx) => {
                    const key = `${val}-${idx}`;
                    return (
                      <div
                        key={key}
                        className={`flex justify-between pb-3 border-b text-xs ${
                          val.toLowerCase() === "picture" && "!hidden"
                        }`}
                      >
                        <div
                          className={`font-medium text-pry-dark-green-300/80 `}
                        >
                          {val?.split(/(?=[A-Z])/)?.join(" ")}:
                        </div>
                        <div className="text-pry-dark-green-300/70">
                          {mainData[val]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div
              className="!bg-transparent h-full w-full 
            lg:w-6/12 pr-[24px] lg:pr-[40px] pl-[24px] pt-[24px] lg:pt-[88px]
            "
            >
              <div className="flex flex-col gap-3">
                {/* More info  */}
                <div className="text-base font-medium">{extraInfoTitle}</div>
                {/* Photo  */}

                {/* Other Info iteration */}
                {secondaryData &&
                  Object.keys(secondaryData)?.map((val, idx) => {
                    const key = `${val}-${idx}`;
                    return (
                      <div
                        key={key}
                        className={`flex justify-between pb-3 border-b-0 h-full text-xs ${
                          val.toLowerCase() === "picture" && "!hidden"
                        }`}
                      >
                        <div
                          className={`font-medium text-pry-dark-green-300/80 `}
                        >
                          {val?.split(/(?=[A-Z])/)?.join(" ")}:
                        </div>
                        <div className="text-pry-dark-green-300/70">
                          {secondaryData[val]}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

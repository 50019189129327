import React, { useState } from "react";
import { useQuery } from "react-query";
import { useApprovals } from "../../../customHooks/useApprovals";
import { TagCard } from "../../cards/tagCard";
import { MemberDetailsModal } from "../../modals/app_modals/memberDetailsModal";
import { MultiSelectApprovalTable } from "../multiSelectApprovalTable";

export const MembershipApprovalTable = ({
  singleCooperative,
  totalCount,
  refresh,
}) => {
  const [query, setQuery] = useState({
    limit: 10,
    offset: 0,
    coop: singleCooperative?.id,
  });
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(undefined);

  const { fetchMembershipApprovals, approveMembership, fetchApprovalsSummary } =
    useApprovals();

  const membershipApprovals = useQuery(
    ["membershipApprovals", query],
    fetchMembershipApprovals,
    { enabled: true }
  );

  return (
    <div>
      <MultiSelectApprovalTable
        singleCooperative={singleCooperative}
        headers={["NAME", "EMAIL ADDRESS", "FEE PAYMENT", " "]}
        data={
          membershipApprovals?.data
            ? membershipApprovals?.data?.map((item, idx) => {
                const key = `row-${idx}-membership-approvals`;

                return {
                  id: item?.id,
                  data: [
                    <div
                      key={`${key}-10 `}
                      className="capitalize"
                    >{`${item?.User?.FirstName} ${item?.User?.LastName}`}</div>,
                    `${item?.User?.Email}`,
                    <TagCard
                      text={`${item?.hasPaid ? "Online" : "No record"}`}
                      type={`${item?.hasPaid ? "success" : "danger"}`}
                    />,
                    <div
                      className="
                text-pry-green-200 !font-medium cursor-pointer
                "
                      key=""
                      onClick={() => {
                        setSelectedMember(item);
                        setModal("memberDetailsModal");
                        setModalOpen(true);
                      }}
                    >
                      View Details
                    </div>,
                  ],
                };
              })
            : []
        }
        payloadIdentifierField="member"
        isLoading={membershipApprovals.isFetching}
        refetchData={membershipApprovals.refetch}
        successCallback={() => {
          fetchApprovalsSummary();
          membershipApprovals?.refetch();
          refresh();
        }}
        approvalFunction={approveMembership}
        totalCount={totalCount}
        query={query}
        setQuery={setQuery}
        emptySubTitle="All your membership approvals will be displayed here whenever you need to approve any new member."
      />

      <MemberDetailsModal
        modalOpen={modalOpen && modal === "memberDetailsModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
          setSelectedMember(undefined);
        }}
        member={selectedMember}
      />
    </div>
  );
};

import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons/icon";

export const BackButton = ({ text, onClick, className, backIcon = "" }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(-1);
        }
      }}
      className={`
      group
      w-fit
      flex items-center 
      gap-2.5
      hover:cursor-pointer
      text-base
      text-pry-dark-green-300
      transition-all
      ${className}
      `}
    >
      <Icon
        svg={backIcon ? backIcon : "left-arrow"}
        width={24}
        height={24}
        className="group-hover:scale-110 group-hover:text-pry-green-200 transition-all"
      />
      <div className="font-medium group-hover:scale-95 transition-all">
        {text}
      </div>
    </div>
  );
};

BackButton.propTypes = {
  backIcon: PropTypes.string,
  className: PropTypes.any,
  onClick: PropTypes.func,
  text: PropTypes.any,
};

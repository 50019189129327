import React, { useState } from "react";
import { useQuery } from "react-query";
import { useApprovals } from "../../../customHooks/useApprovals";
import { decodeUrl } from "../../../helperFunctions";
import { ImageViewModal } from "../../modals/app_modals/imageViewModal";
import { TimeText } from "../../typography/timeText";
import { MultiSelectApprovalTable } from "../multiSelectApprovalTable";

export const PaymentApprovalTable = ({
  singleCooperative,
  totalCount,
  refresh,
}) => {
  const [query, setQuery] = useState({
    limit: 10,
    offset: 0,
    coop: singleCooperative?.id,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [link, setLink] = useState("");

  const { fetchPaymentApprovals, approvePayment, fetchApprovalsSummary } =
    useApprovals();

  const savingsApprovals = useQuery(
    ["savingsApprovals", query],
    fetchPaymentApprovals,
    { enabled: true }
  );

  return (
    <div>
      <MultiSelectApprovalTable
        singleCooperative={singleCooperative}
        headers={["NAME", "AMOUNT", "PAYMENT DATE", " "]}
        data={
          savingsApprovals?.data
            ? savingsApprovals?.data?.map((item, idx) => {
                const key = `row-${idx}-savings-approvals`;

                return {
                  id: item?.id,
                  data: [
                    <div
                      key={`${key}-10 `}
                      className="capitalize"
                    >{`${item?.MembershipWalletID?.MemberID?.User?.FirstName} ${item?.MembershipWalletID?.MemberID?.User?.LastName}`}</div>,
                    `₦${Number(item?.Amount)?.toLocaleString()}`,
                    <TimeText timeString={item?.PaymentDate} key="" />,
                    <div
                      className="
                      text-pry-green-200 !font-medium cursor-pointer
                      "
                      key=""
                      onClick={() => {
                        setModal("imageViewModal");
                        setModalOpen(true);
                        setLink(decodeUrl(item?.payslip));
                      }}
                    >
                      View Proof
                    </div>,
                  ],
                };
              })
            : []
        }
        isLoading={savingsApprovals.isFetching}
        refetchData={savingsApprovals.refetch}
        successCallback={() => {
          fetchApprovalsSummary();
          savingsApprovals?.refetch();
          refresh();
        }}
        approvalFunction={approvePayment}
        totalCount={totalCount}
        query={query}
        setQuery={setQuery}
        emptySubTitle="All your savings approvals will be displayed here whenever you need to approve any savings transaction."
      />

      <ImageViewModal
        modalOpen={modalOpen && modal === "imageViewModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        src={link}
      />
    </div>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import { appSliceReducer } from "./features/app/app";
import { AuthSliceReducer } from "./features/auth/auth";
import { cooperativeSliceReducer } from "./features/cooperative/cooperative";
import { layoutSliceReducer } from "./features/layout/layout";
import { paymentSliceReducer } from "./features/payment/payment";
import { savingsSliceReducer } from "./features/savings/savings";
import { SettingsSliceReducer } from "./features/settings/settings";

export const store = configureStore({
  reducer: {
    layout: layoutSliceReducer,
    auth: AuthSliceReducer,
    payment: paymentSliceReducer,
    cooperative: cooperativeSliceReducer,
    settings: SettingsSliceReducer,
    app: appSliceReducer,
    savings: savingsSliceReducer,
  },
});

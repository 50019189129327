import React from "react";
import { useNavigate } from "react-router-dom";
import { Configurations } from ".";
import { PageHeader } from "../../../../components/layouts/pageHeader";

export const ConfigurationsEdit = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full ">
      <PageHeader
        hasBack={true}
        onBackClick={() => navigate("/app/settings?tab=configurations")}
        pageTitle="Configurations"
        hasRightChildren={false}
      />

      <div className="mt-7">
        <Configurations mode="edit" />
      </div>
    </div>
  );
};

import PropTypes from "prop-types";
import React from "react";

export const FromThriftCorpSign = ({ className = "" }) => {
  return (
    <div
      className={`
      mt-8 flex flex-row gap-2
      items-center
      ${className}
    `}
    >
      <div
        className="
        bg-pry-dark-green-100 
        text-lg md:text-2xl text-white text-center
        flex items-center justify-center
        rounded-full w-[32px] h-[32px] md:w-[48px] md:h-[48px]
      "
      >
        T
      </div>
      <div className="text-sm md:text-lg font-bold">
        From ThriftCorp with ❤️
      </div>
    </div>
  );
};

FromThriftCorpSign.propTypes = {
  className: PropTypes.string,
};

import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "..";
import { useAuth } from "../../../customHooks/useAuth";
import { useCooperative } from "../../../customHooks/useCooperative";
import {
  maskEmail,
  maskPhoneNumber,
  numberToString,
  stringToNumber,
  stripNumber,
} from "../../../helperFunctions";
import { InformationCard } from "../../cards/infoCard";
import { TextInput } from "../../inputs/textInput";
import { SuccessModal } from "./successModal";

const stepData = (step) => {
  switch (step) {
    case 1:
      return {
        title: "Withdraw",
        buttonText: "I understand",
      };
    case 2:
      return {
        title: "How much do you want to withdraw?",
        buttonText: "Proceed",
      };
    case 3:
      return {
        title: "Code",
        buttonText: "Proceed",
      };
    default:
      return {
        title: null,
        buttonText: null,
      };
  }
};

const StepOne = () => {
  return (
    <InformationCard
      info={
        <div>
          <span className="text-info-purple font-bold">
            Reminder: Keeping enough funds for your cooperative's operations
            such as loan disbursements is essential.
          </span>
          <br /> <br />
          <span>
            This withdrawal might leave insufficient funds in your Thriftcorp
            account. You might be required to fund your account in the future.
          </span>
        </div>
      }
      innerClassName="
      !items-start
      md:!min-w-full
      "
      hasIcon
      variant="purple"
      size="mini"
    />
  );
};

const StepTwo = ({ setRef, setStep, setSubmitting, setAmount }) => {
  const { obtainVerificationToken } = useAuth();

  const { handleBlur, touched, errors, values, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        amount: "",
      },
      onSubmit: (values) => {
        if (stripNumber(values.amount) > 0) {
          setSubmitting(true);
          obtainVerificationToken(
            {},
            () => {
              setSubmitting(false);
            },
            () => {
              setAmount(values.amount);
              setStep(3);
            }
          );
        }
      },
    });

  const ref = useRef();

  useEffect(() => {
    setRef(ref);
  }, [ref, setRef]);

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        type="text"
        name="amount"
        value={
          values?.amount?.replace(/,/g, "") !== ""
            ? Number(values?.amount?.replace(/,/g, ""))?.toLocaleString()
            : ""
        }
        placeholder={`Enter amount`}
        onChange={handleChange}
        onBlur={handleBlur}
        hasIcon={false}
        autoComplete="off"
        errors={touched?.amount && errors?.amount && errors?.amount}
        className="!mt-4"
      />

      <button type="submit" className="hidden" ref={ref}>
        Proceed
      </button>
    </form>
  );
};

const StepThree = ({
  setRef,
  handleClose,
  setSubmitting,
  amount,
  cooperativeId,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { initiateCooperativeWithdrawal } = useCooperative();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const ref = useRef();

  useEffect(() => {
    setRef(ref);
  }, [ref, setRef]);

  const { handleBlur, touched, errors, values, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        code: "",
      },
      onSubmit: (values) => {
        if (true) {
          setSubmitting(true);
          initiateCooperativeWithdrawal(
            {
              coop: cooperativeId,
              amount: stringToNumber(amount),
              token: values.code,
            },
            () => {
              setSubmitting(false);
            },
            () => {
              setModalOpen(true);
            }
          );
        }
      },
    });

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <InformationCard
        info={
          <div className="text-sm">
            We sent a code to{" "}
            <span className="text-pry-green-200">
              {maskEmail(profile?.Email)}
            </span>{" "}
            and{" "}
            <span className="text-pry-green-200">
              {maskPhoneNumber(profile?.PhoneNumber)}
            </span>
          </div>
        }
        innerClassName="
          !items-start
          md:!min-w-full
          "
        hasIcon={false}
        variant="green"
        size="mini"
      />

      <TextInput
        type="text"
        name="code"
        value={values.code}
        placeholder={`Enter code`}
        onChange={handleChange}
        onBlur={handleBlur}
        hasIcon={false}
        autoComplete="off"
        errors={touched?.code && errors?.code && errors?.code}
        className="!mt-4"
      />

      <button type="submit" className="hidden" ref={ref}>
        Proceed
      </button>

      <SuccessModal
        message={"Withdrawal initiated successfully"}
        isModalOpen={modalOpen}
        onModalClose={() => {
          setModalOpen(false);
          handleClose();
        }}
        subText={
          <span>
            Once approved, ₦{numberToString(amount)} will be credited into the{" "}
            <span className="font-bold text-pry-green-200">
              cooperative_name’s
            </span>{" "}
            bank account
          </span>
        }
        callback={() => {}}
        transparentBg
      />
    </form>
  );
};

export const CooperativeWithdrawalModal = ({
  modalOpen,
  onModalClose,
  cooperativeId,
}) => {
  const [step, setStep] = useState(1);
  const [proceedRef, setProceedRef] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState(null);

  const handleModalClose = () => {
    setStep(1);
    setAmount(null);
    setSubmitting(false);
    onModalClose();
  };

  const handleRef = useCallback((ref) => {
    setProceedRef(ref);
  }, []);

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      topTitle
      onModalClose={handleModalClose}
      title={stepData(step)?.title}
      subTitle=""
      childrenClassName="!mt-0"
      modalClassName="!max-h-[448px]"
      hasButtons={true}
      proceedButtonText={stepData(step)?.buttonText}
      onProceedClick={() => {
        if (step === 1) {
          setStep(2);
        } else {
          proceedRef && proceedRef?.current?.click();
        }
      }}
      isLoading={submitting}
      isProceedDisabled={false}
      hasCancelButton={false}
    >
      {step === 1 && <StepOne />}
      {step === 2 && (
        <StepTwo
          setRef={handleRef}
          setStep={setStep}
          setSubmitting={setSubmitting}
          setAmount={setAmount}
        />
      )}
      {step === 3 && (
        <StepThree
          setRef={handleRef}
          handleClose={handleModalClose}
          setSubmitting={setSubmitting}
          amount={amount}
          cooperativeId={cooperativeId}
        />
      )}
    </ModalComponent>
  );
};

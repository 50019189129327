import React, { useState } from "react";
import { ErrorText } from "../typography/errorText";

export const RangeRadioInput = ({
  name,
  title,
  titleClassName,
  options = [],
  setValue,
  errors,
}) => {
  const [selected, setSelected] = useState("");

  return (
    <div>
      <div
        className={`${titleClassName}
      text-sm font-medium text-pry-dark-green-300
      mb-[13px]
      `}
      >
        {title}
      </div>
      <div className="flex flex-wrap gap-2">
        {options.map(({ text, value }, idx) => {
          const key = `option-${idx}`;
          return (
            <div
              className={`
            text-xs text-pry-dark-green-300/50
            p-[4px] min-w-[58px] text-center
            border border-transparent rounded-[4px]
            bg-light-green-bg
            hover:cursor-pointer hover:scale-95
            focus:scale-95
            ${
              selected === key &&
              `!text-pry-green-200 font-semibold
            scale-95
            !border-pry-green-200
            `
            }
            transition-all
            `}
              key={key}
              onClick={() => {
                setSelected(key);
                setValue && setValue(name, value);
              }}
            >
              {text}
            </div>
          );
        })}
      </div>
      {errors && <ErrorText text={errors} />}
    </div>
  );
};

import React from "react";
import { decodeUrl } from "../../helperFunctions";

export const CooperativeProfilePic = ({ cooperative, className }) => {
  const colors = [
    "!bg-[#F1D7FD] !border-[#F1D7FD]",
    // "!bg-[#ed6354] !border-[#ed6354] !text-white",
    // "!bg-[#979aa5] !border-[#979aa5] !text-white",
    // "!bg-[#94d2f8ff] !border-[#94d2f8ff] !text-white",
    // "!bg-[#efde9dff] !border-[#efde9dff]",
    "!bg-[#F1D7FD] !border-[#F1D7FD]",
  ];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div
      className={`
            w-[72px] h-[72px]
            flex items-center justify-center
            ${
              !cooperative?.logo &&
              `
            text-[16px] font-bold uppercase
            !border
            ${randomColor}
            `
            }
            rounded-full overflow-hidden
            bg-light-purple/0
            ${className}
            border-[0.5px] border-pry-green-200/[0]
            `}
    >
      {cooperative?.logo ? (
        <img
          src={decodeUrl(cooperative?.logo)}
          alt="cooperative_logo"
          className="object-cover w-full h-full"
        />
      ) : (
        cooperative?.CoopName?.slice(0, 2)
      )}
    </div>
  );
};

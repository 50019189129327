import { useFormik } from "formik";
import React from "react";
import { ModalComponent } from "..";
import { useMembership } from "../../../customHooks/useMembership";

export const RemoveMemberModal = ({
  isModalOpen,
  onModalClose,
  selectedMember,
  selectedMemberFull,
  successCallback = () => {},
}) => {
  const { initiateMemberRemoval } = useMembership();

  const { isSubmitting, handleSubmit } = useFormik({
    initialValues: {},
    validationSchema: undefined,
    onSubmit: (data, { setSubmitting }) => {
      const payload = {
        coop: selectedMemberFull?.Cooperative?.id,
        id: selectedMemberFull?.id,
      };

      initiateMemberRemoval(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
          successCallback(selectedMember);
        }
      );
    },
  });

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
      }}
      title="Remove member"
      subTitle={
        <div>
          You are about to initiate a{" "}
          <span className="font-bold">removal request</span> for{" "}
          <span className="text-pry-green-200">{selectedMember?.Email}</span>.
          This request requires approvals to be completed.
        </div>
      }
      childrenClassName=""
      modalClassName="!max-h-[330px]"
      hasButtons
      proceedButtonText="Initiate"
      onProceedClick={handleSubmit}
      proceedButtonVariant="danger"
      hasCancelButton
      cancelButtonText="Cancel"
      cancelButtonVariant="secondary"
      onCancelClick={onModalClose}
      isLoading={isSubmitting}
      isProceedDisabled={isSubmitting}
    ></ModalComponent>
  );
};

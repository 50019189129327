import React from "react";
import empty_state from "../../assets/images/empty_state_cooperatives.svg";
import { ContainerCard } from "../cards/containerCard";
import { PageLoader } from "../loaders/pageLoader";
import { EmptyState } from "../states/emptyState";

export const TableComponent = ({
  headers = [],
  rows = [], // [...{id: id, items: [...]}]
  className = "",
  isRowClickable = false,
  rowClassName = "",
  onRowClick = () => {},
  isTableEmpty = false,
  emptyTitle = "",
  emptySubTitle = "",
  emptyActionText = "",
  emptyActionOnclick = () => {},
  emptyActionVariant = "secondary",
  isLoading = false,
}) => {
  if (isLoading) {
    return <PageLoader />;
  }

  return isTableEmpty ? (
    <ContainerCard className="!py-12">
      <EmptyState
        title={emptyTitle}
        subtitle={emptySubTitle}
        titleClassName=""
        image={<img src={empty_state} alt="empty" />}
        actionText={emptyActionText}
        onClick={emptyActionOnclick}
        actionButtonVariant={emptyActionVariant}
      />
    </ContainerCard>
  ) : (
    <table
      className={`min-w-max md:min-w-full
          border-separate
          border-spacing-x-0
          border-spacing-y-1 
          !overflow-x-scroll
          ${className}`}
    >
      <thead>
        <tr>
          {headers?.map((header, idx) => {
            const key = `table-header-${idx}`;
            return header ? (
              <th
                key={key}
                className="
                uppercase
                text-[10px] font-semibold
                text-pry-dark-green-300/80
                pb-3 px-4
                first:pl-[24px] last:pr-[24px]
                text-left
                min-w-max
                "
              >
                {header}
              </th>
            ) : undefined;
          })}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, idx) => {
          return (
            <tr
              key={row?.id}
              className={`border border-spacing-y-80 group ${
                isRowClickable &&
                "cursor-pointer md:hover:scale-[0.99] transition-all duration-300"
              }`}
              onClick={() => {
                isRowClickable && onRowClick(idx);
              }}
            >
              {row?.items?.map((item, idx) => {
                const key = `row-item-${idx}`;
                return item ? (
                  <td
                    key={key}
                    className={`
                  mb-2 
                  bg-white
                  duration-300 transition-all
                  border-pry-dark-green-300/10
                  ${
                    isRowClickable &&
                    " md:group-hover:border-pry-dark-green-300/50"
                  }
                  border-t border-b
                  py-[16px] md:py-[20px] px-3 md:px-4
                  first:border-l first:rounded-s-lg first:pl-[24px]
                  last:border-r last:rounded-e-lg last:pr-[24px]
                  text-left text-xs 
                  font-medium
                  text-pry-dark-green-400/80
                  ${rowClassName}
                  `}
                  >
                    {item}
                  </td>
                ) : undefined;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/buttons/button";
import { TextInput } from "../../../components/inputs/textInput";
import { HeaderSubheader } from "../../../components/layouts/headerSubheader";
import { OtpCountdownTImer } from "../../../components/timer/OtpCountdownTimer";
import { useAuth } from "../../../customHooks/useAuth";
import { isAuthenticated } from "../../../helperFunctions/auth";
import { confirmEmailSchema } from "../../../validationSchema";

export const SignUpConfirmEmail = ({ setStep }) => {
  const accountConfirmation = JSON.parse(
    localStorage.getItem("accountConfirmation")
  );

  const { verifyAccount, resendAccountVerificationCode } = useAuth();
  const navigate = useNavigate();

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: confirmEmailSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        Code: data.code,
        Email: accountConfirmation?.email,
      };

      verifyAccount(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          if (isAuthenticated()) {
            navigate("/app/overview");
          } else {
            navigate("/auth/sign-in");
          }
        }
      );
    },
  });

  return (
    <div className="w-full">
      <HeaderSubheader
        header="Confirm Email"
        subheader={
          <div>
            We sent a code to{" "}
            <span className="text-pry-dark-green-300/80 font-medium">
              {accountConfirmation?.email}
            </span>
          </div>
        }
      />
      <form className="mt-6 flex flex-col gap-3" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name={"code"}
          value={values.code}
          placeholder="Enter code"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors?.code && touched?.code && errors?.code}
          hasIcon={false}
        />

        <div className="w-full text-right !text-xs text-grey-200">
          Didn’t get any code?{" "}
          <OtpCountdownTImer
            text="Resend Code"
            onClick={() => {
              const payload = {
                Email: accountConfirmation?.email,
              };
              resendAccountVerificationCode(payload);
            }}
          />
        </div>

        <div className="w-full text-right mt-5 md:mt-3">
          <Button
            text="Confirm Email"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

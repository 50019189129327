import { useSelector } from "react-redux";
import { TextInput } from "../../../components/inputs/textInput";

export const ProfileInformation = () => {
  const { profile } = useSelector((state) => {
    return state.auth;
  });

  return (
    <div>
      <div className="flex flex-row flex-wrap gap-4">
        <TextInput
          type="text"
          name={undefined}
          value={profile?.FirstName}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="First Name"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.MiddleName?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="Middle Name"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.LastName?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="Last Name"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.DateOfBirth || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="Date of Birth"
          disabled
          readOnly
          mobileScaleDown
        />

        <div className="flex gap-4 w-full">
          <TextInput
            type="text"
            name={undefined}
            value={
              profile?.PhoneNumberCode?.replaceAll("#", "") &&
              `+${profile?.PhoneNumberCode?.replaceAll("#", "")}`
            }
            placeholder="Not specified"
            onChange={() => {}}
            onBlur={() => {}}
            hasIcon={false}
            iconSvg={undefined}
            errors={undefined}
            inputClass=""
            className=""
            containerClassName="w-full max-w-[109px]"
            customIcon={undefined}
            hasTitle
            title="Dial Code"
            disabled
            readOnly
            mobileScaleDown
          />

          <TextInput
            type="text"
            name={undefined}
            value={profile?.PhoneNumber?.replaceAll("#", "") || ""}
            placeholder="Not specified"
            onChange={() => {}}
            onBlur={() => {}}
            hasIcon={false}
            iconSvg={undefined}
            errors={undefined}
            inputClass=""
            className=""
            containerClassName="w-full "
            customIcon={undefined}
            hasTitle
            title="Phone Number"
            disabled
            readOnly
            mobileScaleDown
          />
        </div>

        <TextInput
          type="text"
          name={undefined}
          value={profile?.Email?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full"
          customIcon={undefined}
          hasTitle
          title="Email Address"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.Address?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full"
          customIcon={undefined}
          hasTitle
          title="Address"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.Country?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="Country"
          disabled
          readOnly
          mobileScaleDown
        />

        <TextInput
          type="text"
          name={undefined}
          value={profile?.State?.replaceAll("#", "") || ""}
          placeholder="Not specified"
          onChange={() => {}}
          onBlur={() => {}}
          hasIcon={false}
          iconSvg={undefined}
          errors={undefined}
          inputClass=""
          className=""
          containerClassName="w-full md:max-w-[267px]"
          customIcon={undefined}
          hasTitle
          title="State"
          disabled
          readOnly
          mobileScaleDown
        />
      </div>
    </div>
  );
};

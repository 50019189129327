import React from "react";
import { ModalComponent } from "..";
import { FailedDisbursementsTable } from "../../table/app_tables/failedDisbursementsTable";

export const FailedDisbursementsModal = ({
  isModalOpen,
  onModalClose,
  singleCooperative = {},
}) => {
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Failed Loan Disbursements"
      topTitle
      subTitle=""
      childrenClassName=""
      modalClassName="!max-h-[572px] !max-w-[1038px]"
      hasButtons={false}
      proceedButtonText=""
      onProceedClick={() => {}}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={false}
    >
      <FailedDisbursementsTable singleCooperative={singleCooperative} />
    </ModalComponent>
  );
};

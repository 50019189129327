import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { TextInput } from "../../../../components/inputs/textInput";
import { TitleSub } from "../../../../components/typography/titleSub";
import { useSettings } from "../../../../customHooks/useSettings";
import { depositConfigurationSchema } from "../../../../validationSchema";

export const DepositConfigurations = ({
  mode = "",
  cooperativeConfiguration,
}) => {
  const { setDepositConfigurations } = useSettings();

  const navigate = useNavigate();

  const depositsForm = useFormik({
    initialValues: {
      withdrawalDays:
        cooperativeConfiguration?.data?.deposit?.NumberOfDaysToWithdrawal || "",
      depositsInterestRate:
        cooperativeConfiguration?.data?.deposit?.InterestRate || "",
    },
    validationSchema: depositConfigurationSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      setDepositConfigurations(
        {
          InterestRate: data?.depositsInterestRate,
          NumberOfDaysToWithdrawal: data?.withdrawalDays,
        },
        () => {
          setSubmitting(false);
          cooperativeConfiguration?.refetch();
        },
        () => {}
      );
    },
  });

  return (
    <ContainerCard
      className="!px-0
            "
    >
      {/* Mini header  */}
      <div
        className="
              w-full flex flex-row gap-4
              items-center justify-between
              mb-6
              px-4 md:px-8
              "
      >
        <div
          className="
                font-bold text-base
                "
        >
          Deposits Settings
        </div>
        <div className="!w-full !max-w-[80px] md:!max-w-[96px]">
          <Button
            text={mode === "edit" ? "Save" : "Edit"}
            onClick={() => {
              mode === "edit"
                ? depositsForm?.handleSubmit()
                : navigate("/app/settings/configurations/edit/");
            }}
            type="button"
            className="!text-sm md:!py-2"
            loading={depositsForm?.isSubmitting}
            disabled={depositsForm?.isSubmitting}
            variation="primary"
          />
        </div>
      </div>

      {/* Configuration Sub-body */}
      <div
        className="
              px-4 md:px-8
              border-b py-3
              "
      >
        <TitleSub
          title="Withdrawal Approval"
          subtitle={"How long before withdrawal is approved?"}
          subtitleClassName="!mt-0"
        />

        <div className="mt-3">
          {mode === "view" && (
            <div className="text-sm md:text-base text-pry-green-200 font-medium">
              {cooperativeConfiguration?.data?.deposit?.NumberOfDaysToWithdrawal
                ? `${cooperativeConfiguration?.data?.deposit?.NumberOfDaysToWithdrawal} day(s)`
                : "No duration set"}
            </div>
          )}

          {mode === "edit" && (
            <TextInput
              type="text"
              name={"depositsInterestRate"}
              value={depositsForm?.values?.depositsInterestRate}
              placeholder="Input duration (in days)"
              onChange={depositsForm?.handleChange}
              onBlur={depositsForm?.handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                depositsForm?.touched?.depositsInterestRate &&
                depositsForm?.errors?.depositsInterestRate &&
                depositsForm?.errors?.depositsInterestRate
              }
              inputClass=""
              className="!p-0 !pl-4"
              autoComplete="off"
              customIcon={
                <div
                  className="
                        font-medium text-sm md:text-base
                        py-[14px] px-[16px]
                        bg-white
                        border border-pry-dark-green-300/10
                        rounded-e-lg
                        "
                >
                  Days
                </div>
              }
              customIconPosition="right"
            />
          )}
        </div>
      </div>

      <div
        className="
              px-4 md:px-8
              border-b-0 py-3
              "
      >
        <TitleSub
          title="Interest Rate"
          subtitle={"Amount of interest due per period, on a deposit."}
          subtitleClassName="!mt-0"
        />

        <div className="mt-3">
          {mode === "view" && (
            <div className="text-sm md:text-base text-pry-green-200 font-medium">
              {cooperativeConfiguration?.data?.deposit?.InterestRate
                ? `${cooperativeConfiguration?.data?.deposit?.InterestRate}%`
                : "No rate set"}
            </div>
          )}

          {mode === "edit" && (
            <TextInput
              type="text"
              name={"withdrawalDays"}
              value={depositsForm?.values?.withdrawalDays}
              placeholder="Input interest rate"
              onChange={depositsForm?.handleChange}
              onBlur={depositsForm?.handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                depositsForm?.touched?.withdrawalDays &&
                depositsForm?.errors?.withdrawalDays &&
                depositsForm?.errors?.withdrawalDays
              }
              inputClass=""
              className="!p-0 !pr-4"
              autoComplete="off"
              customIcon={
                <div
                  className="
                        font-medium text-sm md:text-base
                        py-[14px] px-[16px]
                        bg-white
                        border border-pry-dark-green-300/10
                        rounded-s-lg
                        "
                >
                  %
                </div>
              }
              customIconPosition="left"
            />
          )}
        </div>
      </div>
    </ContainerCard>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { InformationCard } from "../../../components/cards/infoCard";
import { Icon } from "../../../components/icons/icon";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { CloseMembershipFormModal } from "../../../components/modals/app_modals/closeMembershipFormModal";
import { ScreenOverlay } from "../../../components/overlay/screenOverlay";
import { ProgressTrail } from "../../../components/progress/progressTrail";
import { useAuth } from "../../../customHooks/useAuth";
import { useCooperative } from "../../../customHooks/useCooperative";
import { usePayment } from "../../../customHooks/usePayment";
import { sleep } from "../../../helperFunctions";
import { doNotification } from "../../../helperFunctions/nofitication";
import { MembershipForm } from "./membershipForm";
import { PayMembershipFee } from "./payFeeComponent";
import { ProfileInformation } from "./profileInformation";

export const JoinCooperative = () => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(["Personal Information"]);
  const [membershipForm, setMembershipForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState({});

  const param = useParams();
  const navigate = useNavigate();

  const { fetchingProfile } = useSelector((state) => {
    return state?.auth;
  });

  const { fetchingSingleCooperativeDetails, singleCooperative } = useSelector(
    (state) => {
      return state?.app;
    }
  );

  const { fetchUserProfile } = useAuth();
  const { fetchSingleCooperativeDetails, joinCooperative } = useCooperative();
  const { getRegistrationPaymentLink } = usePayment();

  useEffect(() => {
    fetchUserProfile();
    fetchSingleCooperativeDetails({ id: param?.id });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSteps(["Personal Information"]);
    if (
      singleCooperative?.formdetail?.Field &&
      singleCooperative?.formdetail?.Field?.length > 0
    ) {
      setSteps(["Personal Information", "A little bit more"]);
    }
    if (singleCooperative?.formdetail?.form?.Fee) {
      setSteps(["Personal Information", "Membership Fee"]);
    }
    if (
      singleCooperative?.formdetail?.form?.Fee &&
      singleCooperative?.formdetail?.Field &&
      singleCooperative?.formdetail?.Field?.length > 0
    ) {
      setSteps(["Personal Information", "A little bit more", "Membership Fee"]);
    }

    // Remove duplicates
    setSteps((steps) => {
      if (steps?.length > 1) {
        return [...new Set(steps)];
      }
      return steps;
    });
  }, [singleCooperative]);

  //   Has the cooperative to be joined only set up forms
  const justForm =
    singleCooperative?.formdetail?.Field &&
    singleCooperative?.formdetail?.Field?.length > 0 &&
    !singleCooperative?.formdetail?.form?.Fee;
  //   Has the cooperative to be joined only set up fee
  const justFee =
    singleCooperative?.formdetail?.form?.Fee &&
    !(
      singleCooperative?.formdetail?.Field &&
      singleCooperative?.formdetail?.Field?.length > 0
    );
  //   Has the cooperative to be joined set up both form and fee
  const allComplete =
    singleCooperative?.formdetail?.Field &&
    singleCooperative?.formdetail?.Field?.length > 0 &&
    singleCooperative?.formdetail?.form?.Fee;

  const handleNext = () => {
    const joinCoopPayload = {
      coop: singleCooperative?.id,
      form: singleCooperative?.formdetail?.form?.id,
      formdata: membershipForm,
    };

    // CASE 1: No form or fee set
    if (steps?.length === 1) {
      //   join coop with empty form, no payment
      setLoading(true);
      joinCooperative(
        joinCoopPayload,
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
          navigate("/app/my-cooperatives");
        }
      );
    }
    // CASE 2: Only form has been set by cooperative, no fee
    else if (steps?.length === 2 && justForm) {
      if (step === 1) {
        setStep(step + 1);
      } else if (step === 2) {
        // Join coop with form, no payment
        setLoading(true);
        joinCooperative(
          joinCoopPayload,
          () => {
            setLoading(false);
          },
          () => {
            setLoading(false);
            navigate("/app/my-cooperatives");
          }
        );
      }
    }
    // CASE 3: Only fee has been set by cooperative, no form
    else if (steps?.length === 2 && justFee) {
      if (step === 1) {
        setLoading(true);
        // Join coop with empty form, get payment link
        joinCooperative(
          joinCoopPayload,
          () => {},
          () => {
            setLoading(true);
            sleep(1000).then(() => {
              doNotification("Fetching payment link", "info");

              getRegistrationPaymentLink(
                {
                  coop: singleCooperative?.id,
                  callbackurl: `${window.location.protocol}//${window.location.hostname}/app/success?coopName=${singleCooperative?.CoopName}&amount=${singleCooperative?.formdetail?.form?.Fee}`,
                },
                () => {
                  setLoading(false);
                },
                (data) => {
                  setLoading(false);
                  setPaymentLink(data);
                  setStep(step + 1);
                }
              );
            });
          },
          () => {
            setLoading(false);
          }
        );
      } else if (step === 2) {
        console.log("handle payment");
        // Redirect to my cooperatives - success callback
      }
    }
    // CASE 4: All has been set by cooperative
    else {
      if (step === 1) {
        setStep(step + 1);
      } else if (step === 2) {
        setLoading(true);
        joinCooperative(
          joinCoopPayload,
          () => {},
          () => {
            setLoading(true);
            sleep(1000).then(() => {
              doNotification("Fetching payment link", "info");

              getRegistrationPaymentLink(
                {
                  coop: singleCooperative?.id,
                  callbackurl: `${window.location.protocol}//${window.location.hostname}/app/success?coopName=${singleCooperative?.CoopName}&amount=${singleCooperative?.formdetail?.form?.Fee}`,
                },
                () => {
                  setLoading(false);
                },
                (data) => {
                  setLoading(false);
                  setPaymentLink(data);
                  setStep(step + 1);
                }
              );
            });
          },
          () => {
            setLoading(false);
          }
        );
      } else if (step === 3) {
        console.log("handle payment");
        // Redirect to my cooperatives - success callback
      }
    }
  };

  return (
    <ScreenOverlay>
      <div
        className="
        w-full h-full
        overflow-scroll
        flex items-center justify-center
        py-[64px] px-[16px]
        "
      >
        {fetchingProfile || fetchingSingleCooperativeDetails ? (
          <PageLoader />
        ) : (
          <ContainerCard
            className="
            w-full max-w-[600px]
            h-full max-h-[828px]
            !py-[40px] !px-[18px] md:!px-[24px]
            overflow-scroll
            flex flex-col
            "
          >
            {/* Title and close icon  */}
            <div className="flex items-center justify-between gap-8">
              <div
                className="
                text-sm md:text-[20px] font-bold
                    "
              >
                Membership Form
              </div>

              <div
                className="
                text-white
                w-[20px] h-[20px] md:w-[28px] md:h-[28px]
                bg-pry-dark-green-300
                rounded-full
                flex items-center justify-center
                cursor-pointer
                "
                onClick={() => {
                  setModal("closeMembershipFormModal");
                  setModalOpen(true);
                }}
              >
                <Icon svg="cancel-icon" width={20} height={20} />
              </div>
            </div>

            {/* Subtitle  */}
            <div className="w-full text-xs md:text-base text-pry-dark-green-300/60 mt-1 pr-8">
              To join{" "}
              <span className="text-pry-green-200 font-medium">
                {singleCooperative?.CoopName}
              </span>
              , kindly provide the following information and pay a registration
              fee of{" "}
              <span className="text-pry-green-200 font-medium">
                ₦
                {singleCooperative?.formdetail?.form?.Fee
                  ? Number(
                      singleCooperative?.formdetail?.form?.Fee
                    )?.toLocaleString()
                  : "0"}
              </span>
            </div>

            {/* Edit profile information card  */}
            {step === 1 && (
              <div className="w-full mt-[12px]">
                <InformationCard
                  info="Click the button to edit your profile"
                  variant="purple"
                  size="mini"
                  hasIcon={false}
                  className="!items-center !p-2 md:!px-4"
                  actionElement={
                    <Button
                      text="Edit profile"
                      onClick={() => {
                        navigate("/app/settings/profile/edit");
                      }}
                      type="button"
                      className="!w-fit md:!py-1 !px-2 !text-xs"
                      loading={false}
                      disabled={false}
                      variation="primary"
                    />
                  }
                />
              </div>
            )}

            {/* Progress Trail  */}
            <div className="mt-[12px] w-full">
              <ProgressTrail steps={steps} currentStep={step} />
            </div>

            {/* Main Body */}
            <div className="mt-[20px] flex-1">
              {step === 1 && <ProfileInformation />}

              {((allComplete && step === 2) || (justForm && step === 2)) && (
                <MembershipForm
                  cooperativeDetails={singleCooperative}
                  setMembershipForm={setMembershipForm}
                />
              )}

              {((allComplete && step === 3) || (justFee && step === 2)) && (
                <PayMembershipFee
                  paymentLink={paymentLink?.authorization_url}
                  paymentData={paymentLink}
                />
              )}
            </div>

            {/* Buttons  */}
            {steps[step - 1] !== "Membership Fee" && (
              <div
                className="
                w-full flex flex-row 
                gap-[12px]
                mt-[32px] 
                flex-1
                items-end
                    "
              >
                {step > 1 && step <= steps?.length && (
                  <Button
                    text="Previous"
                    onClick={() => {
                      setStep(step - 1);
                    }}
                    type="button"
                    className=""
                    loading={false}
                    disabled={loading}
                    variation="secondary"
                  />
                )}
                {(step < steps?.length || steps?.length === 1) && (
                  <Button
                    text="Next"
                    onClick={handleNext}
                    type="button"
                    className=""
                    loading={loading}
                    disabled={loading}
                    variation="primary"
                  />
                )}
              </div>
            )}
          </ContainerCard>
        )}
      </div>

      <CloseMembershipFormModal
        modalOpen={modalOpen && modal === "closeMembershipFormModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        cooperativeName={singleCooperative?.CoopName}
      />
    </ScreenOverlay>
  );
};

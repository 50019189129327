import React from "react";
import { getToastColor } from "../../helperFunctions/nofitication";
import { Icon } from "./icon";

export const ToastIcon = ({ theme, type }) => {
  return (
    <div
      className="
    bg-white
    border border-border-grey
    rounded-full
    p-1
    "
    >
      <div
        className={`
    ${getToastColor(type)}
    border !border-transparent
    rounded-full
    p-[6px]
    `}
      >
        <Icon svg={`toast-${type}-icon`} width={28} height={28} />
      </div>
    </div>
  );
};

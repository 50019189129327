import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppLayout } from "../components/layouts/appLayout";
import { getCookie } from "../helperFunctions/cookies";

export const PrivateRoute = () => {
  const token = getCookie("_tC_token");

  const isAuthenticated = token ? true : false;

  if (!isAuthenticated && window.location.pathname.includes("/app")) {
    localStorage.setItem("resumeLink", window.location?.pathname);
    return <Navigate to="/auth/sign-in" replace />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

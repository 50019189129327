import React from "react";
import paystack from "../../assets/images/paystack.svg";

export const PaymentMethods = ({ setPaymentMethod, handleProceed }) => {
  const className = `
    p-4 border border-pry-dark-green-300/10
    bg-light-green-bg
    rounded-[8px]
    hover:border-pry-dark-green-300/50
    transition-all duration-500
    cursor-pointer
    `;

  const handleClick = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    handleProceed();
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <div
        className={className}
        onClick={() => {
          handleClick("paystack");
        }}
      >
        <img src={paystack} alt="paystack" />
      </div>

      <div
        className={`${className} 
            hover:!pl-4 hover:!scale-100 
            hover:!border-pry-dark-green-300/10
            flex gap-4 flex-wrap items-center justify-between
        `}
      >
        <div className="text-base font-bold">ThriftCorp Wallet</div>
        <div className="font-medium opacity-40">Coming soon</div>
      </div>
    </div>
  );
};

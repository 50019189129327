import React, { useState } from "react";
import { TextLink } from "../../../components/buttons/textLink";
import { ContainerCard } from "../../../components/cards/containerCard";
import { CooperativeMemberCount } from "../../../components/cards/cooperativeMemberCount";
import { Icon } from "../../../components/icons/icon";
import { ImageViewModal } from "../../../components/modals/app_modals/imageViewModal";
import { CooperativeProfilePic } from "../../../components/profile/cooperativePicture";
import { decodeUrl } from "../../../helperFunctions";

export const CooperativeOverview = ({ singleCooperative }) => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [link, setLink] = useState("");

  return (
    <div className="w-full">
      <ContainerCard
        className="
        flex flex-col md:flex-row 
        items-center justify-center
        gap-8
    "
      >
        <div
          className="
            flex flex-col
            items-center justify-center
            w-full max-w-[384px]
        "
        >
          <CooperativeProfilePic
            cooperative={singleCooperative}
            className="!w-[80px] !h-[80px]"
          />
          <div className="mt-4 text-lg font-medium text-center">
            {singleCooperative?.CoopName}
          </div>
          <CooperativeMemberCount
            cooperative={singleCooperative}
            className="!text-pry-dark-green-300 !bg-light-purple !mt-3"
          />
        </div>

        <div
          className="
            flex flex-col gap-6
            flex-grow border-t-[1.5px] md:border-t-0 md:border-l-[1.5px]
            min-w-[300px] w-full md:w-fit
            py-6 md:p-[56px]
        "
        >
          <div>
            <div className="text-sm font-bold">Description</div>
            <div className="mt-1 text-sm opacity-80">
              {singleCooperative?.description
                ? singleCooperative?.description
                : "No description"}
            </div>
          </div>

          <div>
            <div className="text-sm font-bold">Email Address</div>
            <div className="mt-1 text-sm opacity-80">
              {singleCooperative?.CoopEmail}
            </div>
          </div>

          <div>
            <div className="text-sm font-bold">Registration Number</div>
            <div className="mt-1 text-sm opacity-80">
              {singleCooperative?.CoopRegistration}
            </div>
          </div>

          <div>
            <div className="text-sm font-bold">Bye Law</div>

            <div
              className="
            mt-1 text-sm text-pry-green-200 font-medium cursor-pointer
            flex items-center gap-1
            "
            >
              <Icon svg="small-document-icon" width={16} height={16} />{" "}
              <TextLink
                text="View cooperative bye law"
                onClick={() => {
                  setLink(decodeUrl(singleCooperative?.byelaw));
                  setModal("viewImageModal");
                  setModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </ContainerCard>

      <ImageViewModal
        modalOpen={modalOpen && modal === "viewImageModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        src={link}
      />
    </div>
  );
};

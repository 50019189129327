import React from "react";
import { BackButton } from "../buttons/backButton";
import { PageTitle } from "../typography/pageTitle";

export const PageHeader = ({
  pageTitle,
  hasBack,
  onBackClick,
  hasRightChildren = false,
  rightChildren,
  rightChildrenClassName,
  hasLeftChildren = false,
  leftChildren,
  className,
  pageTitleClassName,
}) => {
  return (
    <div
      className={`
    w-full flex flex-row flex-wrap 
    items-center justify-between
    gap-6
    ${className}
    `}
    >
      <div
        className="
      flex flex-row
      items-center gap-6
      "
      >
        {hasBack && (
          <BackButton text="Back" onClick={onBackClick} className="" />
        )}
        {pageTitle && (
          <PageTitle text={pageTitle} className={`${pageTitleClassName}`} />
        )}
        {hasLeftChildren && leftChildren}
      </div>
      {hasRightChildren && (
        <div className={`w-fit md:w-max ${rightChildrenClassName}`}>
          {rightChildren}
        </div>
      )}
    </div>
  );
};

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import empty_bank_state from "../../../../assets/images/empty_state_bank.svg";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { DebitCard } from "../../../../components/cards/debitcard";
import { InformationCard } from "../../../../components/cards/infoCard";
import { Icon } from "../../../../components/icons/icon";
import { PageLoader } from "../../../../components/loaders/pageLoader";
import { AddBankAccountModal } from "../../../../components/modals/app_modals/AddBankAccountModal";
import { CardTokenizationModal } from "../../../../components/modals/app_modals/cardTokenizationModal";
import { ConfirmationModal } from "../../../../components/modals/app_modals/confirmationModal";
import { EmptyState } from "../../../../components/states/emptyState";
import { TitleSub } from "../../../../components/typography/titleSub";
import { usePayment } from "../../../../customHooks/usePayment";

const SingleBankComponent = ({
  accountName,
  accountNumber,
  bankName,
  canDelete = false,
  onDelete = () => {},
  isDeleting = false,
  disableDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <div
      className="
            flex flex-row flex-wrap
            gap-2 justify-between items-center
            py-[16px] px-[24px]
            rounded-[8px] bg-light-green-bg
            bg-[url('assets/images/card_bg.svg')]
            bg-no-repeat
            bg-cover md:bg-contain bg-center
            "
    >
      <div
        className="
            flex flex-row flex-wrap md:flex-nowrap flex-0
            gap-[24px]
            items-center
            w-full
            "
      >
        <Icon
          svg="bank-colored"
          className={"w-[32px] h-[32px] md:w-[28px] md:h-[48px]"}
        />
        <div className="flex flex-grow gap-5 items-center justify-between">
          <div className="flex flex-col gap-1">
            <div className="text-sm md:text-base font-medium opacity-80">
              {accountNumber}
            </div>
            <div className="text-xs md:text-sm opacity-80">{accountName}</div>
            <div className="text-xs md:text-sm opacity-50">{bankName}</div>
          </div>

          {canDelete && !isDeleting && (
            <div
              className="
              bg-white p-2 rounded-full text-red-200 
              cursor-pointer border
              hover:scale-[0.95]
              hover:bg-lighter-red-bg
              hover:border-red-200
              transition-all duration-300
              "
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            >
              <Icon svg={"delete-icon"} width={20} height={20} className={""} />
            </div>
          )}
          {canDelete && isDeleting && (
            <PageLoader
              variant="spinner"
              spinnerColor="#EF4444"
              className="!min-h-fit !w-fit"
            />
          )}
        </div>
      </div>

      <ConfirmationModal
        isModalOpen={deleteModalOpen}
        onModalClose={() => {
          setDeleteModalOpen(false);
        }}
        title={"Confirm account deletion"}
        children={undefined}
        subtitle={
          "Are you sure you want to delete your account? This action cannot be undone."
        }
        onProceed={disableDelete ? () => {} : onDelete}
        loading={isDeleting}
        proceedButtonText="Proceed"
      />
    </div>
  );
};

SingleBankComponent.propTypes = {
  accountName: PropTypes.any.isRequired,
  accountNumber: PropTypes.any.isRequired,
  bankName: PropTypes.any.isRequired,
  canDelete: PropTypes.bool.isRequired,
  disableDelete: PropTypes.any,
  isDeleting: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

export const BankAccount = () => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const [deletingAccountId, setDeletingAccountId] = useState(-1);
  const [deletingCardId, setDeletingCardId] = useState(-1);

  const { profile, memberBanks } = useSelector((state) => {
    return state?.auth;
  });

  const isBankAccountComplete = useSelector((state) => {
    return state?.auth?.onboardingCompletionStatus?.isBankDetailsComplete;
  });

  const {
    fetchMemberBankDetails,
    fetchUserCards,
    deleteCard,
    deleteMemberBankDetail,
  } = usePayment();

  const userCards = useQuery(["fetchUserCards"], fetchUserCards, {
    enabled: false,
  });

  useEffect(() => {
    profile?.type === "member" && fetchMemberBankDetails();
    profile?.type === "member" && userCards?.refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="
      flex flex-row flex-wrap
      w-full
      gap-2 md:gap-6
    "
    >
      <ContainerCard
        className="
        w-full max-w-[653px] min-h-[635px]
        order-last width-lim:order-first
        "
      >
        <div className="flex flex-wrap items-center justify-between">
          <TitleSub
            title="Bank Account Details"
            subtitle={
              profile?.type === "cooperative"
                ? "Bank Account Linked to your Cooperative Account"
                : "Add your personal bank account details"
            }
            className={`w-fit max-w-[50%] md:max-w-max`}
          />

          <Button
            text="+ Add account"
            onClick={() => {
              setModal("addBankAccountModal");
              setModalOpen(true);
            }}
            type="button"
            className="!p-2 md:!p-2 !max-w-max md:!max-w-[128px]"
            loading={false}
            disabled={false}
            variation="primary"
          />
        </div>

        {isBankAccountComplete ? (
          <div
            className="
          mt-[32px]
          w-full
          "
          >
            {profile?.type === "cooperative" ? (
              <SingleBankComponent
                accountName={profile?.AccountName}
                accountNumber={profile?.AccountNumber}
                bankName={profile?.BankName}
                canDelete={false}
                onDelete={() => {}}
              />
            ) : (
              <div>
                <div className="flex flex-col gap-4">
                  {memberBanks?.map((item, idx) => {
                    const key = `bank-info-${idx}`;

                    return (
                      <SingleBankComponent
                        accountName={item?.AccountName}
                        accountNumber={item?.AccountNumber}
                        bankName={item?.BankName}
                        disableDelete={isDeletingAccount}
                        isDeleting={
                          isDeletingAccount && deletingAccountId === item?.id
                        }
                        canDelete
                        onDelete={() => {
                          setIsDeletingAccount(true);
                          setDeletingAccountId(item?.id);
                          deleteMemberBankDetail(
                            {
                              id: item?.id,
                            },
                            () => {
                              setIsDeletingAccount(false);
                              setDeletingAccountId(-1);
                            },
                            () => {
                              fetchMemberBankDetails();
                            }
                          );
                        }}
                        key={key}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <EmptyState
            image={
              <img
                src={empty_bank_state}
                alt="empty_state"
                className="max-w-[150px]"
              />
            }
            title="You have not added a bank account"
            subtitle={
              profile?.type === "cooperative"
                ? "Add a bank account that is specifically used for your cooperative’s financial activities"
                : "Kindly ensure you add your personal bank account. This account will be used for withdrawals, Loans etc. "
            }
            className={`my-[50px] md:my-[100px]`}
          />
        )}

        {profile?.type === "member" && <hr className="my-8" />}

        {/* Cards  */}
        {profile?.type === "member" && (
          <div>
            <div className="flex flex-wrap items-center justify-between">
              <TitleSub
                title="My Cards"
                subtitle={`Add your bank cards`}
                className={`w-fit`}
              />

              <Button
                text="+ Add card"
                onClick={() => {
                  setModal("linkCardModal");
                  setModalOpen(true);
                }}
                type="button"
                className="!p-2 md:!p-2 !max-w-max md:!max-w-[128px]"
                loading={false}
                disabled={false}
                variation="primary"
              />
            </div>

            {/* Card list  */}
            <div className="mt-8">
              {userCards?.isFetching ? (
                <PageLoader />
              ) : (
                <div className="">
                  {userCards?.data?.length <= 0 ? (
                    <EmptyState
                      image={
                        <img
                          src={empty_bank_state}
                          alt="empty_state"
                          className="max-w-[150px]"
                        />
                      }
                      title={"You have not linked any cards"}
                      className={`my-[50px] md:my-[100px]`}
                    />
                  ) : (
                    <div className="flex flex-col gap-3">
                      {userCards?.data?.map((card, idx) => {
                        const key = `userCard-${idx}`;

                        return (
                          <DebitCard
                            key={key}
                            cardNumber={card?.CardDetail?.last4}
                            expiryDate={`${card?.CardDetail?.exp_month}/${card?.CardDetail?.exp_year}`}
                            type={card?.CardDetail?.card_type}
                            onClick={() => {}}
                            selectable={false}
                            disableDelete={isDeletingAccount}
                            isDeleting={
                              isDeletingCard && deletingCardId === card?.id
                            }
                            canDelete
                            onDelete={() => {
                              setIsDeletingCard(true);
                              setDeletingCardId(card?.id);
                              deleteCard(
                                {
                                  id: card?.id,
                                },
                                () => {
                                  setIsDeletingCard(false);
                                  setDeletingCardId(-1);
                                },
                                () => {
                                  userCards?.refetch();
                                }
                              );
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </ContainerCard>
      <InformationCard
        info={
          isBankAccountComplete && profile?.type === "cooperative" ? (
            <div>
              To change your{" "}
              <span className="text-pry-green-200 font-medium">
                cooperative’s bank account
              </span>
              , please reach out to support.
            </div>
          ) : profile?.type === "cooperative" ? (
            "Kindly ensure you add the bank account that is tied to your cooperative financial activities"
          ) : (
            "Kindly ensure you add your personal bank account. This account will be used for withdrawals, Loans etc. "
          )
        }
        className="!max-w-[345px] h-fit width-lim:sticky-top"
      />

      <AddBankAccountModal
        modalOpen={modalOpen && modal === "addBankAccountModal"}
        onModalClose={() => {
          setModalOpen(false);
          setModal("");
        }}
      />

      <CardTokenizationModal
        modalOpen={modalOpen && modal === "linkCardModal"}
        handleClose={() => {
          setModalOpen(false);
          setModal("");
        }}
        callback={() => {
          userCards?.refetch();
        }}
      />
    </div>
  );
};

import axios from "axios";
import { getApiUrl } from ".";
import { logoutUtil } from "../customHooks/useAuth";
import { getCookie } from "../helperFunctions/cookies";
import { doNotification } from "../helperFunctions/nofitication";
import { store } from "../redux/store";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401 && !error.response.config.url.includes("token")) {
      // If unauthorized, logout and require login again
      doNotification(
        "Expired token. Please log in again to continue.",
        "warning"
      );

      logoutUtil(store.dispatch, null, true, window.location.replace);
    }
    // else {
    //   // Handle notification for other errors
    //   const errorData = error?.response?.data;
    //   const errorMessage = parseErrorMessage(errorData);
    //   doNotification(errorMessage, "error");
    // }

    return Promise.reject(error);
  }
);

export const getRequest = async (url, params, isAuthorizedRequest = true) => {
  const token = isAuthorizedRequest
    ? getCookie("_tC_token")
    : getCookie("_tC_token");

  const { data } = await axios.get(`${getApiUrl()}/${url}`, {
    params,
    headers: {
      Authorization: isAuthorizedRequest ? `Bearer ${token}` : undefined,
    },
  });

  return data;
};

export const postRequest = async (url, payload, isAuthorizedRequest = true) => {
  const token = isAuthorizedRequest ? getCookie("_tC_token") : undefined;

  const { data } = await axios.post(`${getApiUrl()}/${url}`, payload, {
    headers: {
      Authorization: isAuthorizedRequest ? `Bearer ${token}` : undefined,
    },
  });

  return data;
};

export const putRequest = async (url, payload, isAuthorizedRequest = true) => {
  const token = isAuthorizedRequest ? getCookie("_tC_token") : undefined;

  const { data } = await axios.put(`${getApiUrl()}/${url}`, payload, {
    headers: {
      Authorization: isAuthorizedRequest ? `Bearer ${token}` : undefined,
    },
  });

  return data;
};

export const deleteRequest = async (
  url,
  payload,
  isAuthorizedRequest = true
) => {
  const token = isAuthorizedRequest ? getCookie("_tC_token") : undefined;

  const { data } = await axios.delete(`${getApiUrl()}/${url}`, {
    headers: {
      Authorization: isAuthorizedRequest ? `Bearer ${token}` : undefined,
    },
    data: payload,
  });

  return data;
};

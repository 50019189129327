import { useFormik } from "formik";
import React, { useEffect } from "react";
import { FileUploadInput } from "../../../components/inputs/fileUploadInput";
import { TextInput } from "../../../components/inputs/textInput";
import { getBase64 } from "../../../helperFunctions";

export const MembershipForm = ({ cooperativeDetails, setMembershipForm }) => {
  const formDetails = cooperativeDetails?.formdetail;
  const fields = formDetails?.Field;

  let initialValues = {};
  for (let i = 0; i < fields?.length; i++) {
    let currField = fields?.at(i);
    let objectKey = currField?.question?.replaceAll(" ", "____") || "nothing";
    initialValues = {
      ...initialValues,
      [String(objectKey)]: currField?.fieldType === "file" ? [[], ""] : "",
    };
  }

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: undefined,
    onSubmit: (data) => {
      // Doesn't really do anything
      console.log(data);
    },
  });

  useEffect(() => {
    // Whenever values tracked by formik changes, update form data
    const membershipForm = Object.keys(values)?.map((key) => {
      // Get the field object of the values currently being iterated on
      const singleField = fields?.filter(
        (field) => field?.question === key?.replaceAll("____", " ")
      );
      return {
        // Get id of field being answered
        id: singleField?.length > 0 && singleField[0]?.id,
        // Populate answer for field
        answer:
          // For file answers
          singleField?.length > 0 && singleField[0]?.fieldType === "file"
            ? {
                fileType: values?.[key][0][0]?.name?.split(".")[1],
                fileName: values?.[key][0][0]?.name?.split(".")[0],
                fileBase64: values?.[key][1],
              }
            : values?.[key], // For other text answers
      };
    });
    setMembershipForm(membershipForm);
    // eslint-disable-next-line
  }, [values]);

  return (
    <div className="flex flex-col gap-4">
      {fields?.map((field, idx) => {
        const key = `formField-${idx}`;
        if (field?.fieldType === "file") {
          return (
            <div key={key}>
              <div
                className="
                    text-sm 
                    text-pry-dark-green-300/80 font-medium
                    mb-1
                    "
              >
                {field?.question} *
              </div>
              {/* File values are stored as [file, base64String]  */}
              <FileUploadInput
                maxNumberOfFiles={1}
                selectedFiles={
                  values?.[field?.question?.replaceAll(" ", "____")] &&
                  values?.[field?.question?.replaceAll(" ", "____")]?.length > 0
                    ? values?.[field?.question?.replaceAll(" ", "____")][0]
                    : ""
                }
                handleChange={(files) => {
                  const fileList = Object.values(files);
                  getBase64(fileList[0]).then((resp) => {
                    setFieldValue(field?.question?.replaceAll(" ", "____"), [
                      fileList,
                      resp,
                    ]);
                  });
                }}
                setSelectedFiles={(files) => {
                  const fileList = Object.values(files);
                  getBase64(fileList[0]).then((resp) => {
                    setFieldValue(field?.question?.replaceAll(" ", "____"), [
                      fileList,
                      resp,
                    ]);
                  });
                }}
              />
            </div>
          );
        } else {
          return (
            <TextInput
              type={field?.fieldType}
              name={field?.question?.replaceAll(" ", "____")}
              value={values?.[field?.question?.replaceAll(" ", "____")]}
              placeholder={field?.question}
              onChange={handleChange}
              onBlur={() => {}}
              hasIcon={false}
              iconSvg={undefined}
              errors={undefined}
              rows="3"
              inputClass=""
              className=""
              containerClassName=""
              customIcon={undefined}
              hasTitle
              title={`${field?.question} *`}
              key={key}
              mobileScaleDown
            />
          );
        }
      })}
    </div>
  );
};

import React from "react";
import { useSearchParams } from "react-router-dom";
import success from "../../../assets/images/success.svg";

export const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  return (
    <div
      className="
    h-screen w-full
    flex flex-col
    items-center justify-center
    "
    >
      <img src={success} alt="success" />
      <div className="text-xl font-bold mt-4">Payment Successful</div>
      {searchParams.get("message") && (
        <div className="w-full max-w-[309px] text-center text-sm mt-1 opacity-80">
          {searchParams.get("message")?.replaceAll("_", " ")}
        </div>
      )}
      {searchParams.get("mode") !== "default" && (
        <div className="w-full max-w-[309px] text-center text-sm mt-1 opacity-80">
          You’ve successfully paid{" "}
          <span className="font-medium text-pry-green-200 opacity-100">
            {searchParams?.get("amount")}
          </span>{" "}
          as a membership fee for{" "}
          <span className="font-medium text-pry-green-200 opacity-100">
            {searchParams?.get("coopName")}
          </span>
          . You’ll be redirected to the cooperative page
        </div>
      )}
    </div>
  );
};

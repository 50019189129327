import React from "react";

export const TimeText = ({
  timeString,
  dateClassName,
  timeClassName,
  showTime = true,
}) => {
  const date = new Date(timeString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div>
      <span>
        {date?.getDate()} {months[date?.getMonth()]}, {date?.getFullYear()}
      </span>{" "}
      {showTime && (
        <span className="opacity-40 text-[10px]">
          {date?.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      )}
    </div>
  );
};

import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { usePayment } from "../../../customHooks/usePayment";
import { sleep } from "../../../helperFunctions";

export const PayMembershipFee = ({ paymentLink, paymentData }) => {
  const [isResourceLoaded, setIsResourceLoaded] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { verifyRegistrationPayment } = usePayment();

  return (
    <div
      className="
        w-full h-full overflow-scroll
        flex-grow items-center justify-center
        "
    >
      {!isResourceLoaded && <PageLoader />}

      <iframe
        src={paymentLink}
        title="Membership fee payment"
        onLoadStart={() => {
          setIsResourceLoaded(false);
        }}
        onLoad={(e) => {
          setIsResourceLoaded(true);
          try {
            const params = new URLSearchParams(
              e?.target?.contentWindow?.location?.href
            );
            const verificationPayload = {
              coop: paymentData?.Cooperative,
              corp: paymentData?.Cooperative,
              trxref: params?.get("trxref"),
              reference: params?.get("reference"),
              regPaymentId: paymentData?.id,
            };
            // Verify concluded payment
            verifyRegistrationPayment(
              verificationPayload,
              () => {},
              () => {
                sleep(2000)?.then(() => {
                  navigate("/app/my-cooperatives");
                });
              }
            );
            // Accept invitation if mode is via invitation
            const mode = searchParams.get("mode");
            const invitationId = searchParams.get("invitationId");
            console.log(mode, invitationId);
            // -------------------------------------------------
          } catch {
            console.log("cross-origin");
          }
        }}
        className={
          !isResourceLoaded ? "hidden" : "flex w-full !h-full rounded-lg"
        }
      ></iframe>
    </div>
  );
};

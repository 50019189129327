import React, { useEffect, useState } from "react";
import { Icon } from "../icons/icon";
import { ErrorText } from "../typography/errorText";

export const TextInputDropDown = ({
  type = "text",
  name,
  value,
  placeholder = "Placeholder",
  onChange,
  onBlur,
  hasIcon = true,
  icon,
  iconSvg,
  errors,
  inputClass = "",
  className = "",
  initialDropdownSelected = {},
  dropdownPlaceholder = "",
  dropdownOptions = [] /**[{id: "", title: "", value: ""}] */,
  onDropdownSelect = () => {},
  dropdownOptionsClassName = "",
  ...inputProps
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(
    initialDropdownSelected
  );

  const inputClassName = `
  bg-transparent w-full
  outline-none focus:outline-none
  placeholder:text-pry-dark-green-300/50
  autofill:focus:bg-transparent autofill:bg-transparent
  ${inputClass}
  `;

  useEffect(() => {
    setDropdownSelected(initialDropdownSelected);
  }, [initialDropdownSelected]);

  return (
    <div>
      <div
        className={`w-full
        px-[17px] py-[14px]
        flex flew-row items-center gap-4
        text-sm font-medium
        bg-light-green-bg
        border rounded-lg border-transparent
        focus-within:border-pry-green-200
        ${errors && "focus-within:!border-red-200 !bg-light-red-bg"}
        transition-all
        ${className}
        `}
      >
        {/* Icon element  */}
        {hasIcon && (
          <Icon svg={icon} width={20} height={20} className="w-[24px]" />
        )}

        {/* Input element  */}
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={inputClassName}
          {...inputProps}
        />

        {/* Dropdown element  */}
        <div className="w-fit relative">
          <div
            className="
          !w-max 
          cursor-pointer
          text-sm font-medium
          flex flex-row
          items-center
          gap-1
          text-pry-green-200
          "
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <div
              className={
                dropdownSelected?.title &&
                `
            text-white
            bg-pry-green-200
            rounded-full
            px-3 py-0.5
            overflow-hidden
            max-w-[200px] whitespace-nowrap text-ellipsis
            capitalize
            `
              }
            >
              {dropdownSelected?.title
                ? dropdownSelected?.title
                : dropdownPlaceholder}
            </div>
            <Icon
              svg="caret-down"
              width={16}
              height={16}
              className={`
            ${dropdownOpen && "rotate-180"} transition-all duration-500
            `}
            />
          </div>

          {/* Dropdown choices */}
          {dropdownOpen && (
            <div
              className={`
            absolute
            top-[120%] right-0
            bg-white border border-border-grey
            py-2 px-2
            rounded-lg
            w-max max-w-[270px]
            break-all text-ellipsis
            ani-drop
            z-[40]
            ${dropdownOptionsClassName}
            `}
            >
              {dropdownOptions.map((item, idx) => {
                const key = `dropdownitem-${idx}`;
                return (
                  <div
                    key={key}
                    className="
                    p-3 px-4 cursor-pointer
                    hover:rounded-lg hover:border-b-0
                    hover:bg-light-green-bg capitalize
                    border-b-0 last:border-b-0
                    transition-all
                    font-normal
                    "
                    onClick={() => {
                      onDropdownSelect(item);
                      setDropdownSelected(item);
                      setDropdownOpen(false);
                    }}
                  >
                    {item?.title}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {errors && <ErrorText text={errors} />}
    </div>
  );
};

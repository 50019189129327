import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../../components/icons/icon";
import { PageHeader } from "../../../components/layouts/pageHeader";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { TabNav } from "../../../components/navs/tabNav";
import { useCooperative } from "../../../customHooks/useCooperative";
import { CooperativeDeposits } from "./cooperativeDeposit";
import { CooperativeFinancials } from "./cooperativeFinances";
import { CooperativeLoans } from "./cooperativeLoans";
import { CooperativeMembers } from "./cooperativeMembers";
import { CooperativeOverview } from "./cooperativeOverview";
import { CooperativeSavings } from "./cooperativeSavings";
import { CooperativeShares } from "./cooperativeShares";

const hideTabNavs = ["overview", "financials"];

export const SingleMyCooperative = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [mode, setMode] = useState("personal");
  const [showTab, setShowTab] = useState(false);
  const [navs, setNavs] = useState([]);
  const [isSharesActive, setIsSharesActive] = useState(false);
  const [isDepositsActive, setIsDepositsActive] = useState(false);

  const {
    fetchingSingleCooperativeDetails,
    singleCooperative,
    fetchingMembershipDetails,
    myCooperativePermissions,
  } = useSelector((state) => {
    return state?.app;
  });

  const modeNavs = [
    {
      title: "Personal",
      to: "personal",
      onClick: setMode,
    },
    {
      title: "Admin",
      to: "admin",
      onClick: setMode,
    },
  ];
  const param = useParams();

  const navigate = useNavigate();

  const { fetchSingleCooperativeDetails, fetchMyCooperativeMemberDetails } =
    useCooperative();

  useEffect(() => {
    fetchSingleCooperativeDetails({ id: param?.id });
    fetchMyCooperativeMemberDetails({ coop: param?.id, Permission: "true" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    myCooperativePermissions?.length < 1 && setMode("personal");
  }, [myCooperativePermissions, mode]);

  useEffect(() => {
    setShowTab(
      myCooperativePermissions?.length && !hideTabNavs.includes(activeTab) > 0
    );
  }, [myCooperativePermissions, activeTab]);

  useEffect(() => {
    const sharesActive =
      singleCooperative?.Settings?.share?.InitialSharePrice &&
      singleCooperative?.Settings?.share?.MinShares &&
      singleCooperative?.Settings?.share?.InterestRate;

    const depositsActive =
      singleCooperative?.Settings?.deposit?.InterestRate &&
      singleCooperative?.Settings?.deposit?.NumberOfDaysToWithdrawal;

    setIsSharesActive(sharesActive);
    setIsDepositsActive(depositsActive);

    setNavs([
      {
        title: "Overview",
        to: "overview",
        onClick: setActiveTab,
      },
      {
        title: "Savings",
        to: "savings",
        onClick: setActiveTab,
      },
      depositsActive
        ? {
            title: "Deposits",
            to: "deposits",
            onClick: setActiveTab,
          }
        : undefined,
      sharesActive
        ? {
            title: "Shares",
            to: "shares",
            onClick: setActiveTab,
          }
        : undefined,
      {
        title: "Loans",
        to: "loans",
        onClick: setActiveTab,
      },
      {
        title: "Members",
        to: "members",
        onClick: setActiveTab,
      },
      myCooperativePermissions?.length > 0
        ? {
            title: "Finances",
            to: "financials",
            onClick: setActiveTab,
          }
        : undefined,
    ]);
  }, [singleCooperative, myCooperativePermissions]);

  return (
    <div>
      {fetchingSingleCooperativeDetails || fetchingMembershipDetails ? (
        <PageLoader />
      ) : (
        <div>
          {/* Page header/title  */}
          <PageHeader
            pageTitle=""
            className={`${!showTab && "!gap-0"} transition-all duration-500`}
            hasBack={false}
            onBackClick={() => {}}
            hasRightChildren
            rightChildren={
              <TabNav
                navs={modeNavs}
                initialActiveTab={mode}
                className={``}
                variant="switcher"
              />
            }
            rightChildrenClassName={`!w-full md:!w-max !scale-y-0 !max-h-0 !overflow-hidden ${
              showTab && "!scale-y-100 !max-h-[200px]"
            } !transition-all duration-[1000ms]`}
            hasLeftChildren
            leftChildren={
              <div
                className="
                text-pry-green-200 font-medium text-base
                flex flex-row flex-wrap
                gap-1
                items-center
                "
              >
                <div
                  className="
                    text-pry-dark-green-300/50
                    cursor-pointer
                    hover:scale-95
                    transition-all duration-500
                    "
                  onClick={() => {
                    navigate("/app/my-cooperatives");
                  }}
                >
                  My Cooperatives
                </div>
                <Icon
                  svg="left-caret-icon"
                  width={24}
                  height={24}
                  className="rotate-180"
                />
                <div>{singleCooperative?.CoopName}</div>
              </div>
            }
          />

          {/* Tab navigation  */}
          <TabNav
            navs={navs}
            className="mt-7 w-full md:w-fit overflow-scroll"
            initialActiveTab={activeTab}
          />

          {/* Page sections  */}
          <div className="mt-6 w-full ">
            {activeTab === "overview" && (
              <CooperativeOverview singleCooperative={singleCooperative} />
            )}
            {activeTab === "members" && (
              <CooperativeMembers
                singleCooperative={singleCooperative}
                mode={mode}
              />
            )}
            {activeTab === "savings" && (
              <CooperativeSavings
                singleCooperative={singleCooperative}
                mode={mode}
              />
            )}
            {activeTab === "deposits" && (
              <CooperativeDeposits
                singleCooperative={singleCooperative}
                mode={mode}
                isDepositActive={isDepositsActive}
              />
            )}
            {activeTab === "shares" && (
              <CooperativeShares
                singleCooperative={singleCooperative}
                mode={mode}
                isSharesActive={isSharesActive}
              />
            )}
            {activeTab === "loans" && (
              <CooperativeLoans
                singleCooperative={singleCooperative}
                mode={mode}
              />
            )}
            {activeTab === "financials" &&
              myCooperativePermissions?.length > 0 && (
                <CooperativeFinancials
                  singleCooperative={singleCooperative}
                  mode={mode}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

import React, { useState } from "react";
import { ModalComponent } from "..";
import { PageLoader } from "../../../components/loaders/pageLoader";

export const PaymentModal = ({
  paymentData,
  handleVerification = () => {},
  isModalOpen,
  onModalClose = () => {},
  callback = () => {},
}) => {
  const [isResourceLoaded, setIsResourceLoaded] = useState(false);

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        handleVerification();
        onModalClose();
        callback();
      }}
      hasButtons={false}
      modalClassName="!max-h-[500px] !overflow-scroll"
      childrenClassName="h-full !mt-[8px]"
    >
      <div
        className="
        w-full h-full overflow-scroll
        flex-grow items-center justify-center
        "
      >
        {!isResourceLoaded && <PageLoader />}

        <iframe
          src={paymentData?.authorization_url}
          title="Payment modal"
          onLoadStart={() => {
            // This part doen't work sort of
            setIsResourceLoaded(false);
            console.log("loading");
          }}
          onLoad={(e) => {
            setIsResourceLoaded(true);
            try {
              console.log(e?.target?.contentWindow?.src);
              console.log(e?.target?.contentDocument?.src);
              console.log(e?.target?.contentDocument?.location);
              // eslint-disable-next-line
              const url = e?.target?.contentDocument?.location?.href;

              // Verify concluded payment
              url?.includes("success") && handleVerification();
            } catch {
              console.log("cross-origin");
            }
          }}
          className={
            !isResourceLoaded
              ? "opacity-0 w-0 h-0 overflow-hidden"
              : "flex w-full !h-full rounded-lg"
          }
        ></iframe>
      </div>
    </ModalComponent>
  );
};

import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

export const WebsiteDoubleSection = ({
  leftClassName = "",
  rightClassName = "",
  className = "",
  leftChild,
  rightChild,
}) => {
  useEffect(() => {
    AOS.refresh();
  }, []);

  return (
    <div className={`flex flex-col lg:flex-row items-center ${className}`}>
      <div
        className={`flex h-full w-full lg:w-6/12 justify-end ${leftClassName}`}
      >
        <div
          className={`
            w-full h-full
            lg:max-w-max-1024-half
            width-lim:max-w-website-max-half width-lim-xl:max-w-website-max-xl-half
            !relative
            `}
        >
          {leftChild}
        </div>
      </div>
      <div
        className={`flex h-full w-full lg:w-6/12 justify-start ${rightClassName}`}
      >
        <div
          className={`
            w-full h-full
            lg:max-w-max-1024-half
            width-lim:max-w-website-max-half width-lim-xl:max-w-website-max-xl-half
            `}
        >
          {rightChild}
        </div>
      </div>
    </div>
  );
};

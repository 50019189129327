import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Icon } from "../icons/icon";

// Search function outside component to prevent re-render
const handleSearch = (searchFunction, query) => {
  searchFunction(query);
};

// Convert handleSearch function to a debounced function
const handleDebouncedSearch = debounce(handleSearch, 800);

export const FilterComponent = ({
  searchPlaceholder,
  hasExtraFilter = true,
  handleSearch = () => {},
  className,
  mainClassName = "",
  size = "default", // default | small
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchTyped, setIsSearchTyped] = useState(false);
  const [showOtherFilters, setShowOtherFilters] = useState(false);

  useEffect(() => {
    isSearchTyped && handleDebouncedSearch(handleSearch, searchValue);
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <div className={`${mainClassName}`}>
      <div
        className="
        flex flex-row flex-wrap
        items-center 
        gap-4
        "
      >
        <form
          className={`
            flex flex-row
            gap-4
            px-4 py-3
            w-full min-w-[200px] max-w-[86%] ${!hasExtraFilter && "!max-w-full"}
            bg-white
            border border-pry-dark-green-300/10
            rounded-lg
            ${className}
            `}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Icon
            svg="search-icon"
            width={size === "small" ? 16 : 20}
            height={size === "small" ? 16 : 20}
          />
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e?.target?.value);
              setIsSearchTyped(true);
            }}
            autoComplete="off"
            className={`
                autofill:focus:bg-transparent autofill:bg-transparent
                outline-none focus:outline-none
                bg-transparent w-full
                text-sm font-medium
                ${size === "small" && "!text-xs"}
            `}
            placeholder={searchPlaceholder}
          />
        </form>
        {hasExtraFilter && (
          <div
            className="
                text-base text-pry-green-200
                font-medium
                flex flex-row items-center
                gap-2
                cursor-pointer
                "
            onClick={() => {
              setShowOtherFilters(!showOtherFilters);
            }}
          >
            <Icon svg="filter-icon" width={20} height={20} />
            {showOtherFilters ? "Hide Filter" : "Show Filter"}
          </div>
        )}
      </div>

      {showOtherFilters && <div className="mt-[24px]">other filters</div>}
    </div>
  );
};

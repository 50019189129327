import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../icons/icon";

const NavItem = ({ parentRef, children, onClick = () => {}, className }) => {
  const ref = useRef(null);

  return (
    <div
      ref={ref}
      onClick={() => {
        if (ref?.current && parentRef?.current) {
          const relativeLeft =
            ref.current.getBoundingClientRect().left -
            parentRef.current.getBoundingClientRect().left +
            parentRef.current.scrollLeft;

          // const relativeRight =
          //   parentRef.current.getBoundingClientRect().right +
          //   parentRef.current.scrollLeft -
          //   ref.current.getBoundingClientRect().right;

          parentRef.current.scrollLeft = relativeLeft - 32;
        }

        onClick();
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export const TabSelector = ({
  className,
  navs,
  initialActiveTab = "",
  initialActiveTabs = [],
  onClick,
  multiSelect = false,
  showSelections = false,
  orientation = "horizontal",
  tabClassName = "",
  activeTabClassName = "",
  showCancelOnTab = false,
}) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab ?? "");
  const [selectedTabs, setSelectedTabs] = useState(initialActiveTabs);
  const [navKeyValue, setNavKeyValue] = useState({});

  const navRef = useRef(null);

  useEffect(() => {
    const navKeyVal = navs.reduce((a, v) => ({ ...a, [v.to]: v.title }), {});

    setNavKeyValue(navKeyVal);
  }, [navs]);

  useEffect(() => {
    onClick && activeTab !== "" && !multiSelect && onClick(activeTab);
    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    onClick && selectedTabs?.length > 0 && multiSelect && onClick(selectedTabs);
    // eslint-disable-next-line
  }, [selectedTabs]);

  const unSelect = (selection) => {
    const newSeletedTabs = selectedTabs.filter((tab) => tab !== selection);
    setSelectedTabs(newSeletedTabs);
    onClick &&
      selectedTabs?.length > 0 &&
      multiSelect &&
      onClick(newSeletedTabs);
  };

  return (
    <div className="flex flex-col gap-4">
      <div
        className={`
				${orientation === "horizontal" && "flex flex-row overflow-x-scroll"}
				${orientation === "vertical" && "flex flex-col "}
				hide-scroll-bar
				gap-3 md:gap-4
				transition-all duration-1000
				${className}
				!scroll-smooth
				`}
        ref={navRef}
      >
        {navs?.map(({ title, to }, idx) => {
          const key = `nav-item-${title}-${idx}`;
          return (
            <NavItem
              parentRef={navRef}
              key={key}
              className={` 
							text-xs md:text-sm
							px-3 py-2 md:px-4 md:py-2.5
							rounded-[4px]
							cursor-pointer
							hover:scale-[0.95]
							transition-all duration-300
							flex flex-row justify-between items-center gap-2
							${tabClassName}
							${
                (!multiSelect && activeTab === to) ||
                (multiSelect && selectedTabs.includes(to))
                  ? `
							text-pry-green-200 bg-inherit
							font-medium
              !border !border-inherit
							${activeTabClassName}
							`
                  : ""
              }	
							`}
              onClick={() => {
                !multiSelect && setActiveTab(to);

                multiSelect && selectedTabs.includes(to) && unSelect(to);

                multiSelect &&
                  !selectedTabs.includes(to) &&
                  setSelectedTabs([...selectedTabs, to]);
              }}
            >
              {title}

              <Icon
                height={16}
                width={16}
                svg="close-icon"
                className={`${
                  !showCancelOnTab ||
                  (!multiSelect && activeTab !== to) ||
                  (multiSelect && !selectedTabs.includes(to))
                    ? "hidden"
                    : "!text-pry-green-200"
                } `}
              />
            </NavItem>
          );
        })}
      </div>

      {multiSelect && showSelections && selectedTabs.length > 0 && (
        <div
          className={`
					flex flex-row overflow-x-scroll
					hide-scroll-bar
					gap-3 md:gap-4
					transition-all duration-1000
					${className}
					!scroll-smooth
					`}
        >
          {selectedTabs.map((selection, idx) => {
            const key = `tabSelection-${selection}-${idx}`;
            return (
              <div
                key={key}
                className={` 
								text-xs md:text-sm
								px-3 py-2 md:px-4 md:py-2.5
								bg-[#F0F0F0]
								rounded-[4px]
								cursor-pointer
								hover:scale-[0.95]
								transition-all duration-500
								min-w-max
								flex items-center gap-2
								`}
              >
                {navKeyValue[selection]}

                <div
                  onClick={() => {
                    unSelect(selection);
                  }}
                >
                  <Icon svg="close" width={10} height={10} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

import { getRequest, postRequest, putRequest } from "../api/requestHelpers";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";

export const useLoans = () => {
  const fetchAllLoanStatuses = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Settings/default/filter/LoanStatus", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchAllLoanProducts = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/settings/product", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const addLoanProduct = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Loans/settings/product", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const editLoanProduct = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await putRequest(`Loans/settings/product/${payload?.id}`, payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const generateRepaymentSchedule = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Loans/Generate/Repayment/Schedule", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(resp);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const requestLoan = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Loans/detail", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchLoanInformation = async (
    params = {
      coop: undefined,
      detail: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/detail", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchMyLoanHistory = async (
    params = {
      coop: undefined,
      detail: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/detail", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.Loan?.count,
          data: data?.Loan?.results,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const memberUploadRepayment = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest(`Loans/Manual/repayment`, payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const adminUploadRepayment = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest(`Loans/admin/Manual/repayment`, payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchSingleLoanDetail = async (
    params = {
      loan_id: undefined,
      detail: false,
      coop: undefined,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      `Loans/detail/${paramsDestrct?.loan_id}`,
      paramsDestrct,
      true
    )
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const adminFetchSingleLoanDetail = async (
    params = {
      loan_id: undefined,
      detail: false,
      coop: undefined,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      `Loans/admin/detail/${paramsDestrct?.loan_id}`,
      paramsDestrct,
      true
    )
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchCooperativeLoanInformation = async (
    params = {
      coop: undefined,
      detail: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/admin/detail", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchCooperativeLoanHistory = async (
    params = {
      coop: undefined,
      detail: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/admin/detail", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.Loan?.count,
          data: data?.Loan?.results,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchFailedDisbursementLoans = async (
    params = {
      coop: undefined,
      detail: true,
      offset: 0,
      limit: 10,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Loans/retry/out", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.logs?.count,
          data: data?.logs?.results ?? [],
          cooperativeBalance: data?.totalInCoop,
          totalFailedAmount: data?.totalLoan ?? 0,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchLoanGuarantorRequests = async (
    params = {
      loan_id: undefined,
      detail: false,
      coop: undefined,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(`Loans/detail/invitation`, paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveGuarantorRequest = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest(`Loans/detail/invitation`, payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const retryFailedLoan = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest("Loans/retry/out", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  return {
    fetchAllLoanStatuses,
    fetchAllLoanProducts,
    addLoanProduct,
    editLoanProduct,
    generateRepaymentSchedule,
    requestLoan,
    fetchLoanInformation,
    fetchMyLoanHistory,
    memberUploadRepayment,
    fetchSingleLoanDetail,
    fetchCooperativeLoanInformation,
    fetchCooperativeLoanHistory,
    adminFetchSingleLoanDetail,
    adminUploadRepayment,
    fetchLoanGuarantorRequests,
    approveGuarantorRequest,
    fetchFailedDisbursementLoans,
    retryFailedLoan,
  };
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../../components/icons/icon";
import { PageHeader } from "../../../components/layouts/pageHeader";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { TabNav } from "../../../components/navs/tabNav";
import { DepositsApprovalTable } from "../../../components/table/app_tables/depositsApprovalTable";
import { FinancesApprovalTable } from "../../../components/table/app_tables/financesApprovalTable";
import { LoansRepaymentApprovalTable } from "../../../components/table/app_tables/loanRepaymentApprovalTable";
import { LoansApprovalTable } from "../../../components/table/app_tables/loansApprovalTable";
import { MembershipApprovalTable } from "../../../components/table/app_tables/membershipApprovalTable";
import { PaymentApprovalTable } from "../../../components/table/app_tables/paymentApprovalsTable";
import { SharesApprovalTable } from "../../../components/table/app_tables/sharesApprovalTable";
import { useApprovals } from "../../../customHooks/useApprovals";
import { useCooperative } from "../../../customHooks/useCooperative";
import { usePermissions } from "../../../customHooks/usePermissions";
import { permissionList } from "../../../utils/constants";

export const SingleCooperativeApproval = () => {
  const [loading, setLoading] = useState();
  const [tab, setTab] = useState("membership");
  const [singleApprovalsSummary, setSingleApprovalsSummary] = useState({});
  const [navs, setNavs] = useState([]);

  const navigate = useNavigate();

  const params = useParams();

  const { hasPermissionsFor } = usePermissions();

  const {
    singleCooperative,
    fetchingSingleCooperativeDetails,
    myCooperativePermissions,
  } = useSelector((state) => {
    return state.app;
  });

  const { fetchSingleCooperativeDetails, fetchMyCooperativeMemberDetails } =
    useCooperative();
  const { fetchSingleCooperativeApprovalsSummary } = useApprovals();

  const countRefresh = () => {
    fetchSingleCooperativeApprovalsSummary(
      { coop: params?.id },
      () => {
        setLoading(false);
      },
      (data) => {
        setLoading(false);
        setSingleApprovalsSummary(data);
      },
      setLoading
    );
  };

  useEffect(() => {
    fetchSingleCooperativeDetails({ id: params?.id });
    fetchMyCooperativeMemberDetails({ coop: params?.id, Permission: "true" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    countRefresh();
    // eslint-disable-next-line
  }, [tab]);

  useEffect(() => {
    setNavs([
      {
        title: "Membership",
        to: "membership",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.memberApproval,
        permissionRequired: permissionList.CAN_APPROVE_MEMBER,
      },
      {
        title: "Savings",
        to: "savings",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.paymentApproval,
        permissionRequired: permissionList.CAN_APPROVE_PAYMENT,
      },
      {
        title: "Deposits",
        to: "deposits",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.paymentDepositApproval,
        permissionRequired: permissionList.CAN_APPROVE_PAYMENT,
      },
      {
        title: "Shares",
        to: "shares",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.paymentSharesApproval,
        permissionRequired: permissionList.CAN_APPROVE_PAYMENT,
      },
      {
        title: "Loan Requests",
        to: "loans",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.loanApproval,
        permissionRequired: permissionList.CAN_APPROVE_LOANS,
      },
      {
        title: "Loan Repayments",
        to: "loan_repayments",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.loanRepaymentApproval,
        permissionRequired: permissionList.CAN_APPROVE_LOANS,
      },
      {
        title: "Withdrawal",
        to: "cooperative_withdrawals",
        onClick: setTab,
        hasCount: true,
        count: singleApprovalsSummary?.withdrawalApproval,
        permissionRequired: permissionList.CAN_ADD_PAYMENT,
      },
    ]);
  }, [singleApprovalsSummary]);

  useEffect(() => {
    !navs
      ?.map((nav) => {
        return nav?.to;
      })
      ?.includes(tab) &&
      setTab(
        navs?.filter((nav) => {
          return hasPermissionsFor(nav?.permissionRequired);
        })[0]?.to
      );
    // eslint-disable-next-line
  }, [tab, navs, myCooperativePermissions]);

  return fetchingSingleCooperativeDetails ? (
    <PageLoader />
  ) : (
    <div>
      <PageHeader
        pageTitle=""
        hasBack={false}
        onBackClick={() => {}}
        hasRightChildren={false}
        rightChildren={undefined}
        hasLeftChildren
        leftChildren={
          <div
            className="
                text-pry-green-200 font-medium text-base
                flex flex-row flex-wrap
                gap-1
                items-center
                "
          >
            <div
              className="
                    text-pry-dark-green-300/50
                    cursor-pointer
                    hover:scale-95
                    transition-all duration-500
                    "
              onClick={() => {
                navigate("/app/approvals");
              }}
            >
              Approvals
            </div>
            <Icon
              svg="left-caret-icon"
              width={24}
              height={24}
              className="rotate-180"
            />
            <div>{singleCooperative?.CoopName}</div>
          </div>
        }
      />

      {/* Tab navs  */}
      <TabNav
        navs={navs?.filter((nav) => {
          return myCooperativePermissions?.includes(nav?.permissionRequired);
        })}
        className="mt-7 w-fit max-w-full overflow-scroll"
        initialActiveTab={tab}
      />

      <div className="mt-8">
        {loading ? (
          <PageLoader />
        ) : (
          <div>
            {tab === "membership" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_MEMBER) && (
                <MembershipApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.memberApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "savings" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_PAYMENT) && (
                <PaymentApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.paymentApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "deposits" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_PAYMENT) && (
                <DepositsApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.paymentDepositApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "shares" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_PAYMENT) && (
                <SharesApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.paymentSharesApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "loans" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_LOANS) && (
                <LoansApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.loanApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "loan_repayments" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_LOANS) && (
                <LoansRepaymentApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.loanRepaymentApproval}
                  refresh={countRefresh}
                />
              )}
            {tab === "cooperative_withdrawals" &&
              hasPermissionsFor(permissionList.CAN_APPROVE_PAYMENT) && (
                <FinancesApprovalTable
                  singleCooperative={singleCooperative}
                  totalCount={singleApprovalsSummary?.withdrawalApproval}
                  refresh={countRefresh}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

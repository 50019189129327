import PropTypes from "prop-types";
import React, { useState } from "react";
import { Icon } from "../icons/icon";
import { PageLoader } from "../loaders/pageLoader";
import { ConfirmationModal } from "../modals/app_modals/confirmationModal";

export const DebitCard = ({
  cardNumber = "",
  expiryDate = "",
  type = "",
  onClick = () => {},
  isSelected = false,
  selectable = true,
  canDelete = false,
  onDelete = () => {},
  isDeleting = false,
  disableDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <div
      className={`
        px-6 py-4
        bg-pry-dark-green-400/5
        rounded-lg
        flex items-center justify-between
        border-[2px] border-transparent
        ${
          selectable &&
          "hover:border-pry-green-200 hover:scale-95 cursor-pointer"
        }
        ${isSelected && selectable && "!border-pry-green-200 !scale-95"}
        transition-all diration-500
        bg-[url('assets/images/card_bg.svg')]
        bg-contain bg-no-repeat bg-[center_left_3rem]
        `}
      onClick={onClick}
    >
      <div className="flex items-center gap-4">
        <div className="text-base font-bold">****{cardNumber.slice(-4)}</div>
        <div className="text-sm ">{expiryDate}</div>
        {canDelete && (
          <Icon
            svg={type === "visa" ? "visa" : "mastercard"}
            width={type === "visa" ? 32 : 24}
            height={type === "visa" ? 32 : 24}
          />
        )}
      </div>
      <div>
        {canDelete ? (
          <div
            className={`
            bg-white p-2 rounded-full text-red-200
            cursor-pointer border
            hover:scale-[0.95]
            hover:bg-lighter-red-bg
            hover:border-red-200
            transition-all duration-300
            ${isDeleting && "!hidden"}
            `}
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            <Icon svg={"delete-icon"} width={20} height={20} className={""} />
          </div>
        ) : (
          <Icon
            svg={type === "visa" ? "visa" : "mastercard"}
            width={type === "visa" ? 32 : 24}
            height={type === "visa" ? 32 : 24}
          />
        )}

        {canDelete && isDeleting && (
          <PageLoader
            variant="spinner"
            spinnerColor="#EF4444"
            className="!min-h-fit !w-fit"
            secondaryColor="white"
          />
        )}
      </div>

      <ConfirmationModal
        isModalOpen={deleteModalOpen}
        onModalClose={() => {
          setDeleteModalOpen(false);
        }}
        title={"Confirm card deletion"}
        children={undefined}
        subtitle={
          "Are you sure you want to delete your card? This action cannot be undone."
        }
        onProceed={disableDelete ? () => {} : onDelete}
        loading={isDeleting}
        proceedButtonText="Proceed"
      />
    </div>
  );
};

DebitCard.propTypes = {
  canDelete: PropTypes.bool,
  cardNumber: PropTypes.string,
  disableDelete: PropTypes.any,
  expiryDate: PropTypes.string,
  isDeleting: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  selectable: PropTypes.bool,
  type: PropTypes.string,
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "..";
import awaiting from "../../../assets/images/awaiting.svg";

export const AwaitingMembershipApprovalModal = ({
  modalOpen,
  onModalClose = () => {},
  selectedData = {},
}) => {
  const navigate = useNavigate();

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title={undefined}
      subTitle={undefined}
      childrenClassName=""
      modalClassName="!max-h-[464px]"
      hasButtons={selectedData && !selectedData?.hasPaid}
      proceedButtonText="Pay membership fee"
      onProceedClick={() => {
        navigate(`/app/cooperative/${selectedData?.Cooperative?.id}/join`);
      }}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={selectedData?.hasPaid}
    >
      <div
        className="
            h-full w-full
            flex flex-col
            items-center justify-center
            text-center
        "
      >
        <img src={awaiting} alt="awaiting" className="" />
        <div className="text-center mt-4 text-[20px] font-bold">
          Awaiting Approval
        </div>
        <div className="text-center mt-1 opacity-80 text-sm">
          You will be able to view this cooperative once your registration is
          approved.
        </div>
      </div>
    </ModalComponent>
  );
};

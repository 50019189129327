import { useFormik } from "formik";
import React from "react";
import { Button } from "../../../components/buttons/button";
import { TextLink } from "../../../components/buttons/textLink";
import { RangeRadioInput } from "../../../components/inputs/rangeRadioInput";
import { TextInput } from "../../../components/inputs/textInput";
import { HeaderSubheader } from "../../../components/layouts/headerSubheader";
import { useAuth } from "../../../customHooks/useAuth";
import { cooperativeSizes } from "../../../utils/constants";
import { cooperativeSignUpSchema } from "../../../validationSchema";

export const SignUpCooperative = ({ setStep }) => {
  const { registerCooperative } = useAuth();

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      cooperativeName: "",
      cooperativeRegNum: "",
      cooperativeEmail: "",
      password: "",
      cooperativeSize: "",
    },
    validationSchema: cooperativeSignUpSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        CoopName: data.cooperativeName,
        CoopReg: data.cooperativeRegNum,
        Email: data.cooperativeEmail,
        Password: data.password,
        Size: data.cooperativeSize.join("-"),
      };

      registerCooperative(
        payload,
        () => {
          // General callback function
          setSubmitting(false);
        },
        () => {
          // onSuccess callback function
          // To persist the account validation flow, in case it wasn't completed initially
          const accountConfirmation = {
            email: data.cooperativeEmail,
            isConfirmed: false,
          };
          localStorage.setItem(
            "accountConfirmation",
            JSON.stringify(accountConfirmation)
          );
          // On success callback: Go to next step -> confirm email page
          setStep("confirm-mail");
        }
      );
    },
  });

  return (
    <div className="w-full">
      <HeaderSubheader
        header="Cooperative Signup"
        subheader="Manage your cooperative activities in one place."
      />
      <form className="mt-6 flex flex-col gap-3" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name={"cooperativeName"}
          value={values.cooperativeName}
          placeholder="Cooperative Name"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.cooperativeName &&
            touched.cooperativeName &&
            errors.cooperativeName
          }
          hasIcon={false}
        />
        <TextInput
          type="text"
          name={"cooperativeRegNum"}
          value={values.cooperativeRegNum}
          placeholder="Cooperative Registration Number"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.cooperativeRegNum &&
            touched.cooperativeRegNum &&
            errors.cooperativeRegNum
          }
          hasIcon={false}
        />
        <TextInput
          type="text"
          name={"cooperativeEmail"}
          value={values.cooperativeEmail}
          placeholder="Cooperative Email Address"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.cooperativeEmail &&
            touched.cooperativeEmail &&
            errors.cooperativeEmail
          }
          hasIcon={false}
        />
        <TextInput
          type="password"
          name={"password"}
          value={values.password}
          placeholder="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.password && touched.password && errors.password}
          hasIcon={false}
        />
        <RangeRadioInput
          name="cooperativeSize"
          title="Select Cooperative Size"
          options={cooperativeSizes}
          setValue={setFieldValue}
          errors={
            errors.cooperativeSize &&
            touched.cooperativeSize &&
            errors.cooperativeSize
          }
        />

        <div className="w-full text-right mt-5 md:mt-3">
          <Button
            text="Create Account"
            type="submit"
            onClick={() => {}}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          />
        </div>

        <div className="w-full mt-1 !text-sm text-grey-400">
          Already have an account?{" "}
          <TextLink
            to="/auth/sign-in"
            text="Login"
            className=""
            onClick={() => {}}
          />
        </div>
      </form>
    </div>
  );
};

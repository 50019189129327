import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { BackButton } from "../../../components/buttons/backButton";
import { Button } from "../../../components/buttons/button";
import { Icon } from "../../../components/icons/icon";
import { OnboardingLayout } from "../../../components/layouts/onboardingLayout";
import {
  isAccountConfirmed,
  isAuthenticated,
} from "../../../helperFunctions/auth";
import { removeCookie } from "../../../helperFunctions/cookies";
import { doNotification } from "../../../helperFunctions/nofitication";
import { SignUpConfirmEmail } from "./signUpConfirmEmail";
import { SignUpCooperative } from "./signUpCooperative";
import { SignUpMember } from "./signUpMember";
import { SignUpType } from "./signUpType";

export const SignUp = () => {
  const [params] = useSearchParams();

  const [signUpType, setSignUpType] = useState(params.get("type") || null);
  const [step, setStep] = useState("signup");
  const [showSetupContinue, setShowSetupContine] = useState(false);
  const navigate = useNavigate();

  const accountConfirmation = JSON.parse(
    localStorage.getItem("accountConfirmation")
  );

  const goBack = () => {
    if (step === "signup" && signUpType !== null) {
      setSignUpType(null);
    } else if (step === "signup" && signUpType === null) {
      navigate("/auth/sign-in");
    } else if (step === "confirm-mail") {
      navigate("/auth/sign-in");
      setStep("signup");
      removeCookie("_tC_token");
      doNotification(
        "Confirm your email in order to gain access to your account.",
        "warning"
      );
    }
  };

  useEffect(() => {
    // If account confirmation is not true or undefined
    if (!isAccountConfirmed() && accountConfirmation?.email) {
      setShowSetupContine(() => true);
      if (isAuthenticated()) {
        setStep(() => "confirm-mail");
      }
    }
    // eslint-disable-next-line
  }, [step]);

  if (
    !["member", "cooperative", "", null, undefined].includes(params.get("type"))
  ) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  return (
    <OnboardingLayout>
      <div className="w-full max-w-[327px]">
        <BackButton
          text={step === "signup" && signUpType === null ? "Login" : "Back"}
          onClick={() => {
            goBack();
          }}
          className="mb-7"
        />

        {showSetupContinue && step === "signup" && (
          <div className="flex gap-3 items-start">
            <div
              className="
                p-2 
                bg-light-green-bg-200
                rounded-full
                object-contain text-pry-dark-green-200
                "
            >
              <Icon svg="notification-bell" width={20} height={20} />
            </div>
            <div className="mb-7 text-[14px] break-all">
              Complete account setup for{" "}
              <span className="font-bold">{accountConfirmation?.email}?</span>
              <div className="mt-2">
                <Button
                  text="Continue"
                  onClick={() => {
                    setStep("confirm-mail");
                    // todo: shoot verification email
                    // todo: if account already verified, delete confirmation and redirect to sign-in
                  }}
                  loaderHeight="15"
                  loaderWidth="15"
                  loading={false}
                  type="button"
                  className="!w-fit md:!py-1 !px-3 !text-[12px]"
                  variation="secondary-light-2"
                />
              </div>
            </div>
          </div>
        )}

        {step === "signup" && !signUpType && (
          <SignUpType setSignUpType={setSignUpType} />
        )}
        {step === "signup" && signUpType === "member" && (
          <SignUpMember setSignUpType={setSignUpType} setStep={setStep} />
        )}
        {step === "signup" && signUpType === "cooperative" && (
          <SignUpCooperative setSignUpType={setSignUpType} setStep={setStep} />
        )}
        {step === "confirm-mail" && (
          <SignUpConfirmEmail setSignUpType={setSignUpType} setStep={setStep} />
        )}
      </div>
    </OnboardingLayout>
  );
};

import React from "react";
import { Icon } from "../icons/icon";

export const CooperativeMemberCount = ({ cooperative, className }) => {
  return (
    <div
      className={`
            mt-2 w-fit
            text-xs text-white
            font-medium
            py-1 px-2
            rounded-full
            bg-pry-green-200
            flex flex-row flex-wrap
            items-center
            gap-1
            ${className}
            `}
    >
      <Icon svg="people" width={14} height={14} />
      {cooperative?.membercount}{" "}
      {cooperative?.membercount === 0 || cooperative?.membercount > 1
        ? "members"
        : "member"}
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "..";

export const CloseMembershipFormModal = ({
  modalOpen,
  onModalClose = () => {},
  cooperativeName,
}) => {
  const navigate = useNavigate();

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title="Close Form"
      subTitle={
        <div>
          You are about to close this{" "}
          <span className="text-pry-green-200 font-medium">
            {cooperativeName}
          </span>{" "}
          membership form. You might need to start all over again the next time
          you try.{" "}
        </div>
      }
      childrenClassName=""
      modalClassName="!max-h-[320px]"
      hasButtons
      proceedButtonText="Don't Close"
      onProceedClick={onModalClose}
      hasCancelButton
      cancelButtonText="Close"
      cancelButtonVariant="danger"
      onCancelClick={() => {
        navigate("/app/discover");
      }}
      isLoading={false}
      isProceedDisable={false}
    />
  );
};

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useApprovals } from "../../../customHooks/useApprovals";
import { useLoans } from "../../../customHooks/useLoans";
import { numberToString } from "../../../helperFunctions";
import { MoreDetailsModal } from "../../modals/app_modals/moreDetailsModal";
import { Pagination } from "../../pagination/pagination";
import { TimeText } from "../../typography/timeText";
import { MultiSelectApprovalTable } from "../multiSelectApprovalTable";

export const LoansApprovalTable = ({
  singleCooperative,
  totalCount,
  refresh,
}) => {
  const [query, setQuery] = useState({
    limit: 10,
    offset: 0,
    coop: singleCooperative?.id,
  });
  const [moreDetailsQuery, setMoreDetailsQuery] = useState({
    loan_id: undefined,
    detail: true,
    coop: singleCooperative?.id,
  });
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [moreDetails, setMoreDetails] = useState({});

  const { fetchLoansApprovals, approveLoan, fetchApprovalsSummary } =
    useApprovals();

  const { adminFetchSingleLoanDetail } = useLoans();

  const loanApprovals = useQuery(
    ["loanApprovals", query],
    fetchLoansApprovals,
    { enabled: true }
  );

  const singleLoanDetail = useQuery(
    ["fetchSingleLoanDetail", moreDetailsQuery],
    adminFetchSingleLoanDetail,
    { enabled: false }
  );

  useEffect(() => {
    selectedItem?.id &&
      setMoreDetailsQuery({
        loan_id: selectedItem?.id,
        detail: true,
        coop: singleCooperative?.id,
      });
    // eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    moreDetailsQuery?.loan_id && singleLoanDetail?.refetch();
    // eslint-disable-next-line
  }, [moreDetailsQuery]);

  useEffect(() => {
    const allGuarantors = singleLoanDetail?.data?.Guarantor?.map(
      (item, idx) => {
        return {
          key: `Guarantor ${idx + 1}`,
          value: `${item?.MembershipLoanRequestID?.MemberID?.User?.FirstName} ${item?.MembershipLoanRequestID?.MemberID?.User?.LastName}`,
          key2: `Amount guarantor ${idx + 1} to guarantee`,
          value2: numberToString(item?.AmountGuarantee),
        };
      }
    );
    const result = {
      NumberOfGuarantors: singleLoanDetail?.data?.NoOfGuarantor,
      ...allGuarantors?.reduce(
        (obj, item) => ({
          ...obj,
          [item.key]: item.value,
          [item.key2]: item.value2,
        }),
        {}
      ),
    };
    setMoreDetails(result);
  }, [singleLoanDetail?.data]);

  return (
    <div>
      <MultiSelectApprovalTable
        singleCooperative={singleCooperative}
        headers={["NAME", "LOAN TYPE", "AMOUNT", "DATE", " "]}
        data={
          loanApprovals?.data
            ? loanApprovals?.data?.map((item, idx) => {
                const key = `row-${idx}-loan-approvals`;

                return {
                  id: item?.id,
                  data: [
                    <div
                      key={`${key}-10 `}
                      className="capitalize"
                    >{`${item?.MemberID?.User?.FirstName} ${item?.MemberID?.User?.LastName}`}</div>,
                    `${item?.LoanProduct?.LoanName}`,
                    `₦${numberToString(item?.Amount)}`,
                    <TimeText timeString={item?.DateAdded} showTime />,
                    <div
                      className="
                text-pry-green-200 !font-medium cursor-pointer
                "
                      key=""
                      onClick={() => {
                        setSelectedItem(item);
                        setModal("loanDetailsModal");
                        setModalOpen(true);
                      }}
                    >
                      View Details
                    </div>,
                  ],
                };
              })
            : []
        }
        isLoading={loanApprovals.isFetching}
        refetchData={loanApprovals.refetch}
        successCallback={() => {
          fetchApprovalsSummary();
          loanApprovals?.refetch();
          refresh();
        }}
        approvalFunction={approveLoan}
        totalCount={totalCount}
        query={query}
        setQuery={setQuery}
        emptySubTitle="All your loan approvals will be displayed here whenever you need to approve any loan request."
      />

      <div className="w-full mt-4">
        <Pagination
          limit={query?.limit}
          totalCount={totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
        />
      </div>

      <MoreDetailsModal
        modalOpen={modalOpen && modal === "loanDetailsModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
          setSelectedItem(undefined);
        }}
        mainData={{
          FirstName: selectedItem?.MemberID?.User?.FirstName,
          MiddleName: selectedItem?.MemberID?.User?.MiddleName,
          LastName: selectedItem?.MemberID?.User?.LastName,
          LoanType: selectedItem?.LoanProduct?.LoanName,
          LoanAmount: `₦${numberToString(selectedItem?.Amount)}`,
          LoanTenor: `${selectedItem?.Tenure} months`,
          InterestRate: `${selectedItem?.LoanProduct?.MaxInterestRate}%`,
          DateRequested: (
            <TimeText timeString={selectedItem?.DateAdded} showTime={false} />
          ),
        }}
        secondaryData={moreDetails}
        title="Loan Details"
        extraInfoTitle="Guarantors Information"
        loading={singleLoanDetail?.isLoading}
      />
    </div>
  );
};

import React from "react";
import { Icon } from "../icons/icon";

export const EmptySideNavFeaturesState = () => {
  return (
    <div
      className="
        py-8 px-6 text-sm font-medium
        flex flex-col gap-6
        justify-center items-center
        text-center
        bg-light-green-bg-200
        rounded-lg
        "
    >
      <Icon svg="smile-face" width={72} height={72} />

      <div>
        You will get access to more features once your account is approved
      </div>
    </div>
  );
};

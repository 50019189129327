import React from "react";
import { TextLink } from "../buttons/textLink";
import { Icon } from "../icons/icon";

export const UploadedFileView = ({
  viewLink = "",
  text = "View document",
  className = "",
  iconClassName = "",
  textClassName = "",
  variant = "normal", // oneOf 'normal' | 'mini',
  status,
}) => {
  const getColor = () => {
    switch (status) {
      case "active":
        return "!text-pry-green-200";
      case "pending":
        return "!text-gold-200";
      case "submitted":
        return "!text-pry-green-200";
      case "approved":
        return "!text-pry-green-200";
      case "rejected":
        return "!text-error-200";
      default:
        return "!text-pry-green-200";
    }
  };

  return (
    <div
      className={`
                !w-fit flex
                items-center 
                gap-[20px]
                ${className}
                ${variant === "mini" && "!gap-[6px]"}
                `}
    >
      <div
        className={`w-fit p-[8px] 
                    rounded-full
                    bg-light-green-bg
                    ${iconClassName}
                    ${variant === "mini" && "!p-0 !bg-none"}
                    ${getColor()}
                    `}
      >
        <Icon svg="small-document-icon" width={16} height={16} />
      </div>
      <TextLink
        text={text}
        disabled={false}
        to={viewLink}
        className={`!font-medium ${textClassName} ${
          variant === "mini" && "!text-sm"
        } ${getColor()}`}
        target="_blank"
      />
    </div>
  );
};

import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { InformationCard } from "../../../../components/cards/infoCard";
import { DateInput } from "../../../../components/inputs/dateInput";
import { Dropdown } from "../../../../components/inputs/dropDown";
import { TextInput } from "../../../../components/inputs/textInput";
import { TextInputViewOnly } from "../../../../components/inputs/textInputViewOnly";
import { UploadedFileView } from "../../../../components/inputs/uploadedFileView";
import { PageHeader } from "../../../../components/layouts/pageHeader";
import { UploadCertificateModal } from "../../../../components/modals/app_modals/uploadCertificateModal";
import { UploadConstitutionModal } from "../../../../components/modals/app_modals/uploadConstitutionModal";
import { ProfilePicture } from "../../../../components/profile/profilePicture";
import { useAuth } from "../../../../customHooks/useAuth";
import { useSettings } from "../../../../customHooks/useSettings";
import { decodeUrl, getTextFromStatus } from "../../../../helperFunctions";
import { profileUpdateSchema } from "../../../../validationSchema";

const CooperativeProfileEdit = ({ profile, navigate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [uploadedPicture, setUploadedPicture] = useState(null);
  const [logoUploading, setLogoUploading] = useState(false);

  const { uploadLogo, uploadDescription } = useAuth();

  const profilePicRef = useRef();

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleLogoUpdate = () => {
    const formData = new FormData();
    formData.append("file", uploadedPicture);

    setLogoUploading(true);
    uploadLogo(
      formData,
      () => {
        setLogoUploading(false);
      },
      () => {
        setUploadedPicture(null);
      }
    );
  };

  const {
    values,
    errors,
    isSubmitting,
    isValid,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      description: profile?.description || "",
    },
    enableReinitialize: true,
    validationSchema: undefined,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);

      const payload = {
        desc: data?.description,
      };

      uploadDescription(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
        }
      );
    },
  });

  return (
    <div className="w-full">
      <PageHeader
        hasBack={true}
        onBackClick={() => navigate("/app/settings?tab=profile")}
        pageTitle={
          profile?.type === "cooperative" ? "Cooperative Profile" : "Profile"
        }
      />

      <div
        className="
          mt-[28px] 
          flex flex-row flex-wrap
          gap-2 md:gap-6
          
          "
      >
        <ContainerCard
          className="
            !px-0
            w-full md:max-w-[655px]
            order-last width-lim:order-first
            "
        >
          <div
            className="
              px-[28px] 
              border-b border-pry-dark-green-300/5
              pb-[20px]
              "
          >
            <TextInputViewOnly
              className="!px-0 !py-0"
              text="Cooperative Description"
              subtext="Tell us something about your cooperative"
              hasIcon={false}
              plain
            />

            <div
              className="
            mt-4
            w-full
            "
            >
              <TextInput
                type="textarea"
                name="description"
                value={values?.description}
                placeholder="A brief overview of your cooperative"
                onChange={handleChange}
                onBlur={handleBlur}
                hasIcon={false}
                iconSvg=""
                errors={
                  errors?.description &&
                  touched?.description &&
                  errors?.description
                }
                rows="3"
              />
            </div>

            <div className="w-full flex justify-end mt-2">
              <Button
                text={"Save"}
                onClick={handleSubmit}
                type="button"
                className="!px-4 md:!py-1 !w-fit "
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                icon={undefined}
                variation="primary"
              />
            </div>
          </div>

          <div
            className="
              px-[28px] 
              border-b border-pry-dark-green-300/5
              py-[20px]
              flex items-center justify-between
              flex-wrap gap-4
              "
          >
            <div className="">
              <TextInputViewOnly
                className="!px-0 !py-0 max-w-fit"
                text="Certificate of Registration"
                subtext="Upload your cooperative certificate of registration"
                hasIcon={false}
                plain
              />
              {profile?.Certificate && (
                <UploadedFileView
                  viewLink={`${decodeUrl(profile?.Certificate)}`}
                  text={
                    profile?.Certificatestatus
                      ? `View certificate - ${getTextFromStatus(
                          profile?.Certificatestatus
                        )}`
                      : "View certificate"
                  }
                  className="mt-3"
                  status={profile?.Certificatestatus}
                />
              )}
            </div>

            {([null, undefined, "rejected"].includes(
              profile?.Certificatestatus
            ) ||
              !profile?.Certificate) && (
              <Button
                text={profile?.Certificate ? "Re-upload" : "Upload"}
                onClick={() => {
                  setModalOpen(true);
                  setModal("uploadCertificateModal");
                }}
                type="button"
                className="!text-sm md:!py-3 md:max-w-[120px]"
                loading={false}
                disabled={false}
                variation="secondary"
              />
            )}
          </div>

          <div
            className="
              px-[28px] 
              border-b border-pry-dark-green-300/5
              py-[20px]
              flex items-center justify-between
              flex-wrap gap-4
              "
          >
            <div className="">
              <TextInputViewOnly
                className="!px-0 !py-0 max-w-fit"
                text="Bye Laws or Constitution"
                subtext="Upload cooperative bye laws or constitution"
                hasIcon={false}
                plain
              />
              {profile?.byelaw && (
                <UploadedFileView
                  viewLink={`${decodeUrl(profile?.byelaw)}`}
                  text="View document"
                  className="mt-3"
                />
              )}
            </div>

            <Button
              text={profile?.byelaw ? "Update" : "Upload"}
              onClick={() => {
                setModalOpen(true);
                setModal("uploadConstitutionModal");
              }}
              type="button"
              className="!text-sm md:!py-3 md:max-w-[120px]"
              loading={false}
              disabled={false}
              variation="secondary"
            />
          </div>

          <div
            className="
              px-[28px]
              py-[20px]
              "
          >
            <TextInputViewOnly
              className="!px-0 !py-0"
              text="Email Address"
              subtext={profile?.CoopEmail}
              hasIcon={false}
              plain
            />
          </div>
        </ContainerCard>

        <div
          className="
          w-full lg:max-w-[345px]
          md:max-w-[655px]
        "
        >
          <InformationCard
            info={
              <div>
                To change{" "}
                <span className="text-pry-green-200 font-medium">
                  email address
                </span>
                , please reach out to support.
              </div>
            }
          />

          <ContainerCard
            className="flex flex-col 
            items-center justify-center
            mt-4
            gap-6
            "
          >
            <ProfilePicture
              className="
              !w-[106px] !h-[106px] 
              !text-2xl !font-bold
              hover:!scale-100
              "
              tempSrc={uploadedPicture && URL.createObjectURL(uploadedPicture)}
            />

            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={profilePicRef}
              multiple={false}
              onChange={(e) => {
                if (e?.target?.files && e?.target?.files[0]) {
                  setUploadedPicture(e?.target?.files[0]);
                }
              }}
            />

            <div
              className="
            w-full 
            items-center justify-center 
            flex flex-row flex-wrap gap-2"
            >
              {uploadedPicture && (
                <Button
                  text="Change"
                  onClick={() => {
                    profilePicRef?.current?.click();
                  }}
                  type="button"
                  className="!text-sm md:!py-3 w-full max-w-[120px]"
                  loading={false}
                  disabled={false}
                  variation="secondary"
                />
              )}

              <Button
                text={
                  profile?.logo
                    ? uploadedPicture
                      ? "Save photo"
                      : "Change photo"
                    : uploadedPicture
                    ? "Save photo"
                    : "Add a photo"
                }
                onClick={() => {
                  if (!uploadedPicture) {
                    profilePicRef?.current?.click();
                  } else {
                    handleLogoUpdate();
                  }
                }}
                type="button"
                className="!text-sm md:!py-3 w-full max-w-[120px]"
                loading={logoUploading}
                disabled={logoUploading}
                variation={uploadedPicture ? "primary" : "secondary-light"}
              />
            </div>

            <div
              className="
              w-full max-w-[353px]
              text-center
              "
            >
              <div
                className="
                font-bold text-base
                "
              >
                Help members to identify your cooperative easily
              </div>

              <div className="mt-1 text-pry-dark-green-300/60">
                Your logo will be visible to your members on ThriftCorp
              </div>
            </div>
          </ContainerCard>
        </div>
      </div>

      <UploadCertificateModal
        modalOpen={modalOpen && modal === "uploadCertificateModal"}
        onModalClose={handleModalClose}
      />

      <UploadConstitutionModal
        modalOpen={modalOpen && modal === "uploadConstitutionModal"}
        onModalClose={handleModalClose}
      />
    </div>
  );
};

const MemberProfileEdit = ({ profile, navigate }) => {
  const profilePicRef = useRef();

  const [uploadedPicture, setUploadedPicture] = useState(null);
  const [dialCodes, setDialCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [fetchingStates, setFetchingStates] = useState(false);

  const settings = useSelector((state) => {
    return state?.settings;
  });

  const { fetchDialCodesCountries, fetchStates } = useSettings();
  const { updateUserProfile } = useAuth();

  useEffect(() => {
    fetchDialCodesCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDialCodes(settings?.dialCodes);
    setCountries(settings?.countries);
    setStates(settings?.states);
  }, [settings]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: profile?.FirstName,
      middleName: profile?.MiddleName?.replaceAll("#", ""),
      lastName: profile?.LastName,
      dateOfBirth: profile?.DateOfBirth,
      dialCode: {
        id:
          profile?.PhoneNumberCode?.replaceAll("#", "") &&
          `+${profile?.PhoneNumberCode?.replaceAll("#", "")}`,
        title:
          profile?.PhoneNumberCode?.replaceAll("#", "") &&
          `+${profile?.PhoneNumberCode?.replaceAll("#", "")}`,
        value:
          profile?.PhoneNumberCode?.replaceAll("#", "") &&
          `+${profile?.PhoneNumberCode?.replaceAll("#", "")}`,
      },
      phoneNumber: profile?.PhoneNumber?.replaceAll("#", ""),
      email: profile?.Email,
      address: profile?.Address,
      country: {
        id: profile?.Country?.replaceAll("#", ""),
        title: profile?.Country?.replaceAll("#", ""),
        value: profile?.Country?.replaceAll("#", ""),
      },
      state: {
        id: profile?.State?.replaceAll("#", ""),
        title: profile?.State?.replaceAll("#", ""),
        value: profile?.State?.replaceAll("#", ""),
      },
    },
    enableReinitialize: true,
    validationSchema: profileUpdateSchema,
    onSubmit: (data, { setSubmitting }) => {
      const formData = new FormData();
      const payload = {
        dob: data?.dateOfBirth
          ? new Date(data?.dateOfBirth).toISOString().split("T")[0]
          : null,
        phone:
          data?.dialCode && data?.phoneNumber
            ? `${data?.dialCode?.value}${data?.phoneNumber}`
            : null,
        mName: data?.middleName,
        fName: data?.firstName,
        lName: data?.lastName,
        address: data?.address,
        country: data?.country?.value,
        state: data?.state?.value,
        bvn: data?.bvn,
        pic: uploadedPicture,
      };

      Object.keys(payload)?.map((key) => {
        formData.append(key, payload?.[key]);
        return "";
      });

      updateUserProfile(
        formData,
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
          navigate("/app/settings");
        }
      );
    },
  });

  const handleFetchStates = () => {
    setFetchingStates(true);
    fetchStates({ country: values?.country?.value }, () => {
      setFetchingStates(false);
    });
  };

  useEffect(() => {
    values?.country && handleFetchStates();
    // eslint-disable-next-line
  }, [values?.country]);

  return (
    <div className="w-full">
      <PageHeader
        hasBack={true}
        onBackClick={() => navigate("/app/settings?tab=profile")}
        pageTitle={
          profile?.type === "cooperative" ? "Cooperative Profile" : "Profile"
        }
        hasRightChildren
        rightChildren={
          <div
            className="
            w-full md:w-max 
            flex flex-row
            gap-1 
            md:pt-0
          "
          >
            <Button
              text="Clear"
              onClick={() => {
                resetForm();
                setUploadedPicture(null);
              }}
              type="button"
              className="md:!w-[107px] md:!py-[12px] hidden md:flex"
              loading={false}
              disabled={isSubmitting}
              variation="plain"
            />
            <Button
              text="Save"
              onClick={handleSubmit}
              type="button"
              className="!w-[82px] md:!w-[107px] md:!py-[12px]"
              loading={isSubmitting}
              disabled={isSubmitting}
              variation="primary"
            />
          </div>
        }
      />

      <div
        className="
          mt-[28px] 
          flex flex-row flex-wrap
          gap-2 md:gap-6
          "
      >
        <ContainerCard
          className="
            !px-4 md:!px-[24px]
            w-full max-w-[655px]
            order-last width-lim:order-first
            "
        >
          <div
            className="
          text-pry-dark-green-400
          font-bold text-base
          mb-[32px]
          "
          >
            Personal Information
          </div>

          <div
            className="
          flex flex-row flex-wrap gap-[16px]
          w-full
          "
          >
            <TextInput
              type="text"
              name="firstName"
              value={values?.firstName}
              placeholder="Your first name"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                touched?.firstName && errors?.firstName && errors?.firstName
              }
              inputClass=""
              className=""
              containerClassName="!w-full !max-w-[294px]"
              hasTitle
              title="First Name"
              disabled
            />

            <TextInput
              type="text"
              name="middleName"
              value={values?.middleName}
              placeholder="Your middle name"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                errors?.middleName && touched?.middleName && errors?.middleName
              }
              inputClass=""
              className=""
              containerClassName="!w-full !max-w-[294px]"
              hasTitle
              title="Middle Name"
            />

            <TextInput
              type="text"
              name="lastName"
              value={profile?.LastName}
              placeholder="Your last name"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={errors?.lastName && touched?.lastName && errors?.lastName}
              inputClass=""
              className=""
              containerClassName="!w-full !max-w-[294px]"
              hasTitle
              title="Last Name"
              disabled
            />

            <DateInput
              name="dateOfBirth"
              value={values?.dateOfBirth}
              placeholder="Date of Birth"
              onChange={(val) => {
                setFieldValue("dateOfBirth", val);
              }}
              onBlur={handleBlur}
              hasIcon={false}
              errors={
                errors?.dateOfBirth &&
                touched?.dateOfBirth &&
                errors?.dateOfBirth
              }
              containerClassName="!w-full !max-w-[294px]"
              hasTitle
              title="Date of Birth"
            />

            <Dropdown
              value={values?.dialCode}
              options={dialCodes}
              placeholder="Dial code"
              onChange={(e) => {
                setFieldValue("dialCode", e);
              }}
              onBlur={handleBlur("dialCode")}
              hasIcon={false}
              errors={errors?.dialCode}
              dropdownClassName="max-h-[250px] !w-[250px]"
              onSearch={(searchValue) => {
                const filteredList = settings?.dialCodes.filter((val) => {
                  return val?.title
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes(searchValue?.trim()?.toLowerCase());
                });
                setDialCodes(filteredList);
              }}
              resetOptionsList={() => {
                setDialCodes(settings?.dialCodes);
              }}
              isSearchable
              className=""
              hasTitle
              title="Dial Code"
              containerClassName="!w-full !max-w-[107px]"
            />

            <TextInput
              type="text"
              name="phoneNumber"
              value={values?.phoneNumber}
              placeholder="Your phone number"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                errors?.phoneNumber &&
                touched?.phoneNumber &&
                errors?.phoneNumber
              }
              inputClass=""
              className=""
              containerClassName="!w-full !max-w-[482px]"
              hasTitle
              title="Phone Number"
            />

            <TextInput
              type="text"
              name="email"
              value={values?.email}
              placeholder="Your email address"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={errors?.email && touched?.email && errors?.email}
              inputClass=""
              className=""
              containerClassName="!w-full"
              hasTitle
              title="Email Address"
              disabled
            />

            <TextInput
              type="text"
              name="address"
              value={values?.address || ""}
              placeholder="Your residential address"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={errors?.address && touched?.address && errors?.address}
              inputClass=""
              className=""
              containerClassName="!w-full"
              hasTitle
              title="Address"
            />

            <Dropdown
              value={values?.country}
              options={countries}
              placeholder="Country"
              onChange={(e) => {
                setFieldValue("country", e);
                setFieldValue("state", {});
              }}
              onBlur={handleBlur("country")}
              hasIcon={false}
              errors={errors?.country}
              dropdownClassName="max-h-[250px]"
              onSearch={(searchValue) => {
                const filteredList = settings?.countries.filter((val) => {
                  return val?.value
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes(searchValue?.trim()?.toLowerCase());
                });
                setCountries(filteredList);
              }}
              resetOptionsList={() => {
                setCountries(settings?.countries);
              }}
              isSearchable
              className=""
              hasTitle
              title="Country"
              containerClassName="!w-full !max-w-[294px]"
              dropDownPosition="top"
              loading={false}
            />

            <Dropdown
              value={values?.state}
              options={states}
              placeholder="State"
              onChange={(e) => {
                setFieldValue("state", e);
              }}
              onBlur={handleBlur("state")}
              hasIcon={false}
              errors={errors?.state}
              dropdownClassName="max-h-[250px]"
              onSearch={(searchValue) => {
                const filteredList = settings?.states.filter((val) => {
                  return val?.value
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes(searchValue?.trim()?.toLowerCase());
                });
                setStates(filteredList);
              }}
              resetOptionsList={() => {
                setStates(settings?.states);
              }}
              isSearchable
              className=""
              hasTitle
              title="State"
              containerClassName="!w-full !max-w-[294px]"
              dropDownPosition="top"
              loading={fetchingStates}
            />
          </div>
        </ContainerCard>

        <div className="w-full max-w-[345px]">
          <InformationCard
            info={
              <div>
                To change your{" "}
                <span className="text-pry-green-200 font-medium">
                  email address
                </span>
                , please reach out to support.
              </div>
            }
          />

          <ContainerCard
            className="flex flex-col 
            items-center justify-center
            mt-4
            gap-6
            "
          >
            <ProfilePicture
              className="
              !w-[106px] !h-[106px] 
              !text-2xl !font-bold
              hover:!scale-100
              "
              tempSrc={uploadedPicture && URL.createObjectURL(uploadedPicture)}
            />

            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={profilePicRef}
              multiple={false}
              onChange={(e) => {
                if (e?.target?.files && e?.target?.files[0]) {
                  setUploadedPicture(e?.target?.files[0]);
                }
              }}
            />

            <div
              className="
              w-full 
              items-center justify-center 
              flex flex-row flex-wrap gap-2"
            >
              {uploadedPicture && (
                <Button
                  text="Change"
                  onClick={() => {
                    profilePicRef?.current?.click();
                  }}
                  type="button"
                  className="!text-sm md:!py-3 w-full max-w-[120px]"
                  loading={false}
                  disabled={false}
                  variation="secondary"
                />
              )}

              {!uploadedPicture && (
                <Button
                  text={profile?.Picture ? "Change photo" : "Add photo"}
                  onClick={() => {
                    profilePicRef?.current?.click();
                  }}
                  type="button"
                  className="!text-sm md:!py-3 w-full max-w-[120px]"
                  loading={false}
                  disabled={false}
                  variation={false ? "primary" : "secondary-light"}
                />
              )}
            </div>

            <div
              className="
              w-full max-w-[353px]
              text-center
              "
            >
              <div
                className="
                font-bold text-base
                "
              >
                Help cooperatives to identify you easily
              </div>

              <div className="mt-1 text-pry-dark-green-300/60">
                Your picture will be visible to your cooperatives on ThriftCorp
              </div>
            </div>
          </ContainerCard>
        </div>
      </div>
    </div>
  );
};

export const ProfileEdit = () => {
  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const navigate = useNavigate();

  return profile?.type === "cooperative" ? (
    <CooperativeProfileEdit profile={profile} navigate={navigate} />
  ) : (
    <MemberProfileEdit profile={profile} navigate={navigate} />
  );
};

import React from "react";
import { Icon } from "../icons/icon";

export const ProgressTrail = ({
  steps = [],
  currentStep = 1,
  diasbledIndexes = [],
}) => {
  return (
    <div className="w-full transition-all">
      <div
        className="
        flex flex-row
        items-center justify-between
        gap-2
        w-full
        transition-all
        pb-4 //to-account-for-the-absolute-positioned-step-texts
      "
      >
        {steps?.map((step, idx) => {
          const key = `progressTrailStrep-${idx}`;
          const isLast = idx + 1 === steps?.length && idx + 1 > 1;
          const isFirst = idx + 1 === 1;

          return (
            <div
              key={key}
              className={`
                flex flex-row ${!isLast && "flex-grow"}
                gap-2
                items-center
                transition-all
                
                `}
            >
              <div
                className={`w-fit opacity-40 transition-all ${
                  currentStep >= idx + 1 &&
                  !diasbledIndexes?.includes(idx) &&
                  "!text-pry-green-200 !opacity-100"
                } relative flex flex-col 
                items-center
                ${isLast && "!items-end"}
                ${isFirst && "!items-start"}
                `}
              >
                <Icon svg={"check"} width={20} height={20} />
                <div className="absolute text-xs w-[80px] md:w-max top-[110%] font-medium text-ellipsis overflow-hidden whitespace-nowrap">
                  {step}
                </div>
              </div>
              {!isLast && (
                <div
                  className={`h-[0.5px] w-full border-t ${
                    currentStep >= idx + 2 && "!border-pry-green-200"
                  } transition-all`}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

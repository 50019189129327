import React, { useEffect, useState } from "react";
import { ModalComponent } from "..";
import { copyToClipBoard, numberToString } from "../../../helperFunctions";
import { TagCard } from "../../cards/tagCard";
import { Icon } from "../../icons/icon";
import { TimeText } from "../../typography/timeText";

export const TransactionDetailsModal = ({
  transaction,
  isModalOpen,
  onModalClose,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    isModalOpen &&
      setData([
        {
          title: "Status",
          value: (
            <TagCard
              text={transaction?.isPaymentMade ? "Success" : "Pending"}
              type={transaction?.isPaymentMade ? "success" : "pending"}
            />
          ),
        },
        {
          title: "Amount",
          value: (
            <span className="font-bold">
              ₦{numberToString(transaction?.Amount)}
            </span>
          ),
        },
        transaction?.Unit && {
          title: "Share Quantity",
          value: (
            <span className="font-bold">
              {numberToString(transaction?.Unit)} units
            </span>
          ),
        },
        {
          title: "Reference",
          value: (
            <div className="font-bold flex items-center gap-2">
              {transaction?.reference}
              <Icon
                svg={"copy-icon"}
                width={16}
                height={16}
                className={"text-pry-green-300 cursor-pointer"}
                onClick={() => {
                  copyToClipBoard(transaction?.reference);
                }}
              />
            </div>
          ),
        },
        {
          title: "Payment Method",
          value: transaction?.inAppPayment ? "Online" : "Upload",
        },
        {
          title: "Paid At",
          value: (
            <TimeText
              timeString={transaction?.PaymentDateAdded}
              showTime={false}
            />
          ),
        },
      ]);
    // eslint-disable-next-line
  }, [isModalOpen]);

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
      }}
      title={`Transaction Details`}
      topTitle
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons={false}
      proceedButtonText=""
      onProceedClick={() => {}}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={false}
    >
      <div className="flex flex-col">
        {data?.map((itm, idx) => {
          const key = `transaction-details-${idx}`;
          return (
            itm && (
              <div
                className="
                py-3 border-b flex 
                items-center justify-between 
                first:!pt-0 last:!border-b-0
                text-xs
                "
                key={key}
              >
                <div className="font-medium">{itm?.title}:</div>
                <div>{itm?.value}</div>
              </div>
            )
          );
        })}
      </div>
    </ModalComponent>
  );
};

import React, { useEffect, useState } from "react";
import { Button } from "../buttons/button";
import { Checkbox } from "../inputs/checkbox";
import { Pagination } from "../pagination/pagination";
import { TableComponent } from "./table";

export const MultiSelectApprovalTable = ({
  singleCooperative,
  headers,
  data,
  isLoading,
  refetchData = () => {},
  successCallback = () => {},
  approvalFunction,
  totalCount,
  query,
  setQuery,
  emptyTitle = "You have no pending approvals",
  emptySubTitle = "",
  payloadIdentifierField = "logs",
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [selected, setSelected] = useState([]);

  //   Onload or on query change, refectch
  useEffect(() => {
    singleCooperative?.id && refetchData();
    // eslint-disable-next-line
  }, [query, singleCooperative]);

  // Function to approve or decline one or more approvals
  const doApproval = (accept = true, useStateData = true, data) => {
    setSubmitting(true);
    setIsApproval(accept);

    const payload = {
      coop: singleCooperative?.id,
      corp: singleCooperative?.id,
      [payloadIdentifierField]: useStateData
        ? selected?.map((item) => {
            return { id: item?.id, answer: accept };
          })
        : data,
    };

    approvalFunction &&
      approvalFunction(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          successCallback();
        }
      );
  };

  //   Define table headers
  const tableHeaders = [
    <Checkbox
      key="global-check"
      initialChecked={selected?.length === data?.length}
      onCheck={() => {
        const selectAll = data?.map((item) => {
          return {
            id: item?.id,
            answer: null,
          };
        });
        setSelected(selectAll);
      }}
      onUnCheck={() => {
        setSelected([]);
      }}
    />,
    ...headers,
    selected?.length > 0 ? (
      <div
        key={`Action-all-2`}
        className="flex flex-row gap-2 justify-end items-center"
      >
        <Button
          text={`Decline all (${selected?.length})`}
          onClick={() => {
            doApproval(false);
          }}
          type="button"
          className="!py-1 !px-4 !text-sm !w-fit"
          loading={submitting && !isApproval}
          disabled={submitting}
          variation="secondary-2"
        />
        <Button
          text={`Accept all (${selected?.length})`}
          onClick={() => {
            doApproval(true);
          }}
          type="button"
          className="!py-1 !px-4 !text-sm !w-fit"
          loading={submitting && isApproval}
          disabled={submitting}
          variation="primary-dark"
        />
      </div>
    ) : (
      <div className="h-[28px]" />
    ),
  ];

  return (
    <div className="w-full overflow-x-scroll">
      <TableComponent
        emptyTitle={emptyTitle}
        emptySubTitle={emptySubTitle}
        empt
        isTableEmpty={!data?.length || data?.length < 1}
        isLoading={isLoading}
        headers={tableHeaders}
        rows={data?.map((dataItem, idx) => {
          const key = `multi-select-row-${idx}`;

          const alreadySelected = selected
            ?.map((selectedItem) => {
              return selectedItem?.id;
            })
            ?.includes(dataItem?.id);

          return {
            id: dataItem?.id,
            items: [
              <div className="flex gap-2 items-center" key={`${key}-1`}>
                <Checkbox
                  initialChecked={alreadySelected}
                  onCheck={() => {
                    setSelected([
                      ...selected,
                      {
                        id: dataItem?.id,
                        answer: null,
                      },
                    ]);
                  }}
                  onUnCheck={() => {
                    setSelected(
                      selected?.filter(
                        (selectedItem) => selectedItem?.id !== dataItem?.id
                      )
                    );
                  }}
                />
              </div>,
              ...dataItem.data,
              selected?.length < 1 ? (
                <div
                  key={`${key}-2`}
                  className="
                  flex flex-row gap-2 justify-end items-center
                  "
                >
                  <Button
                    text="Decline"
                    onClick={() => {
                      const selection = [
                        {
                          id: dataItem?.id,
                          answer: false,
                        },
                      ];
                      setSelected(selection);

                      doApproval(false, false, selection);
                    }}
                    type="button"
                    className="!py-1 !px-4 !text-sm !w-fit"
                    loading={submitting && !isApproval}
                    disabled={submitting}
                    variation="secondary-2"
                  />
                  <Button
                    text="Accept"
                    onClick={() => {
                      const selection = [
                        {
                          id: dataItem?.id,
                          answer: true,
                        },
                      ];
                      setSelected(selection);

                      doApproval(true, false, selection);
                    }}
                    type="button"
                    className="!py-1 !px-4 !text-sm !w-fit"
                    loading={submitting && isApproval}
                    disabled={submitting}
                    variation="primary"
                  />
                </div>
              ) : (
                <div className="h-[28px]" />
              ),
            ],
          };
        })}
      />

      <div className="w-full mt-4">
        <Pagination
          limit={query?.limit}
          totalCount={totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
        />
      </div>
    </div>
  );
};

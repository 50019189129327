import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons/icon";
import { Button } from "./button";

export const WebsiteButton = ({ text, to, className, variation }) => {
  const navigate = useNavigate();

  return (
    <Button
      text={text}
      onClick={() => {
        navigate(to);
      }}
      className={`${className} !rounded-[40px]`}
      variation={variation}
      loading={false}
      disabled={false}
      extra={
        <Icon
          svg="left-arrow"
          width={21}
          height={20}
          className={"rotate-180"}
        />
      }
    />
  );
};

WebsiteButton.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
  to: PropTypes.any,
  variation: PropTypes.any,
};

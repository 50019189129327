import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { PageHeader } from "../../../../components/layouts/pageHeader";
import { MembershipSettingsView } from "./membershipSettingsView";

export const MembershipSettingsEdit = () => {
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const clearButtonRef = useRef();
  const saveButtonRef = useRef();

  return (
    <div className="w-full">
      <PageHeader
        hasBack={true}
        onBackClick={() => navigate("/app/settings?tab=membership_settings")}
        pageTitle={`Membership Settings`}
        pageTitleClassName="max-w-[100px] md:max-w-max"
        className={`mb-[28px]`}
        hasRightChildren={true}
        rightChildren={
          <div
            className="
            flex flex-row
            gap-2
            w-full md:w-max
            "
          >
            <Button
              text="Clear"
              onClick={() => {
                clearButtonRef?.current?.click();
              }}
              type="button"
              className="md:!w-[107px] md:!py-[12px] hidden md:flex"
              loading={false}
              disabled={false}
              variation="plain"
            />

            <Button
              text="Save"
              onClick={() => {
                saveButtonRef?.current?.click();
              }}
              type="button"
              className="!w-[100px] md:!w-[107px] md:!py-[12px]"
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              variation="primary"
            />
          </div>
        }
      />
      <MembershipSettingsView
        mode="edit"
        clearButtonRef={clearButtonRef}
        saveButtonRef={saveButtonRef}
        setIsSubmitting={setIsSubmitting}
        setIsValid={setIsValid}
      />
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dialCodes: [],
  countries: [],
  states: [],
};

const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    /**Handles dial codes update */
    dialCodes_updated: (state, action) => {
      state.dialCodes = action.payload;
    },
    /**Handles countries update */
    countries_updated: (state, action) => {
      state.countries = action.payload;
    },
    /**Handles states update */
    states_updated: (state, action) => {
      state.states = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const SettingsSliceReducer = SettingsSlice.reducer;
export const SettingsSliceActions = SettingsSlice.actions;

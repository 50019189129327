import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../api/requestHelpers";
import { doFileDownload } from "../helperFunctions";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { savingsSliceActions } from "../redux/features/savings/savings";

export const useSavings = () => {
  const dispatch = useDispatch();

  const fetchMySavingsInformation = async (
    params = {
      coop: undefined,
      logs: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(savingsSliceActions.fetchingMySavings(true));
    await getRequest("Saving/My/Cooperative", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(savingsSliceActions.mySavingsUpdated(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(savingsSliceActions.fetchingMySavings(false));
    callback();
  };

  const fetchMySavingsHistory = async (
    params = {
      coop: undefined,
      logs: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(savingsSliceActions.fetchingMySavingsHistory(true));
    await getRequest("Saving/My/Cooperative", params, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.Logs?.count,
          data: data?.Logs?.results,
        };

        dispatch(savingsSliceActions.mySavingsHistoryUpdated(result));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(savingsSliceActions.fetchingMySavingsHistory(false));
    callback();
  };

  const fetchCooperativeSavingsInformation = async (
    params = {
      coop: undefined,
      logs: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(savingsSliceActions.fetchingCooperativeSavings(true));
    await getRequest("Saving/Cooperative/Admin", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(
          savingsSliceActions.cooperativeSavingsUpdated({
            Balance: data?.summary,
          })
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(savingsSliceActions.fetchingCooperativeSavings(false));
    callback();
  };

  const fetchCooperativeMemberSavingsInformation = async (
    params = {
      coop: undefined,
      logs: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(savingsSliceActions.fetchingCooperativeMemberSavings(true));
    await getRequest("Saving/Cooperative/Admin", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(
          savingsSliceActions.cooperativeMemberSavingsUpdated({
            totalCount: data?.member?.returncount,
            data: data?.member?.results,
          })
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(savingsSliceActions.fetchingCooperativeMemberSavings(false));
    callback();
  };

  const downloadBulkSavingsTemplate = async (
    params = {
      coop: undefined,
      file: "csv",
    },
    fileName = "sample",
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Saving/Member/Manual/file/upload", params, true)
      .then((resp) => {
        doFileDownload(resp, fileName, params?.file);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doBulkSavingsUpload = async (
    payload,
    callback = () => {},
    successCallback = (data) => {}
  ) => {
    await postRequest("Saving/Member/Manual/file/upload", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Savings uploaded for approval sucessfully", "success");

        // Call success callback
        successCallback(data);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doAdminAddSavings = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Saving/Member/Manual/Admin", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doMemberAddSavings = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Saving/Member/Manual", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    fetchMySavingsInformation,
    fetchCooperativeSavingsInformation,
    fetchCooperativeMemberSavingsInformation,
    downloadBulkSavingsTemplate,
    doBulkSavingsUpload,
    fetchMySavingsHistory,
    doAdminAddSavings,
    doMemberAddSavings,
  };
};

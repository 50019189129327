import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "..";
import { usePayment } from "../../../customHooks/usePayment";
import { PageLoader } from "../../loaders/pageLoader";
import { PaymentModal } from "./paymentModal";

export const CardTokenizationModal = ({
  callback,
  modalOpen,
  handleClose = () => {},
}) => {
  const [fetchingLink, setFetchingLink] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  const { getPaymentLink, addNewCard } = usePayment();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  useEffect(() => {
    if (modalOpen) {
      setFetchingLink(true);
      getPaymentLink(
        {
          callbackurl: `${window.location.protocol}//${window.location.hostname}/app/success`,
          amount: 50,
          email: profile?.Email,
          type: "tokenization",
        },
        () => {
          setFetchingLink(false);
        },
        (data) => {
          setFetchingLink(false);
          setPaymentData(data?.data);
        }
      );
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  return (
    modalOpen && (
      <div>
        {fetchingLink ? (
          <ModalComponent
            isModalOpen={true}
            onModalClose={handleClose}
            hasButtons={false}
            modalClassName="!max-h-[500px] !overflow-scroll"
            childrenClassName="h-full !mt-[8px]"
          >
            <PageLoader />
          </ModalComponent>
        ) : (
          <PaymentModal
            paymentData={paymentData}
            handleVerification={() => {
              addNewCard(
                {
                  trxref: paymentData?.reference,
                  reference: paymentData?.reference,
                },
                () => {},
                () => {
                  callback();
                  handleClose();
                }
              );
            }}
            isModalOpen={true}
            onModalClose={handleClose}
          />
        )}
      </div>
    )
  );
};

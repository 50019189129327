import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import empty_approvals from "../../../assets/images/empty_approvals.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { CooperativeProfilePic } from "../../../components/profile/cooperativePicture";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useApprovals } from "../../../customHooks/useApprovals";

export const Approvals = () => {
  const [query, setQuery] = useState({});

  const { approvalSummary } = useSelector((state) => {
    return state.app;
  });

  const { fetchApprovalsSummary } = useApprovals();

  const navigate = useNavigate();

  useEffect(() => {
    fetchApprovalsSummary();
    // eslint-disable-next-line
  }, []);

  if (!approvalSummary?.Official) {
    return <Navigate to="/app/overview" replace />;
  }

  return (
    <div className="w-full min-h-full">
      <PageTitle
        text="Approvals"
        subtext="Manage all your cooperative approvals in one place."
      />

      <div
        className="
        mt-6
        "
      >
        <FilterComponent
          searchPlaceholder="Search any cooperative name"
          hasExtraFilter={false}
          handleSearch={(e) => {
            setQuery({ ...query, text: e, offset: 0 });
          }}
        />
      </div>

      <div className="mt-[32px]">
        {!approvalSummary?.totalapprovalCount ? (
          <ContainerCard>
            <EmptyState
              image={<img src={empty_approvals} alt="approvals" />}
              title="You have no pending approvals"
              subtitle="All your cooperative approvals will be displayed here whenever you need to approve any action."
            />
          </ContainerCard>
        ) : (
          <div className="flex flex-col gap-2">
            {approvalSummary?.approvalList?.map((item, idx) => {
              const key = `cooperativeApproval${idx}`;
              const totalApprovalCount =
                item?.loanApproval +
                item?.memberApproval +
                item?.paymentApproval +
                item?.paymentDepositApproval +
                item?.paymentSharesApproval +
                item?.memberRemoval +
                item?.loanRepaymentApproval +
                item?.withdrawalApproval;
              return (
                <ContainerCard
                  key={key}
                  className="w-full !py-3 !px-6 overflow-hidden"
                >
                  <div className="w-full flex flex-wrap gap-4 items-center justify-between">
                    <div className="flex items-center gap-4">
                      <CooperativeProfilePic
                        cooperative={item?.Cooperative}
                        className="!w-[48px] !h-[48px]"
                      />

                      <div className="text-base text-pry-dark-green-100 font-medium">
                        {item?.Cooperative?.CoopName}
                      </div>
                    </div>

                    <div className="w-full md:w-fit md:max-w-[295px] md:flex-none">
                      <Button
                        text="Manage Approvals"
                        onClick={() => {
                          navigate(`/app/approvals/${item?.Cooperative?.id}`);
                        }}
                        type="button"
                        className="md:!py-2 !px-4"
                        loading={false}
                        disabled={false}
                        icon={undefined}
                        variation="primary"
                        extra={
                          <div
                            className="
                          text-xs bg-white text-pry-green-200 font-medium
                          p-1 h-[20px] min-w-[20px] rounded-full
                          flex items-center justify-center
                          "
                          >
                            {totalApprovalCount}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </ContainerCard>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Icon } from "../icons/icon";
import { PageLoader } from "../loaders/pageLoader";
import { ErrorText } from "../typography/errorText";

export const Dropdown = ({
  value /**  format => {id: "", title: "", value: ""}*/,
  options = [] /** format => {id: "", title: "", value: ""}*/,
  resetOptionsList = () => {} /**  Reset the dropdown options when dropdown is closed*/,
  placeholder = "Placeholder",
  onChange = () => {},
  onBlur = () => {},
  hasIcon = true,
  icon,
  errors,
  dropdownClassName = "",
  onSearch = () => {},
  isSearchable = true,
  className = "",
  hasTitle = false,
  title = "",
  containerClassName,
  dropDownPosition = "bottom",
  loading = false,
}) => {
  const [selected, setSelected] = useState(value ? value : {});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const inputRef = useRef();

  useEffect(() => {
    if (dropdownOpen && isSearchable) {
      inputRef?.current?.focus();
    }
    if (!dropdownOpen) {
      // Reset option list to default, in cases where already filtered
      resetOptionsList();
    }
    // eslint-disable-next-line
  }, [dropdownOpen, isSearchable]);

  useEffect(() => {
    // Once selected value is changed, act according. But set to undefined if selected is empty
    if (Object.keys(selected)?.length === 0) {
      onChange(undefined);
    } else {
      onChange(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    value && Object.keys(value)?.length > 0
      ? setSelected(value)
      : setSelected({});
  }, [value]);

  return (
    <div className={containerClassName}>
      {hasTitle && (
        <div
          className="
            text-sm 
            text-pry-dark-green-300/80 font-medium
            mb-1
            "
        >
          {title}
        </div>
      )}
      <div
        className={`
        group
        w-full
        px-[17px] py-[14px]
        flex flew-row items-center gap-4
        text-sm font-medium
        bg-light-green-bg
        border rounded-lg border-transparent
        focus-within:border-pry-green-200
        ${dropdownOpen && "!border-pry-green-200"}
        transition-all
        cursor-pointer
        relative
        ${className}
        ${errors && "focus-within:!border-red-200 !bg-light-red-bg"}
        `}
        onBlur={onBlur}
      >
        {hasIcon && (
          <Icon
            svg={icon ? icon : "sms"}
            width={20}
            height={20}
            className="w-[24px]"
          />
        )}

        <input
          ref={inputRef}
          className={`
          bg-transparent w-full
          outline-none focus:outline-none
          placeholder:text-pry-dark-green-300/50
          autofill:focus:bg-transparent autofill:bg-transparent
          `}
          type="text"
          value={selected?.title || textValue}
          placeholder={placeholder}
          onChange={(e) => {
            setSelected({}); // No typed valued should be a valid selection; this enforces selction of options...not typing of options
            setTextValue(e?.target?.value);
            onSearch(e?.target?.value);
          }}
          onBlur={onBlur}
          disabled={!isSearchable}
          onFocus={() => {
            setDropdownOpen(true);
          }}
          onClick={() => {
            setDropdownOpen(true);
          }}
          autoComplete="off"
        />

        <Icon
          svg={"caret-down"}
          width={20}
          height={20}
          className={`
          w-[20px] 
          group-hover:scale-90 group-hover:text-pry-green-200
          transition-all
          ${dropdownOpen && "rotate-180"}
          `}
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        />

        {dropdownOpen && (
          <ClickAwayListener
            onClickAway={() => {
              setDropdownOpen(false);
            }}
            mouseEvent="mousedown"
            touchEvent="touchstart"
          >
            <div
              className={`absolute left-0 right-0 z-[100] ani-drop 
              ${dropDownPosition === "top" && "!bottom-[120%]"}
              ${dropDownPosition === "bottom" && "!top-[120%]"}
              `}
            >
              {loading ? (
                <div
                  className={`
              border
              bg-white 
              rounded-lg
              w-full
              py-[16px] px-4
              flex flex-col
              ${dropdownClassName}
              overflow-scroll
              shadow-lg
              `}
                >
                  <PageLoader
                    width={25}
                    height={25}
                    className="!min-h-[100px]"
                    variant="spinnner"
                  />
                </div>
              ) : (
                <div
                  className={`
                border
                bg-white 
                rounded-lg
                w-full
                py-[16px] px-4
                flex flex-col
                ${dropdownClassName}
                overflow-scroll
                shadow-lg
                `}
                >
                  {options?.map((val, idx) => {
                    const key = `${val?.id}-${idx}`;
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          setSelected(val);
                          setDropdownOpen(false);
                        }}
                        className={`
                    text-sm font-medium
                    text-pry-dark-green-300/80
                    px-[16px] py-[12px]
                    border-t
                    first:border-t-0
                    border-border-grey-2
                    hover:bg-light-green-bg
                    hover:text-pry-green-200
                    hover:rounded-lg
                    transition-all
                    ${!val && "!hidden"}
                    `}
                      >
                        {val?.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
      {errors && <ErrorText text={errors} />}
    </div>
  );
};

import PropTypes from "prop-types";
import React from "react";

export const ContainerCard = ({ children, className }) => {
  return (
    <div
      className={`bg-white border border-border-grey
        p-4 md:p-8 rounded-lg w-full ${className}`}
    >
      {children}
    </div>
  );
};

ContainerCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
};

import PropTypes from "prop-types";
import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useSelector } from "react-redux";
import { useLayout } from "../../customHooks/useLayout";
import { isProfileApproved } from "../../helperFunctions";
import { SideNav } from "../navs/sideNav";
import { TopNav } from "../navs/topNav";
import { OnboardingPopup } from "../popups/onboardingPopup";

export const AppLayout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);

  const popupOpen = useSelector((state) => {
    return state.layout.onboardingPopupOpen;
  });

  const onboardingCompletionStatus = useSelector((state) => {
    return state?.auth?.onboardingCompletionStatus;
  });

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const { setPopupOpen } = useLayout();

  return (
    <div
      className="
      relative !bg-light-green-bg
      flex w-full max-w-[100vw] h-screen max-h-[100dvh]
      transition-all
      overflow-x-hidden
      "
    >
      <ClickAwayListener
        onClickAway={() => {
          if (navOpen) {
            setNavOpen(false);
          }
        }}
        mouseEvent="mousedown"
        touchEvent="touchstart"
        className="min-w-max"
      >
        <div
          className={`
          z-[999]
          w-full md:min-w-[272px] max-w-[272px]
          h-full absolute lg:relative
          ${navOpen ? "left-0 flex" : "-left-[300px] lg:left-0 lg:flex"}
          ${popupOpen && "!z-[100]"}
          transition-all duration-500
          `}
        >
          <SideNav navOpen={navOpen} setNavOpen={setNavOpen} />
        </div>
      </ClickAwayListener>

      <div
        className="!z-[99]
        flex flex-col
        h-full !w-full !max-w-full
        relative
        overflow-x-hidden
       "
      >
        {/* SideNav open overlay  */}
        {navOpen && (
          <div
            className="
            absolute inset-0 lg:hidden 
            bg-black/50
            transition-all duration-200
            z-[998]
            "
          ></div>
        )}

        <TopNav navOpen={navOpen} setNavOpen={setNavOpen} />
        <div
          className="
          overflow-y-scroll h-full
          px-5 md:!px-8 xl:!px-[72px] lg:!pt-10
          py-6
          text-pry-dark-green-400
          !pb-[200px] lg:!pb-[400px]
          !w-full md:!max-w-full 2xl:max-w-[1500px] hide-scroll-bar
        "
        >
          {children}
        </div>
      </div>

      {/* Overlay div */}
      <div
        className={`absolute hidden z-[997] transition-all duration-[1000ms]
        ${popupOpen && "!flex left-0 !bottom-0 !top-0 !right-0 bg-black/50"}
        `}
      ></div>
      {/* Popup div */}
      {isProfileApproved(profile) &&
        !onboardingCompletionStatus?.isAllComplete && (
          <div
            className={`
        absolute bottom-0 md:bottom-10 pb-0 md:right-20 flex justify-end items-end
        transition-all duration-[1000ms] !z-[998] w-full md:w-fit
        ${
          popupOpen &&
          `!bottom-0 !top-0
          md:!pb-10 md:!pt-20
          !w-full md:!max-w-[590px] 
          !h-full
          `
        }
        flex
        `}
          >
            <OnboardingPopup
              popupOpen={popupOpen}
              setPopupOpen={setPopupOpen}
            />
          </div>
        )}
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

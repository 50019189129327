import React from "react";
import default_image from "../../assets/images/empty_state_coming_soon.svg";
import { Button } from "../buttons/button";

export const EmptyState = ({
  image = <img src={default_image} alt="empty" />,
  title,
  titleClassName = "",
  subtitle,
  subtitleClassName,
  actionText,
  onClick = () => {},
  actionButtonVariant = "primary",
  actionDisabled = false,
  buttonContainerClassName = "",
  imageClassName = "",
  className,
}) => {
  return (
    <div
      className={`
        w-full
        flex flex-col
        justify-center items-center
        gap-6
        px-4 md:px-0
        py-8 md:py-0
        ${className}
        `}
    >
      <div
        className={`ani-shake max-w-[150px] md:max-w-[200px] md:px-0 ${imageClassName}`}
      >
        {image}
      </div>
      <div className="max-w-[374px]">
        <div
          className={`text-base md:text-[20px] font-bold text-center ${titleClassName}`}
        >
          {title}
        </div>
        <div
          className={`mt-2 text-center text-sm md:text-sm text-pry-dark-green-300/80 ${subtitleClassName}`}
        >
          {subtitle}
        </div>
      </div>
      {actionText && (
        <div className={`w-full !max-w-[327px] ${buttonContainerClassName}`}>
          <Button
            text={actionText}
            onClick={onClick}
            type="button"
            className="w-full md:!py-[12px]"
            loading={false}
            disabled={actionDisabled}
            variation={actionButtonVariant}
          />
        </div>
      )}
    </div>
  );
};

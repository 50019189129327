import React, { useEffect, useState } from "react";
import { ModalComponent } from "..";
import { useMembership } from "../../../customHooks/useMembership";
import { decodeUrl } from "../../../helperFunctions";
import { Icon } from "../../icons/icon";
import { PageLoader } from "../../loaders/pageLoader";
import { ImageViewModal } from "./imageViewModal";

export const MemberDetailsModal = ({
  modalOpen,
  onModalClose = () => {},
  member,
}) => {
  const [loading, setLoading] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [data, setData] = useState({});

  const { fetchCooperativeMembershipDetails } = useMembership();

  useEffect(() => {
    setLoading(true);
    const params = {
      form: "true",
      coop: member?.Cooperative?.id,
      id: member?.id,
    };

    member &&
      fetchCooperativeMembershipDetails(
        params,
        () => {
          setLoading(false);
        },
        (data) => {
          setData(data);
          setLoading(false);
        }
      );
    // eslint-disable-next-line
  }, [member]);

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title={"Member Details"}
      topTitle
      subTitle={undefined}
      childrenClassName="!mt-0"
      childrenContainerClassName=""
      modalClassName="!max-h-[480px] md:!max-w-[792px] !overflow-y-scroll"
      hasButtons={false}
      proceedButtonText=""
      proceedButtonClassName=""
      onProceedClick={() => {}}
      proceedButtonVariant="secondary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisable={false}
      buttonsContainerClassName="!justify-center"
      blank
    >
      {loading ? (
        <PageLoader />
      ) : (
        <div
          className="
        !w-full !h-full
        bg-light-green-bg
        flex flex-wrap
        absolute inset-0 -z-[1]
        "
        >
          <div
            className="bg-white w-full
            lg:w-6/12 pl-[24px] lg:pl-[40px] pr-[24px] pt-[88px]
        "
          >
            <div className="flex flex-col gap-3">
              {data?.User &&
                Object.keys(data?.User)?.map((val, idx) => {
                  const key = `${val}-${idx}`;
                  return (
                    <div
                      key={key}
                      className={`flex justify-between pb-3 border-b text-xs ${
                        val.toLowerCase() === "picture" && "!hidden"
                      }`}
                    >
                      <div
                        className={`font-medium text-pry-dark-green-300/80 `}
                      >
                        {val?.split(/(?=[A-Z])/)?.join(" ")}:
                      </div>
                      <div className="text-pry-dark-green-300/70">
                        {data?.User[val]?.replaceAll("#", "")}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="bg-transparent w-full 
            lg:w-6/12 pr-[24px] lg:pr-[40px] pl-[24px] pt-[24px] lg:pt-[88px]
        "
          >
            <div className="flex flex-col gap-3">
              {/* More info  */}
              <div className="text-base font-medium">More Information</div>
              {/* Photo  */}
              <div className="flex justify-between pb-3 border-b-0 text-xs">
                <div className="font-medium text-pry-dark-green-300/80">
                  Personal Photograph:
                </div>
                <div
                  className="
                    text-pry-green-200 
                    font-medium flex items-center gap-1
                    cursor-pointer
                    "
                  onClick={() => {
                    data?.User?.Picture && setImgModalOpen(true);
                  }}
                >
                  <Icon svg="small-image-icon" width={16} height={16} />
                  {data?.User?.Picture ? "View photo" : "No photo"}
                </div>
              </div>
              {/* Other Info iteration */}
              {data?.formdata?.map((formItem, idx) => {
                const key = `${formItem?.Data}-${idx}`;
                return (
                  <div
                    className="flex justify-between pb-3 border-b-0 text-xs"
                    key={key}
                  >
                    <div className="font-medium text-pry-dark-green-300/80">
                      {formItem?.Question}:
                    </div>
                    <div className="text-pry-dark-green-300/70">
                      {formItem?.Data}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <ImageViewModal
        modalOpen={imgModalOpen}
        onModalClose={() => {
          setImgModalOpen(false);
        }}
        src={decodeUrl(data?.User?.Picture)}
      />
    </ModalComponent>
  );
};

import { useDispatch } from "react-redux";
import { layoutSliceActions } from "../redux/features/layout/layout";

export const useLayout = () => {
  const dispatch = useDispatch();

  const setPageTitle = (title) => {
    dispatch(layoutSliceActions.changed(title));
  };

  const setPopupOpen = (value) => {
    dispatch(layoutSliceActions.onboardingPopupChanged(value));
  };

  return {
    setPageTitle,
    setPopupOpen,
  };
};

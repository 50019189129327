import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../api/requestHelpers";
import { doFileDownload } from "../helperFunctions";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { appSliceActions } from "../redux/features/app/app";

export const useMembership = () => {
  const dispatch = useDispatch();

  const downloadMembershipTemplate = async (
    params = {
      coop: undefined,
      file: "csv",
    },
    fileName = "sample",
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Membership/Detail/upload", params, true)
      .then((resp) => {
        doFileDownload(resp, fileName, params?.file);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doBulkMembershipupload = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Membership/Detail/upload", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        if (data?.numberError > 0) {
          data?.error?.map((error) => {
            doNotification(
              `On row ${error?.LineNumber}: ${error?.error}`,
              "error"
            );
            return null;
          });
          return null;
        }

        doNotification("Members uploaded sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchInvitations = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingInvitations(true));
    await getRequest("Settings/invitation", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(appSliceActions.invitationsFetched(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(appSliceActions.fetchingInvitations(false));
    callback();
  };

  const acceptOfficialInvite = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Register/Official/Member/AR", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Invitation accepted sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const rejectOfficialInvite = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Register/Official/Member/AR", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Invitation rejected sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const rejectMembershipInvite = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Membership/Invitation/link/decline", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Invitation rejected sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchCooperativeMembershipDetails = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingInvitations(true));
    await getRequest("Membership/Detail/Admin", params, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback(data);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(appSliceActions.fetchingInvitations(false));
    callback();
  };

  const initiateMemberRemoval = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Membership/Removal", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Removal initiated sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    downloadMembershipTemplate,
    doBulkMembershipupload,
    fetchInvitations,
    acceptOfficialInvite,
    rejectMembershipInvite,
    rejectOfficialInvite,
    fetchCooperativeMembershipDetails,
    initiateMemberRemoval,
  };
};

import { createSlice } from "@reduxjs/toolkit";
import { updateOnboardingCompletionStatus } from "../../../helperFunctions";

const profileString = localStorage.getItem("profile");
const profileObject = JSON.parse(profileString);

const initialState = {
  fetchingProfile: false,
  profile: profileString ? profileObject : {},
  onboardingCompletionStatus: updateOnboardingCompletionStatus(profileObject),
  memberBanks: [],
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetching_profile: (state, action) => {
      state.fetchingProfile = action.payload;
    },
    /**Handles profile update */
    profile_updated: (state, action) => {
      // Whenever this action is dispatched, both local Storage
      // and application state should be updated
      const updated_profile = { ...state.profile, ...action.payload };
      state.profile = updated_profile;
      state.onboardingCompletionStatus =
        updateOnboardingCompletionStatus(updated_profile);
      localStorage.setItem("profile", JSON.stringify(updated_profile));
    },
    profile_cleared: (state) => {
      state.profile = {};
      state.onboardingCompletionStatus = {};
    },
    memberBanks_updated: (state, action) => {
      state.memberBanks = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const AuthSliceReducer = AuthSlice.reducer;
export const AuthSliceActions = AuthSlice.actions;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ModalComponent } from "..";
import { useLoans } from "../../../customHooks/useLoans";
import { numberToString } from "../../../helperFunctions";
import { TagCard } from "../../cards/tagCard";
import { PageLoader } from "../../loaders/pageLoader";
import { TableComponent } from "../../table/table";
import { TimeText } from "../../typography/timeText";

export const RepaymentScheduleModal = ({
  isModalOpen,
  onModalClose,
  doFetch = false,
  schedule = [],
  loanStatus = "new", //new | existing
  loanId,
  singleCooperative = {},
  mode = "personal",
}) => {
  const [repaymentSchedule, setRepaymentSchedule] = useState(schedule);

  const { fetchSingleLoanDetail, adminFetchSingleLoanDetail } = useLoans();

  const loanDetails = useQuery(
    [
      "fetchLoanDetails",
      { loan_id: loanId, detail: true, coop: singleCooperative?.id },
    ],
    fetchSingleLoanDetail,
    {
      enabled: false,
    }
  );

  const adminloanDetails = useQuery(
    [
      "adminFetchLoanDetails",
      { loan_id: loanId, detail: true, coop: singleCooperative?.id },
    ],
    adminFetchSingleLoanDetail,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    isModalOpen && doFetch && mode === "personal" && loanDetails?.refetch();
    isModalOpen && doFetch && mode === "admin" && adminloanDetails?.refetch();
    // eslint-disable-next-line
  }, [isModalOpen]);

  useEffect(() => {
    doFetch &&
      mode === "personal" &&
      setRepaymentSchedule(loanDetails?.data?.Repayment);
    // eslint-disable-next-line
  }, [loanDetails?.data]);

  useEffect(() => {
    doFetch &&
      mode === "admin" &&
      setRepaymentSchedule(adminloanDetails?.data?.Repayment);
    // eslint-disable-next-line
  }, [adminloanDetails?.data]);

  useEffect(
    () => {
      !doFetch && setRepaymentSchedule(schedule);
    }, // eslint-disable-next-line
    [schedule]
  );

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Repayment Schedule"
      topTitle
      subTitle=""
      childrenClassName=""
      modalClassName="!max-h-[572px] !max-w-[1038px]"
      hasButtons={false}
      proceedButtonText=""
      onProceedClick={() => {}}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={false}
    >
      {loanDetails?.isLoading || adminloanDetails?.isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <TableComponent
            className=""
            headers={[
              "DATE",
              "REPAYMENT AMOUNT",
              "PRINCIPAL",
              "INTEREST",
              "PENALTY FEE",
              "STATUS",
              "PAYMENT DATE",
            ]}
            rows={repaymentSchedule?.map((item, idx) => {
              const key = `schedule-${idx}`;
              return {
                id: item?.installment_number,
                items: [
                  <TimeText
                    timeString={item?.due_date}
                    key={`${key}-dueDate`}
                    showTime={false}
                  />,
                  `₦${numberToString(item?.amount_due)}`,
                  `₦${numberToString(item?.principal_due)}`,
                  `₦${numberToString(item?.interest_due)}`,
                  item?.isLate ? `₦${numberToString(item?.lateFee)}` : "N/A",
                  <TagCard
                    text={
                      loanStatus === "new" || !item?.isPaid ? `Pending` : `Paid`
                    }
                    type={
                      loanStatus === "new" || !item?.isPaid
                        ? `pending`
                        : `success`
                    }
                    key={`${key}-status`}
                  />,
                  loanStatus === "new" ? (
                    "N/A"
                  ) : (
                    <TimeText timeString={""} key={`${key}-paymentDate`} />
                  ),
                ],
              };
            })}
          />
        </div>
      )}
    </ModalComponent>
  );
};

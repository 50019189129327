import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { TextInput } from "../../../../components/inputs/textInput";
import { TitleSub } from "../../../../components/typography/titleSub";
import { useSettings } from "../../../../customHooks/useSettings";
import { sharesConfigurationSchema } from "../../../../validationSchema";

export const SharesConfiguration = ({
  mode = "",
  cooperativeConfiguration,
}) => {
  const { setSharesConfigurations } = useSettings();

  const navigate = useNavigate();

  const sharesForm = useFormik({
    initialValues: {
      price: cooperativeConfiguration?.data?.share?.InitialSharePrice || "",
      minAmount: cooperativeConfiguration?.data?.share?.MinShares || "",
      sharesInterestRate:
        cooperativeConfiguration?.data?.share?.InterestRate || "",
    },
    validationSchema: sharesConfigurationSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      setSharesConfigurations(
        {
          InterestRate: data?.sharesInterestRate,
          InitialSharePrice: data?.price,
          MinShares: data?.minAmount,
        },
        () => {
          setSubmitting(false);
          cooperativeConfiguration?.refetch();
        },
        () => {}
      );
    },
  });

  return (
    <ContainerCard className="!px-0">
      {/* Mini header  */}
      <div
        className="
              w-full flex flex-row gap-4
              items-center justify-between
              mb-6
              px-4 md:px-8
              "
      >
        <div
          className="
                font-bold text-base
                "
        >
          Shares Settings
        </div>
        <div className="!w-full !max-w-[80px] md:!max-w-[96px]">
          <Button
            text={mode === "edit" ? "Save" : "Edit"}
            onClick={() => {
              mode === "edit"
                ? sharesForm?.handleSubmit()
                : navigate("/app/settings/configurations/edit/");
            }}
            type="button"
            className="!text-sm md:!py-2"
            loading={sharesForm?.isSubmitting}
            disabled={sharesForm?.isSubmitting}
            variation="primary"
          />
        </div>
      </div>

      {/* Configuration Sub-body */}
      <div
        className="
              px-4 md:px-8
              border-b py-3
              "
      >
        <TitleSub
          title="Initial Share Price"
          subtitle={"What’s the cooperative’s initial share price?"}
          subtitleClassName="!mt-0"
        />

        <div className="mt-3">
          {mode === "view" && (
            <div className="text-sm md:text-base text-pry-green-200 font-medium">
              {cooperativeConfiguration?.data?.share?.InitialSharePrice
                ? `₦${Number(
                    cooperativeConfiguration?.data?.share?.InitialSharePrice
                  )?.toLocaleString()}`
                : "No initial share price set"}
            </div>
          )}

          {mode === "edit" && (
            <TextInput
              type="text"
              name={"price"}
              value={sharesForm?.values?.price}
              placeholder="Minimum share price"
              onChange={sharesForm?.handleChange}
              onBlur={sharesForm?.handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                sharesForm?.touched?.price &&
                sharesForm?.errors?.price &&
                sharesForm?.errors?.price
              }
              inputClass=""
              className="!p-0 !pr-4"
              autoComplete="off"
              customIcon={
                <div
                  className="
                        font-medium text-sm md:text-base
                        py-[14px] px-[16px]
                        bg-white
                        border border-pry-dark-green-300/10
                        rounded-s-lg
                        "
                >
                  ₦
                </div>
              }
              customIconPosition="left"
            />
          )}
        </div>
      </div>

      <div
        className="
              px-4 md:px-8
              border-b py-3
              "
      >
        <TitleSub
          title="Minimum Shares To Buy"
          subtitle={"What’s the minimum amount of shares that can be bought?"}
          subtitleClassName="!mt-0"
        />

        <div className="mt-3">
          {mode === "view" && (
            <div className="text-sm md:text-base text-pry-green-200 font-medium">
              {cooperativeConfiguration?.data?.share?.MinShares
                ? cooperativeConfiguration?.data?.share?.MinShares
                : "No amount set"}
            </div>
          )}

          {mode === "edit" && (
            <TextInput
              type="text"
              name={"minAmount"}
              value={sharesForm?.values?.minAmount}
              placeholder="Minimum share to buy"
              onChange={sharesForm?.handleChange}
              onBlur={sharesForm?.handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                sharesForm?.touched?.minAmount &&
                sharesForm?.errors?.minAmount &&
                sharesForm?.errors?.minAmount
              }
              inputClass=""
              className="!p-0 !pl-4"
              autoComplete="off"
              customIcon={
                <div
                  className="
                        font-medium text-sm md:text-base
                        py-[14px] px-[16px]
                        bg-white
                        border border-pry-dark-green-300/10
                        rounded-e-lg
                        "
                >
                  Shares
                </div>
              }
              customIconPosition="right"
            />
          )}
        </div>
      </div>

      <div
        className="
              px-4 md:px-8
              border-b-0 py-3
              "
      >
        <TitleSub
          title="Interest Rate"
          subtitle={"Amount of interest due per period, on a share."}
          subtitleClassName="!mt-0"
        />

        <div className="mt-3">
          {mode === "view" && (
            <div className="text-sm md:text-base text-pry-green-200 font-medium">
              {cooperativeConfiguration?.data?.share?.InterestRate
                ? `${cooperativeConfiguration?.data?.share?.InterestRate}%`
                : "No rate set"}
            </div>
          )}

          {mode === "edit" && (
            <TextInput
              type="text"
              name={"sharesInterestRate"}
              value={sharesForm?.values?.sharesInterestRate}
              placeholder="Enter interest rate"
              onChange={sharesForm?.handleChange}
              onBlur={sharesForm?.handleBlur}
              hasIcon={false}
              iconSvg={undefined}
              errors={
                sharesForm?.touched?.sharesInterestRate &&
                sharesForm?.errors?.sharesInterestRate &&
                sharesForm?.errors?.sharesInterestRate
              }
              inputClass=""
              className="!p-0 !pr-4"
              autoComplete="off"
              customIcon={
                <div
                  className="
                        font-medium text-sm md:text-base
                        py-[14px] px-[16px]
                        bg-white
                        border border-pry-dark-green-300/10
                        rounded-s-lg
                        "
                >
                  %
                </div>
              }
              customIconPosition="left"
            />
          )}
        </div>
      </div>
    </ContainerCard>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discoveringCooperatives: false,
  discoverCooperatives: {},
  fetchingSingleCooperativeDetails: false,
  singleCooperative: undefined,
  fetchingMyCooperatives: false,
  myCooperatives: {},
  myCooperativePermissions: [],
  fetchingMembershipDetails: false,
  myMembershipDetails: {},
  fetchingInvitations: false,
  invitations: [],
  fetchingApprovalsSummary: false,
  approvalSummary: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    /**Handles discover cooperatives update */
    reset: (state, action) => {
      state = initialState;
    },
    /**Handles discover cooperatives update */
    discoverCooperativesUpdated: (state, action) => {
      state.discoverCooperatives = action.payload;
    },
    /**Handles discover cooperatives loading */
    discoveringCooperatives: (state, action) => {
      state.discoveringCooperatives = action.payload;
    },
    /**Handles my cooperatives update */
    myCooperativesUpdated: (state, action) => {
      state.myCooperatives = action.payload;
    },
    /**Handles my cooperatives loading */
    fetchingMyCooperatives: (state, action) => {
      state.fetchingMyCooperatives = action.payload;
    },
    /**Handles single coopertive details fetching */
    fetchingSingleCooperativeDetails: (state, action) => {
      state.fetchingSingleCooperativeDetails = action.payload;
    },
    /**Handles single coopertive details fetch update */
    singleCooperativeDetailsFetched: (state, action) => {
      state.singleCooperative = action.payload;
    },
    /**Handles membership details fetching */
    fetchingMyMembershipDetails: (state, action) => {
      state.fetchingMembershipDetails = action.payload;
    },
    myMembershipDetailsUpdated: (state, action) => {
      state.myCooperativePermissions = action?.payload?.Role
        ? action?.payload?.Role?.Permission?.map((permission) => {
            return permission?.Permission?.CodeName;
          })
        : [];
      state.myMembershipDetails = action.payload;
    },
    /**Handles invitations fetching */
    fetchingInvitations: (state, action) => {
      state.fetchingInvitations = action.payload;
    },
    /**Handles invitations fetch update */
    invitationsFetched: (state, action) => {
      state.invitations = action.payload;
    },
    /**Handles approvals summary fetching */
    fetchingApprovalsSummary: (state, action) => {
      state.fetchingApprovalsSummary = action.payload;
    },
    /**Handles approval summary update */
    approvalSummaryUpdated: (state, action) => {
      state.approvalSummary = action.payload;
    },
  },
});

export const appSliceReducer = appSlice.reducer;
export const appSliceActions = appSlice.actions;

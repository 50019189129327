export const navs = [
  {
    icon: "overview-icon",
    title: "Overview",
    to: "/app/overview",
    profileAvailable: "all",
  },

  // {
  //   icon: "transaction",
  //   title: "Transactions",
  //   to: "/app/transactions",
  //   profileAvailable: "cooperative",
  // },

  // {
  //   icon: "group-icon",
  //   title: "Members ",
  //   to: "/app/members",
  //   profileAvailable: "cooperative",
  // },

  // {
  //   icon: "discover",
  //   title: "Discover",
  //   to: "/app/discover",
  //   profileAvailable: "member",
  // },

  {
    icon: "invitation",
    title: "Invitations",
    to: "/app/invitations",
    profileAvailable: "member",
    hasCount: true,
  },

  {
    icon: "verified-2",
    title: "Approvals",
    to: "/app/approvals",
    profileAvailable: "member",
    hasCount: true,
  },

  {
    icon: "people",
    title: "My Cooperatives ",
    to: "/app/my-cooperatives",
    profileAvailable: "member",
  },

  {
    icon: "wallet",
    title: "Wallet ",
    to: "/app/wallet",
    profileAvailable: "member",
  },

  {
    icon: "settings",
    title: "Settings",
    to: "/app/settings",
    profileAvailable: "all",
  },
];

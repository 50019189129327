import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bankList: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    /**Handles bank list update */
    bankListUpdated: (state, action) => {
      state.bankList = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const paymentSliceReducer = paymentSlice.reducer;
export const paymentSliceActions = paymentSlice.actions;

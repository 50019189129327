import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import empty_discover from "../../../assets/images/empty_state_discover.svg";
import { ContainerCard } from "../../../components/cards/containerCard";
import { CooperativeCard } from "../../../components/cards/cooperativeCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { JoinCooperativeModal } from "../../../components/modals/app_modals/joinCooperativeModal";
import { Pagination } from "../../../components/pagination/pagination";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useCooperative } from "../../../customHooks/useCooperative";

export const Discover = () => {
  const [query, setQuery] = useState({
    offset: 0,
    limit: 9,
    text: null,
  });
  const [selectedCooperative, setSelectedCooperative] = useState(undefined);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const { discoverCooperatives } = useCooperative();

  const discoveredCooperatives = useSelector((state) => {
    return state.app.discoverCooperatives;
  });

  const { discoveringCooperatives } = useSelector((state) => {
    return state.app;
  });

  useEffect(() => {
    discoverCooperatives(query);
    // eslint-disable-next-line
  }, [query]);

  //todo: this block will always redirect to cooperative page, making this page unreachable
  if (typeof discoveringCooperatives === typeof true) {
    return <Navigate to="/app/my-cooperatives" replace />;
  }

  return (
    <div className="w-full min-h-full">
      <PageTitle
        text="Discover"
        subtext="Search for any cooperative and join them within few minutes."
      />

      <div
        className="
        mt-6
        "
      >
        <FilterComponent
          searchPlaceholder="Search any cooperative name"
          hasExtraFilter={false}
          handleSearch={(e) => {
            setQuery({ ...query, text: e, offset: 0 });
          }}
        />
      </div>

      <div
        className="
        mt-10
        flex flex-row flex-wrap
        gap-4
        h-full
        "
      >
        {discoveringCooperatives && (
          <ContainerCard className="!p-[48px] border-none !h-full !bg-transparent">
            <PageLoader />
          </ContainerCard>
        )}

        {discoveredCooperatives?.data &&
          discoveredCooperatives?.data?.length > 0 &&
          !discoveringCooperatives &&
          discoveredCooperatives?.data?.map((cooperative, idx) => {
            const key = `discoverdCoop-${idx}`;
            return (
              <CooperativeCard
                cooperative={cooperative}
                key={key}
                onClick={() => {
                  setSelectedCooperative(cooperative);
                  setModalOpen(true);
                  setModal("joinCooperativeModal");
                }}
              />
            );
          })}

        {!discoveringCooperatives &&
          (!discoveredCooperatives?.data ||
            discoveredCooperatives?.data?.length < 1) && (
            <ContainerCard className="md:!p-[48px]">
              <EmptyState
                image={<img src={empty_discover} alt="discover" />}
                title="Welcome to Discover"
                subtitle="Here, you can find and join cooperatives anytime, anyday. Stay tuned!"
                // subtitleClassName="!text-base !font-medium !text-pry-dark-green-300"
              />
            </ContainerCard>
          )}
      </div>

      {/* Pagination  */}
      {discoveredCooperatives?.data &&
        discoveredCooperatives?.data?.length > 0 &&
        !discoveringCooperatives && (
          <div className="mt-7 ">
            <Pagination
              limit={query?.limit}
              totalCount={discoveredCooperatives?.totalCount}
              otherQueryParams={query}
              onPageChange={(e) => setQuery(e)}
            />
          </div>
        )}

      <JoinCooperativeModal
        modalOpen={modalOpen && modal === "joinCooperativeModal"}
        onModalClose={() => {
          setModalOpen(false);
          setModal("");
        }}
        cooperative={selectedCooperative}
      />
    </div>
  );
};

import React from "react";
import { Icon } from "../icons/icon";

export const TextInputViewOnly = ({
  className,
  text,
  subtext,
  hasIcon,
  icon = "warning-icon",
  plain = false,
  extraContent = undefined,
  hasTitle = false,
  title = "",
}) => {
  return (
    <div className="w-full">
      {hasTitle && (
        <div
          className="
            text-sm 
            text-pry-dark-green-300/80 font-medium
            mb-1
            "
        >
          {title}
        </div>
      )}

      <div
        className={`
        w-full
        py-3 px-4 md:px-6
        flex flex-row
        items-center justify-between
        bg-light-green-bg border border-light-green-bg
        ${
          plain &&
          `
        !bg-transparent !border-transparent
        `
        }
        rounded-[8px]
        ${className}
        `}
      >
        <div className="text-sm">
          <div
            className={`${plain ? "font-medium md:text-base" : "font-bold"}`}
          >
            {text}
          </div>
          <div
            className={`mt-1 ${plain && "text-pry-dark-green-300/50 !mt-0"}`}
          >
            {subtext}
          </div>
          {extraContent && <div className="pt-1">{extraContent}</div>}
        </div>
        {hasIcon && (
          <div className="text-pry-green-200">
            <Icon svg={icon} width={24} height={24} />
          </div>
        )}
      </div>
    </div>
  );
};

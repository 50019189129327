import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../api/requestHelpers";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { SettingsSliceActions } from "../redux/features/settings/settings";

export const useSettings = () => {
  const dispatch = useDispatch();

  const fetchDialCodesCountries = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Settings/default/country/list/mobilecode", params, true)
      .then((resp) => {
        const respData = resp?.data;

        const dialCodes = respData?.map((item) => {
          return {
            id: item["Mobile Code"][0],
            title: `${item["Country"]} (+${item["Mobile Code"][0]})`,
            value: `+${item["Mobile Code"][0]}`,
          };
        });

        const countries = respData?.map((item) => {
          return {
            id: item["Country"],
            title: `${item["Country"]}`,
            value: `${item["Country"]}`,
          };
        });

        dispatch(SettingsSliceActions.dialCodes_updated(dialCodes));
        dispatch(SettingsSliceActions.countries_updated(countries));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const fetchStates = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Settings/default/country/list/state", params, true)
      .then((resp) => {
        const respData = resp?.data;

        const states = respData?.map((item) => {
          return {
            id: item?.name,
            title: item?.name,
            value: item?.name,
          };
        });

        dispatch(SettingsSliceActions.states_updated(states));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const fetchSharesSettings = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Shares/Settings", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchDepositsSettings = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Depository/Settings", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchAllConfiguration = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Cooperative/Config/Setting", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const setDepositConfigurations = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Depository/Settings", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const respData = resp?.data;

        doNotification("Deposit configurations saved", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const setSharesConfigurations = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Shares/Settings", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const respData = resp?.data;

        doNotification("Shares configurations saved", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    fetchDialCodesCountries,
    fetchStates,
    fetchSharesSettings,
    fetchDepositsSettings,
    setDepositConfigurations,
    setSharesConfigurations,
    fetchAllConfiguration,
  };
};

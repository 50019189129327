import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchingMySavings: false,
  mySavings: {}, //Savings data IN single cooperative
  fetchingMySavingsHistory: false,
  mySavingsHistory: {},
  fetchingCooperativeSavings: false,
  cooperativeSavings: {}, //Savings data FOR single cooperative
  fetchingCooperativeMemberSavings: false,
  cooperativeMemberSavings: {},
};

const savingsSlice = createSlice({
  name: "savings",
  initialState,
  reducers: {
    fetchingMySavings: (state, action) => {
      state.fetchingMySavings = action.payload;
    },
    fetchingMySavingsHistory: (state, action) => {
      state.fetchingMySavingsHistory = action.payload;
    },
    fetchingCooperativeSavings: (state, action) => {
      state.fetchingCooperativeSavings = action.payload;
    },
    fetchingCooperativeMemberSavings: (state, action) => {
      state.fetchingCooperativeMemberSavings = action.payload;
    },
    /**Handles my savings update */
    mySavingsUpdated: (state, action) => {
      state.mySavings = action.payload;
    },
    /**Handles my savings history update */
    mySavingsHistoryUpdated: (state, action) => {
      state.mySavingsHistory = action.payload;
    },
    /**Handles cooperative savings update */
    cooperativeSavingsUpdated: (state, action) => {
      state.cooperativeSavings = action.payload;
    },
    /**Handles coooperative member savings update */
    cooperativeMemberSavingsUpdated: (state, action) => {
      state.cooperativeMemberSavings = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const savingsSliceReducer = savingsSlice.reducer;
export const savingsSliceActions = savingsSlice.actions;

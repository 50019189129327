import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import empty_state from "../../../assets/images/empty_state_cooperatives.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { NumberCard } from "../../../components/cards/numberCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { AddProductModal } from "../../../components/modals/app_modals/addProductModal";
import { AdminAddProductModal } from "../../../components/modals/app_modals/adminAddProductModal";
import { AdminViewProductHistoryModal } from "../../../components/modals/app_modals/adminViewProductHistoryModal";
import { MemberAddProductModal } from "../../../components/modals/app_modals/memberAddProductModal";
import { PaymentModal } from "../../../components/modals/app_modals/paymentModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { UploadMemberSavingsModal } from "../../../components/modals/app_modals/uploadMemberSavingsModal";
import { EmptyState } from "../../../components/states/emptyState";
import { CooperativeMemberProductTable } from "../../../components/table/app_tables/cooperativeMemberProductTable";
import { ProductHistoryTable } from "../../../components/table/app_tables/productHistoryTable";
import { usePayment } from "../../../customHooks/usePayment";
import { usePermissions } from "../../../customHooks/usePermissions";
import { useSavings } from "../../../customHooks/useSavings";
import { downloadExcel } from "../../../helperFunctions";
import { permissionList } from "../../../utils/constants";

export const CooperativeSavings = ({ singleCooperative, mode }) => {
  const defaultQuery = {
    coop: singleCooperative?.id,
    logs: true,
    text: "",
    limit: 10,
    offset: 0,
  };

  const [query, setQuery] = useState(defaultQuery);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [bulkUploadDetails, setBulkUploadDetails] = useState({
    hasError: false,
    fileBase64: "",
  });

  const { hasPermissionsFor } = usePermissions();

  const {
    fetchingMySavings,
    mySavings,
    fetchingMySavingsHistory,
    mySavingsHistory,
    fetchingCooperativeSavings,
    cooperativeSavings,
    fetchingCooperativeMemberSavings,
    cooperativeMemberSavings,
  } = useSelector((state) => {
    return state.savings;
  });

  const {
    fetchMySavingsInformation,
    fetchCooperativeSavingsInformation,
    fetchCooperativeMemberSavingsInformation,
    fetchMySavingsHistory,
  } = useSavings();

  const { verifyPayment } = usePayment();

  useEffect(() => {
    mode === "personal" &&
      fetchMySavingsInformation({
        coop: singleCooperative?.id,
        logs: false,
      });
    mode === "admin" &&
      fetchCooperativeSavingsInformation({
        coop: singleCooperative?.id,
        logs: false,
      });

    setQuery(defaultQuery);

    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    mode === "personal" && fetchMySavingsHistory(query);
    mode === "admin" && fetchCooperativeMemberSavingsInformation(query);
    // eslint-disable-next-line
  }, [query]);

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleUserSaveStart = () => {
    setModal("addSavingsModal");
    setModalOpen(true);
  };

  const handleMemberSavingsUploadStart = () => {
    setModal("memberAddSavingsModal");
    setModalOpen(true);
  };

  return (
    <div className="w-full">
      {/* Card information and action */}
      {(mode === "admin" && fetchingCooperativeSavings) ||
      (mode === "personal" && fetchingMySavings) ? (
        <PageLoader />
      ) : (
        <NumberCard
          title={
            mode === "personal" ? "Savings Balance" : "Total Savings Balance"
          }
          value={
            mode === "personal"
              ? mySavings?.Balance
              : cooperativeSavings?.Balance
          }
          rightChildren={
            mode === "personal" ? (
              <div className="w-full flex flex-col gap-[10px]">
                <Button
                  text="Add savings"
                  onClick={handleUserSaveStart}
                  type="button"
                  className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                  loading={false}
                  disabled={false}
                  icon={"plus"}
                  variation="primary-white"
                />

                <Button
                  text="Upload savings"
                  onClick={handleMemberSavingsUploadStart}
                  type="button"
                  className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                  loading={false}
                  disabled={false}
                  icon={"arrow-up"}
                  variation="secondary-white"
                />
              </div>
            ) : (
              <div
                className={`flex flex-col gap-1 ${
                  !hasPermissionsFor(permissionList.CAN_ADD_PAYMENT) &&
                  "!hidden border-4"
                }`}
              >
                <Button
                  text="Bulk upload savings"
                  onClick={() => {
                    handleModalOpen("uploadSavingsModal");
                  }}
                  type="button"
                  className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                  loading={false}
                  disabled={false}
                  icon={"file-upload-icon"}
                  variation="primary-white"
                />
              </div>
            )
          }
        />
      )}

      {/* History  */}
      {!fetchingMySavings && !fetchingCooperativeSavings && (
        <div>
          <div className="my-[24px] text-[16px] font-medium flex flex-wrap gap-2 items-center justify-between">
            <div className="">
              {mode === "personal" ? "Savings History" : "Members' Savings"}
            </div>

            {mode === "admin" && (
              <div className="w-full md:w-max md:flex-grow flex items-center md:justify-end gap-2 flex-wrap">
                <FilterComponent
                  searchPlaceholder={"Search member name, email"}
                  hasExtraFilter={false}
                  handleSearch={(search) => {
                    setQuery({
                      ...query,
                      text: search,
                      limit: 10,
                      offset: 0,
                    });
                  }}
                  mainClassName="!w-full md:!max-w-[286px]"
                  className={"!w-full md:!w-[286px] !py-2"}
                  size="small"
                />

                <Button
                  onClick={() => {
                    setModal("adminProductHistoryModal");
                    setModalOpen(true);
                  }}
                  text="View History"
                  type="button"
                  className="md:!py-[8px] w-full md:!max-w-[140px] !text-xs"
                  loading={false}
                  disabled={false}
                  icon={"refresh"}
                  variation="primary"
                />
              </div>
            )}
          </div>
          {fetchingMySavingsHistory || fetchingCooperativeMemberSavings ? (
            <div className="mt-[24px]">
              <PageLoader />
            </div>
          ) : (
            <div className="">
              {(mode === "admin" && !cooperativeMemberSavings?.data?.length) ||
              (mode === "personal" && !mySavingsHistory?.data?.length) ? (
                <ContainerCard className="!py-12">
                  <EmptyState
                    title={
                      mode === "admin"
                        ? "No savings found"
                        : "You don’t have any savings yet"
                    }
                    titleClassName="!text-base"
                    image={<img src={empty_state} alt="empty" />}
                    actionText={mode === "personal" && "+ Add my first savings"}
                    onClick={handleUserSaveStart}
                    actionButtonVariant="secondary"
                  />
                </ContainerCard>
              ) : (
                <div>
                  {mode === "personal" ? (
                    <ProductHistoryTable
                      data={mySavingsHistory}
                      query={query}
                      setQuery={setQuery}
                    />
                  ) : (
                    <CooperativeMemberProductTable
                      data={cooperativeMemberSavings}
                      query={query}
                      setQuery={setQuery}
                      onAddProductClick={(data) => {
                        setSelectedData(data);
                        handleModalOpen("adminAddSavingsModal");
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}{" "}
        </div>
      )}

      <UploadMemberSavingsModal
        isModalOpen={modalOpen && modal === "uploadSavingsModal"}
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
        successCallback={(data) => {
          handleModalOpen("uploadSavingsSuccessModal");
          setBulkUploadDetails(data);
        }}
      />

      <SuccessModal
        message={"Savings uploaded successfully"}
        subText={
          "Once approved, each transaction will be added as savings to the respective members."
        }
        isModalOpen={modalOpen && modal === "uploadSavingsSuccessModal"}
        onModalClose={handleModalClose}
        hasError={bulkUploadDetails.hasError}
        errorComponent={
          <span
            className="
            text-error-200 text-sm border border-error-200
            p-2 rounded-md bg-error-200/5
            "
          >
            Some entries were not processed due to errors.{" "}
            <button
              onClick={() => {
                downloadExcel(
                  bulkUploadDetails.fileBase64,
                  `${singleCooperative?.CoopName}_savings_upload_errors`
                );
              }}
              className="underline font-bold"
            >
              Download
            </button>{" "}
            to view and correct for re-upload.
          </span>
        }
      />

      <AdminAddProductModal
        selectedData={selectedData}
        isModalOpen={modalOpen && modal === "adminAddSavingsModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("adminAddSavingsSuccessModal");
        }}
      />

      <SuccessModal
        message={"Savings added successfully"}
        subText={
          <div>
            Once approved, it will reflect on{" "}
            <span className="text-pry-green-200">{`${selectedData?.MemberID?.User?.FirstName} ${selectedData?.MemberID?.User?.LastName}'s`}</span>{" "}
            savings.
          </div>
        }
        isModalOpen={modalOpen && modal === "adminAddSavingsSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AddProductModal
        isModalOpen={modalOpen && modal === "addSavingsModal"}
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
        successCallback={(data) => {
          setPaymentData(data);
          handleModalOpen("paymentModal");
        }}
      />

      <PaymentModal
        isModalOpen={modalOpen && modal === "paymentModal"}
        onModalClose={handleModalClose}
        paymentData={paymentData}
        handleVerification={() => {
          verifyPayment(
            {
              coop: singleCooperative?.id,
              trxref: paymentData?.reference,
              amount: paymentData?.amount,
              reference: paymentData?.reference,
              mode: "paystack",
            },
            () => {
              fetchMySavingsInformation({
                coop: singleCooperative?.id,
                logs: false,
              });
              fetchMySavingsHistory(query);
            },
            () => {
              handleModalClose();
              handleModalOpen("addSavingsSuccessModal");
              setPaymentData({});
              setSelectedData({});
              setQuery(defaultQuery);
            }
          );
        }}
      />

      <SuccessModal
        message={"Savings added successfully"}
        subText={
          <div>
            You have successfully added{" "}
            <span className="text-pry-green-200">{paymentData?.amount}</span> to
            your {""}
            <span className="text-pry-green-200">
              {singleCooperative?.CoopName}
            </span>{" "}
            savings.
          </div>
        }
        isModalOpen={modalOpen && modal === "addSavingsSuccessModal"}
        onModalClose={handleModalClose}
      />

      <MemberAddProductModal
        cooperative={singleCooperative?.id}
        isModalOpen={modalOpen && modal === "memberAddSavingsModal"}
        onModalClose={handleModalClose}
        onSuccess={() => {
          handleModalOpen("memberAddSavingsSuccessModal");
        }}
      />

      <SuccessModal
        message={"Savings added successfully"}
        subText={
          <div>
            Your uploaded savings will reflect in your account once approved.
          </div>
        }
        isModalOpen={modalOpen && modal === "memberAddSavingsSuccessModal"}
        onModalClose={handleModalClose}
      />

      <AdminViewProductHistoryModal
        isModalOpen={modalOpen && modal === "adminProductHistoryModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        singleCooperative={singleCooperative}
        type="savings"
      />
    </div>
  );
};

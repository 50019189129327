import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/button";
import { TextLink } from "../../components/buttons/textLink";
import { TextInput } from "../../components/inputs/textInput";
import { HeaderSubheader } from "../../components/layouts/headerSubheader";
import { OnboardingLayout } from "../../components/layouts/onboardingLayout";
import { useAuth } from "../../customHooks/useAuth";
import { doNotification } from "../../helperFunctions/nofitication";
import { loginSchema, loginSchema1 } from "../../validationSchema";

export const SignIn = () => {
  const { loginUser, fetchUserProfile, verifyUserEmail } = useAuth();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: step === 1 ? loginSchema1 : loginSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        username: data.email,
        password: data.password,
      };
      // Verify email
      step === 1 &&
        verifyUserEmail(
          {
            Email: data?.email,
          },
          () => {
            setSubmitting(false);
          },
          () => {
            setStep(2);
          }
        );
      // Do actual login
      step === 2 &&
        loginUser(
          payload,
          () => {
            setSubmitting(false);
          },
          () => {
            setSubmitting(true);
            fetchUserProfile(
              () => {},
              () => {
                doNotification("Login successful", "success");
                setSubmitting(false);
                const resumeLink = localStorage.getItem("resumeLink");
                resumeLink && resumeLink?.includes("/app")
                  ? navigate(resumeLink)
                  : navigate("/app/overview");
                localStorage.removeItem("resumeLink");
              }
            );
          }
        );
    },
  });

  useEffect(() => {
    setStep(1);
    // eslint-disable-next-line
  }, [values?.email]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <OnboardingLayout>
      <div className="!w-full !max-w-[327px]">
        <HeaderSubheader
          header="Welcome Back"
          subheader="Start managing your finances faster and better."
          className="mt-7"
        />
        <form
          className="w-full mt-6 flex flex-col gap-3"
          onSubmit={handleSubmit}
        >
          <TextInput
            type="text"
            name="email"
            value={values.email}
            placeholder="Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.email && touched.email && errors.email}
            autoComplete="on"
          />
          {step === 2 && (
            <TextInput
              type="password"
              name="password"
              value={values.password}
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.password && touched.password && errors.password}
              autoComplete="on"
            />
          )}
          {step === 2 && (
            <div className="w-full text-right">
              <TextLink
                to="/auth/forgot-password"
                text="Forgot Password?"
                className="!text-xs"
              />
            </div>
          )}
          <div className="w-full text-right mt-3">
            <Button
              text={step === 2 ? "Login" : "Continue"}
              disabled={!isValid || isSubmitting}
              type="submit"
              loading={isSubmitting}
            />
          </div>
          <div className="w-full mt-1 !text-sm text-grey-400">
            Don’t have an account?{" "}
            <TextLink to="/auth/sign-up" text="Register" className="" />
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};

import React from "react";

export const YouCard = () => {
  return (
    <div
      className="
            text-info-purple text-[10px] 
            py-0.5 px-2 bg-light-purple/50
            font-bold rounded-[4px]
            "
    >
      You
    </div>
  );
};

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import {
  InformationCard,
  InformationCardNoIcon,
} from "../../../../components/cards/infoCard";
import { MembershipFormField } from "../../../../components/cards/membershipFormField";
import { Dropdown } from "../../../../components/inputs/dropDown";
import { TextInput } from "../../../../components/inputs/textInput";
import { TitleSub } from "../../../../components/typography/titleSub";
import { useCooperative } from "../../../../customHooks/useCooperative";
import {
  addMembershipFormFieldSchema,
  membershipFormSchema,
} from "../../../../validationSchema";

const AddFieldForm = ({ formFieldTypes, form = [], setForm = () => {} }) => {
  const {
    values,
    handleBlur,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fieldName: "",
      fieldType: undefined,
    },
    validationSchema: addMembershipFormFieldSchema,
    onSubmit: (data) => {
      let formBucket = JSON.parse(JSON.stringify(form));

      const newFormData = {
        question: data?.fieldName,
        fieldType: data?.fieldType?.value,
        option: [],
        isDefault: false,
      };

      formBucket?.unshift(newFormData);

      setForm(formBucket);

      resetForm();
    },
  });

  return (
    <div className="px-4 md:px-8 mt-[32px]">
      <div
        className="
                    w-full flex flex-row flex-wrap
                    gap-[12px]
                    "
      >
        <div className="w-full max-w-[376px] ">
          <div className="mb-[12px] text-sm font-medium">Field Name</div>
          <div>
            <TextInput
              type="text"
              name={"fieldName"}
              value={values?.fieldName}
              placeholder="E.g Address, Means of ID "
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              errors={
                errors?.fieldName && touched?.fieldName && errors?.fieldName
              }
              inputClass=""
              className=""
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full md:max-w-[199px]">
          <div className="mb-[12px] text-sm font-medium">Type</div>
          <div>
            <Dropdown
              value={values?.fieldType}
              options={formFieldTypes}
              resetOptionsList={() => {}}
              placeholder="Select Type"
              onChange={(selectedObj) => {
                setFieldValue("fieldType", selectedObj);
              }}
              onBlur={handleBlur("fieldType")}
              hasIcon={false}
              icon=""
              errors={
                touched?.fieldType && errors?.fieldType && errors?.fieldType
              }
              dropdownClassName="h-[130px]"
              isSearchable
              onSearch={() => {}}
              className={
                !errors?.fieldType && "!bg-white !border-pry-dark-green-300/40"
              }
            />
          </div>
        </div>
      </div>

      <div
        className="
                w-full flex flex-row
                items-center justify-end
                mt-3
                "
      >
        <Button
          text="+ Add Field"
          onClick={handleSubmit}
          type="button"
          className="max-w-[104px] md:!py-2"
          loading={false}
          disabled={false}
          variation="primary-dark"
        />
      </div>
    </div>
  );
};

export const MembershipSettingsView = ({
  mode = "view",
  clearButtonRef,
  saveButtonRef,
  setIsSubmitting = () => {},
  setIsValid = () => {},
}) => {
  const [form, setForm] = useState([]);

  const navigate = useNavigate();

  const { onboardingCompletionStatus } = useSelector((state) => {
    return state?.auth;
  });

  const { membershipForm, formFieldTypes, membershipFee } = useSelector(
    (state) => {
      return state?.cooperative;
    }
  );

  const {
    fetchCooperativeMembershipFormDetails,
    fetchDefaultMembershipFormDetails,
    fetchDefaultFormTypes,
    updateCooperativeMembershipFormDetails,
  } = useCooperative();

  /**UseEffect for page load; No runs -> [1] */
  useEffect(() => {
    // If form has been set up, fetch the cooperative form details
    // Else fetch the default form details
    onboardingCompletionStatus?.isMembershipFormComplete
      ? fetchCooperativeMembershipFormDetails()
      : fetchDefaultMembershipFormDetails();

    // Fetch the allowed form types
    fetchDefaultFormTypes();

    // eslint-disable-next-line
  }, []);

  const {
    values,
    handleBlur,
    handleChange,
    resetForm,
    isValid,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: {
      fee: onboardingCompletionStatus?.isMembershipFormComplete
        ? membershipFee
        : "",
      form: form?.filter((item) => {
        return !item?.isDefault;
      }),
    },
    enableReinitialize: true,
    validationSchema: membershipFormSchema,
    onSubmit: (data, { setSubmitting }) => {
      const newFormData = data?.form?.filter((item) => {
        return !item?.isDefault;
      });

      const payload = {
        howMany: newFormData.length,
        fee: data?.fee?.replace(/,/g, ""),
        form: newFormData,
      };

      setSubmitting(true);
      updateCooperativeMembershipFormDetails(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {}
      );
    },
  });

  useEffect(() => {
    setForm(membershipForm);
  }, [membershipForm]);

  useEffect(() => {
    setFieldValue("form", form);
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    setIsValid(isValid);
    // eslint-disable-next-line
  }, [isValid, isSubmitting]);

  return (
    <div>
      {/* Hidden button refs  */}
      <button
        ref={clearButtonRef}
        className="hidden"
        onClick={() => {
          resetForm();
          setForm(membershipForm);
        }}
      />
      <button ref={saveButtonRef} className="hidden" onClick={handleSubmit} />
      <div>
        {/* Information on how to edit (only shows when account hasn't made any edit) */}
        {!onboardingCompletionStatus?.isMembershipFormComplete &&
          mode === "view" && (
            <InformationCardNoIcon
              className="max-w-[653px] mb-3"
              info={
                <div>
                  Click the{" "}
                  <span className="text-pry-green-200 font-medium">Edit</span>{" "}
                  button to set registration fee and membership form
                </div>
              }
            />
          )}

        {/* Main Page Content  */}
        <div
          className="
            w-full
            flex flex-row flex-wrap
            gap-2 md:gap-6
            "
        >
          <ContainerCard
            className="
            w-full max-w-[653px]
            h-[685px]
            !px-0
            flex flex-col
            order-last width-lim:order-first
            "
          >
            <div
              className="
                px-4 md:px-8 pb-6 
                border-b
                border-pry-dark-green-400/5
                "
            >
              <div
                className="
                w-full
                flex flex-row flex-wra
                gap-4
                items-center justify-between
                "
              >
                <TitleSub
                  title="Set Registration Fee"
                  subtitle="This is the amount prospective members will be charged"
                  titleClassName=""
                  subtitleClassName=""
                  className=""
                />

                {mode === "view" && (
                  <Button
                    text="Edit"
                    onClick={() => {
                      navigate("/app/settings/membership-settings/edit");
                    }}
                    type="button"
                    className="max-w-[80px] md:max-w-[96px] md:!py-2"
                    loading={false}
                    disabled={false}
                    variation="primary"
                  />
                )}
              </div>

              {mode === "view" ? (
                <div
                  className="
              mt-4
              text-pry-green-200
              font-medium text-2xl
              "
                >
                  {onboardingCompletionStatus?.isMembershipFormComplete
                    ? `₦${membershipFee}`
                    : "₦0.00 (Set a Fee)"}
                </div>
              ) : (
                <div className="mt-4">
                  <TextInput
                    type="text"
                    name={"fee"}
                    value={Number(
                      values?.fee?.replace(/,/g, "")
                    )?.toLocaleString()}
                    placeholder="Input registration fee"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasIcon={false}
                    iconSvg={undefined}
                    errors={errors?.fee && touched?.fee && errors?.fee}
                    inputClass=""
                    className="!p-0 !pr-[16px]"
                    autoComplete="off"
                    customIcon={
                      <div
                        className="
                      font-medium text-[20px]
                      py-[14px] px-[16px]
                      bg-white
                      border border-pry-dark-green-300/10
                      rounded-s-lg
                      "
                      >
                        ₦
                      </div>
                    }
                  />
                </div>
              )}
            </div>

            {/* For setup preview  */}
            <div
              className="
            px-4 md:px-8 pt-6 overflow-scroll h-full
            flex flex-col
            "
            >
              <TitleSub
                title="Membership Form"
                subtitle="Below are the fields(information) that will be collected from prospective members"
                titleClassName=""
                subtitleClassName=""
                className="order-1"
              />

              <div
                className="
                mt-[16px]
                flex flex-row flex-wrap
                gap-2
                order-3 md:order-2
                "
              >
                {form?.map((field, idx) => {
                  const key = `membershipFormField-${idx}`;
                  return (
                    <MembershipFormField
                      field={field}
                      mode={mode}
                      key={key}
                      form={form}
                      setForm={setForm}
                      index={idx}
                    />
                  );
                })}
              </div>
            </div>

            {/* For adding new fields in edit mode  */}
            {mode === "edit" && (
              <div className="order-2 md:order-3">
                <AddFieldForm
                  formFieldTypes={formFieldTypes}
                  form={form}
                  setForm={setForm}
                />
              </div>
            )}
          </ContainerCard>
          <InformationCard
            className="w-full md:max-w-[345px] h-fit width-lim:sticky-top"
            info={
              <span>
                <span className="text-pry-green-200">Membership form</span> is
                what a prospective member will fill before joining your
                cooperative. You can make changes to the form here anytime
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { mobileTabPadding } from "../../utils/constants";
import { WebsiteFooter } from "../navs/websiteFooter";
import { WebsiteNavbar } from "../navs/websiteNavBar";

export const WebsiteLayout = ({ children }) => {
  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const [navVisible, setNavVisible] = useState(true);

  const handleNavigation = () => {
    if (window.scrollY > lastScrollHeight) {
      // if scroll down hide the navbar
      setNavVisible(false);
    } else {
      // if scroll up show the navbar
      setNavVisible(true);
    }

    // remember current page location to use in the next move
    setLastScrollHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
    // eslint-disable-next-line
  }, [lastScrollHeight]);

  return (
    <div
      className={`
        w-full
        overflow-x-hidden 
        flex flex-col
        relative
        `}
    >
      <div
        className={`
        fixed top-0 left-0 right-0
        bg-pry-dark-green-200/50
        backdrop-blur-lg
        z-[10]
        ${mobileTabPadding}
        ${!navVisible && window.scrollY > 0 && "!-top-[200px]"}
        transition-all duration-700
        `}
      >
        <WebsiteNavbar
          className={`
            mx-auto
            py-7 width-lim:py-8
            width-lim:px-0
            max-w-full md:max-w-max-1024 width-lim:max-w-website-max width-lim-xl:max-w-website-max-xl
        `}
        />
      </div>
      <div className="">{children}</div>
      <WebsiteFooter />
    </div>
  );
};

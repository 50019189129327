import React, { useState } from "react";
import { useQuery } from "react-query";
import { useApprovals } from "../../../customHooks/useApprovals";
import { TimeText } from "../../typography/timeText";
import { MultiSelectApprovalTable } from "../multiSelectApprovalTable";

export const FinancesApprovalTable = ({
  singleCooperative,
  totalCount,
  refresh,
}) => {
  const [query, setQuery] = useState({
    limit: 10,
    offset: 0,
    coop: singleCooperative?.id,
  });

  const { fetchWithdrawalApprovals, approveWithdrawal, fetchApprovalsSummary } =
    useApprovals();

  const withdrawalApprovals = useQuery(
    ["withdrawalApprovals", query],
    fetchWithdrawalApprovals,
    { enabled: true }
  );

  return (
    <div>
      <MultiSelectApprovalTable
        singleCooperative={singleCooperative}
        headers={["TYPE", "AMOUNT", "DATE"]}
        data={
          withdrawalApprovals?.data
            ? withdrawalApprovals?.data?.map((item, idx) => {
                const key = `row-${idx}-savings-approvals`;

                return {
                  id: item?.id,
                  data: [
                    <div key={`${key}-10 `} className="capitalize">
                      Withdrawal From Cooperative Account
                    </div>,
                    `₦${Number(item?.Amount)?.toLocaleString()}`,
                    <TimeText timeString={item?.RequestDate} key="" />,
                  ],
                };
              })
            : []
        }
        isLoading={withdrawalApprovals.isFetching}
        refetchData={withdrawalApprovals.refetch}
        successCallback={() => {
          fetchApprovalsSummary();
          withdrawalApprovals?.refetch();
          refresh();
        }}
        approvalFunction={approveWithdrawal}
        totalCount={totalCount}
        query={query}
        setQuery={setQuery}
        emptySubTitle="All your savings approvals will be displayed here whenever you need to approve any savings transaction."
      />
    </div>
  );
};

import { useFormik } from "formik";
import React from "react";
import { Button } from "../../../components/buttons/button";
import { TextLink } from "../../../components/buttons/textLink";
import { TextInput } from "../../../components/inputs/textInput";
import { HeaderSubheader } from "../../../components/layouts/headerSubheader";
import { useAuth } from "../../../customHooks/useAuth";
import { forgotPasswordSchema } from "../../../validationSchema";

export const ForgotPasswordDetails = ({ setStep }) => {
  const { sendForgotPasswordCode } = useAuth();
  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        Email: data.email,
      };

      sendForgotPasswordCode(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          sessionStorage.setItem("passwordResetEmail", data.email);
          setStep(2);
        }
      );
    },
  });

  return (
    <div className="w-full">
      <HeaderSubheader
        header="Forgot Password"
        subheader="Kindly provide your email address."
        className="mt-7"
      />
      <form className="mt-6 flex flex-col gap-3" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name={"email"}
          value={values.email}
          placeholder="Email Address"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email && errors.email}
          hasIcon={false}
        />
        <div className="w-full text-right mt-5 md:mt-3">
          <Button
            text="Continue"
            type="submit"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          />
        </div>
        <div className="w-full mt-1 !text-sm">
          I remember my password.{" "}
          <TextLink to="/auth/sign-in" text="Login" className="" />
        </div>
      </form>
    </div>
  );
};

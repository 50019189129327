import { useFormik } from "formik";
import React from "react";
import { ModalComponent } from "..";
import { useAuth } from "../../../customHooks/useAuth";
import { changePasswordSchema } from "../../../validationSchema";
import { TextInput } from "../../inputs/textInput";

export const ChangePasswordModal = ({
  isModalOpen,
  onModalClose,
  successCallback = () => {},
}) => {
  const { changePassword } = useAuth();

  const {
    values,
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      changePassword(
        {
          oldpassword: data?.oldPassword,
          password: data?.newPassword,
        },
        () => {
          setSubmitting(false);
        },
        () => {
          resetForm();
          setSubmitting(false);
          onModalClose();
        }
      );
    },
  });
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        resetForm();
        onModalClose();
      }}
      title="Change Password"
      subTitle="Kindly ensure you keep your password safe."
      childrenClassName=""
      modalClassName="!max-h-[480px]"
      hasButtons
      proceedButtonText="Change Password"
      onProceedClick={handleSubmit}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={isSubmitting}
      isProceedDisabled={isSubmitting || !isValid}
    >
      <div className="flex flex-col gap-3">
        <TextInput
          type="password"
          name="oldPassword"
          value={values?.oldPassword}
          placeholder="Enter current password"
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          iconSvg={undefined}
          errors={
            errors?.oldPassword && touched?.oldPassword && errors?.oldPassword
          }
          autoComplete="off"
        />
        <TextInput
          type="password"
          name="newPassword"
          value={values?.newPassword}
          placeholder="Enter new password"
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          iconSvg={undefined}
          errors={
            errors?.newPassword && touched?.newPassword && errors?.newPassword
          }
          autoComplete="off"
        />
        <TextInput
          type="password"
          name="confirmNewPassword"
          value={values?.confirmNewPassword}
          placeholder="Enter new password again"
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          iconSvg={undefined}
          errors={
            errors?.confirmNewPassword &&
            touched?.confirmNewPassword &&
            errors?.confirmNewPassword
          }
          autoComplete="off"
        />
      </div>
    </ModalComponent>
  );
};

import { useFormik } from "formik";
import React from "react";
import { ModalComponent } from "..";
import { useCooperative } from "../../../customHooks/useCooperative";
import { inviteMemberSchema } from "../../../validationSchema";
import { TextInput } from "../../inputs/textInput";

export const InviteMemberModal = ({
  isModalOpen,
  onModalClose,
  successCallback = () => {},
  singleCooperative,
}) => {
  const { sendMembershipInvite } = useCooperative();

  const {
    values,
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: inviteMemberSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      sendMembershipInvite(
        {
          email: data?.email,
          coop: singleCooperative?.id,
        },
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
          resetForm();
          onModalClose();
          successCallback({ Email: data?.email });
        }
      );
    },
  });
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        resetForm();
        onModalClose();
      }}
      title="Add Member"
      subTitle=""
      childrenClassName=""
      modalClassName="!max-h-[330px]"
      hasButtons
      proceedButtonText="Send an Invitation"
      onProceedClick={handleSubmit}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={isSubmitting}
      isProceedDisabled={isSubmitting || !isValid}
    >
      <TextInput
        type="text"
        name="email"
        value={values?.email}
        placeholder="Enter email address"
        onChange={handleChange}
        onBlur={handleBlur}
        hasIcon={false}
        iconSvg={undefined}
        errors={errors?.email && touched?.email && errors?.email}
        autoComplete="off"
      />
    </ModalComponent>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/button";

export const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <div
      className="
  w-full min-h-screen 
  flex flex-col
  justify-center items-center
  text-pry-dark-green-300
  p-10
  "
    >
      <div>
        <img src="/assets/images/we_got_a_problem.svg" alt="We got a problem" />
      </div>
      <div
        className="
      text-[32px] text-pry-dark-green-300
      font-bold
      text-center
      leading-tight md:leading-normal
      mt-[31px]
      "
      >
        Oops! Seems something is wrong
      </div>
      <div
        className="
      text-[20px] 
      text-center
      leading-tight md:leading-normal
      mt-2"
      >
        Looks like we took a wrong turn. Let's get you back on track:
      </div>
      <div
        className="
      text-[16px] max-w-[306px]
      mt-2
      "
      >
        <ul className="s !list-disc">
          <li>Let us know about the broken link.</li>
          <li>Double-check the URL for any typos.</li>
          <li>Return to our homepage and start fresh.</li>
        </ul>
      </div>

      <div
        className="
      w-full max-w-[327px]
      mt-6
      "
      >
        <Button
          text="Back to Home"
          onClick={() => {
            navigate("/app/overview/");
          }}
          type="button"
          className="md:!py-[12px] w-full"
          loading={false}
          disabled={false}
          variation="secondary"
        />
      </div>
    </div>
  );
};

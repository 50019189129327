import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ModalComponent } from "..";
import success from "../../../assets/images/success.svg";
import { useCooperative } from "../../../customHooks/useCooperative";
import { useLoans } from "../../../customHooks/useLoans";
import { usePayment } from "../../../customHooks/usePayment";
import { useSavings } from "../../../customHooks/useSavings";
import {
  numberToString,
  stringToNumber,
  stripNumber,
} from "../../../helperFunctions";
import { BackButton } from "../../buttons/backButton";
import { Button } from "../../buttons/button";
import { DebitCard } from "../../cards/debitcard";
import { MembershipFormField } from "../../cards/membershipFormField";
import { Icon } from "../../icons/icon";
import { Dropdown } from "../../inputs/dropDown";
import { TextInput } from "../../inputs/textInput";
import { PageLoader } from "../../loaders/pageLoader";
import { ProgressTrail } from "../../progress/progressTrail";
import { EmptyState } from "../../states/emptyState";
import { TimeText } from "../../typography/timeText";
import { CardTokenizationModal } from "./cardTokenizationModal";
import { RepaymentScheduleModal } from "./repaymentScheduleModal";

const LoanInformation2 = ({ fetchingBanks, bankList, loanInformationForm }) => {
  return (
    <div className="w-full flex flex-col gap-4 hide-scroll-bar">
      <TextInput
        type="text"
        name={"loanAmount"}
        value={
          stripNumber(loanInformationForm?.values?.loanAmount) !== ""
            ? numberToString(loanInformationForm?.values?.loanAmount)
            : ""
        }
        placeholder="Enter amount"
        onBlur={loanInformationForm?.handleBlur}
        onChange={loanInformationForm?.handleChange}
        errors={
          loanInformationForm?.errors?.loanAmount &&
          loanInformationForm?.touched?.loanAmount &&
          loanInformationForm?.errors?.loanAmount
        }
        inputClass=""
        className=""
        customIcon={
          <div>
            <Icon svg={"naira"} width={20} height={20} />
          </div>
        }
        customIconPosition="left"
        hasTitle
        title="Loan Amount"
      />

      <Dropdown
        value={loanInformationForm?.values?.disbursebankID}
        options={bankList}
        resetOptionsList={() => {}}
        placeholder="Select disbursal method"
        onChange={(selected) => {
          loanInformationForm?.setFieldValue("disbursebankID", selected);
        }}
        onBlur={loanInformationForm?.handleBlur("disbursebankID")}
        hasIcon={false}
        errors={
          loanInformationForm?.errors?.disbursebankID &&
          loanInformationForm?.touched?.disbursebankID &&
          loanInformationForm?.errors?.disbursebankID
        }
        dropdownClassName=""
        onSearch={() => {}}
        isSearchable
        className=""
        hasTitle
        title="Disbursal Method"
        containerClassName=""
        dropDownPosition="bottom"
        loading={fetchingBanks}
      />

      <TextInput
        type="text"
        name={"tenor"}
        value={loanInformationForm?.values?.tenor}
        placeholder="Enter loan tenor"
        onBlur={loanInformationForm?.handleBlur}
        onChange={loanInformationForm?.handleChange}
        errors={
          loanInformationForm?.errors?.tenor &&
          loanInformationForm?.touched?.tenor &&
          loanInformationForm?.errors?.tenor
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  "
          >
            Months
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Loan Tenor/Duration (in months)"
      />
    </div>
  );
};

const LoanInformationForm = ({
  fetchingBanks,
  memberBanks,
  loanConfigs,
  loanInformationForm,
  setSelectedLoan,
  selectedLoan,
  loanInformationForm2,
}) => {
  const [bankList, setBankList] = useState([]);
  const [loanProducts, setLoanProducts] = useState([]);

  useEffect(() => {
    setBankList([
      ...memberBanks?.map((bank) => {
        return {
          id: bank?.id,
          title: `${bank?.AccountNumber} - ${bank?.BankName}`,
          value: bank?.id,
        };
      }),
      // {
      //   id: -1,
      //   title: "My ThriftCorp Wallet",
      //   value: -1,
      // },
    ]);
  }, [memberBanks]);

  useEffect(() => {
    setLoanProducts(
      loanConfigs?.map((loanProduct) => {
        return {
          id: loanProduct?.id,
          title: loanProduct?.LoanName,
          value: loanProduct?.id,
        };
      })
    );
  }, [loanConfigs]);

  return (
    <div className="w-full flex flex-col gap-4 hide-scroll-bar">
      <Dropdown
        value={loanInformationForm?.values?.loanproductID}
        options={loanProducts}
        resetOptionsList={() => {}}
        placeholder="Select loan product"
        onChange={(selected) => {
          setSelectedLoan(
            loanConfigs?.find((item) => item?.id === selected?.id)
          );
          loanInformationForm?.setFieldValue("loanproductID", selected);
        }}
        onBlur={loanInformationForm?.handleBlur("loanproductID")}
        hasIcon={false}
        errors={
          loanInformationForm?.errors?.loanproductID &&
          loanInformationForm?.touched?.loanproductID &&
          loanInformationForm?.errors?.loanproductID
        }
        dropdownClassName=""
        onSearch={() => {}}
        isSearchable
        className=""
        hasTitle
        title="Loan Product"
        containerClassName=""
        dropDownPosition="bottom"
      />

      {selectedLoan && (
        <LoanInformation2
          fetchingBanks={fetchingBanks}
          bankList={bankList}
          selectedLoan={selectedLoan}
          loanInformationForm={loanInformationForm2}
        />
      )}
    </div>
  );
};

// ___---------__________GUARANTOR FORM___---------__________
// Search function outside component to prevent re-render
const handleSearch = (searchFunction) => {
  searchFunction();
};

// Convert handleSearch function to a debounced function
const handleDebouncedSearch = debounce(handleSearch, 800);

const GuarantorForm = ({
  singleCooperative,
  finalPayload,
  setFinalPayload,
  selectedLoan,
  profile,
}) => {
  const [text, setText] = useState("");
  const [query, setQuery] = useState({
    limit: 20,
    offset: 0,
    coop: singleCooperative?.id,
    text: "",
  });

  const { fetchMyCooperativeMembersQuery } = useCooperative();

  const cooperativeMembers = useQuery(
    ["fetchMyCooperativeMembersQuery", query],
    fetchMyCooperativeMembersQuery,
    { enabled: true }
  );

  const guarantorForm = useFormik({
    initialValues: {
      guarantor: "",
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string("Enter a valid value").required(
        "Loan amount is required"
      ),
      guarantor: Yup.object().test(
        "not-empty",
        "Select an option from the list",
        (value) => {
          try {
            return (
              Object.values(value)?.length > 0 &&
              Object.values(value)?.every((el) => el)
            );
          } catch {
            return false;
          }
        }
      ),
    }),
    enableReinitialize: true,
    onSubmit: (data, { resetForm }) => {
      setFinalPayload({
        ...finalPayload,
        guarantor: [
          ...finalPayload?.guarantor,
          {
            email: data?.guarantor?.value,
            amount: stringToNumber(data?.amount),
            name: data?.guarantor?.title,
          },
        ],
      });
      resetForm();
    },
  });

  useEffect(() => {
    handleDebouncedSearch(() =>
      setQuery((initial) => {
        return { ...initial, text: text };
      })
    );
  }, [text]);

  useEffect(() => {
    // Only fetch member when text is not empty
    query?.text && cooperativeMembers?.refetch();
    // eslint-disable-next-line
  }, [query]);

  return (
    <div className="w-full flex flex-col gap-4">
      <Dropdown
        value={guarantorForm?.values?.guarantor}
        options={
          // Only show options when use starts typing
          query?.text === ""
            ? []
            : cooperativeMembers?.data?.results?.map((member) => {
                const guarantorEmails = finalPayload?.guarantor?.map((g) => {
                  return g?.email;
                });
                // Hide member that have already been adeed to the guarator list and is not user him/herself
                if (
                  !guarantorEmails?.includes(member?.User?.Email) &&
                  !guarantorEmails?.includes(profile?.Email)
                ) {
                  return {
                    id: member?.id,
                    title: `${member?.User?.FirstName} ${member?.User?.LastName}`,
                    value: member?.User?.Email,
                  };
                }
                return undefined;
              })
        }
        resetOptionsList={() => {}}
        placeholder="Start typing email address, name or phone number"
        onChange={(val) => {
          guarantorForm?.setFieldValue("guarantor", val);
        }}
        onBlur={guarantorForm?.handleBlur("guarantor")}
        hasIcon={false}
        errors={
          guarantorForm?.errors?.guarantor &&
          guarantorForm?.touched?.guarantor &&
          guarantorForm?.errors?.guarantor
        }
        dropdownClassName=""
        onSearch={(val) => {
          setText(val);
        }}
        loading={cooperativeMembers?.isFetching}
        isSearchable={true}
        className=""
        hasTitle
        title="Email address, Name or Phone Number"
        containerClassName=""
        dropDownPosition="bottom"
      />

      <TextInput
        type="text"
        name={"amount"}
        value={
          stripNumber(guarantorForm?.values?.amount) !== ""
            ? numberToString(guarantorForm?.values?.amount)
            : ""
        }
        placeholder="Enter amount"
        onBlur={guarantorForm?.handleBlur}
        onChange={guarantorForm?.handleChange}
        errors={
          guarantorForm?.errors?.amount &&
          guarantorForm?.touched?.amount &&
          guarantorForm?.errors?.amount
        }
        inputClass=""
        className=""
        customIcon={
          <div>
            <Icon svg={"naira"} width={20} height={20} />
          </div>
        }
        customIconPosition="left"
        hasTitle
        title="Amount to Guarantee"
      />

      <div className="flex justify-end">
        <Button
          text={"Add Guarantor"}
          onClick={guarantorForm?.handleSubmit}
          type="button"
          className="!max-w-[150px] !py-2 !px-3 !text-sm"
          loading={false}
          disabled={
            !guarantorForm?.isValid ||
            finalPayload?.guarantor?.length >= selectedLoan?.MaxGuarantor
          }
          icon={"plus"}
          variation="primary-dark"
        />
      </div>

      <div className="flex w-full justify-end font-medium text-xs">
        ({finalPayload?.guarantor?.length ?? 0} / {selectedLoan?.MaxGuarantor})
      </div>

      {finalPayload?.guarantor?.map((item, idx) => {
        const key = `guarantor-${idx}`;
        return (
          <MembershipFormField
            key={key}
            field={{
              fieldType: item?.name,
              question: (
                <div>
                  To guaranree:{" "}
                  <span className="font-medium">
                    ₦{numberToString(item?.amount)}
                  </span>
                </div>
              ),
              isDefault: false,
            }}
            mode="edit"
            form={finalPayload?.guarantor}
            setForm={(val) => {
              setFinalPayload({
                ...finalPayload,
                guarantor: val,
              });
            }}
            index={idx}
            icon="profile"
            className="!w-full !p-3"
            barClassName="!bg-dark-purple !text-[14px]"
            bodyClassName="!text-dark-purple !text-[14px]"
            iconHeight={16}
            iconWidth={16}
          />
        );
      })}
    </div>
  );
};

// ___---------__________LOAN SUMMARY___---------__________
export const LoanSummary = ({ singleCooperative, finalPayload }) => {
  const [scheduleModal, setScheduleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loanDetails, setLoanDetails] = useState({
    loan_type: finalPayload?.loanproductID?.title,
    loan_amount: "",
    loan_tenor: "",
    monthly_repayment: undefined,
    total_interest: undefined,
    first_due_date: undefined,
    maturity_date: undefined,
  });
  const [repaymentSchedule, setRepaymentSchedule] = useState([]);

  const { generateRepaymentSchedule } = useLoans();

  useEffect(() => {
    finalPayload?.loanproductID?.value && setLoading(true);
    finalPayload?.loanproductID?.value &&
      generateRepaymentSchedule(
        {
          coop: singleCooperative?.id,
          loanproductID: finalPayload?.loanproductID?.value,
          loanAmount: finalPayload?.loanAmount,
          tenor: finalPayload?.tenor,
        },
        () => {
          setLoading(false);
        },
        (data) => {
          const loanSummary = {};

          loanSummary.loan_type = finalPayload?.loanproductID?.title;
          loanSummary.loan_amount = `₦${numberToString(data?.loanAmount)}`;
          loanSummary.loan_tenor = `${data?.tenor} month(s)`;
          loanSummary.monthly_repayment = `₦${numberToString(
            data?.monthly_payment
          )}`;
          loanSummary.total_interest = `₦${numberToString(
            data?.totalInterest
          )}`;
          loanSummary.first_due_date = (
            <TimeText timeString={data?.FirstDueDate} showTime={false} />
          );
          loanSummary.maturity_date = (
            <TimeText timeString={data?.MaturityDate} showTime={false} />
          );

          // Define key and value pair for guarantors
          const guarantors = finalPayload?.guarantor?.map((guarantor, idx) => {
            return {
              key: `guarantor_${idx + 1}_payment`,
              value: `₦${numberToString(guarantor?.amount)}`,
            };
          });

          // Add guarantors to main object
          guarantors?.forEach(({ key, value }) => {
            Object.assign(loanSummary, { [key]: value });
          });

          // Add repayment schedule view to main object
          loanSummary.repayment_schedule = (
            <span
              className="
            text-pry-green-300 
            cursor-pointer !opacity-100
            "
              onClick={() => {
                setScheduleModal(true);
              }}
            >
              View schedule
            </span>
          );

          setLoanDetails((val) => {
            return { ...val, ...loanSummary };
          });
          setRepaymentSchedule(data?.repayment);

          setLoading(false);
        }
      );
    // eslint-disable-next-line
  }, [finalPayload]);

  return (
    <div
      className="
      p-4 rounded-lg
      bg-light-green-bg
      
      "
    >
      {loading ? (
        <PageLoader width={30} height={30} />
      ) : (
        <div
          className="
          flex flex-col
          gap-3"
        >
          {Object.keys(loanDetails)?.map((detailKey, idx) => {
            const key = `loan-detail-${idx}`;
            return (
              <div
                key={key}
                className="
              flex justify-between 
              items-center text-xs ms:text-sm
              font-medium
              "
              >
                <div className="capitalize ">
                  {detailKey.split("_").join(" ")}
                </div>
                <div className="opacity-70">{loanDetails[detailKey]}</div>
              </div>
            );
          })}
        </div>
      )}

      <RepaymentScheduleModal
        isModalOpen={scheduleModal}
        onModalClose={() => {
          setScheduleModal(false);
        }}
        cooperativeId={singleCooperative?.id}
        loanProductId={finalPayload?.loanproductID?.value}
        loanAmount={finalPayload?.loanAmount}
        tenor={finalPayload?.tenor}
        doFetch={false}
        schedule={repaymentSchedule}
        loanStatus="new"
      />
    </div>
  );
};

// ___---------__________REPAYMENT FORM___---------__________
const RepaymentForm = ({ repaymentForm }) => {
  const [tokenizationModalOpen, setTokenizationModalOpen] = useState(false);

  const { fetchUserCards } = usePayment();

  const userCards = useQuery(["fetchUserCards"], fetchUserCards, {
    enabled: true,
  });

  const repaymentMethods = [
    // {
    //   id: 1,
    //   title: "Debit my ThriftCorp wallet",
    //   value: "wallet",
    // },
    {
      id: 2,
      title: "Card",
      value: "card",
    },
    {
      id: 3,
      title: "Manual",
      value: "manual",
    },
  ];

  useEffect(() => {
    repaymentForm?.values?.repaymentMode?.value === "card" &&
      userCards?.refetch();
    // eslint-disable-next-line
  }, [repaymentForm?.values?.repaymentMode]);

  return (
    <div>
      <Dropdown
        value={repaymentForm?.values?.repaymentMode}
        options={repaymentMethods}
        resetOptionsList={() => {}}
        placeholder="Select loan repayment method"
        onChange={(selected) => {
          repaymentForm?.setFieldValue("repaymentMethodId", "");
          repaymentForm?.setFieldValue("repaymentMode", selected);
        }}
        onBlur={repaymentForm?.handleBlur("repaymentMode")}
        hasIcon={false}
        errors={
          repaymentForm?.errors?.repaymentMode &&
          repaymentForm?.touched?.repaymentMode &&
          repaymentForm?.errors?.repaymentMode
        }
        dropdownClassName=""
        onSearch={() => {}}
        isSearchable={true}
        className=""
        hasTitle
        title="How do you plan to repay your loan?"
        containerClassName=""
        dropDownPosition="bottom"
      />

      {repaymentForm?.values?.repaymentMode?.value === "card" && (
        <div className="mt-4">
          <div className="text-sm font-bold mb-3">Select a card</div>
          {userCards?.isFetching ? (
            <PageLoader />
          ) : (
            <div className="">
              {userCards?.data?.length <= 0 ? (
                <EmptyState
                  title={"No cards added"}
                  imageClassName="!max-w-[100px] md:!max-w-[150px]"
                />
              ) : (
                <div className="flex flex-col gap-3">
                  {userCards?.data?.map((card, idx) => {
                    const key = `userCard-${idx}`;
                    return (
                      <DebitCard
                        key={key}
                        cardNumber={card?.CardDetail?.last4}
                        expiryDate={`${card?.CardDetail?.exp_month}/${card?.CardDetail?.exp_year}`}
                        type={card?.CardDetail?.card_type}
                        onClick={() => {
                          repaymentForm?.setFieldValue(
                            "repaymentMethodId",
                            card?.id
                          );
                        }}
                        isSelected={
                          repaymentForm?.values?.repaymentMethodId === card?.id
                        }
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <Button
            text={"Add a new card"}
            type="button"
            variation="plain"
            className="mt-3"
            icon={"plus"}
            onClick={() => {
              setTokenizationModalOpen(true);
            }}
          />
        </div>
      )}

      <CardTokenizationModal
        callback={() => {
          userCards?.refetch();
        }}
        modalOpen={tokenizationModalOpen}
        handleClose={() => {
          setTokenizationModalOpen(false);
          userCards?.refetch();
        }}
      />
    </div>
  );
};

// ___---------__________MAIN MODAL___---------__________
const defaultFinalPayload = { guarantor: [] };

export const RequestLoanModal = ({
  modalOpen,
  onModalClose = () => {},
  refresh,
  loanConfigs = [],
  singleCooperative,
}) => {
  const [showSuccess, setShowSuccess] = useState();
  const [step, setStep] = useState(1);
  const [fetchingBanks, setFetchingBanks] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState({});
  const [finalPayload, setFinalPayload] = useState(defaultFinalPayload);

  const steps = ["Loan Information", "Guarantors", "Loan Summary"];

  const { fetchMemberBankDetails } = usePayment();
  const { requestLoan } = useLoans();
  const { fetchMySavingsInformation } = useSavings();

  const { memberBanks, profile } = useSelector((state) => {
    return state?.auth;
  });

  const { mySavings } = useSelector((state) => {
    return state.savings;
  });

  const loanInformationForm = useFormik({
    initialValues: {
      loanproductID: "",
    },
    validationSchema: Yup.object().shape({
      loanproductID: Yup.object()
        .required("Select an option from the list")
        .test("not-empty", "Select an option from the list", (value) => {
          try {
            return (
              Object.values(value)?.length > 0 &&
              Object.values(value)?.every((el) => el)
            );
          } catch {
            return false;
          }
        })
        .required("Select an option from the list"),
    }),
    enableReinitialize: true,
    onSubmit: (data) => {
      setFinalPayload({ ...finalPayload, ...data });
    },
  });

  const loanInformationForm2 = useFormik({
    initialValues: {
      loanAmount: "",
      disbursemode: "",
      disbursebankID: "",
      tenor: "",
    },
    validationSchema: Yup.object().shape({
      loanAmount: Yup.string("Enter a valid value")
        .required("Loan amount is required")
        .test(
          "min",
          `Loan amount can't be less than ₦${Number(
            selectedLoan?.MinAmount
          )?.toLocaleString()}`,
          (value) => {
            try {
              return Number(value.replace(/,/g, "")) >= selectedLoan?.MinAmount;
            } catch {
              return false;
            }
          }
        )
        .test(
          "max",
          `You are currently only eligible for up to ₦${(
            selectedLoan?.XAmount * mySavings.Balance
          )?.toLocaleString()}`,
          (value) => {
            try {
              return (
                Number(value.replace(/,/g, "")) <
                mySavings.Balance * selectedLoan?.XAmount
              );
            } catch {
              return false;
            }
          }
        ),
      disbursebankID: Yup.object().test(
        "not-empty",
        "Select an option from the list",
        (value) => {
          try {
            return (
              Object.values(value)?.length > 0 &&
              Object.values(value)?.every((el) => el)
            );
          } catch {
            return false;
          }
        }
      ),
      tenor: Yup.number("Enter a valid value")
        .required("Tenor is required")
        .min(1, `Minimum loan tenor is 1 month`)
        .max(
          selectedLoan?.MaxTenure,
          `Maximum loan tenor is ${selectedLoan?.MaxTenure} months`
        ),
    }),
    enableReinitialize: true,
    onSubmit: (data) => {
      // Decode and set disburse mode
      const disburseMode =
        data?.disbursebankID?.value === -1 ? "wallet" : "bank";
      data.disbursemode = disburseMode;

      // Convert loan amount to number
      data.loanAmount = stringToNumber(data?.loanAmount);

      // Submit first half of loan information form
      loanInformationForm?.handleSubmit();

      // Update final payload
      setFinalPayload({ ...finalPayload, ...data });

      // Move to next step, check if guarantors are needed
      if (selectedLoan?.MaxGuarantor <= 0) {
        setStep(3);
      } else {
        setStep(step + 1);
      }
    },
  });

  const repaymentForm = useFormik({
    initialValues: {
      repaymentMode: "",
      repaymentMethodId: "",
    },
    validationSchema: Yup.object().shape({
      repaymentMode: Yup.object()
        .required("Select an option from the list")
        .test("not-empty", "Select an option from the list", (value) => {
          try {
            return (
              Object.values(value)?.length > 0 &&
              Object.values(value)?.every((el) => el)
            );
          } catch {
            return false;
          }
        })
        .required("Select an option from the list"),
      repaymentMethodId: Yup.string("Enter a valid value").when(
        "repaymentMode",
        {
          is: (repaymentMode) => repaymentMode?.value === "card",
          then: () => Yup.string().required("Choose a card"),
        }
      ),
    }),
    enableReinitialize: true,
    onSubmit: (data, { setSubmitting }) => {
      const payload = { ...finalPayload, ...data };
      payload.disbursebankID = payload?.disbursebankID?.value;
      payload.repaymentMode = payload?.repaymentMode?.value;
      payload.repaymentMethodId = payload?.repaymentMethodId || null;
      payload.loanproductID = payload?.loanproductID?.value;
      payload.coop = singleCooperative?.id;

      setSubmitting(true);
      requestLoan(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
          resetForms();
          setShowSuccess(true);
        }
      );
    },
  });

  // Fetch member's bank accounts and savings information
  useEffect(() => {
    if (modalOpen) {
      fetchMemberBankDetails(
        {},
        () => {
          setFetchingBanks(false);
        },
        () => {
          setFetchingBanks(false);
        },
        setFetchingBanks
      );

      fetchMySavingsInformation({
        coop: singleCooperative?.id,
        logs: false,
      });
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  // handles modal close
  const handleClose = () => {
    onModalClose();
    setShowSuccess(false);
    setStep(1);
    loanInformationForm?.handleReset();
  };

  // Check when the modal proceed button should be disabled
  const getProceedDisabled = () => {
    if (
      step === 1 &&
      (!loanInformationForm2?.isValid || !loanInformationForm?.isValid)
    ) {
      return true;
    } else if (step === 2 && finalPayload?.guarantor?.length <= 0) {
      return true;
    } else if (step === 4 && !repaymentForm?.isValid) {
      return true;
    }
    return false;
  };

  // Reset fianl payload to it's default value
  const resetFinalPayload = () => {
    setFinalPayload(defaultFinalPayload);
  };

  // Global reset for all forms and data
  const resetForms = (fullReset = true) => {
    fullReset && loanInformationForm?.handleReset();
    loanInformationForm2?.handleReset();
    resetFinalPayload();
  };

  useEffect(() => {
    resetForms(false);
    // eslint-disable-next-line
  }, [selectedLoan]);

  useEffect(() => {});

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={() => {
        handleClose();
        resetForms();
      }}
      topTitle
      title="Loan Request"
      subTitle=""
      modalClassName="!max-w-[424px] hide-scroll-bar"
      childrenClassName={`!flex !flex-col ${
        showSuccess && `!justify-center`
      } !min-h-full hide-scroll-bar`}
      proceedButtonText={step === 3 ? "Accept and Proceed" : "Proceed"}
      hasButtons={!showSuccess}
      isProceedDisabled={
        getProceedDisabled() || (step === 4 && repaymentForm?.isSubmitting)
      }
      isLoading={step === 4 ? repaymentForm?.isSubmitting : false}
      onProceedClick={() => {
        // step === 4 ? setShowSuccess(true) : setStep(step + 1);
        if (step === 1) {
          loanInformationForm2?.handleSubmit();
        }
        if (step === 2) {
          setStep(step + 1);
        }
        if (step === 3) {
          // Update final payload
          setFinalPayload({ ...finalPayload, hasAccepted: true });
          // Move to next step
          setStep(step + 1);
        }
        if (step === 4) {
          repaymentForm?.handleSubmit();
        }
      }}
      hasCancelButton={false}
      cancelButtonText="Cancel"
      cancelButtonVariant="secondary"
      onCancelClick={() => {
        handleClose();
        resetForms();
      }}
      modalStyle="side"
    >
      {showSuccess ? (
        <EmptyState
          image={<img src={success} alt="success" />}
          titleClassName=""
          title={`Loan request submitted`}
          subtitle={"Your loan application has been forwarded for approval"}
          actionText={"View loans"}
          actionButtonVariant="primary"
          onClick={() => {
            handleClose();
            resetForms();
            refresh();
          }}
        />
      ) : (
        <form className="transition-all duration-500">
          {/* Back button */}
          <div
            className={`
          ${showSuccess && "!hidden"}
          flex items-center justify-between
          mb-5
          `}
          >
            {!showSuccess && step > 1 && (
              <BackButton
                text={"Previous"}
                onClick={() => {
                  // Go to first page if selected loan doesn't require guarantors
                  if (selectedLoan?.MaxGuarantor <= 0 && step === 3) {
                    setStep(1);
                  } else {
                    step !== 1 && setStep(step - 1);
                  }
                }}
                className="!gap-1"
                backIcon="back-caret"
              />
            )}
            {!showSuccess && step === 2 && (
              <div
                onClick={() => {
                  setFinalPayload({ ...finalPayload, guarantor: [] });
                  setStep(3);
                }}
                className="cursor-pointer font-medium text-info-purple"
              >
                Skip
              </div>
            )}
          </div>
          {/* ProgressTrail */}
          {step < 4 && (
            <div className="mb-6">
              <ProgressTrail
                steps={steps}
                currentStep={step}
                diasbledIndexes={selectedLoan?.MaxGuarantor <= 0 ? [1] : []}
              />
            </div>
          )}
          {/* Forms and screens */}
          {step === 1 && (
            <LoanInformationForm
              setFetchingBanks={fetchingBanks}
              memberBanks={memberBanks}
              loanConfigs={loanConfigs}
              loanInformationForm={loanInformationForm}
              setSelectedLoan={setSelectedLoan}
              selectedLoan={
                selectedLoan &&
                Object.keys(selectedLoan)?.length > 0 &&
                selectedLoan
              }
              loanInformationForm2={loanInformationForm2}
            />
          )}
          {step === 2 && (
            <GuarantorForm
              singleCooperative={singleCooperative}
              setFinalPayload={setFinalPayload}
              finalPayload={finalPayload}
              selectedLoan={selectedLoan}
              profile={profile}
            />
          )}
          {step === 3 && (
            <LoanSummary
              singleCooperative={singleCooperative}
              finalPayload={finalPayload}
            />
          )}
          {step === 4 && <RepaymentForm repaymentForm={repaymentForm} />}
        </form>
      )}
    </ModalComponent>
  );
};

import React from "react";
import empty_coming_soon from "../../../assets/images/empty_state_coming_soon.svg";
import { ContainerCard } from "../../../components/cards/containerCard";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";

export const Wallet = () => {
  return (
    <div className="w-full">
      <PageTitle text="Wallet" />
      <ContainerCard className="mt-6 !py-14">
        <EmptyState
          image={<img src={empty_coming_soon} alt="empty_state" />}
          title="Coming soon..."
          subtitle="We are getting wallet ready for you!"
        />
      </ContainerCard>
    </div>
  );
};

import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../customHooks/useAuth";
import { isProfileApproved } from "../helperFunctions";
import { isAccountConfirmed, isAuthenticated } from "../helperFunctions/auth";
import {
  callbackMap,
  FE_APP_PROD_URL,
  FE_APP_STAGING_URL,
  FE_PROD_URL,
  FE_STAGING_URL,
  INACTIVITY_TIMEOUT,
} from "../utils/constants";
import { mainRoutes } from "./mainRoutes";
import { PrivateRoute } from "./privateRoutes";

export const AppRoutes = () => {
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  useEffect(() => {
    if (
      isAccountConfirmed() === false &&
      window.location.pathname.includes("app/")
    ) {
      navigate("/auth/sign-up");
    }

    if (isAuthenticated() && window.location.pathname.includes("auth/")) {
      navigate("/app/overview");
    }
  }, [navigate]);

  // Switch domains logic
  useEffect(() => {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    if (
      (pathname.includes("/auth") || pathname.includes("/app")) &&
      hostname === FE_STAGING_URL
    ) {
      window.location.replace(`https://${FE_APP_STAGING_URL}${pathname}`);
    }
    if (
      (pathname.includes("/auth") || pathname.includes("/app")) &&
      hostname === FE_PROD_URL
    ) {
      window.location.replace(`https://${FE_APP_PROD_URL}${pathname}`);
    }
    if (
      !pathname.includes("/auth") &&
      !pathname.includes("/app") &&
      hostname === FE_APP_STAGING_URL
    ) {
      window.location.replace(`https://${FE_STAGING_URL}${pathname}`);
    }
    if (
      !pathname.includes("/auth") &&
      !pathname.includes("/app") &&
      hostname === FE_APP_PROD_URL
    ) {
      window.location.replace(`https://${FE_PROD_URL}${pathname}`);
    }
  });

  // UseEffect for callback execution on page load
  useEffect(() => {
    // Execute callback on page load
    const callbackName = localStorage.getItem("logOutCallback");
    if (callbackName && callbackMap[callbackName]) {
      localStorage.removeItem("logOutCallback");
      callbackMap[callbackName]();
    }

    // Check localstorage for last activity and log out if exceeded allowed limit
    const lastActiveTimeStr = localStorage.getItem("lastActiveTime");
    if (lastActiveTimeStr) {
      const lastActiveTime = parseInt(lastActiveTimeStr, 10);
      if (
        Date.now() - lastActiveTime > INACTIVITY_TIMEOUT &&
        window.location.pathname.includes("app/")
      ) {
        logoutUser("securityAutoLogOutCallback", true);
      }
      localStorage.removeItem("lastActiveTime");
    }

    // Handle tab close
    const handleTabClose = () => {
      localStorage.setItem("lastActiveTime", Date.now());
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
    // eslint-disable-next-line
  }, []);

  // Logout after some time of inactivity
  useIdleTimer({
    timeout: INACTIVITY_TIMEOUT,
    onIdle: () => {
      isAuthenticated() && logoutUser("securityAutoLogOutCallback", true);
    },
    onActive: () => {},
    onAction: () => {},
    debounce: 500,
  });

  return (
    <Routes>
      {/* Private routes */}
      <Route exact path="/app" element={<PrivateRoute />}>
        {mainRoutes
          .filter(({ isPrivate }) => isPrivate)
          .map(({ route, element, profileAvailable }, idx) => {
            const key = `private-route-${idx}`;

            // Check If the account profile type matches the allowed type for the route
            if (
              profile?.type === profileAvailable ||
              profileAvailable === "all"
            ) {
              // Another check if cooperative profile types have been aprroved, to enable access to features
              if (!isProfileApproved(profile) && route !== "overview") {
                return undefined;
              }
              return <Route path={route} element={element} key={key} />;
            } else {
              return undefined;
            }
          })}
      </Route>

      {/* Public routes */}
      {mainRoutes
        .filter(({ isPrivate }) => !isPrivate)
        .map(({ route, element, profileAvailable }, idx) => {
          const key = `public-route-${idx}`;
          return profile?.type === profileAvailable ||
            profileAvailable === "all" ? (
            <Route exact path={route} element={element} key={key} />
          ) : undefined;
        })}
    </Routes>
  );
};

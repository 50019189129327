import React, { useState } from "react";
import { useQuery } from "react-query";
import { Button } from "../../../components/buttons/button";
import { NumberCard } from "../../../components/cards/numberCard";
import { Icon } from "../../../components/icons/icon";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { AddProductModal } from "../../../components/modals/app_modals/addProductModal";
import { PaymentModal } from "../../../components/modals/app_modals/paymentModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { CooperativeWithdrawalModal } from "../../../components/modals/app_modals/withdrawCooperativeModal";
import { CooperativeTransactionHistoryTable } from "../../../components/table/app_tables/cooperativeTransactionHistoryTable";
import { useCooperative } from "../../../customHooks/useCooperative";
import { usePayment } from "../../../customHooks/usePayment";
import { usePermissions } from "../../../customHooks/usePermissions";
import { permissionList } from "../../../utils/constants";

const balanceBreakdowns = [
  {
    title: "Savings Breakdown",
    key: "saving",
  },
  {
    title: "Deposits Breakdown",
    key: "deposit",
  },
  {
    title: "Shares Breakdown",
    key: "shares",
  },
  {
    title: "Membership Fee Breakdown",
    key: "membership",
  },
];

const AccordionCaret = ({ setAccordionOpen, accordionOpen, type }) => {
  return (
    <button
      className={`
      cursor-pointer p-1 
      rounded-full bg-pry-green-200
      text-white
      ${accordionOpen[type] && "rotate-180"} transition-all duration-500
      `}
      onClick={() => {
        setAccordionOpen({
          ...accordionOpen,
          [type]: !accordionOpen[type],
        });
      }}
    >
      <Icon svg={"caret-down"} className={""} width={24} height={24} />
    </button>
  );
};

export const CooperativeFinancials = ({ singleCooperative, mode }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [accordionOpen, setAccordionOpen] = useState({
    balanceBreakdown: false,
  });
  const [paymentData, setPaymentData] = useState({});

  const { adminFetchCooperativeFinances } = useCooperative();

  const { verifyPayment } = usePayment();

  const { hasPermissionsFor } = usePermissions();

  const cooperativeFinances = useQuery(
    [
      "cooperativeFinances",
      {
        coop: singleCooperative?.id,
        text: true,
        id: singleCooperative?.id,
        logs: false,
      },
    ],
    adminFetchCooperativeFinances,
    {
      enabled: true,
    }
  );

  const containerClassName = `
        w-full max-w-[504px]
        border border-pry-dark-green-300/10 
        rounded-[16px]
        p-4 bg-light-green-bg-300/30
        h-full
        `;

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const refreshPage = () => {
    cooperativeFinances?.refetch();
  };

  return cooperativeFinances?.isLoading ? (
    <PageLoader />
  ) : (
    <div className="w-full flex flex-col gap-4">
      {/* Cooperative Balance */}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 items-center">
        <div className={containerClassName}>
          <NumberCard
            title={"Available Thriftcorp Balance"}
            value={cooperativeFinances?.data?.Wallet?.InAppBalance}
            icon="wallet"
            className="!bg-pry-dark-green-100"
          />

          <div className="mt-3 flex flex-wrap md:flex-nowrap items-center gap-2.5">
            <Button
              text="Fund account"
              onClick={() => {
                handleModalOpen("fundCooperativeWalletModal");
              }}
              type="button"
              className="md:!py-[12px] w-full !text-sm"
              loading={false}
              disabled={!hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)}
              icon={
                hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)
                  ? "plus"
                  : "lock"
              }
              variation="primary"
            />

            <Button
              text="Withdraw to bank"
              onClick={() => {
                handleModalOpen("cooperativeWithdrawalModal");
              }}
              type="button"
              className="md:!py-[12px] w-full !text-sm"
              loading={false}
              disabled={!hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)}
              icon={
                hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)
                  ? "arrow-up"
                  : "lock"
              }
              variation="plain-3"
            />
          </div>
        </div>

        <div className={containerClassName}>
          <NumberCard
            title={"Total Balance Recorded"}
            value={cooperativeFinances?.data?.Wallet?.TotalBalance}
            className="!bg-white !text-pry-green-200 border"
            iconClassName="!bg-light-green-bg-300"
            icon="wallet"
          />

          <div
            className="
            text-sm mt-3
            py-1 px-3
            bg-light-green-bg-300
            rounded-[4px]
            "
          >
            <span className="font-medium text-pry-green-200">
              Total Balance Recorded
            </span>{" "}
            adds up both money received through Thriftcorp online payments and
            those uploaded as offline payments.
          </div>
        </div>
      </div>

      <div className={`${containerClassName} !max-w-full !rounded-lg`}>
        <div className="text-sm font-medium flex flex-row items-center justify-between gap-4">
          <div className="font-bold">View Balance Breakdown</div>
          <AccordionCaret
            setAccordionOpen={setAccordionOpen}
            accordionOpen={accordionOpen}
            type={"balanceBreakdown"}
          />
        </div>

        {accordionOpen?.balanceBreakdown && (
          <div className="flex flex-col gap-4 mt-6">
            {balanceBreakdowns.map((breakdown, idx) => {
              const key = `${breakdown.title}-${breakdown.key}-breakdown-${idx}`;

              return (
                <div
                  key={key}
                  className="
                  px-3 md:px-8 py-6 
                  border
                  border-pry-dark-green-300/10
                  bg-light-grey-bg-2
                  rounded-lg
                  "
                >
                  <div className="mb-4 font-medium text-base">
                    {breakdown.title}
                  </div>
                  <NumberCard
                    title={breakdown.title}
                    value={cooperativeFinances?.data[breakdown.key]}
                    className="!bg-white !text-pry-green-200 border !min-h-fit !max-w-full"
                    iconClassName="!bg-light-green-bg-300"
                    icon="wallet"
                    titleClassName="!text-xs"
                    valueClassName="!text-base"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="mt-8">
        <div className="text-base font-medium mb-6">Transaction History</div>

        <CooperativeTransactionHistoryTable
          singleCooperative={singleCooperative}
        />
      </div>

      <AddProductModal
        isModalOpen={modalOpen && modal === "fundCooperativeWalletModal"}
        onModalClose={handleModalClose}
        successCallback={(data) => {
          setPaymentData(data);
          handleModalOpen("paymentModal");
        }}
        singleCooperative={singleCooperative}
        type="fundCooperativeWallet"
        cooperative={singleCooperative}
        callback={() => {}}
      />

      <PaymentModal
        isModalOpen={modalOpen && modal === "paymentModal"}
        onModalClose={handleModalClose}
        paymentData={paymentData}
        handleVerification={(doVerify = true) => {
          doVerify &&
            verifyPayment(
              {
                coop: singleCooperative?.id,
                trxref: paymentData?.reference,
                amount: paymentData?.amount,
                reference: paymentData?.reference,
                mode: "paystack",
              },
              () => {
                refreshPage();
              },
              () => {
                handleModalClose();
                handleModalOpen("fundCooperativeWalletSuccessModal");
                setPaymentData({});
              },
              "fundCooperativeWallet"
            );
        }}
      />

      <SuccessModal
        message={"Account funded successfully"}
        subText={
          <span>
            <span className="text-pry-green-300 font-medium">
              {singleCooperative?.CoopName}'s
            </span>{" "}
            ThriftCorp balance has been successfully funded
          </span>
        }
        isModalOpen={modalOpen && modal === "fundCooperativeWalletSuccessModal"}
        onModalClose={handleModalClose}
      />

      <CooperativeWithdrawalModal
        modalOpen={modalOpen && modal === "cooperativeWithdrawalModal"}
        onModalClose={handleModalClose}
        cooperativeId={singleCooperative?.id}
      />
    </div>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMembership } from "../../customHooks/useMembership";
import { Button } from "../buttons/button";
import { Icon } from "../icons/icon";
import { CooperativeProfilePic } from "../profile/cooperativePicture";
import { ProfilePicture } from "../profile/profilePicture";
import { ContainerCard } from "./containerCard";

export const InvitationCard = ({
  invitation,
  handleModalOpen,
  setSelected,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { acceptOfficialInvite, fetchInvitations } = useMembership();

  return (
    <ContainerCard className="!px-6 md:!px-8 !py-6 !max-w-[526px]">
      <div className="flex items-center gap-2">
        <ProfilePicture className="w-[32px] h-[32px]" />
        <Icon svg="left-arrow" width={20} height={20} className="rotate-180" />
        <CooperativeProfilePic
          cooperative={invitation?.Cooperative}
          className="!w-[32px] !h-[32px] !text-[12px]"
        />
      </div>

      <div className="mt-4 text-base">
        You were invited to join{" "}
        <span className="text-pry-green-200 font-medium">
          {invitation?.Cooperative?.CoopName}
        </span>{" "}
        as{" "}
        <span className="text-pry-green-200 font-medium">
          {invitation?.InvitationAction !== "official"
            ? "a Member"
            : `an Official (role)`}
        </span>
      </div>

      {!invitation?.ismember && (
        <div className="mt-4 text-sm">
          <ul className="list-disc leading-6 list-inside">
            <li>Complete a membership form</li>
            <li>Pay your membership fee</li>
          </ul>
        </div>
      )}

      <div className="flex flex-row gap-2 mt-4">
        <Button
          text="Decline"
          onClick={() => {
            setSelected(invitation);
            handleModalOpen("rejectInvitationModal");
          }}
          type="button"
          className="md:!py-2"
          loading={false}
          disabled={false}
          variation="secondary-light-2"
        />
        <Button
          text="Accept Invitation"
          onClick={() => {
            if (!invitation?.ismember) {
              navigate(
                `/app/cooperative/${invitation?.Cooperative?.id}/join?mode=invitation&invitationId=${invitation?.id}`
              );
            } else if (
              invitation?.ismember &&
              invitation?.InvitationAction === "official"
            ) {
              setLoading(true);
              acceptOfficialInvite(
                {
                  coop: invitation?.Cooperative?.id,
                  isAccept: true,
                },
                () => {
                  setLoading(false);
                },
                () => {
                  setLoading(false);
                  fetchInvitations();
                }
              );
            } else {
              console.log("outlier");
            }
          }}
          type="button"
          className="md:!py-2"
          loading={loading}
          disabled={loading}
          variation="primary"
        />
      </div>
    </ContainerCard>
  );
};

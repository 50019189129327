import React, { useEffect } from "react";
import { useQuery } from "react-query";
import {
  InformationCard,
  InformationCardNoIcon,
} from "../../../../components/cards/infoCard";
import { PageLoader } from "../../../../components/loaders/pageLoader";
import { useSettings } from "../../../../customHooks/useSettings";
import { DepositConfigurations } from "./depositConfigurations";
import { LoanConfiguration } from "./loanConfiguration";
import { SharesConfiguration } from "./sharesConfiguration";

export const Configurations = ({ mode = "view" }) => {
  const { fetchAllConfiguration } = useSettings();

  const cooperativeConfiguration = useQuery(
    "allCooperativeConfiguration",
    fetchAllConfiguration,
    {
      enabled: true,
    }
  );

  useEffect(() => {
    cooperativeConfiguration?.refetch();
    // eslint-disable-next-line
  }, []);

  return cooperativeConfiguration?.isFetching ? (
    <PageLoader />
  ) : (
    <div>
      {mode === "view" && (
        <InformationCardNoIcon
          className="max-w-[653px]"
          info={
            <div>
              Click the{" "}
              <span className="text-pry-green-200 font-medium">Edit</span>{" "}
              button to modify configurations
            </div>
          }
        />
      )}

      <div
        className="
        flex flex-row flex-wrap
        w-full
        gap-2 md:gap-6
        mt-3
        "
      >
        <div
          className="
            w-full max-w-[653px]
            order-last width-lim:order-first
            flex flex-col gap-2
            "
        >
          {/* Deposit Configurations */}
          <DepositConfigurations
            mode={mode}
            cooperativeConfiguration={cooperativeConfiguration}
          />

          {/* Loan Configurations */}
          <LoanConfiguration
            mode={mode}
            cooperativeConfiguration={cooperativeConfiguration}
          />

          {/* Shares Configurations */}
          <SharesConfiguration
            mode={mode}
            cooperativeConfiguration={cooperativeConfiguration}
          />
        </div>

        <InformationCard
          info={`Set up controls and configurations for loans, deposits, and shares.`}
          className="!max-w-[345px] h-fit width-lim:sticky-top"
        />
      </div>
    </div>
  );
};

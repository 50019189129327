import React, { useEffect, useState } from "react";
import empty_state_member from "../../../assets/images/empty_state_member.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { MemberCard } from "../../../components/cards/memberCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { InviteMemberModal } from "../../../components/modals/app_modals/inviteMemberModal";
import { RemoveMemberModal } from "../../../components/modals/app_modals/memberRemovalConfirmModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { UploadMembersModal } from "../../../components/modals/app_modals/uploadMembersModal";
import { Pagination } from "../../../components/pagination/pagination";
import { EmptyState } from "../../../components/states/emptyState";
import { useCooperative } from "../../../customHooks/useCooperative";
import { usePermissions } from "../../../customHooks/usePermissions";
import { permissionList } from "../../../utils/constants";

export const CooperativeMembers = ({ singleCooperative, mode }) => {
  const [query, setQuery] = useState({
    offset: 0,
    limit: 10,
    text: null,
    coop: singleCooperative?.id,
  });
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState({});
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMemberFull, setSelectedMemberFull] = useState({});
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const { hasPermissionsFor } = usePermissions();

  const { fetchMyCooperativeMembers } = useCooperative();

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const doCooperativeMembersFetch = () => {
    fetchMyCooperativeMembers(
      query,
      () => {
        setLoading(false);
      },
      (data) => {
        setMembers(data);
        setLoading(false);
      },
      setLoading
    );
  };

  useEffect(() => {
    doCooperativeMembersFetch();
    // eslint-disable-next-line
  }, [query]);

  return (
    <div>
      <div
        className="
        flex flex-row flex-wrap 
        gap-4
        items-center justify-between
      "
      >
        {!loading ? (
          <div className="text-sm font-medium opacity-80 w-full md:w-fit">
            {members?.count} {members?.count === 1 ? "member" : "members"}
          </div>
        ) : (
          <div></div>
        )}

        <div
          className="flex-grow flex flex-row flex-wrap gap-2 
        items-center justify-end
        "
        >
          <div className="w-full md:w-fit">
            <FilterComponent
              searchPlaceholder="Search member first name or last name"
              hasExtraFilter={false}
              size="small"
              className="!w-full md:!w-[286px] !py-2"
              handleSearch={(e) => {
                setQuery({ ...query, text: e, offset: 0 });
              }}
            />
          </div>

          {mode === "admin" &&
            hasPermissionsFor(permissionList.CAN_ADD_MEMBER) && (
              <Button
                text={"Add Member"}
                onClick={() => {
                  setModal("inviteModal");
                  setModalOpen(true);
                }}
                type="button"
                className="md:!max-w-[140px] flex-1 !text-xs md:!text-sm md:!py-1.5"
                loading={false}
                disabled={false}
                variation="primary"
                icon={"add-profile-icon"}
              />
            )}

          {mode === "admin" &&
            hasPermissionsFor(permissionList.CAN_ADD_MEMBER) && (
              <Button
                text={"Upload"}
                onClick={() => {
                  setModal("uploadMembersModal");
                  setModalOpen(true);
                }}
                type="button"
                className="md:!max-w-[140px] flex-1 !text-xs md:!text-sm md:!py-1.5"
                loading={false}
                disabled={false}
                variation="plain-2"
                icon={"file-upload-icon"}
              />
            )}
        </div>
      </div>

      <div className="mt-[24px]">
        {loading ? (
          <PageLoader />
        ) : !members?.count ? (
          <ContainerCard>
            <EmptyState
              image={<img src={empty_state_member} alt="empty state" />}
              title="We couldn't find anything related to your search"
            />
          </ContainerCard>
        ) : (
          <div>
            <div className="overflow-scroll">
              <div className="min-w-full w-max md:w-full flex flex-col gap-2 ">
                {members?.results?.map((member, idx) => {
                  const key = `memberCard-${idx}`;
                  return (
                    <MemberCard
                      memberDetails={member?.User}
                      mode={mode}
                      key={key}
                      onClick={(data) => {
                        setSelectedMember(data);
                      }}
                      onRemoveClick={(data) => {
                        setSelectedMemberFull(member);
                        setSelectedMember(data);
                        setModalOpen(true);
                        setModal("removeModal");
                      }}
                    />
                  );
                })}
              </div>
            </div>

            <div className="mt-6">
              <Pagination
                totalCount={members?.count}
                limit={query?.limit}
                otherQueryParams={query}
                onPageChange={(e) => setQuery(e)}
              />
            </div>
          </div>
        )}
      </div>

      <SuccessModal
        message={`An invitation to join your cooperative has been sent to ${selectedMember?.Email}`}
        isModalOpen={modalOpen && modal === "invitationSentSuccessModal"}
        onModalClose={() => {
          handleModalClose();
          setSelectedMember({});
        }}
      />

      <InviteMemberModal
        isModalOpen={modalOpen && modal === "inviteModal"}
        onModalClose={handleModalClose}
        successCallback={(data) => {
          setSelectedMember(data);
          setModalOpen(true);
          setModal("invitationSentSuccessModal");
        }}
        singleCooperative={singleCooperative}
      />

      <RemoveMemberModal
        isModalOpen={modalOpen && modal === "removeModal"}
        onModalClose={() => {
          handleModalClose();
          setSelectedMember({});
        }}
        selectedMember={selectedMember}
        selectedMemberFull={selectedMemberFull}
        successCallback={(data) => {
          setSelectedMember(data);
          setModalOpen(true);
          setModal("removeSuccessModal");
        }}
      />

      <SuccessModal
        message={`You have requested to remove ${selectedMember?.Email}. Once approved, ${selectedMember?.Email} will no longer be a member of ${singleCooperative?.CoopName} `}
        isModalOpen={modalOpen && modal === "removeSuccessModal"}
        onModalClose={() => {
          handleModalClose();
          setSelectedMember({});
        }}
      />

      <UploadMembersModal
        isModalOpen={modalOpen && modal === "uploadMembersModal"}
        onModalClose={() => {
          handleModalClose();
          setSelectedMember({});
        }}
        singleCooperative={singleCooperative}
        successCallback={() => {
          doCooperativeMembersFetch();
        }}
      />
    </div>
  );
};

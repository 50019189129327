import { getRequest, postRequest } from "../api/requestHelpers";
import { doFileDownload } from "../helperFunctions";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";

export const useDeposit = () => {
  const fetchMyDepositInformation = async (
    params = {
      coop: undefined,
      logs: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Depository/My/Cooperative", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchMyDepositHistory = async (
    params = {
      coop: undefined,
      logs: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Depository/My/Cooperative", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.Logs?.count,
          data: data?.Logs?.results,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchCooperativeDepositInformation = async (
    params = {
      coop: undefined,
      logs: false,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Depository/Cooperative/Admin", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        response = {
          Balance: data?.summary,
        };

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchCooperativeMemberDepositInformation = async (
    params = {
      coop: undefined,
      logs: true,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Depository/Cooperative/Admin", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        response = {
          totalCount: data?.member?.returncount,
          data: data?.member?.results,
        };

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const doAdminAddDeposit = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Depository/Member/Manual/Admin", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doMemberAddDeposit = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Depository/Member/Manual", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const downloadBulkDepositsTemplate = async (
    params = {
      coop: undefined,
      file: "csv",
    },
    fileName = "sample",
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Depository/Member/Manual/file/upload", params, true)
      .then((resp) => {
        doFileDownload(resp, fileName, params?.file);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const doBulkDepositsUpload = async (
    payload,
    callback = () => {},
    successCallback = (data) => {}
  ) => {
    await postRequest("Depository/Member/Manual/file/upload", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Deposits uploaded for approval sucessfully", "success");

        // Call success callback
        successCallback(data);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    fetchMyDepositInformation,
    fetchCooperativeDepositInformation,
    fetchCooperativeMemberDepositInformation,
    fetchMyDepositHistory,
    doMemberAddDeposit,
    doAdminAddDeposit,
    downloadBulkDepositsTemplate,
    doBulkDepositsUpload,
  };
};

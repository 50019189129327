import React from "react";
import ClickAwayListener from "react-click-away-listener";
import { useSelector } from "react-redux";
import { OnboardingChecklist } from "./onboardingChecklist";

export const OnboardingPopup = ({ popupOpen, setPopupOpen }) => {
  const onboardingCompletion = useSelector((state) => {
    return state.auth.onboardingCompletionStatus;
  });

  return (
    <div
      className={`
            w-full
            h-full md:max-h-[765px]
            flex flex-col
            md:gap-6
            items-end justify-end
            transition-all duration-500
            ${!popupOpen && "!gap-0"}
        `}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (popupOpen) {
            setPopupOpen(false);
          }
        }}
        mouseEvent="mousedown"
        touchEvent="touchstart"
      >
        <div
          className={`w-full h-0 transition-all duration-[1000ms] ${
            popupOpen && "!h-full overflow-scroll"
          }`}
        >
          {popupOpen && (
            <OnboardingChecklist
              setPopupOpen={setPopupOpen}
              onboardingCompletion={onboardingCompletion}
            />
          )}
        </div>
      </ClickAwayListener>

      <div
        className={`
            group
            w-full md:w-fit
            flex gap-3 justify-between
            px-8 py-6
            md:rounded-[56px]
            text-base lg:text-[20px] font-bold
            transition-all duration-500
            md:hover:scale-[0.95] hover:cursor-pointer
            ${
              popupOpen
                ? "text-pry-dark-green-100 bg-white border-t md:border-t-0"
                : "text-white bg-pry-dark-green-100"
            }
            `}
        onClick={() => {
          setPopupOpen(!popupOpen);
        }}
      >
        <div className="">Onboarding Checklist</div>
        <div className="">|</div>
        <div>{onboardingCompletion?.onboardingCompletionProgress}%</div>
        <div className="">complete</div>
      </div>
    </div>
  );
};

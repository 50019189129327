import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageTitle: "",
  onboardingPopupOpen: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    /**Handles page title change */
    changed: (state, action) => {
      state.pageTitle = action.payload;
    },
    onboardingPopupChanged: (state, action) => {
      state.onboardingPopupOpen = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const layoutSliceReducer = layoutSlice.reducer;
export const layoutSliceActions = layoutSlice.actions;

import jwt_decode from "jwt-decode";
import { getCookie } from "../cookies";

/**
 * Checks if a user is authenticated
 */
export const isAuthenticated = () => {
  const token = getCookie("_tC_token");

  const isAuthenticated = token ? true : false;

  return isAuthenticated;
};

/**
 * Checks if an account's email has been verified.
 * It prioritizes verification status from the backend
 * then local storage flag if that isn't available
 */
export const isAccountConfirmed = () => {
  // Fetch account confirmation status from the local storage flag
  const accountConfirmation = JSON.parse(
    localStorage.getItem("accountConfirmation")
  );
  const isConfirmed = accountConfirmation?.isConfirmed;

  //   Fetch and decode stored token
  const token = getCookie("_tC_token");
  const decoded = token && jwt_decode(token);

  if (decoded) {
    // If there's a token, account confirmation status
    // will be based on the verification status of decoded token
    return decoded?.verified;
  }

  // If no token, base account confirmation status on local storage flag
  return isConfirmed;
};

/**
 * Clears out account confirmation flag from the local storage.
 * At this point, account should have been verified.
 */
export const clearAccountConfirmation = () => {
  localStorage.removeItem("accountConfirmation");
};

/**
 * Clears out password reset flag from the local storage.
 * At this point, account should have been verified.
 */
export const clearPasswordResetInfo = () => {
  sessionStorage.removeItem("passwordResetEmail");
};

/**
 * Parses error message from API response
 *
 * @param {*} data
 * @returns
 */
export const parseErrorMessage = (data) => {
  try {
    let errorMessage;
    if (data?.error) {
      errorMessage = data?.error;
    } else if (data?.data) {
      errorMessage = data?.data;
    } else {
      errorMessage = "An error occured";
    }
    return JSON.stringify(errorMessage);
  } catch (error) {
    console.log(error);
    return "An error occured";
  }
};

import React, { useState } from "react";
import { useQuery } from "react-query";
import { usePayment } from "../../../customHooks/usePayment";
import { decodeWalletType, numberToString } from "../../../helperFunctions";
import { Icon } from "../../icons/icon";
import { Pagination } from "../../pagination/pagination";
import { TimeText } from "../../typography/timeText";
import { TableComponent } from "../table";

export const CooperativeTransactionHistoryTable = ({ singleCooperative }) => {
  const defaultQuery = {
    coop: singleCooperative?.id,
    logs: true,
    text: "",
    limit: 10,
    offset: 0,
  };

  const [query, setQuery] = useState(defaultQuery);

  const { fetchCooperativeTransactionHistory } = usePayment();

  const cooperativeTransactions = useQuery(
    ["cooperativeTransactions", query],
    fetchCooperativeTransactionHistory,
    {
      enabled: true,
    }
  );

  const headers = [
    "DESCRIPTION",
    `AMOUNT`,
    <div className="text-right" key={`-33-header`}>
      DATE
    </div>,
  ];

  return (
    <div className="w-full ">
      <div className="w-full overflow-x-scroll">
        <TableComponent
          className="w-full !min-w-max md:!min-w-full"
          headers={headers}
          rows={cooperativeTransactions?.data?.data?.map((transaction, idx) => {
            const key = `cooperative-transaction-${idx}`;
            return {
              id: transaction?.id,
              items: [
                <div key={`${key}-19`} className="flex gap-4 items-center">
                  <div
                    className={`
                    p-1 rounded
                    `}
                  >
                    <Icon
                      svg={
                        transaction?.status === "in"
                          ? "credit-icon"
                          : "debit-icon"
                      }
                      width={18}
                      height={18}
                      className={`
                        `}
                    />
                  </div>

                  <span className="font-medium">
                    {decodeWalletType(transaction?.wallettype)}
                  </span>
                </div>,
                `₦${numberToString(transaction?.Amount)}`,
                <div className="flex justify-end" key={`${key}-339`}>
                  <TimeText
                    timeString={
                      transaction?.PaymentDateAdded ?? transaction?.PaymentDate
                    }
                  />
                </div>,
              ],
            };
          })}
          isLoading={cooperativeTransactions?.isFetching}
          isTableEmpty={!cooperativeTransactions?.data?.data?.length}
        />
      </div>

      <div className="w-full mt-4">
        <Pagination
          limit={query?.limit}
          totalCount={cooperativeTransactions?.data?.totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
          isLoading={cooperativeTransactions?.isFetching}
        />
      </div>
    </div>
  );
};

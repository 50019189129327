import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons/icon";
import { ProfileNotificationComponent } from "./profileNotificationComp";

export const TopNav = ({ navOpen, setNavOpen }) => {
  const navigate = useNavigate();

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div
      className="w-full
    bg-white
    px-5 md:px-8 py-[18px]
    border-b border-light-grey
    flex justify-between items-center
    transition-all
    opacity-100 lg:hidden
    backdrop-blur-sm z-[50]
    "
    >
      <div className="flex flex-row gap-4 items-center">
        <span
          onClick={!navOpen ? toggleNav : () => {}}
          className={`
          block lg:hidden
          hover:cursor-pointer
          transition-all
          `}
        >
          <Icon svg={"hamburger-icon"} width={24} height={24} className="" />
        </span>

        <div
          className={`transition-all duration-[800ms] pl-0 cursor pointer ${
            navOpen && "opacity-0 pl-20"
          }`}
          onClick={() => {
            navigate("/app/overview");
          }}
        >
          <img src="/assets/images/logo.svg" alt="logo" className="md:hidden" />
          <img
            src="/assets/images/logo_desktop.svg"
            alt="logo"
            className="hidden md:flex"
          />
        </div>
      </div>

      <div className="flex items-center gap-4">
        <ProfileNotificationComponent />
      </div>
    </div>
  );
};

import React from "react";
import { ModalComponent } from "..";
import { Button } from "../../buttons/button";

export const ConfirmInviteDeleteModal = ({
  modalOpen,
  onModalClose = () => {},
  confirmationMessage,
  onConfirm = () => {},
  isDeleting,
}) => {
  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={() => {
        onModalClose();
      }}
      title="Remove Official"
      subTitle={confirmationMessage}
      modalClassName="!max-h-[360px]"
      isProceedDisabled={false}
      isLoading={false}
      onProceedClick={() => {}}
      hasButtons={false}
    >
      <div
        className="
      w-full
      flex flex-row flex-wrap
      gap-3
      items-center justify-between
      "
      >
        <Button
          text="Cancel"
          onClick={onModalClose}
          type="button"
          className="!w-full max-w-[157px] md:!py-[12px] h-fit !text-pry-dark-green-300"
          loading={false}
          disabled={false}
          variation="secondary-light"
        />
        <Button
          text="Yes, Remove"
          onClick={onConfirm}
          type="button"
          className="!w-full max-w-[157px] md:!py-[12px] h-fit"
          loading={isDeleting}
          disabled={isDeleting}
          variation="danger"
        />
      </div>
    </ModalComponent>
  );
};

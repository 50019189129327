import React from "react";
import { useSelector } from "react-redux";
import { usePermissions } from "../../../customHooks/usePermissions";
import { decodePaymentAction } from "../../../helperFunctions";
import { permissionList } from "../../../utils/constants";
import { YouCard } from "../../cards/youCard";
import { Icon } from "../../icons/icon";
import { Pagination } from "../../pagination/pagination";
import { TimeText } from "../../typography/timeText";
import { TableComponent } from "../table";

export const CooperativeMemberProductTable = ({
  data,
  query,
  setQuery = () => {},
  onAddProductClick = () => {},
  type = "savings",
  isProductActive = true,
}) => {
  const { hasPermissionsFor } = usePermissions();

  const { profile } = useSelector((state) => {
    return state.auth;
  });

  const headers = hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)
    ? [
        "NAME",
        `TOTAL ${decodePaymentAction(type)?.subject}`,
        `LAST TRANSACTION DATE`,
        "",
      ]
    : [
        "NAME",
        `TOTAL ${decodePaymentAction(type)?.subject}`,
        `LAST TRANSACTION DATE`,
      ];

  return (
    <div className="w-full ">
      <div className="w-full overflow-x-scroll">
        <TableComponent
          className="w-full !min-w-max md:!min-w-full"
          headers={headers}
          rows={data?.data?.map((member, idx) => {
            const key = `member-savings-${idx}`;
            return {
              id: member?.id,
              items: hasPermissionsFor(permissionList.CAN_ADD_PAYMENT)
                ? [
                    <div className="flex gap-2 items-center" key={`${key}-1`}>
                      {`${member?.MemberID?.User?.FirstName} ${member?.MemberID?.User?.LastName}`}{" "}
                      {profile?.Email === member?.MemberID?.User?.Email && (
                        <YouCard />
                      )}
                    </div>,
                    type === "shares"
                      ? `${Number(member?.BalanceUnit)?.toLocaleString()}`
                      : `₦${Number(member?.Balance)?.toLocaleString()}`,
                    <TimeText
                      timeString={member?.DateUpdated}
                      key={`${key}-3`}
                    />,
                    <div
                      className={`w-full flex justify-end cursor-pointer ${
                        !isProductActive && "!opacity-60 !cursor-not-allowed"
                      }`}
                      onClick={() => {
                        isProductActive && onAddProductClick(member);
                      }}
                      key={`${key}-3`}
                    >
                      <div className="flex gap-2 text-pry-green-200 w-fit !capitalize">
                        <Icon svg="plus" width={16} height={16} />
                        Add {decodePaymentAction(type)?.subject}
                      </div>
                    </div>,
                  ]
                : [
                    <div className="flex gap-2 items-center" key={`${key}-1`}>
                      {`${member?.MemberID?.User?.FirstName} ${member?.MemberID?.User?.LastName}`}{" "}
                      {profile?.Email === member?.MemberID?.User?.Email && (
                        <YouCard />
                      )}
                    </div>,
                    type === "shares"
                      ? `${Number(member?.BalanceUnit)?.toLocaleString()}`
                      : `₦${Number(member?.Balance)?.toLocaleString()}`,
                    <TimeText
                      timeString={member?.DateUpdated}
                      key={`${key}-2`}
                    />,
                  ],
            };
          })}
        />
      </div>

      <div className="w-full mt-4">
        <Pagination
          limit={query?.limit}
          totalCount={data?.totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
        />
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useQuery } from "react-query";
import empty_state_invitation from "../../../assets/images/empty_state_invite.svg";
import { ContainerCard } from "../../../components/cards/containerCard";
import { InformationCard } from "../../../components/cards/infoCard";
import { LoanInvitationCard } from "../../../components/cards/invitationCardLoan";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { ConfirmationModal } from "../../../components/modals/app_modals/confirmationModal";
import { SuccessModal } from "../../../components/modals/app_modals/successModal";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useLoans } from "../../../customHooks/useLoans";
import { numberToString } from "../../../helperFunctions";

export const LoanGuarantorInvitation = () => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState({});

  const [approving, setApproving] = useState(false);

  const { fetchLoanGuarantorRequests, approveGuarantorRequest } = useLoans();

  const guarantorRequests = useQuery(
    ["guarantorRequests", { detail: true }],
    fetchLoanGuarantorRequests,
    {
      enabled: true,
    }
  );

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  return (
    <div className="w-full">
      <PageTitle
        text="Guarantee a loan"
        subtext="Kindly look through before consenting to be a guarantor to any of the loans listed here"
      />

      <div className="mt-6 ">
        {guarantorRequests?.isLoading ? (
          <PageLoader />
        ) : (
          <div className="flex flex-col gap-4">
            {!guarantorRequests?.data?.length && (
              <ContainerCard className=" !py-14">
                <EmptyState
                  image={<img src={empty_state_invitation} alt="empty_state" />}
                  title="You have no invitations"
                  subtitle="All your invitations will be displayed here whenever you have any."
                />
              </ContainerCard>
            )}

            {guarantorRequests?.data?.length > 0 &&
              guarantorRequests?.data?.map((invitation, idx) => {
                const key = `invitation-${idx}`;
                return (
                  <LoanInvitationCard
                    key={key}
                    invitation={invitation}
                    handleModalOpen={handleModalOpen}
                    setSelected={setSelectedInvitation}
                    successCallback={() => {
                      guarantorRequests?.refetch();
                    }}
                  />
                );
              })}
          </div>
        )}
      </div>

      <ConfirmationModal
        isModalOpen={modalOpen && modal === "confirmationModal"}
        onModalClose={handleModalClose}
        title="Fianl confirmation"
        subtitle={""}
        children={
          <div>
            <InformationCard
              info={
                <span>
                  Please note that ₦
                  <span>
                    {numberToString(selectedInvitation?.AmountGuarantee)}
                  </span>{" "}
                  will be locked from your savings as the amount you guaranteed
                  for this loan
                </span>
              }
              size="mini"
              variant="purple"
            />

            <div className="mt-3 text-sm">
              By proceeding, you agree and consent to be a guarantor for{" "}
              <span className="text-pry-green-200">{`${selectedInvitation?.MembershipLoanRequestID?.MemberID?.User?.FirstName}
                ${selectedInvitation?.MembershipLoanRequestID?.MemberID?.User?.LastName}`}</span>
            </div>
          </div>
        }
        onProceed={() => {
          setApproving(true);
          approveGuarantorRequest(
            {
              coop: selectedInvitation?.MembershipLoanRequestID?.Cooperative,
              isaccept: true,
              loanid: selectedInvitation?.MembershipLoanRequestID?.id,
            },
            () => {
              setApproving(false);
            },
            () => {
              setApproving(false);
              guarantorRequests?.refetch();
              handleModalOpen("approvalSuccessModal");
            }
          );
        }}
        loading={approving}
      />

      <SuccessModal
        message={`You’ve successfully consented`}
        isModalOpen={modalOpen && modal === "approvalSuccessModal"}
        onModalClose={handleModalClose}
      />
    </div>
  );
};

import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../api/requestHelpers";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { appSliceActions } from "../redux/features/app/app";

export const useApprovals = () => {
  const dispatch = useDispatch();

  const fetchApprovalsSummary = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingApprovalsSummary(true));
    await getRequest("AccessMgmt/Manage/Approval/status", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(appSliceActions.approvalSummaryUpdated(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(appSliceActions.fetchingApprovalsSummary(false));
    callback();
  };

  const fetchSingleCooperativeApprovalsSummary = async (
    params = {},
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await getRequest("AccessMgmt/approval/status", params, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback(data);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    setLoading(false);
    callback();
  };

  const fetchMembershipApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("AccessMgmt/Member/Add/Approval", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveMembership = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest("AccessMgmt/Member/Add/Approval", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchPaymentApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      "AccessMgmt/Wallet/Member/Manual/Approval",
      paramsDestrct,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approvePayment = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest("AccessMgmt/Wallet/Member/Manual/Approval", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchDepositApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      "AccessMgmt/Wallet/Deposit/Member/Manual/Approval",
      paramsDestrct,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveDeposit = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest(
      "AccessMgmt/Wallet/Deposit/Member/Manual/Approval",
      payload,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchSharesApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      "AccessMgmt/Wallet/Shares/Member/Manual/Approval",
      paramsDestrct,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveShares = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest(
      "AccessMgmt/Wallet/Shares/Member/Manual/Approval",
      payload,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchLoansApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("AccessMgmt/Member/Loan/Approval", paramsDestrct, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveLoan = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest("AccessMgmt/Member/Loan/Approval", payload, true)
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchLoanRepaymentApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      "AccessMgmt/Member/Loan/Repayment/Approval",
      paramsDestrct,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveLoanRepayment = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest(
      "AccessMgmt/Member/Loan/Repayment/Approval",
      payload,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  const fetchWithdrawalApprovals = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest(
      "AccessMgmt/Wallet/Withdrawal/Manual/Approval",
      paramsDestrct,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        // Call success callback
        successCallback(data?.results);

        response = data?.results;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");

        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const approveWithdrawal = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await postRequest(
      "AccessMgmt/Wallet/Withdrawal/Manual/Approval",
      payload,
      true
    )
      .then((resp) => {
        // eslint-disable-next-line

        doNotification("Operation successful", "success");
        setLoading(false);

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
    setLoading(false);
  };

  return {
    fetchApprovalsSummary,
    fetchSingleCooperativeApprovalsSummary,
    fetchMembershipApprovals,
    approveMembership,
    fetchPaymentApprovals,
    approvePayment,
    fetchDepositApprovals,
    approveDeposit,
    fetchSharesApprovals,
    approveShares,
    fetchLoansApprovals,
    approveLoan,
    fetchLoanRepaymentApprovals,
    approveLoanRepayment,
    fetchWithdrawalApprovals,
    approveWithdrawal,
  };
};

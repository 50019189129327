import React from "react";
import ReactDOM from "react-dom";

export const ScreenOverlay = ({ children }) => {
  return ReactDOM.createPortal(
    <div
      className="
        fixed inset-0
        z-[999]
        border
        bg-light-green-bg
        "
    >
      {children}
    </div>,
    document.getElementById("portal")
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../icons/icon";

export const Checkbox = ({
  initialChecked,
  onCheck = () => {},
  onUnCheck = () => {},
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const checkBoxRef = useRef();

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div
      className="w-fit cursor-pointer"
      onClick={() => {
        checkBoxRef?.current?.click();
      }}
    >
      <Icon
        svg={checked ? "checkbox-active" : "checkbox-inactive"}
        width={16}
        height={16}
      />
      <input
        type="checkbox"
        className="hidden"
        ref={checkBoxRef}
        checked={checked}
        onChange={() => {
          const initialCheckStatus = checked;
          if (initialCheckStatus) {
            onUnCheck();
          } else {
            onCheck();
          }
          setChecked(!checked);
        }}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { range } from "../../helperFunctions";
import { Icon } from "../icons/icon";

export const Pagination = ({
  totalCount,
  limit,
  onPageChange = () => {},
  otherQueryParams = {},
  otherButtonClassName = "",
  isLoading = false,
}) => {
  const [currPage, setCurrPage] = useState(
    Math.ceil(otherQueryParams?.offset / otherQueryParams?.limit) + 1
  );

  const noPages = Math.ceil(totalCount / limit);
  const pages = range(1, noPages, 1);
  const minPage = Math.min(...pages);
  const maxPage = Math.max(...pages);

  const pageClassName = (page = "") => `
  py-1 px-2 text-xs font-medium
  bg-white rounded-[4px]
  border border-pry-dark-green-300/10
  cursor-pointer
  hover:scale-90
  transition-all duration-300
  ${currPage === page && "!bg-pry-green-200 !text-white"}
  `;

  const buttonClassName = `
  p-1 bg-light-green-bg-300 rounded
  cursor-pointer
  flex items-center
  disabled:cursor-not-allowed
  ${otherButtonClassName}
  `;

  const handlePageChange = (page) => {
    const paginationQuery = {
      offset: limit * (page - 1),
      limit: limit,
    };
    onPageChange({ ...otherQueryParams, ...paginationQuery });
    // eslint-disable-next-line
  };

  useEffect(() => {
    setCurrPage(
      Math.ceil(otherQueryParams?.offset / otherQueryParams?.limit) + 1
    );
  }, [otherQueryParams]);

  return (
    !isLoading && (
      <div
        className={`
        flex flex-row flex-wrap 
        gap-1
        items-center justify-end
        ${noPages <= 1 && "hidden"}
        `}
      >
        {/* Previous button */}
        <button
          className={buttonClassName}
          disabled={currPage === minPage}
          onClick={() => {
            setCurrPage(currPage - 1);
            handlePageChange(currPage - 1);
          }}
        >
          <Icon svg="left-caret-icon" width={16} height={16} />
        </button>
        {/* Pages  */}
        {pages?.map((page) => {
          if (page >= currPage && page <= currPage + 1 && page < maxPage - 1) {
            return (
              <div
                key={page}
                className={pageClassName(page)}
                onClick={() => {
                  setCurrPage(page);
                  handlePageChange(page);
                }}
              >
                {page}
              </div>
            );
          }
          return undefined;
        })}
        {maxPage - currPage > 3 && <div className={pageClassName()}>...</div>}
        {pages?.slice(-2)?.map((page) => {
          return (
            <div
              key={page}
              className={pageClassName(page)}
              onClick={() => {
                setCurrPage(page);
                handlePageChange(page);
              }}
            >
              {page}
            </div>
          );
        })}
        {/* Next button */}
        <button
          className={buttonClassName}
          disabled={currPage === maxPage}
          onClick={() => {
            setCurrPage(currPage + 1);
            handlePageChange(currPage + 1);
          }}
        >
          <Icon
            svg="left-caret-icon"
            width={16}
            height={16}
            className="rotate-180"
          />
        </button>

        <div className="text-xs font-medium">
          Showing{" "}
          <span className="font-bold">
            {limit * (currPage - 1) + 1} -{" "}
            {limit * (currPage - 1) + limit < totalCount
              ? limit * (currPage - 1) + limit
              : totalCount}
          </span>{" "}
          of <span className="font-bold">{totalCount}</span> entries
        </div>
      </div>
    )
  );
};

import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { range } from "../../helperFunctions";
import { Icon } from "../icons/icon";
import { ErrorText } from "../typography/errorText";

export const DateInput = ({
  name,
  value,
  placeholder = "Placeholder",
  onChange,
  onBlur,
  hasIcon = true,
  iconSvg,
  errors,
  inputClass = "",
  className = "",
  containerClassName = "",
  hasTitle = false,
  title = "",
  ...inputProps
}) => {
  const [startDate, setStartDate] = useState(value ? new Date(value) : "");

  const inputClassName = `
  bg-transparent !w-full
  outline-none focus:outline-none
  placeholder:text-pry-dark-green-300/50
  autofill:focus:bg-transparent autofill:bg-transparent
  !cursor-pointer
  ${inputClass}
  `;

  const selectClassName = `
  bg-transparent
  text-pry-green-200 !text-base font-medium
  outline-none
  !w-fit !max-w-[60px] 
  overflow-hidden
  text-ellipsis
  `;

  const optionClassName = `
  !text-sm
  !max-h-[100px]
  `;

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      name={name}
      type={"text"}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      className={inputClassName}
      ref={ref}
      onClick={onClick}
      readOnly
      {...inputProps}
    />
  ));

  const years = range(1800, new Date().getFullYear(), 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const customHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <div
        className="
        w-full
        flex flex-row
        items-center
        justify-between
        mb-[24px]
        "
      >
        <div
          className="
            flex flex-row
            gap-2
            items-center
            "
        >
          <select
            value={months[date?.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className={selectClassName}
          >
            {months.map((option) => (
              <option key={option} value={option} className={optionClassName}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={date?.getFullYear()}
            onChange={({ target: { value } }) => {
              changeYear(value);
            }}
            className={selectClassName}
          >
            {years.map((option) => (
              <option key={option} value={option} className={optionClassName}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div
          className="
            text-pry-green-200
            flex flex-row items-center gap-6 
            "
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <Icon svg="left-caret-icon" width={24} height={24} />
          </button>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <Icon
              svg="left-caret-icon"
              width={24}
              height={24}
              className="rotate-180"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={containerClassName}>
      {hasTitle && (
        <div
          className="
            text-sm 
            text-pry-dark-green-300/80 font-medium
            mb-1
            "
        >
          {title}
        </div>
      )}

      <div
        className={`w-full
          px-[17px] py-[14px]
          flex flew-row items-center gap-4
          text-sm font-medium
          bg-light-green-bg
          border rounded-lg border-transparent
          focus-within:border-pry-green-200
          ${errors && "focus-within:!border-red-200 !bg-light-red-bg"}
          transition-all
          ${className}
          `}
      >
        {hasIcon && (
          <Icon svg={iconSvg} width={20} height={20} className="w-[24px]" />
        )}

        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            onChange(date);
          }}
          className="border-4"
          customInput={<CustomInput />}
          renderCustomHeader={customHeader}
          calendarClassName="
            !p-[16px] 
            !border-white !bg-white
            satoshi
            !text-[12px] !font-medium
            "
          dayClassName={() => {
            return `
            !p-[px 
            !text-pry-dark-green-300
            !rounded-full 
            hover:!bg-light-green-bg hover:!text-pry-dark-green-300
            focus:!bg-pry-green-200 focus:!text-white
            active:!bg-pry-green-200 focus:!text-white
            `;
          }}
          wrapperClassName="!border-4 !border-black"
          dropdownMode="select"
        />
      </div>

      {errors && <ErrorText text={errors} />}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { PageHeader } from "../../../components/layouts/pageHeader";
import { TabNav } from "../../../components/navs/tabNav";
import { useAuth } from "../../../customHooks/useAuth";
import { BankAccount } from "./bankAccountSettings";
import { Configurations } from "./configurations";
import { CooperativeOfficials } from "./cooperativeOfficials";
import { MembershipSettingsView } from "./membershipSettings/membershipSettingsView";
import { ProfileView } from "./profileSettings/profileView";
import { CooperativeSecurity } from "./security/cooperativeSecurity";
import { PersonalSecurity } from "./security/personalSecurity";

export const Settings = () => {
  // eslint-disable-next-line
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("");

  const { profile } = useSelector((state) => {
    return state.auth;
  });

  const tabNavs =
    profile?.type === "cooperative"
      ? [
          //Tab nav menu for cooperatives
          {
            title: "Profile",
            to: "profile",
            onClick: setTab,
          },
          {
            title: "Bank Account",
            to: "bank_account",
            onClick: setTab,
          },
          {
            title: "Configurations",
            to: "configurations",
            onClick: setTab,
          },
          {
            title: "Manage Officials",
            to: "manage_officials",
            onClick: setTab,
          },
          {
            title: "Membership Settings",
            to: "membership_settings",
            onClick: setTab,
          },
          {
            title: "Security",
            to: "security",
            onClick: setTab,
          },
        ]
      : [
          //Tab nav menu for members
          {
            title: "Profile",
            to: "profile",
            onClick: setTab,
          },
          {
            title: "Bank Account & Cards",
            to: "bank_account",
            onClick: setTab,
          },
          {
            title: "Security",
            to: "security",
            onClick: setTab,
          },
        ];

  const { fetchUserProfile } = useAuth();

  useEffect(() => {
    // Check if tab is specified in query param
    const predefinedTab = searchParams.get("tab");
    // If tab set in query param, set tab to specified one
    predefinedTab ? setTab(predefinedTab) : setTab(tabNavs[0].to);
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    fetchUserProfile();
    // eslint-disable-next-line
  }, [tab]);

  return (
    <div className="">
      {/* Page header */}
      <PageHeader
        pageTitle={`Settings`}
        hasBack={false}
        onBackClick={() => {}}
        className=""
      />

      {/* Tab Navigation */}
      <TabNav
        navs={tabNavs}
        initialActiveTab={tab}
        className="mt-6 w-full md:w-fit overflow-scroll"
      />

      {/* Settings Pages */}
      <div className="mt-7">
        {tab === "profile" && <ProfileView />}
        {tab === "bank_account" && <BankAccount />}
        {tab === "manage_officials" && profile?.type === "cooperative" && (
          <CooperativeOfficials />
        )}
        {tab === "membership_settings" && profile?.type === "cooperative" && (
          <MembershipSettingsView />
        )}
        {tab === "configurations" && profile?.type === "cooperative" && (
          <Configurations />
        )}
        {tab === "security" && profile?.type === "cooperative" && (
          <CooperativeSecurity />
        )}
        {tab === "security" && profile?.type === "member" && (
          <PersonalSecurity />
        )}
      </div>
    </div>
  );
};

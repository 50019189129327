import React from "react";
import { SimpleCard } from "../../../components/cards/simpleCard";

export const SignUpType = ({ setSignUpType }) => {
  return (
    <div className="flex flex-col gap-[16px] md:gap-[20px]">
      <div className="text-[20px] font-bold mb-[8px] md:mb-0">Register As</div>

      <div className="flex items-center">
        <SimpleCard
          text="A Cooperative"
          subtext={`Create a cooperative account to manage all your members and activities in one place.`}
          growAnimate
          className=""
          onClick={() => {
            setSignUpType("cooperative");
          }}
        />
      </div>

      <div className="flex items-center">
        <SimpleCard
          text="A Member"
          subtext="Create a member account to join your cooperative, track savings, and much more."
          growAnimate
          onClick={() => setSignUpType("member")}
        />
      </div>
    </div>
  );
};

import React from "react";

export const TitleSub = ({
  title,
  subtitle,
  titleClassName,
  subtitleClassName,
  className,
}) => {
  return (
    <div
      className={`
        ${className}
        `}
    >
      <div
        className={`
        font-medium
        text-base md:text-base
        ${titleClassName}
        `}
      >
        {title}
      </div>
      <div
        className={`
        text-sm md:text-sm
        text-pry-dark-green-300/50
        mt-2
        ${subtitleClassName}
        `}
      >
        {subtitle}
      </div>
    </div>
  );
};

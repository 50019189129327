import React from "react";

export const OnboardingLayout = ({ children }) => {
  return (
    <div className="flex flex-row w-full h-screen">
      <div
        className="
        lg:w-6/12 hidden 
        lg:flex bg-light-green-bg-200
        bg-[url('assets/images/onboarding_bg.svg')]
        bg-[length:100%]
        p-[64px]
        bg-no-repeat bg-bottom
        "
      >
        <div>
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            className="W-[182px] h-[32px]"
          />

          <div className="mt-[80px]">
            <div className="text-[40px] font-black leading-[130%]">
              Everything Cooperative.
              <br />
              <span className="text-pry-green-200">One Platform.</span>
            </div>
            <div className="mt-2 text-base text-text-gray">
              Managing your cooperative activities is now made easy with
              Thriftcorp
            </div>
          </div>
        </div>
      </div>
      <div
        className="
        w-full lg:w-6/12
        py-20 md:py-28 lg:py-44
        px-7
        flex justify-center
        overflow-y-scroll
      "
      >
        <div className="h-max w-full flex justify-center">{children}</div>
      </div>
    </div>
  );
};

import React from "react";
import { ModalComponent } from "..";

export const ConfirmationModal = ({
  isModalOpen,
  onModalClose,
  title,
  children,
  subtitle,
  onProceed,
  loading,
  proceedButtonText = "Yes, I consent",
  cancelButtonText = "No, Cancel",
}) => {
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title={title}
      topTitle
      subTitle={subtitle}
      childrenContainerClassName="!mt-0"
      modalClassName="!max-h-[360px]"
      hasButtons
      proceedButtonText={proceedButtonText}
      proceedButtonVariant="primary"
      onProceedClick={onProceed}
      hasCancelButton
      cancelButtonText={cancelButtonText}
      cancelButtonVariant="danger"
      onCancelClick={() => {
        onModalClose();
      }}
      isLoading={loading}
      isProceedDisabled={loading}
    >
      {children && children}
    </ModalComponent>
  );
};

import React, { useState } from "react";
import { usePayment } from "../../../customHooks/usePayment";
import { usePermissions } from "../../../customHooks/usePermissions";
import { decodeLoanStatus, numberToString } from "../../../helperFunctions";
import { permissionList } from "../../../utils/constants";
import { TagCard } from "../../cards/tagCard";
import { ClickDropDown } from "../../dropdown/clickDropdown";
import { Icon } from "../../icons/icon";
import { AddProductModal } from "../../modals/app_modals/addProductModal";
import { AdminAddProductModal } from "../../modals/app_modals/adminAddProductModal";
import { MemberAddProductModal } from "../../modals/app_modals/memberAddProductModal";
import { PaymentModal } from "../../modals/app_modals/paymentModal";
import { RepaymentScheduleModal } from "../../modals/app_modals/repaymentScheduleModal";
import { SuccessModal } from "../../modals/app_modals/successModal";
import { Pagination } from "../../pagination/pagination";
import { TimeText } from "../../typography/timeText";
import { TableComponent } from "../table";

export const LoanHistoryTable = ({
  data,
  query,
  setQuery = () => {},
  singleCooperative,
  mode = "personal",
  refreshPage = () => {},
}) => {
  const headers = [
    mode === "admin" && "NAME",
    "LOAN TYPE",
    "LOAN AMOUNT",
    "TENOR",
    "STATUS",
    "DATE APPROVED",
    "",
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedLoan, setSelectedLoan] = useState({});
  const [paymentData, setPaymentData] = useState({});

  const { verifyPayment } = usePayment();

  const { hasPermissionsFor } = usePermissions();

  const openModal = (modalName) => {
    setModalOpen(true);
    setModal(modalName);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModal("");
    setSelectedLoan({});
  };

  return (
    <div className="w-full relative">
      <div
        className={`w-full max-h-max overflow-x-scroll overflow-y-hidden pb-[250px] `}
      >
        <TableComponent
          headers={headers}
          rows={data?.data?.map((item, idx) => {
            const key = `loan-history-${idx}`;
            return {
              id: item?.id,
              items: [
                mode === "admin" &&
                  `${item?.MemberID?.User?.FirstName} ${item?.MemberID?.User?.LastName}`,
                item?.LoanProduct?.LoanName ?? "N/A",
                `₦${numberToString(item?.Amount)}`,
                `${numberToString(item?.Tenure)} months`,
                <TagCard
                  text={decodeLoanStatus(item?.Status)?.text}
                  type={decodeLoanStatus(item?.Status)?.type}
                />,
                <TimeText timeString={item?.DateAdded} key={key} />,
                <ClickDropDown
                  dropDownClassName="!w-max z-[1]"
                  className="max-w-fit "
                  options={[
                    {
                      title: (
                        <div className="flex flex-row gap-2 items-center text-sm font-medium">
                          <Icon svg={"calendar"} width={20} height={20} />
                          <div>View Schedule</div>
                        </div>
                      ),
                      onClick: () => {
                        setSelectedLoan(item);
                        openModal("repaymentScheduleModal");
                      },
                      isClickable: true,
                    },
                    mode === "personal" &&
                      item?.Status === "active" && {
                        title: (
                          <div className="flex flex-row gap-2 items-center text-sm font-medium">
                            <Icon svg={"calendar"} width={20} height={20} />
                            <div>Make Repayment</div>
                          </div>
                        ),
                        onClick: () => {
                          setSelectedLoan(item);
                          openModal("makeRepaymentModal");
                        },
                        isClickable: true,
                      },
                    item?.Status === "active" &&
                      (mode === "personal" ||
                        (mode === "admin" &&
                          hasPermissionsFor(
                            permissionList.CAN_ADD_PAYMENT
                          ))) && {
                        title: (
                          <div className="flex flex-row gap-2 items-center text-sm font-medium">
                            <Icon svg={"fund-wallet"} width={20} height={20} />
                            <div>Upload Repayment</div>
                          </div>
                        ),
                        onClick: () => {
                          setSelectedLoan(item);
                          mode === "personal"
                            ? openModal("uploadRepaymentModal")
                            : openModal("adminUploadRepaymentModal");
                        },
                        isClickable: true,
                      },
                  ]}
                />,
              ],
            };
          })}
          className=""
        />
      </div>

      <div
        className={`w-full absolute  right-0 left-0 top-[calc(100%-250px+16px)]`}
      >
        <Pagination
          limit={query?.limit}
          totalCount={data?.totalCount}
          otherQueryParams={query}
          onPageChange={(e) => setQuery(e)}
          otherButtonClassName="z-[-1]"
        />
      </div>

      <RepaymentScheduleModal
        isModalOpen={modalOpen && modal === "repaymentScheduleModal"}
        onModalClose={closeModal}
        doFetch
        schedule={[]}
        loanStatus="existing"
        loanId={selectedLoan?.id}
        singleCooperative={singleCooperative}
        mode={mode}
      />

      <MemberAddProductModal
        cooperative={singleCooperative?.id}
        isModalOpen={modalOpen && modal === "uploadRepaymentModal"}
        onModalClose={closeModal}
        onSuccess={() => {
          openModal("memberRepaymentUploadSuccessModal");
        }}
        type="loan_repayment"
        selectedLoan={selectedLoan}
      />

      <SuccessModal
        message={"Loan repayment uploaded successfully"}
        subText={<div>Once approved, it will reflect on your repayments.</div>}
        isModalOpen={modalOpen && modal === "memberRepaymentUploadSuccessModal"}
        onModalClose={closeModal}
      />

      <AdminAddProductModal
        isModalOpen={modalOpen && modal === "adminUploadRepaymentModal"}
        onModalClose={closeModal}
        selectedData={selectedLoan}
        onSuccess={() => {
          openModal("adminRepaymentUploadSuccessModal");
        }}
        type="loan_repayment"
      />

      <SuccessModal
        message={"Loan repayment uploaded successfully"}
        subText={<div>Once approved, it will reflect on your repayments.</div>}
        isModalOpen={modalOpen && modal === "adminRepaymentUploadSuccessModal"}
        onModalClose={closeModal}
      />

      <AddProductModal
        isModalOpen={modalOpen && modal === "makeRepaymentModal"}
        onModalClose={closeModal}
        successCallback={(data) => {
          setPaymentData(data);
          openModal("paymentModal");
        }}
        singleCooperative={singleCooperative}
        loanInfo={selectedLoan}
        type="loan_repayment"
        cooperative={singleCooperative}
        callback={() => {}}
      />

      <PaymentModal
        isModalOpen={modalOpen && modal === "paymentModal"}
        onModalClose={closeModal}
        paymentData={paymentData}
        handleVerification={(doVerify = true) => {
          doVerify &&
            verifyPayment(
              {
                coop: singleCooperative?.id,
                trxref: paymentData?.reference,
                amount: paymentData?.amount,
                reference: paymentData?.reference,
                mode: "paystack",
                loanId: selectedLoan?.id,
              },
              () => {
                refreshPage();
              },
              () => {
                closeModal();
                openModal("memberRepaymentSuccessModal");
                setPaymentData({});
                setSelectedLoan({});
              },
              "loan_repayment"
            );
        }}
      />

      <SuccessModal
        message={"Loan repayment made successfully"}
        subText={""}
        isModalOpen={modalOpen && modal === "memberRepaymentSuccessModal"}
        onModalClose={closeModal}
      />
    </div>
  );
};

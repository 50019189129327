import React from "react";
import { ModalComponent } from "..";
import success from "../../../assets/images/success.svg";

export const SuccessModal = ({
  message,
  isModalOpen,
  onModalClose,
  subText,
  callback = () => {},
  transparentBg = false,
  hasError = false,
  errorComponent,
}) => {
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        callback();
        onModalClose();
      }}
      title=""
      subTitle=""
      childrenClassName=""
      modalClassName="!max-h-[548px]"
      hasButtons={false}
      proceedButtonText=""
      onProceedClick={() => {}}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={false}
      transparentBg={transparentBg}
    >
      <div className="w-full flex flex-col items-center justify-center">
        {hasError ? errorComponent : undefined}

        <img
          src={success}
          alt="success"
          className="w-full max-w-[224px] mt-4"
        />

        <div className="mt-[16px] w-full text-center text-lg font-bold">
          {message}
        </div>

        {subText && (
          <div className="mt-1 text-sm opacity-80 w-full text-center">
            {subText}
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

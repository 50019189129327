import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../icons/icon";
import { CooperativeProfilePic } from "../profile/cooperativePicture";
import { CooperativeMemberCount } from "./cooperativeMemberCount";

export const MyCooperativeCard = ({
  cooperativeData,
  handleModalOpen = () => {},
  setSelectedData = () => {},
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="
        flex flex-row
        items-center justify-between
        w-full
        gap-2
        flex-wrap
        px-6 py-4
        bg-white
        border border-pry-dark-green-300/10
        rounded-lg
        hover:scale-[0.98]
        transition-all duration-300
        cursor-pointer
    "
      onClick={() => {
        setSelectedData(cooperativeData);
        cooperativeData?.isApprove
          ? navigate(`/app/my-cooperatives/${cooperativeData?.Cooperative?.id}`)
          : handleModalOpen();
      }}
    >
      <div
        className="
        flex flex-row
        items-center
        gap-4
      "
      >
        <CooperativeProfilePic
          cooperative={cooperativeData?.Cooperative}
          className="!w-[40px] !h-[40px] md:!w-[64px] md:!h-[64px]"
        />
        <div>
          <div
            className="
            text-base font-medium
          "
          >
            {cooperativeData?.Cooperative?.CoopName}
          </div>
          <CooperativeMemberCount
            cooperative={cooperativeData?.Cooperative}
            className="!mt-1 !text-pry-green-200 !bg-light-green-bg-300"
          />
        </div>
      </div>

      <div
        className={`
            ${
              cooperativeData?.isApprove
                ? "text-pry-green-200 bg-light-green-bg-500"
                : "text-pry-dark-green-300/50 bg-light-green-bg"
            }
            px-4 py-2
            rounded-[4px]
            flex flex-row
            gap-[10px]
            text-sm font-medium
            w-full md:w-fit
            items-center justify-center
        `}
      >
        {cooperativeData?.isApprove && (
          <Icon svg={"verified"} width={20} height={20} />
        )}
        <div>
          {cooperativeData?.isApprove ? "Registered" : "Awaiting Approval"}
        </div>
      </div>
    </div>
  );
};

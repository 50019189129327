import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { InformationCardNoIcon } from "../../../../components/cards/infoCard";
import { TextInputViewOnly } from "../../../../components/inputs/textInputViewOnly";
import { UploadedFileView } from "../../../../components/inputs/uploadedFileView";
import { ProfilePicture } from "../../../../components/profile/profilePicture";
import { decodeUrl, getTextFromStatus } from "../../../../helperFunctions";

// ------------------------------------------------------------------------------------------------------------------------------------------------
// COOPERATIVE PROFILE VIEW COMPONENT
// ------------------------------------------------------------------------------------------------------------------------------------------------

const CooperativeProfileView = ({ profile, onboardingCompletionStatus }) => {
  const navigate = useNavigate();

  const goToProfileEdit = () => {
    navigate("/app/settings/profile/edit");
  };

  return (
    <div className="w-full">
      {/* Information on how to edit (only shows when account hant made any edit) */}
      {!onboardingCompletionStatus?.isGetStartedStarted && (
        <InformationCardNoIcon
          className="max-w-[500px]"
          info={
            <div>
              Click the{" "}
              <span className="text-pry-green-200 font-medium">Edit</span>{" "}
              button to provide the information and documents required
            </div>
          }
        />
      )}

      <div
        className="
            w-full flex flex-row flex-wrap
            gap-2 md:gap-6 mt-4
            "
      >
        {/* Main information half */}
        <div className="w-full md:max-w-[500px] order-last lg:order-first">
          <ContainerCard className="">
            {/* Title and button */}
            <div
              className="
          w-full flex flex-row gap-4
          items-center justify-between
          "
            >
              <div
                className="
            font-bold text-base
            "
              >
                Cooperative Profile
              </div>
              <div className="!w-full max-w-[80px] md:!max-w-[96px]">
                <Button
                  text="Edit"
                  onClick={goToProfileEdit}
                  type="button"
                  className="!text-sm md:!py-2"
                  loading={false}
                  disabled={false}
                  variation="primary"
                />
              </div>
            </div>

            {/* Profile details */}
            <div
              className="
              mt-[28px] 
              flex flex-col 
              gap-2
              "
            >
              <TextInputViewOnly
                className=""
                text="Cooperative Description"
                subtext={
                  profile?.description
                    ? ""
                    : "Tell us something about your cooperative"
                }
                hasIcon={!profile?.description}
                extraContent={
                  profile?.description && <div>{profile?.description}</div>
                }
              />

              <TextInputViewOnly
                className=""
                text="Certificate of Registration"
                subtext={
                  profile?.Certificate
                    ? ""
                    : "Upload your cooperative certificate of registration"
                }
                hasIcon={!profile?.Certificate}
                extraContent={
                  profile?.Certificate && (
                    <UploadedFileView
                      viewLink={`${decodeUrl(profile?.Certificate)}`}
                      text={getTextFromStatus(profile?.Certificatestatus)}
                      variant="mini"
                      status={profile?.Certificatestatus}
                    />
                  )
                }
              />

              <TextInputViewOnly
                className=""
                text="Bye Laws or Constitution"
                subtext={
                  profile?.byelaw
                    ? ""
                    : "Upload cooperative bye laws or constitution"
                }
                hasIcon={!profile?.byelaw}
                extraContent={
                  profile?.byelaw && (
                    <UploadedFileView
                      viewLink={`${decodeUrl(profile?.byelaw)}`}
                      text="View document"
                      variant="mini"
                    />
                  )
                }
              />

              <TextInputViewOnly
                className=""
                text="Email Address"
                subtext={profile?.CoopEmail}
                hasIcon={false}
              />

              <TextInputViewOnly
                className=""
                text="Date Created"
                subtext={new Date(profile?.DateAdded).toDateString()}
                hasIcon={false}
              />
            </div>
          </ContainerCard>
        </div>

        {/* Logo half */}
        <div className="w-full md:max-w-[500px]">
          <ContainerCard
            className="flex flex-col 
          items-center justify-center
          gap-6
          width-lim:sticky-top
          "
          >
            <div
              className="
            w-full max-w-[353px]
            text-center
            "
            >
              <div
                className="
                font-bold text-base
                "
              >
                Cooperative Logo
              </div>

              <div className="mt-1 text-pry-dark-green-300/60">
                Your logo will be visible to your members on ThriftCorp
              </div>
            </div>

            <ProfilePicture
              className="
            !w-[106px] !h-[106px] 
            !text-2xl !font-bold
            hover:!scale-100
            "
            />

            <div className="w-full max-w-[120px]">
              <Button
                text={profile?.logo ? "Change photo" : "Add a photo"}
                onClick={goToProfileEdit}
                type="button"
                className="!text-sm md:!py-3"
                loading={false}
                disabled={false}
                variation="secondary-light"
              />
            </div>
          </ContainerCard>
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------------------------------------------------------
// MEMBER PROFILE VIEW COMPONENT
// ------------------------------------------------------------------------------------------------------------------------------------------------

const MemberProfileView = ({ profile, onboardingCompletionStatus }) => {
  const navigate = useNavigate();

  const goToProfileEdit = () => {
    navigate("/app/settings/profile/edit");
  };

  return (
    <div className="w-full">
      {/* Information on how to edit (only shows when account hant made any edit) */}
      {!onboardingCompletionStatus?.isGetStartedStarted && (
        <InformationCardNoIcon
          className="max-w-[500px]"
          info={
            <div>
              Click the{" "}
              <span className="text-pry-green-200 font-medium">Edit</span>{" "}
              button to provide the information and documents required
            </div>
          }
        />
      )}

      <div
        className="
            w-full flex flex-row flex-wrap
            gap-2 md:gap-6 mt-4
            "
      >
        {/* Main information half */}
        <div
          className="
        w-full md:max-w-[500px]
        order-last lg:order-first
        "
        >
          <ContainerCard className="">
            {/* Title and button */}
            <div
              className="
              w-full flex flex-row gap-4
              items-center justify-between
              "
            >
              <div
                className="
                font-bold text-base
                "
              >
                Profile
              </div>
              <div className="!w-full !max-w-[80px] md:!max-w-[96px]">
                <Button
                  text="Edit"
                  onClick={goToProfileEdit}
                  type="button"
                  className="!text-sm md:!py-2"
                  loading={false}
                  disabled={false}
                  variation="primary"
                />
              </div>
            </div>

            {/* Profile details */}
            <div
              className="
              mt-[28px] 
              flex flex-col 
              gap-2
              overflow-scroll hide-scroll-bar
              "
            >
              <TextInputViewOnly
                className=""
                text="First Name"
                subtext={profile?.FirstName}
                hasIcon={!profile?.FirstName}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Last Name"
                subtext={profile?.LastName}
                hasIcon={!profile?.LastName}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Middle Name"
                subtext={
                  profile?.MiddleName?.replaceAll("#", "") || "Not specified"
                }
                hasIcon={!profile?.MiddleName?.replaceAll("#", "")}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Date of Birth"
                subtext={
                  (profile?.DateOfBirth &&
                    new Date(profile?.DateOfBirth).toDateString()) ||
                  "Not specified"
                }
                hasIcon={!profile?.DateOfBirth}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Phone Number"
                subtext={
                  profile?.PhoneNumber?.replaceAll("#", "")
                    ? `+${
                        profile?.PhoneNumberCode
                      }${profile?.PhoneNumber?.replaceAll("#", "")}`
                    : "Not specified"
                }
                hasIcon={!profile?.PhoneNumber?.replaceAll("#", "")}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Email Address"
                subtext={profile?.Email}
                hasIcon={false}
              />

              <TextInputViewOnly
                className=""
                text="Residential Address"
                subtext={profile?.Address || "Not specified"}
                hasIcon={!profile?.Address}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Country"
                subtext={
                  profile?.Country?.replaceAll("#", "") || "Not specified"
                }
                hasIcon={!profile?.Country?.replaceAll("#", "")}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="State"
                subtext={profile?.State?.replaceAll("#", "") || "Not specified"}
                hasIcon={!profile?.State?.replaceAll("#", "")}
                extraContent={undefined}
              />

              <TextInputViewOnly
                className=""
                text="Date Joined"
                subtext={new Date(profile?.DateAdded).toDateString()}
                hasIcon={false}
              />
            </div>
          </ContainerCard>
        </div>

        {/* Logo half */}
        <div className="w-full md:max-w-[500px]">
          <ContainerCard
            className="flex flex-col 
          items-center justify-center
          gap-6
          width-lim:sticky-top
          "
          >
            <div
              className="
            w-full max-w-[353px]
            text-center
            "
            >
              <div
                className="
                font-bold text-base
                "
              >
                Profile Picture
              </div>

              <div className="mt-1 text-pry-dark-green-300/60">
                Your picture will be visible to your cooperatives on ThriftCorp
              </div>
            </div>

            <ProfilePicture
              className="
            !w-[106px] !h-[106px] 
            !text-2xl !font-bold
            hover:!scale-100
            "
            />

            <div className="w-full max-w-[120px]">
              <Button
                text={profile?.logo ? "Change photo" : "Add a photo"}
                onClick={goToProfileEdit}
                type="button"
                className="!text-sm md:!py-3"
                loading={false}
                disabled={false}
                variation="secondary-light"
              />
            </div>
          </ContainerCard>
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------

export const ProfileView = () => {
  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const onboardingCompletionStatus = useSelector((state) => {
    return state.auth.onboardingCompletionStatus;
  });

  return profile?.type === "cooperative" ? (
    <CooperativeProfileView
      profile={profile}
      onboardingCompletionStatus={onboardingCompletionStatus}
    />
  ) : (
    <MemberProfileView
      profile={profile}
      onboardingCompletionStatus={onboardingCompletionStatus}
    />
  );
};

import React from "react";

export const WebsiteSectionTitle = ({
  minitext,
  text,
  minitextClassName,
  textClassName,
}) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <div
        className={`
        text-base font-medium 
        tracking-[2px] uppercase
        ${minitextClassName}
        `}
      >
        {minitext}
      </div>
      <div
        className={`
        text-[32px] md:text-[40px] width-lim:text-[56px] tracking-[-2px] 
        leading-[40px] md:leading-[46px] width-lim:leading-[64px]
        ${textClassName}
        `}
      >
        {text}
      </div>
    </div>
  );
};

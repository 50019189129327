import PropTypes from "prop-types";
import React from "react";
import { Oval } from "react-loader-spinner";
import { Icon } from "../icons/icon";

export const Button = ({
  text,
  onClick,
  type = "submit",
  className = "",
  loading,
  disabled,
  icon,
  variation = "primary",
  loaderWidth = "25",
  loaderHeight = "25",
  extra,
}) => {
  const getColors = () => {
    switch (variation) {
      case "primary":
        return "bg-pry-green-200 border-pry-green-200 text-white";
      case "primary-dark":
        return "bg-pry-dark-green-300 border-pry-dark-green-300 text-white";
      case "primary-purple":
        return "bg-info-purple border-info-purple text-white";
      case "primary-white":
        return "border-white bg-white text-pry-green-200";
      case "secondary":
        return "bg-transparent border-pry-green-200 text-pry-green-200";
      case "secondary-white":
        return "bg-transparent border-white text-white";
      case "secondary-2":
        return "bg-white border-pry-dark-green-300/20 text-pry-dark-green-300";
      case "secondary-dark":
        return "bg-transparent border-pry-dark-green-400/80 text-pry-dark-green-400/80";
      case "secondary-light":
        return "bg-light-green-bg-100 border-light-green-bg-100 text-pry-green-200";
      case "secondary-light-2":
        return "bg-light-green-bg-300 border-light-green-bg-300 text-pry-dark-green-300";
      case "secondary-light-3":
        return "!bg-light-green-bg !border-light-green-bg !text-pry-green-200";
      case "plain":
        return "bg-transparent border-transparent text-pry-green-200";
      case "plain-2":
        return "bg-white border-pry-dark-green-300/20 text-pry-dark-green-300";
      case "plain-3":
        return "bg-white border-light-green-bg-300 text-pry-green-200";
      case "danger":
        return "bg-red-300 border-red-300 text-white";
      default:
        break;
    }
  };

  return (
    <button
      type={type}
      onClick={(e) => {
        type !== "submit" && e.preventDefault();
        onClick !== undefined && onClick();
      }}
      disabled={disabled || loading}
      className={`
      ${getColors()}
      border
      flex gap-2 items-center justify-center
      w-full rounded-[4px]
      py-3 md:py-[14px]
      text-sm font-medium
      lg:hover:scale-95
      relative
      disabled:hover:scale-100 disabled:cursor-not-allowed disabled:opacity-40
      ${loading && "!text-transparent"}
      transition-all
      ${className}
    `}
    >
      {icon && <Icon svg={icon} width={16} height={16} />}
      {text}
      {extra && extra}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Oval
            visible={true}
            height={loaderHeight}
            width={loaderWidth}
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color="#ffffff"
            strokeWidth={1}
            strokeWidthSecondary={5}
          />
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.any,
  extra: PropTypes.any,
  icon: PropTypes.any,
  loaderHeight: PropTypes.string,
  loaderWidth: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.any,
  type: PropTypes.string,
  variation: PropTypes.string,
};

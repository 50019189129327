import PropTypes from "prop-types";
import React from "react";
import { Button } from "../buttons/button";
import { Icon } from "../icons/icon";

export const ActionCard = ({
  svg,
  title,
  text,
  buttonText,
  isCompleted,
  className,
  onActionClick = () => {},
}) => {
  return (
    <div
      className={`
    px-[12px] py-[16px] md:py-4 md:px-6
    rounded-lg
    bg-light-green-bg
    flex gap-4
    items-center
    ${className}
    transition-all
    `}
    >
      <div className="md:hidden flex">
        <Icon svg={svg} width={32} height={32} />
      </div>
      <div className="hidden md:flex">
        <Icon svg={svg} width={48} height={48} />
      </div>
      <div className="w-full md:max-w-[266px] transition-all">
        <div className="w-full text-sm md:text-base font-medium mb-1">
          {title}
        </div>
        {!isCompleted && (
          <div className="w-full max-w-[154px] md:max-w-full text-xs md:text-sm text-pry-lighter-green-200">
            {text}
          </div>
        )}
      </div>
      <div className="flex justify-end w-full max-w-[90px] md:max-w-[120px] ml-auto">
        <div className="w-full max-w-[90px] md:max-w-[120px] flex items-center justify-center">
          {!isCompleted ? (
            <Button
              text={buttonText}
              onClick={onActionClick}
              type="button"
              className="max-w-[90px] md:!max-w-[120px] !py-2  md:!py-3 text-xs md:!text-sm !font-medium"
              loading={false}
              disabled={false}
              variation="secondary-dark"
            />
          ) : (
            <div
              className="
            text-pry-green-200
            flex gap-1 items-center
            text-sm md:text-base font-medium
            "
            >
              <Icon
                svg="verified-icon"
                width={24}
                height={24}
                className={"scale-[0.8] md:scale-100"}
              />
              <div>Done</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ActionCard.propTypes = {
  buttonText: PropTypes.any,
  className: PropTypes.any,
  isCompleted: PropTypes.any,
  onActionClick: PropTypes.func,
  svg: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

import React from "react";

export const Icon = ({ svg, width, height, className, ...props }) => {
  return (
    <svg
      className={className}
      width={width ? width : 20}
      height={height ? height : 20}
      {...props}
    >
      <use href={`/assets/images/icons_sprite.svg#${svg}`}></use>
    </svg>
  );
};

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../api/requestHelpers";
import { parseErrorMessage } from "../helperFunctions/auth";
import { getCookie, removeCookie, setCookie } from "../helperFunctions/cookies";
import { doNotification } from "../helperFunctions/nofitication";
import { appSliceActions } from "../redux/features/app/app";
import { AuthSliceActions } from "../redux/features/auth/auth";
import { cooperativeSliceActions } from "../redux/features/cooperative/cooperative";
import { layoutSliceActions } from "../redux/features/layout/layout";
import { paymentSliceActions } from "../redux/features/payment/payment";
import { savingsSliceActions } from "../redux/features/savings/savings";
import { SettingsSliceActions } from "../redux/features/settings/settings";

export const logoutUtil = (
  dispatch = () => {},
  callbackIdentifier = null,
  resumeActivity = false,
  navigate = () => {}
) => {
  // Delete token cookie and clear the local staorage
  removeCookie("_tC_token");
  localStorage.clear();

  // Clear profile from the application state
  dispatch(AuthSliceActions.profile_cleared());
  dispatch(AuthSliceActions.reset());
  dispatch(cooperativeSliceActions.reset());
  dispatch(layoutSliceActions.reset());
  dispatch(paymentSliceActions.reset());
  dispatch(savingsSliceActions.reset());
  dispatch(SettingsSliceActions.reset());
  dispatch(appSliceActions.reset());

  // Store the callback identifier in local storage
  callbackIdentifier &&
    localStorage.setItem("logOutCallback", callbackIdentifier);

  //Store last location before logout to resume activity on re log-in
  resumeActivity &&
    localStorage.setItem("resumeLink", window.location?.pathname);

  // Redirect to sign-in page
  navigate("/auth/sign-in");

  window.location.reload();
};

export const useAuth = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const redirectForEmailVerification = (email) => {
    doNotification(
      "Kindly complete email verification to gain access into your account.",
      "info"
    );
    const accountConfirmation = {
      email: email,
      isConfirmed: false,
    };
    localStorage.setItem(
      "accountConfirmation",
      JSON.stringify(accountConfirmation)
    );
    navigate("/auth/sign-up");
  };

  /**Delete user token and information, and log user out of the application */
  const logoutUser = (callbackIdentifier = null, resumeActivity = false) => {
    logoutUtil(dispatch, callbackIdentifier, resumeActivity, navigate);
  };

  /**Obtain user token */
  const loginUser = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("token", payload, false)
      .then((resp) => {
        // Extract access token from response
        const token = resp?.access;

        // decode token
        const decoded = jwt_decode(token);

        // Check if account is verified before moving to dashboard
        if (decoded?.verified) {
          // Set token as cookie
          setCookie("_tC_token", token);

          const accountConfirmation = {
            email: decoded?.username,
            isConfirmed: true,
          };
          localStorage.setItem(
            "accountConfirmation",
            JSON.stringify(accountConfirmation)
          );

          // doNotification("Login successful", "success");
          successCallback();
        } else {
          redirectForEmailVerification(decoded?.username);
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.detail || "An error occured";
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Register new cooperative */
  const registerCooperative = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Register/Cooperative", payload, false)
      .then((resp) => {
        doNotification("Registration successful", "success");
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Register new member */
  const registerMember = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Register", payload, false)
      .then((resp) => {
        doNotification("Registration successful", "success");
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Verify account after signup */
  const verifyAccount = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Register/verify", payload, false)
      .then((resp) => {
        doNotification(
          `Welcome to ThriftCorp ${payload?.Email} 🥳🎉`,
          "success"
        );
        // Update account confirmation flag in local storage
        const accountConfirmation = {
          email: payload?.Email,
          isConfirmed: true,
        };
        localStorage.setItem(
          "accountConfirmation",
          JSON.stringify(accountConfirmation)
        );
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Resend account verification code */
  const resendAccountVerificationCode = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Register/resend/code", payload, false)
      .then((resp) => {
        doNotification(
          `Verification code sent to ${payload?.Email}`,
          "success"
        );
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Send code for Forgot password */
  const sendForgotPasswordCode = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Password/forgot", payload, false)
      .then((resp) => {
        doNotification(
          `Verification code sent to ${payload?.Email}`,
          "success"
        );
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Change account password */
  const forgotPasswordChange = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Password/Change", payload, false)
      .then((resp) => {
        doNotification(`Password changed successfully`, "success");
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Change password */
  const changePassword = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/Password/Change/new", payload, true)
      .then((resp) => {
        doNotification(`Password changed successfully`, "success");
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Fetch user profile */
  const fetchUserProfile = async (
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(AuthSliceActions.fetching_profile(true));

    await getRequest("Account/myprofile", {}, true)
      .then((resp) => {
        // Get profile data from response object
        let profile = resp?.data;
        // Extract token and decode for account type [member | cooperative]
        const token = getCookie("_tC_token");
        const decoded = jwt_decode(token);

        // Include account type in profile object
        profile.type = decoded?.account;

        // Save profile information in the local storage
        localStorage.setItem("profile", JSON.stringify(profile));

        // Dispatch profile update action to update application state
        dispatch(AuthSliceActions.profile_updated(profile));

        dispatch(AuthSliceActions.fetching_profile(false));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        dispatch(AuthSliceActions.fetching_profile(false));
      });

    dispatch(AuthSliceActions.fetching_profile(false));
    callback();
  };

  /** Verify user email */
  const verifyUserEmail = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Account/Status", params, false)
      .then((resp) => {
        let data = resp?.data;

        if (!data?.isActive) {
          sendForgotPasswordCode(
            { Email: params?.Email },
            () => {},
            () => {
              sessionStorage.setItem("passwordResetEmail", params?.Email);
              sessionStorage.setItem("user", "exists");
              navigate("/auth/forgot-password");
            }
          );
        }

        if (!data?.isVerified) {
          redirectForEmailVerification(params?.Email);
        }

        // Call success callback
        data?.isActive && data?.isVerified && successCallback();
      })
      .catch((error) => {
        let errorMessage = parseErrorMessage(error?.response?.data);
        const messageCode = error?.response?.data?.MsgCode;

        if (messageCode === "no.user") {
          errorMessage =
            "No account found with given credentials, proceed to register";
        } else if (messageCode === "no.user") {
          errorMessage =
            "No account found with given credentials, proceed to register";
        }
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const getProfileInformationFromLS = () => {
    const profileString = localStorage.getItem("profile");
    const profileObject = JSON.parse(profileString);
    return profileObject;
  };

  /** Upload cooperative byLaw/constitution document */
  const uploadByeLaw = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Bye/Law", payload, true)
      .then((resp) => {
        const updatedProfile = resp?.data;

        dispatch(AuthSliceActions.profile_updated(updatedProfile));

        doNotification("Document uploaded successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Upload cooperative Certificate */
  const uploadCertificate = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Certificate", payload, true)
      .then((resp) => {
        const updatedProfile = resp?.data;

        dispatch(AuthSliceActions.profile_updated(updatedProfile));

        doNotification("Document uploaded successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Upload cooperative logo */
  const uploadLogo = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Logo", payload, true)
      .then((resp) => {
        const updatedProfile = resp?.data;

        dispatch(AuthSliceActions.profile_updated(updatedProfile));

        doNotification("Logo uploaded successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Upload cooperative logo */
  const uploadDescription = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Description", payload, true)
      .then((resp) => {
        const updatedProfile = resp?.data;

        dispatch(AuthSliceActions.profile_updated(updatedProfile));

        doNotification("Description uploaded successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Upload USER PROFILE */
  const updateUserProfile = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Account/myprofile", payload, true)
      .then((resp) => {
        const updatedProfile = resp?.data;

        dispatch(AuthSliceActions.profile_updated(updatedProfile));

        doNotification("Profile updated successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /** Send verification token to user email and/or phone number */
  const obtainVerificationToken = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Settings/send/token", params, true)
      .then(() => {
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    loginUser,
    logoutUser,
    registerCooperative,
    registerMember,
    verifyAccount,
    resendAccountVerificationCode,
    sendForgotPasswordCode,
    forgotPasswordChange,
    fetchUserProfile,
    getProfileInformationFromLS,
    uploadByeLaw,
    uploadCertificate,
    uploadLogo,
    updateUserProfile,
    uploadDescription,
    verifyUserEmail,
    changePassword,
    obtainVerificationToken,
  };
};

import React, { useState } from "react";
import { ModalComponent } from "..";
import { useMembership } from "../../../customHooks/useMembership";

export const DeclineInvitationModal = ({
  isModalOpen,
  onModalClose,
  cooperative,
  invitation,
}) => {
  const [loading, setLoading] = useState(false);
  const { rejectMembershipInvite, fetchInvitations, rejectOfficialInvite } =
    useMembership();

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Decline Invitation"
      subTitle={
        <div>
          You are about to decline this invitation from{" "}
          <span className="text-pry-green-200">{cooperative?.CoopName}</span>.{" "}
        </div>
      }
      childrenClassName=""
      modalClassName="!max-h-[300px]"
      hasButtons
      proceedButtonText="Yes, Decline"
      proceedButtonVariant="danger"
      onProceedClick={() => {
        setLoading(true);
        if (invitation?.InvitationAction === "official") {
          rejectOfficialInvite(
            {
              coop: invitation?.Cooperative?.id,
              isAccept: false,
            },
            () => {
              setLoading(false);
            },
            () => {
              setLoading(false);
              fetchInvitations();
              onModalClose();
            }
          );
        } else {
          rejectMembershipInvite(
            {
              coop: invitation?.Cooperative?.id,
              answer: false,
            },
            () => {
              setLoading(false);
            },
            () => {
              setLoading(false);
              fetchInvitations();
              onModalClose();
            }
          );
        }
      }}
      hasCancelButton
      cancelButtonText="No, Don't decline"
      cancelButtonVariant="primary"
      onCancelClick={() => {
        onModalClose();
      }}
      isLoading={loading}
      isProceedDisabled={loading}
    />
  );
};

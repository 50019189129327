import React from "react";
import { TableComponent } from "../../../components/table/table";
import { PageTitle } from "../../../components/typography/pageTitle";

export const Members = () => {
  const headers = [
    "Member name",
    "Email address",
    "Executive role",
    "Status",
    "Date joined",
  ];
  return (
    <div className="">
      <PageTitle text={`Members (count)`} />
      <TableComponent
        headers={headers}
        className="mt-[24px]"
        rows={[
          {
            id: 1,
            items: ["name", "email", "role", "state", "date"],
          },
          {
            id: 2,
            items: ["name", "email", "role", "state", "date"],
          },
          {
            id: 3,
            items: ["name", "email", "role", "state", "date"],
          },
        ]}
      />
    </div>
  );
};

import React, { useState } from "react";
import { Button } from "../../../../components/buttons/button";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { InformationCard } from "../../../../components/cards/infoCard";
import { Icon } from "../../../../components/icons/icon";
import { ChangePasswordModal } from "../../../../components/modals/app_modals/changePasswordModal";
import { TitleSub } from "../../../../components/typography/titleSub";

export const PersonalSecurity = () => {
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleModalOpen = (modalName) => {
    setModal("changePasswordModal");
    setModalOpen(true);
  };

  return (
    <div
      className="
        flex flex-row flex-wrap
        w-full
        gap-2 md:gap-6
        "
    >
      <ContainerCard
        className="
            w-full max-w-[653px] h-[685px]
            order-last width-lim:order-first
            "
      >
        <TitleSub
          title="Security"
          subtitle={`ThriftCorp maintains high standards for account security. That's why we've provided a couple of ways for you to protect your account.`}
        />

        <div className="mt-[40px]">
          <ContainerCard
            className="
            !pt-[24px] !px-[0px]
            !border-x-0 !rounded-none
            flex flex-row flex-wrap gap-4
            items-center
          "
          >
            <div
              className="
              p-[8px] md:p-[14px] rounded-lg
              bg-light-green-bg
              w-fit h-fit
            "
            >
              <Icon
                svg="password-key"
                className="
              text-pry-green-200
              w-[24px] h-[24px] md:w-[40px] md:h-[40px]
            "
              />
            </div>

            <div className="flex-1">
              <div className="text-sm font-medium max-w-[249px]">Password</div>
              <div className="text-xs opacity-60 max-w-[249px]">
                Keep your password safe. We recommend you change your password
                periodically.
              </div>
            </div>

            <Button
              text="Change Password"
              onClick={() => {
                handleModalOpen("changePasswordModal");
              }}
              type="button"
              className="md:!max-w-[140px] !h-fit md:!py-3"
              loading={false}
              disabled={false}
              icon={undefined}
              variation="primary"
            />
          </ContainerCard>
        </div>
      </ContainerCard>

      <InformationCard
        info={`Manage your account security`}
        className="!max-w-[345px] h-fit width-lim:sticky-top"
      />

      <ChangePasswordModal
        isModalOpen={modal === "changePasswordModal" && modalOpen}
        onModalClose={handleModalClose}
        successCallback={() => {}}
      />
    </div>
  );
};

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import empty_invite_state from "../../../../assets/images/empty_state_invite.svg";
import { Button } from "../../../../components/buttons/button";
import { TextLink } from "../../../../components/buttons/textLink";
import { ContainerCard } from "../../../../components/cards/containerCard";
import { InformationCard } from "../../../../components/cards/infoCard";
import { Icon } from "../../../../components/icons/icon";
import { TextInputDropDown } from "../../../../components/inputs/textInputDropdown";
import { ConfirmInviteDeleteModal } from "../../../../components/modals/app_modals/confirmInviteDeleteModal";
import { EmptyState } from "../../../../components/states/emptyState";
import { TitleSub } from "../../../../components/typography/titleSub";
import { useAuth } from "../../../../customHooks/useAuth";
import { useCooperative } from "../../../../customHooks/useCooperative";
import { inviteCooperativeOfficialsSchema } from "../../../../validationSchema";

const OfficialStatusComponent = ({
  official,
  setModalOpen,
  setModal,
  setSelectedOfficial,
}) => {
  const isConfirmed = official?.isVerified;
  return (
    <div
      className="
    flex flex-row gap-4 flex-wrap
    items-center justify-between
    "
    >
      {/* Official's details  */}
      <div className="text-sm flex flex-col gap-[6px]">
        <div className="font-medium capitalize">{official?.Role?.Name}</div>
        <div className="flex flex-wrap gap-2">
          <div className="italic opacity-60">{official?.MemberEmail}</div>
          {!isConfirmed && (
            <TextLink
              text="Resend Invite"
              to={undefined}
              className="w-full md:w-fit"
              onClick={() => {}}
              disabled={false}
            />
          )}
        </div>
      </div>

      {/* Actions  */}
      <div
        className="
      flex flex-row
      items-center
      gap-4
      w-full md:w-fit
      "
      >
        {!isConfirmed && (
          <button
            className="
            text-red-200 bg-lighter-red-bg
            p-2 rounded-full
            cursor-pointer
            "
            onClick={() => {
              setModal("confirmInviteDeleteModal");
              setModalOpen(true);
              setSelectedOfficial(official);
            }}
          >
            <Icon svg="delete-icon" width={20} height={20} />
          </button>
        )}

        <div
          className={`
        font-medium text-sm
        py-[8px] 
        w-full md:w-[120px]
        text-center
        rounded-[4px]
        ${
          isConfirmed
            ? "text-pry-dark-green-200 bg-light-green-bg-50"
            : "text-pry-dark-green-300/50 bg-pry-dark-green-300/5"
        }
        `}
        >
          {isConfirmed ? "Accepted" : "Awaiting"}
        </div>
      </div>
    </div>
  );
};

const InviteRoleComponent = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOfficial, setSelectedOfficial] = useState();
  const [isDeleting, setIsDeleting] = useState(false);

  const { roles, cooperativeOfficials } = useSelector((state) => {
    return state.cooperative;
  });

  const {
    fetchCooperativeRoles,
    inviteOfficials,
    fetchCooperativeOfficials,
    deleteOfficialInvite,
  } = useCooperative();
  const { fetchUserProfile } = useAuth();

  const {
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      role: {},
    },
    validationSchema: inviteCooperativeOfficialsSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);

      const payload = [
        {
          email: data?.email,
          official: data?.role?.value,
        },
      ];

      inviteOfficials(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          setSubmitting(false);
          resetForm();
          fetchUserProfile();
          fetchCooperativeOfficials();
        }
      );
    },
  });

  useEffect(() => {
    fetchCooperativeRoles();
    fetchCooperativeOfficials();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const options = roles.map((role) => {
      return {
        id: role?.code,
        title: role?.Title,
        value: role?.code,
      };
    });

    setRoleOptions(options);
  }, [roles]);

  return (
    <div>
      <div
        className={`
      w-full
      flex flex-col md:flex-row
      ${!errors?.email && !errors?.role && "items-center"}
      gap-2
      `}
      >
        <div className="w-full">
          <TextInputDropDown
            type="text"
            name="email"
            value={values?.email}
            placeholder="Enter email address"
            onChange={handleChange}
            onBlur={handleBlur}
            hasIcon={false}
            errors={
              (touched?.email && errors?.email && errors?.email) ||
              (touched?.email && errors?.role && errors.role)
            }
            inputClass=""
            className="w-full"
            initialDropdownSelected={values?.role}
            dropdownPlaceholder="Select role"
            dropdownOptions={roleOptions}
            onDropdownSelect={(item) => {
              setFieldValue("role", item);
            }}
            autoComplete="off"
          />
        </div>

        <Button
          text="Send Invite"
          onClick={handleSubmit}
          type="button"
          className="!w-full md:!max-w-[120px] h-fit"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          variation="primary-dark"
        />
      </div>

      <div className="mt-[24px] flex flex-col gap-6">
        {cooperativeOfficials?.map((official, idx) => {
          const key = `official-${idx}`;
          return (
            <OfficialStatusComponent
              official={official}
              deleteOfficialInvite={deleteOfficialInvite}
              fetchCooperativeOfficials={fetchCooperativeOfficials}
              setModalOpen={setModalOpen}
              setModal={setModal}
              setSelectedOfficial={setSelectedOfficial}
              key={key}
            />
          );
        })}
      </div>

      {/* Confirm invite delete modal sits here  */}
      <ConfirmInviteDeleteModal
        modalOpen={modalOpen && modal === "confirmInviteDeleteModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        confirmationMessage={
          <div>
            You are about to revoke{" "}
            <span className="text-pry-green-200">
              {selectedOfficial?.MemberEmail}
            </span>
            's invitation as the{" "}
            <span className="text-pry-green-200">
              {selectedOfficial?.Role?.Name}
            </span>{" "}
            of the cooperative. The user will no longer have exececutive rights
            in your cooperative.
          </div>
        }
        onConfirm={() => {
          setIsDeleting(true);
          deleteOfficialInvite(
            { official: selectedOfficial?.Role?.CodeName },
            () => {},
            () => {
              setIsDeleting(false);
              fetchCooperativeOfficials();
              setModal("");
              setModalOpen(false);
            }
          );
        }}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export const CooperativeOfficials = () => {
  const isOfficialsStarted = useSelector((state) => {
    return state?.auth?.onboardingCompletionStatus?.isOfficialsStarted;
  });

  const [emptyState, setEmptyState] = useState(!isOfficialsStarted);

  return (
    <div
      className="
    flex flex-row flex-wrap
    w-full
    gap-2 md:gap-6
    "
    >
      <ContainerCard
        className="
      w-full max-w-[653px] h-[685px]
      overflow-y-scroll
      order-last width-lim:order-first
      "
      >
        <TitleSub
          title="Manage Cooperative Officials"
          subtitle="Assemble the executive team that will manage your cooperative"
        />

        {!emptyState ? (
          <div
            className="
          mt-[24px]
          w-full
          "
          >
            <InviteRoleComponent />
          </div>
        ) : (
          <div className="md:mt-[104px]">
            <EmptyState
              image={<img src={empty_invite_state} alt="empty_state" />}
              title="Send invites to officials"
              subtitle="Add your cooperative officials by sending an email invitation to them"
              actionText="Send invites"
              onClick={() => {
                setEmptyState(false);
              }}
            />
          </div>
        )}
      </ContainerCard>
      <InformationCard
        info={
          <div>
            <span className="text-pry-green-200 font-medium">
              Cooperative Officials{" "}
            </span>
            are the executives that coordinate the day to day activities of the
            cooperative.
          </div>
        }
        className="w-full md:!max-w-[345px] h-fit width-lim:sticky-top"
      />
    </div>
  );
};

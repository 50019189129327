import React from "react";
import { Icon } from "../icons/icon";

export const InformationCard = ({
  info,
  className,
  innerClassName = "",
  variant = "green",
  size = "regular", // "regular" | "mini"
  hasIcon = true,
  actionElement,
}) => {
  const getColor = () => {
    switch (variant) {
      case "green":
        return "bg-light-green-bg-100 border-pry-dark-green-300/10";
      case "purple":
        return "bg-info-purple-bg border-light-purple";
      default:
        return "bg-light-green-bg-100 border-pry-dark-green-300/10";
    }
  };
  return (
    <div
      className={`
        w-full
        ${size === "regular" && "px-4 py-6"}
        ${size === "mini" && "px-4 py-2"}
        ${getColor()}
        border
        rounded-[8px]
        flex flex-row flex-wrap
        items-start
        gap-2
        ${className}
        `}
    >
      <div
        className={`
          min-w-full md:w-max md:flex-1
          flex items-center gap-2
          max-w-full
          ${innerClassName}
        `}
      >
        {hasIcon && (
          <div className="w-fit">
            <Icon
              svg="info-icon"
              className={`
            ${size === "regular" && "w-[20px] h-[20px]"}
            ${size === "mini" && "w-[14px] h-[14px]"}
            `}
            />
          </div>
        )}

        <div
          className={`
        ${size === "regular" && "text-sm"}
        ${size === "mini" && "text-xs"}
        !w-full !max-w-full
        `}
        >
          {info}
        </div>
      </div>

      {actionElement && (
        <div className="flex md:justify-end w-full md:w-max">
          {actionElement}
        </div>
      )}
    </div>
  );
};

export const InformationCardNoIcon = ({ info, className }) => {
  return (
    <div
      className={`
            w-full
            text-sm
            py-1 px-[9px]
            bg-light-green-bg-300
            border border-transparent rounded-[4px]
            ${className}
            `}
    >
      {info}
    </div>
  );
};

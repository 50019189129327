import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../api/requestHelpers";
import { decodePaymentAction } from "../helperFunctions";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { appSliceActions } from "../redux/features/app/app";
import { cooperativeSliceActions } from "../redux/features/cooperative/cooperative";

export const useCooperative = () => {
  const dispatch = useDispatch();

  const fetchCooperativeRoles = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("AccessMgmt/defaultrole", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(cooperativeSliceActions.rolesUpdated(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const inviteOfficials = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Register/Official", payload, true)
      .then((resp) => {
        doNotification("Invitation sent successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const deleteOfficialInvite = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Cooperative/Register/Official/delete", payload, true)
      .then((resp) => {
        doNotification("Invitation revoked", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchCooperativeOfficials = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Cooperative/Register/Official", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(cooperativeSliceActions.officialsUpdated(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchDefaultMembershipFormDetails = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Settings/default/FormField", params, true)
      .then((resp) => {
        const data = resp?.data;

        const new_data = data?.map((item) => {
          return { ...item, isDefault: true, option: [] };
        });

        dispatch(cooperativeSliceActions.membershipFormUpdated(new_data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchDefaultFormTypes = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Settings/default/FormType", params, true)
      .then((resp) => {
        const data = resp?.data;

        const new_data = data?.map((fieldType) => {
          let displayName = "";

          switch (fieldType[0]?.toLowerCase()) {
            case "text":
              displayName = "Short answer";
              break;
            case "file":
              displayName = "File";
              break;
            case "textarea":
              displayName = "Long answer";
              break;
            case "checkbox":
              displayName = "Multiple choice";
              break;
            case "radio":
              displayName = "Radio";
              break;
            case "select":
              displayName = "Dropdown";
              break;
            case "datetime":
              displayName = "Date and time";
              break;
            case "date":
              displayName = "Date";
              break;
            case "email":
              displayName = "Email";
              break;
            default:
              displayName = "Answer";
              break;
          }
          return {
            id: fieldType[0],
            title: displayName,
            value: fieldType[0],
          };
        });

        dispatch(
          cooperativeSliceActions.membershipFormFieldTypesUpdated(new_data)
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchCooperativeMembershipFormDetails = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Membership/FormDetail", params, true)
      .then((resp) => {
        const data = resp?.data;

        const default_data = data?.defaultfield?.map((item) => {
          return { ...item, isDefault: true, option: [] };
        });

        const new_data = data?.Field?.map((item) => {
          return {
            ...item,
            isDefault: false,
            option: [],
            fieldType: item?.FieldType || item?.fieldType,
            question: item?.Question || item?.question,
          };
        });

        const merged_data = new_data?.concat(default_data);

        dispatch(cooperativeSliceActions.membershipFormUpdated(merged_data));
        dispatch(
          cooperativeSliceActions.membershipFeeUpdated(
            Number(data?.Form?.Fee).toLocaleString()
          )
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const updateCooperativeMembershipFormDetails = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Membership/FormDetail", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Membership form updated successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const discoverCooperatives = async (
    params = {
      limit: 10,
      offset: 0,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.discoveringCooperatives(true));
    await getRequest("Cooperative/Discover", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(
          appSliceActions.discoverCooperativesUpdated({
            totalCount: data?.count,
            data: data?.results,
          })
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();

    dispatch(appSliceActions.discoveringCooperatives(false));
  };

  const fetchSingleCooperativeDetails = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingSingleCooperativeDetails(true));
    await getRequest("Cooperative/detail", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(appSliceActions.singleCooperativeDetailsFetched(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(appSliceActions.fetchingSingleCooperativeDetails(false));
    callback();
  };

  const fetchMyCooperatives = async (
    params = {
      limit: 10,
      offset: 0,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingMyCooperatives(true));
    await getRequest("Cooperative/my", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(
          appSliceActions.myCooperativesUpdated({
            totalCount: data?.count,
            data: data?.results,
          })
        );

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();

    dispatch(appSliceActions.fetchingMyCooperatives(false));
  };

  const joinCooperative = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    failureCallback = () => {}
  ) => {
    await postRequest("Membership/FormDetail/filled", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Form completed sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        failureCallback();
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const sendMembershipInvite = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Membership/Invitation/link/send", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Invitation sent sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchMyCooperativeMembers = async (
    params = {
      limit: 10,
      offset: 0,
    },
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    setLoading(true);
    await getRequest("Cooperative/members", params, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback(data);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchMyCooperativeMembersQuery = async (
    params = {
      limit: 20,
      offset: 0,
      coop: "",
      text: null,
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Cooperative/members", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const fetchMyCooperativeMemberDetails = async (
    params = {
      limit: 10,
      Permission: "true",
    },
    callback = () => {},
    successCallback = () => {}
  ) => {
    dispatch(appSliceActions.fetchingMyMembershipDetails(true));
    await getRequest("Membership/Detail", params, true)
      .then((resp) => {
        const data = resp?.data;

        dispatch(appSliceActions.myMembershipDetailsUpdated(data));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    dispatch(appSliceActions.fetchingMyMembershipDetails(false));
    callback();
  };

  const fetchCooperativeProductHistory = async (
    params = {
      coop: undefined,
      detail: true,
      type: "savings",
    },

    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];
    const type = params?.queryKey[2];

    let response;

    let endpoint = decodePaymentAction(type)?.adminHistoryEndpoint;

    await getRequest(endpoint, paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.count,
          data: data?.results,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const adminFetchCooperativeFinances = async (
    params = {
      coop: undefined,
      text: true,
      id: undefined,
      logs: false,
    },

    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    let endpoint = "Payment/Cooperative/Finances/Admin";

    await getRequest(endpoint, paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = data;

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const initiateCooperativeWithdrawal = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    failureCallback = () => {}
  ) => {
    await postRequest("Withdrawal/Cooperative", payload, true)
      .then((resp) => {
        // eslint-disable-next-line
        const data = resp?.data;

        doNotification("Withdrawal initiated sucessfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        failureCallback();
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  return {
    fetchCooperativeRoles,
    inviteOfficials,
    deleteOfficialInvite,
    fetchCooperativeOfficials,
    fetchDefaultMembershipFormDetails,
    fetchMyCooperativeMembersQuery,
    fetchDefaultFormTypes,
    fetchCooperativeMembershipFormDetails,
    updateCooperativeMembershipFormDetails,
    discoverCooperatives,
    fetchSingleCooperativeDetails,
    fetchMyCooperatives,
    joinCooperative,
    fetchMyCooperativeMembers,
    fetchMyCooperativeMemberDetails,
    sendMembershipInvite,
    fetchCooperativeProductHistory,
    adminFetchCooperativeFinances,
    initiateCooperativeWithdrawal,
  };
};

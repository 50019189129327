import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import hero from "../../../assets/images/hero.png";
import mobileHero from "../../../assets/images/mobileHeroHalf.png";
import { WebsiteAccordion } from "../../../components/accordions/websiteAccordion";
import { WebsiteAccordionTitle } from "../../../components/accordions/websiteAccordionTitle";
import { WebsiteButton } from "../../../components/buttons/websiteButton";
import { WebsiteDoubleSection } from "../../../components/layouts/websiteDoubleSection";
import { WebsiteLayout } from "../../../components/layouts/websiteLayout";
import { WebsiteCTASubSection } from "../../../components/typography/websiteCTASubSection";
import { WebsiteSectionTitle } from "../../../components/typography/websiteSectionTitle";
import {
  mobileTabPadding,
  mobileTabPaddingLeft,
  mobileTabPaddingRight,
} from "../../../utils/constants";

const accordionElements = [
  {
    id: 1,
    number: "01",
    title: "Track All Your Cooperatives",
    content:
      "Easily monitor and manage savings, deposits, share purchases, and loan requests across multiple cooperatives, all from a single platform.",
  },
  {
    id: 2,
    number: "02",
    title: "Savings",
    content:
      "Seamlessly save funds into your cooperative accounts, ensuring your financial goals are on track.",
  },
  {
    id: 3,
    number: "03",
    title: "Deposits",
    content:
      "Make deposits into your cooperative accounts hassle-free, with secure and efficient transaction processing.",
  },
  {
    id: 4,
    number: "04",
    title: "Share Purchases",
    content:
      "Invest in your cooperative by purchasing shares directly through the ThriftCorp platform, empowering cooperative growth and member ownership.",
  },
  {
    id: 5,
    number: "05",
    title: "Loan Requests",
    content:
      "Request loans from your cooperative with ease, facilitating access to financial assistance when needed most.",
  },
];

const AccordionImagesComponent = ({ accordionOpen, className }) => {
  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.init();
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check initially in case the section is already in view

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`
      flex
      w-full md:h-[600px] width-lim:h-[800px]
      items-center justify-center
      ${
        accordionOpen === 1 &&
        "bg-[url('assets/images/accordion1.png')] bg-[length:430px]"
      }
      ${accordionOpen === 2 && "bg-[url('assets/images/accordion2.png')]"}
      ${accordionOpen === 3 && "bg-[url('assets/images/accordion3.png')]"}
      ${accordionOpen === 4 && "bg-[url('assets/images/accordion4.png')]"}
      ${accordionOpen === 5 && "bg-[url('assets/images/accordion5.png')]"}
      bg-contain
      bg-no-repeat bg-center
      transition-all duration-500
      ${className}
      `}
    ></div>
  );
};

export const LandingPage = () => {
  const [accordionOpen, setAccordionOpen] = useState(1);

  return (
    <WebsiteLayout>
      {/* Hero Section  */}
      <div
        className={`
        h-[calc(100vw+502px)] md:h-[852px]
        width-lim:min-h-[1024px] width-lim:h-[calc(100vh+172px)] max-h-[1024px] 
        bg-pry-dark-green-200
        bg-[url('assets/images/hero.png')]
        bg-[length:calc(100%+300px)] md:bg-[length:1254px] width-lim:bg-[length:1254px] width-lim-xl:bg-[length:1554px] bg-no-repeat
        bg-[24px_calc(100%-64px)] md:bg-[calc(50%+690px)_50%] width-lim:bg-[calc(50%+690px)_50%]
        flex 
        md:items-center md:justify-center
        width-lim:items-center width-lim:justify-center
        pt-[128px] md:pt-0
        width-lim:px-0 ${mobileTabPadding}
        `}
      >
        <div
          className="
            w-full
            max-w-full md:max-w-max-1024 width-lim:max-w-website-max width-lim-xl:max-w-website-max-xl
            "
        >
          <div
            className="
            text-5xl md:text-[64px] width-lim:text-[80px] 
            leading-[48px] md:leading-[64px] width-lim:leading-[80px]
            text-white
            "
          >
            Everything
            <br />
            Cooperative.
            <br />
            <span className="text-light-green-text">One Platform.</span>
          </div>

          <div
            className="
            text-white/80 
            mt-6 width-lim:mt-4
            max-w-[450px] 
            text-base width-lim:text-lg"
          >
            Welcome to ThriftCorp: Empowering Cooperatives with Innovative
            Solutions
          </div>

          <WebsiteButton
            text="Get started for free"
            to="/auth/sign-up"
            className="
            !py-4
            !px-6 width-lim:!px-0
            !max-w-max width-lim:!max-w-[277px] 
            mt-8 width-lim:mt-16 
            !text-pry-dark-green-400 
            !text-base width-lim:!text-lg
            "
            variation="primary-white"
          />
        </div>
      </div>

      {/* Why ThriftCorp  */}
      <div
        className={`
        w-full 
        py-[64px] md:py-[112px] width-lim:py-[160px]
        ${mobileTabPadding}
        `}
      >
        <div
          className="
            w-full
            max-w-full md:max-w-max-1024 width-lim:max-w-website-max width-lim-xl:max-w-website-max-xl
            mx-auto
            "
        >
          <div className="text-pry-green-200 font-medium tracking-[2px]">
            WHY THRIFTCORP?
          </div>
          <div
            className="
            mt-4 
            text-2xl md:text-4xl width-lim:text-[56px]
            leading-[32px] width-lim:leading-[63px]
            tracking-[-1.6px]
            "
          >
            ThriftCorp's mission is to provide comprehensive and user-friendly
            tech solutions that enable cooperatives to streamline their
            operations, enhance member experiences, and achieve sustainable
            growth.
          </div>
        </div>
      </div>

      {/* Accordion Section  */}
      <WebsiteDoubleSection
        className={`py-6 lg:py-0 ${mobileTabPadding} lg:!px-0`}
        leftClassName={`!pl-0 !pr-0 md:!pl-0 md:!pr-0 lg:!pl-10 lg:!pr-0 width-lim:!pl-0 width-lim:!pr-0`}
        rightClassName={`
        transition-all duration-300
        pl-[30px] width-lim:pl-[60px]
        bg-[url('assets/images/topography.png')]
        ${accordionOpen === 1 && "bg-light-purple-bg"}
        ${accordionOpen === 2 && "bg-cool-green-bg"}
        ${accordionOpen === 3 && "bg-light-purple-bg"}
        ${accordionOpen === 4 && "bg-cool-blue-bg"}
        ${accordionOpen === 5 && "bg-cool-yellow-bg"}
        bg-contain
        `}
        leftChild={
          <div className="w-full !max-w-full lg:pr-[30px] width-lim:pr-[60px]">
            {accordionElements?.map((elem) => {
              return (
                <div
                  key={elem.id}
                  className={`
                    py-[24px] width-lim:py-[33px]
                    border-b
                    cursor-pointer
                    ${
                      accordionOpen !== elem.id &&
                      `
                      lg:hover:bg-light-grey-bg-3
                      lg:hover:px-4
                      lg:hover:rounded-lg
                      `
                    }
                    transition-all duration-200
                    testtt
                    `}
                  onClick={() => {
                    setAccordionOpen(elem.id);
                    document.getElementsByClassName("testtt")[4].focus();
                  }}
                >
                  <WebsiteAccordion
                    title={
                      <WebsiteAccordionTitle
                        number={elem.number}
                        title={elem.title}
                      />
                    }
                    content={
                      <div>
                        <div className="text-base lg:text-lg">
                          {elem.content}
                        </div>
                        <div
                          className={`
                          lg:!hidden
                          mt-6
                          h-0
                          ${accordionOpen && "!h-[400px]"}
                          bg-[url('assets/images/topography.png')]
                          ${accordionOpen === 1 && "bg-light-purple-bg"}
                          ${accordionOpen === 2 && "bg-cool-green-bg"}
                          ${accordionOpen === 3 && "bg-light-purple-bg"}
                          ${accordionOpen === 4 && "bg-cool-blue-bg"}
                          ${accordionOpen === 5 && "bg-cool-yellow-bg"}
                          bg-cover
                          transition-all duration-500 delay-300
                        `}
                        >
                          <AccordionImagesComponent
                            accordionOpen={accordionOpen}
                            className={`
                            !h-full
                          `}
                          />
                        </div>
                      </div>
                    }
                    isOpen={accordionOpen === elem.id}
                  />
                </div>
              );
            })}
          </div>
        }
        rightChild={
          <AccordionImagesComponent
            accordionOpen={accordionOpen}
            className={`!hidden lg:!flex`}
          />
        }
      />

      {/* For Cooperative members  */}
      <WebsiteDoubleSection
        className="bg-pry-dark-green-400 mt-6 lg:mt-0 py-[64px] lg:py-[167px]"
        leftClassName={`${mobileTabPaddingLeft}`}
        rightClassName={`
        ${mobileTabPaddingRight}
        `}
        leftChild={
          <div className="w-full lg:!pr-[60px] width-lim:!pr-[104px]">
            <WebsiteSectionTitle
              minitext={"FOR COOPERATIVE MEMBERS"}
              text={"There’s so much you can do as a Member"}
              minitextClassName={"text-pry-green-200"}
              textClassName={"text-white"}
            />
          </div>
        }
        rightChild={
          <div
            className="
            text-grey-300 text-xl mt-6 lg:mt-0
            lg:!pl-[60px] width-lim:!pl-[104px]
            "
          >
            ThriftCorp goes beyond mere transactions; it{" "}
            <span className="text-white font-medium">empowers members</span> to
            actively participate in their cooperatives' operations
          </div>
        }
      />

      {/* Invite other members */}
      <WebsiteDoubleSection
        className="lg:h-[480px] width-lim:h-[560px]"
        leftClassName={`
        bg-pry-green-200
        !h-[328px] lg:!h-full
        !pl-6 !pr-6 md:!pl-10 md:!pr-10 lg:!pl-10 lg:!pr-10 width-lim:!pl-0 width-lim:!pr-20
        lg:!pr-[65px] width-lim:!pr-[65px]
        `}
        rightClassName={`
        bg-pry-dark-green-400
        transition-all duration-300
        ${mobileTabPaddingRight}
        py-[64px] lg:py-0
        `}
        leftChild={
          <div
            className={`
            h-full
            flex items-center justify-center
            bg-[url('assets/images/invite_members.png')]
            bg-[center_calc(100%+10px)] md:bg-[center_calc(100%+40px)] lg:bg-[100%_bottom] width-lim-xl:bg-[100%_calc(80px)]
            bg-contain  lg:bg-contain width-lim-xl:bg-cover
            bg-no-repeat
            transition-all duration-500
            `}
          ></div>
        }
        rightChild={
          <div
            className={`
            h-full
            flex items-center
            lg:!pl-[60px] width-lim:!pl-[104px]
            `}
          >
            <WebsiteCTASubSection
              minititle={"01"}
              title={"Invite Other Members"}
              text={
                <div>
                  <span className="font-medium text-white">
                    Actively grow your cooperative
                  </span>{" "}
                  by inviting new members to join, expanding the cooperative's
                  reach and impact.
                </div>
              }
              ctaVariant={"primary"}
              ctaText={"Start Inviting"}
              ctaTo={"/auth/sign-in"}
              minititleClassName="text-pry-green-200"
              titleClassName="text-white"
              textClassName="text-grey-300"
              ctaClassName=""
            />
          </div>
        }
      />

      {/* Approve loans */}
      <WebsiteDoubleSection
        className="lg:h-[560px] width-lim:h-[632px]"
        leftClassName={`
        bg-pry-dark-green-400
        ${mobileTabPaddingLeft}
        py-[64px] lg:py-0
        !order-last lg:!order-first
        `}
        rightClassName={`
        !h-[328px] lg:!h-full
        bg-light-green-text
        transition-all duration-300
        bg-cover
        bg-[url('assets/images/app_screenshot1.png')]
        bg-[40px_32px] lg:bg-[40px_64px]
        bg-cover
        bg-no-repeat
        `}
        rightChild={
          <div
            className={`
            h-full
            flex items-center justify-center
            transition-all duration-500
            `}
          ></div>
        }
        leftChild={
          <div
            className={`
            h-full
            flex items-center
            lg:!pr-[60px] width-lim:!pr-[104px]
            `}
          >
            <WebsiteCTASubSection
              minititle={"02"}
              title={
                "Approve Loans and Other Transactions within the cooperative"
              }
              text={
                <div>
                  Serve as an official within your cooperative by approving
                  transactions, ensuring smooth and secure financial operations.
                </div>
              }
              ctaVariant={"primary"}
              ctaText={"Join a Cooperative"}
              ctaTo={"/auth/sign-in"}
              minititleClassName="text-pry-green-200"
              titleClassName="text-white"
              textClassName="text-grey-300"
              ctaClassName=""
            />
          </div>
        }
      />

      {/* For Cooperatives  */}
      <WebsiteDoubleSection
        className="bg-off-white py-[64px] lg:py-[167px]"
        leftClassName={`${mobileTabPaddingLeft}`}
        rightClassName={`
        ${mobileTabPaddingRight}
        `}
        leftChild={
          <div className="w-full lg:pr-[60px]">
            <WebsiteSectionTitle
              minitext={"FOR COOPERATIVES"}
              text={"Cooperative Management Made Easy"}
              minitextClassName={"text-pry-green-200"}
              textClassName={""}
            />
          </div>
        }
        rightChild={
          <div
            className="
            text-pry-dark-green-300/60 text-xl mt-6 lg:mt-0 
            lg:!pl-[60px] width-lim:!pl-[104px]
          "
          >
            ThriftCorp offers comprehensive tools to{" "}
            <span className="text-pry-dark-green-300 font-medium">
              manage and track
            </span>{" "}
            member activities efficiently:
          </div>
        }
      />

      {/* KYC Upload */}
      <WebsiteDoubleSection
        className="lg:h-[480px] width-lim:h-[560px]"
        leftClassName={`
        bg-pry-green-200 
        !h-[328px] lg:!h-full
        !pl-6 !pr-6 md:!pl-10 md:!pr-10 lg:!pl-10 lg:!pr-10 width-lim:!pl-0 width-lim:!pr-20
        lg:!pr-[65px] width-lim:!pr-[65px]
        `}
        rightClassName={`
        bg-off-white
        transition-all duration-300
        ${mobileTabPaddingRight}
        py-[64px] lg:py-0
        `}
        leftChild={
          <div
            className={`
            h-full
            flex items-center justify-center
            bg-[url('assets/images/invite_members.png')]
            bg-[center_calc(100%+10px)] md:bg-[center_calc(100%+40px)] lg:bg-[100%_bottom] width-lim-xl:bg-[100%_calc(80px)]
            bg-contain  lg:bg-contain width-lim-xl:bg-cover
            bg-no-repeat
            transition-all duration-500
            `}
          ></div>
        }
        rightChild={
          <div
            className={`
            h-full
            flex items-center
            lg:!pl-[60px] width-lim:!pl-[104px]
            `}
          >
            <WebsiteCTASubSection
              minititle={"01"}
              title={"KYC Document Upload"}
              text={
                <div>
                  Simplify compliance processes by enabling members to upload
                  Know Your Customer (KYC) documents securely.
                </div>
              }
              ctaVariant={"primary"}
              ctaText={"Create an Account"}
              ctaTo={"/auth/sign-up"}
              minititleClassName="text-pry-green-200"
              titleClassName=""
              textClassName="text-pry-dark-green-300/60"
              ctaClassName=""
            />
          </div>
        }
      />

      {/* Customize Settings */}
      <WebsiteDoubleSection
        className="lg:h-[480px] width-lim:h-[560px]"
        leftClassName={`
        bg-off-white pr-[65px]
        ${mobileTabPaddingLeft}
        py-[64px] lg:py-0
        !order-last lg:!order-first
        `}
        rightClassName={`
        !h-[328px] lg:!h-full
        bg-light-green-text
        transition-all duration-300
        !pr-6 !pl-6 md:!pr-10 md:!pl-10 lg:!pr-10 lg:!pl-10 width-lim:!pr-0 width-lim:!pl-20
        `}
        leftChild={
          <div
            className={`
            h-full
            flex items-center
            lg:!pr-[60px] width-lim:!pr-[104px]
            `}
          >
            <WebsiteCTASubSection
              minititle={"02"}
              title={"Customize Your Cooperative Settings"}
              text={
                <div>
                  Tailor deposit, savings, share, and loan settings to meet the
                  unique needs of your cooperative, ensuring flexibility and
                  control.
                </div>
              }
              ctaVariant={"primary"}
              ctaText={"Start Customizing"}
              ctaTo={"/auth/sign-up"}
              minititleClassName="text-pry-green-200"
              titleClassName=""
              textClassName="text-pry-dark-green-300/60"
              ctaClassName=""
            />
          </div>
        }
        rightChild={
          <div
            className={`
              h-full
              lg:!pl-[60px] width-lim:!pl-[104px]
              flex items-center justify-center
              bg-[url('assets/images/app_screenshot2.png')]
              bg-[center_calc(100%+24px)] lg:bg-[0px_calc(100%+80px)] width-lim-xl:bg-[100%_calc(80px)]
              bg-contain width-lim-xl:bg-cover
              bg-no-repeat
              transition-all duration-500
              `}
          ></div>
        }
      />

      {/* Invite Officials */}
      <WebsiteDoubleSection
        className="lg:h-[480px] width-lim:h-[560px]"
        leftClassName={`
        bg-pry-green-200
        !h-[328px] lg:!h-full
        !pl-6 !pr-6 md:!pl-10 md:!pr-10 lg:!pl-10 lg:!pr-10 width-lim:!pl-0 width-lim:!pr-20
        lg:!pr-[65px] width-lim:!pr-[65px]
        `}
        rightClassName={`
        bg-off-white
        transition-all duration-300
        ${mobileTabPaddingRight}
        py-[64px] lg:py-0
        `}
        leftChild={
          <div
            className={`
            h-full
            flex items-center justify-center
            bg-[url('assets/images/app_screenshot3.png')] 
            bg-[center_calc(100%+24px)] lg:bg-[100%_calc(100%+80px)] width-lim-xl:bg-[100%_calc(80px)]
            bg-contain width-lim-xl:bg-cover
            bg-no-repeat
            transition-all duration-500
            `}
          ></div>
        }
        rightChild={
          <div
            className={`
            h-full
            flex items-center
            lg:!pl-[60px] width-lim:!pl-[104px]
            `}
          >
            <WebsiteCTASubSection
              minititle={"03"}
              title={"Invite Cooperative Officials"}
              text={
                <div>
                  Invite officials to your cooperative effortlessly,
                  facilitating collaboration and effective governance.
                </div>
              }
              ctaVariant={"primary"}
              ctaText={"Get Started"}
              ctaTo={"/auth/sign-up"}
              minititleClassName="text-pry-green-200"
              titleClassName=""
              textClassName="text-pry-dark-green-300/60"
              ctaClassName=""
            />
          </div>
        }
      />

      <div
        className={`
        bg-pry-dark-green-200
        py-[64px] md:py-[112px] width-lim:py-[120px]
        w-full
      `}
      >
        <div
          className="
            w-full
            max-w-full md:max-w-max-1024 width-lim:max-w-website-max width-lim-xl:max-w-website-max-xl
            mx-auto
            flex flex-col gap-[80px]
            "
        >
          <div
            className={`
            w-full
            flex flex-wrap items-center justify-between gap-8
            ${mobileTabPadding}
            `}
          >
            <div className="max-w-[689px]">
              <WebsiteSectionTitle
                minitext={"elevate your cooperative"}
                text={
                  "Join the growing community of licensed cooperatives that trust ThriftCorp"
                }
                minitextClassName={"text-pry-green-200"}
                textClassName={"text-white"}
              />
            </div>

            <div>
              <WebsiteButton
                text="Get started for free"
                to="/auth/sign-up"
                className="!text-pry-dark-green-400 !text-lg !px-[48px] !py-4"
                variation="primary-white"
              />
            </div>
          </div>

          <div className={`w-full md:hidden pl-6`}>
            <img src={mobileHero} alt="hero" className="w-full" />
          </div>

          <div className={`w-full ${mobileTabPadding} hidden md:flex`}>
            <img src={hero} alt="hero" className="w-full" />
          </div>
        </div>
      </div>
    </WebsiteLayout>
  );
};

import React from "react";
import { Icon } from "../icons/icon";

export const MembershipFormField = ({
  field = {},
  mode = "view", // OneOf: "edit" | "view"
  form = [],
  setForm = () => {},
  index,
  icon = "",
  className = "",
  barClassName = "",
  bodyClassName = "",
  iconWidth,
  iconHeight,
}) => {
  const getIcon = (fieldType) => {
    switch (fieldType?.toLocaleLowerCase()) {
      case "text":
        return "text-left-align";
      case "file":
        return "file-upload-icon";
      case "textarea":
        return "";
      case "checkbox":
        return "";
      case "radio":
        return "";
      case "select":
        return "";
      case "datetime":
        return "";
      case "date":
        return "";
      case "email":
        return "sms";
      default:
        return icon;
    }
  };
  return (
    <div
      className={`
    w-fit min-w-[140px] md:min-w-[168px]
    rounded-[8px]
    border border-pry-dark-green-300/10
    ${mode === "view" ? "p-3 bg-light-green-bg" : "p-2"}
    ${className}
    `}
    >
      {mode === "edit" && (
        <div className="flex flex-row gap-1 justify-between items-center">
          <div
            className={`
            w-full
            flex flex-row
            gap-1
            items-center
            text-[10px] text-white
            bg-pry-green-300
            px-[4px] py-[2px]
            rounded-[4px]
            ${barClassName}
            `}
          >
            <Icon
              svg={getIcon(field?.fieldType)}
              width={iconWidth ?? 12}
              height={iconHeight ?? 12}
            />
            {field?.fieldType}
          </div>
          <Icon
            svg="close-icon"
            width={20}
            height={20}
            className={`text-pry-green-300 cursor-pointer ${
              field?.isDefault && "opacity-10 !cursor-not-allowed"
            }`}
            onClick={() => {
              if (field?.isDefault) {
                return undefined;
              }
              let formBucket = JSON.parse(JSON.stringify(form));

              formBucket = formBucket
                .slice(0, index)
                .concat(formBucket.slice(index + 1));

              setForm(formBucket);
            }}
          />
        </div>
      )}
      <div
        className={`text-[12px] ${mode === "edit" && "text-pry-green-200 mt-2"}
        ${bodyClassName}
        `}
      >
        {field?.question}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as WelcomeGraphic } from "../../../assets/images/welcome_vector.svg";
import { ReactComponent as WelcomeGraphicLoading } from "../../../assets/images/welcome_vector_loading.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { FromThriftCorpSign } from "../../../components/typography/fromThriftCorpSign";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useLayout } from "../../../customHooks/useLayout";
import { isProfileApproved } from "../../../helperFunctions";

export const Overview = () => {
  const [greetingText, setGreetingText] = useState("");
  const [greetingEmoji, setGreetingEmoji] = useState("");
  const [bannerHover, setBannerHover] = useState(false);
  const [name, setName] = useState("");

  const { profile, onboardingCompletionStatus } = useSelector((state) => {
    return state.auth;
  });

  const { setPopupOpen } = useLayout();

  useEffect(() => {
    let today = new Date();
    let curHr = today.getHours();

    if (curHr < 12) {
      setGreetingText("morning");
      setGreetingEmoji("⛅️");
    } else if (curHr < 18) {
      setGreetingText("afternoon");
      setGreetingEmoji("🌤️");
    } else {
      setGreetingText("evening");
      setGreetingEmoji("🌙");
    }

    profile?.type === "member"
      ? setName(profile?.FirstName)
      : setName(profile?.CoopName);
  }, [profile]);

  return (
    <div className="w-full max-w-[1024px">
      {/* Greeting */}
      <PageTitle
        text={`Good ${greetingText}, ${name} ${greetingEmoji}`}
        className="capitalize"
      />

      {/* Large card */}
      <div
        className="
      w-full
      mt-6
      bg-white border border-border-grey
      rounded-lg
      flex
      overflow-hidden
      "
        onMouseEnter={() => setBannerHover(true)}
        onMouseLeave={() => setBannerHover(false)}
      >
        <div
          className="
        text-pry-dark-green-100
        text-[18px] lg:text-[32px]
        font-bold
        px-[20px] py-[32px] lg:px-8 lg:py-12 xl:pr-0
        leading-normal lg:leading-10
        xl:w-5/12
        "
        >
          {isProfileApproved(profile) ? (
            <>
              <div className="w-full">
                Complete your onboarding checklist to get the full ThriftCorp
                experience.
              </div>
              <Button
                text="View Checklist"
                onClick={() => {
                  !onboardingCompletionStatus?.isAllComplete &&
                    setPopupOpen(true);
                }}
                type="button"
                className="max-w-[150px] lg:max-w-[180px] mt-[24px] lg:mt-10 md:!py-3 !text-sm lg:!text-base"
                loading={false}
                disabled={false}
                variation="primary"
              />
            </>
          ) : (
            <div
              className="
              text:lg md:text-xl 
              leading-normal md:!leading-[28px]
              !font-medium
              "
            >
              <div
                className="
                bg-warning-yellow-bg-50
                text-orange-100
                w-fit
                px-5 py-3
                rounded-[120px]
                text-base md:text-xl 
              "
              >
                We are reviewing your account
              </div>

              <div
                className="
                !text-pry-dark-green-300/80 
                mt-[24px]
                "
              >
                Once approved, you’ll gain access to more features. We will
                notify you via email with the next steps. Thank you for your
                patience!
              </div>

              <FromThriftCorpSign className="!mt-8 md:!mt-[40px]" />
            </div>
          )}
        </div>
        <div className={`hidden xl:flex justify-end w-7/12`}>
          {isProfileApproved(profile) ? (
            <WelcomeGraphic
              className={`w-full max-w-[565px] h-full transition-all duration-[700ms] 
            text-transparent
            ${
              bannerHover &&
              `lg:-rotate-[3deg] lg:scale-[1.1] 
              welcome-graphic
            `
            }
            `}
            />
          ) : (
            <WelcomeGraphicLoading
              className={`
                w-full max-w-[565px] h-full transition-all duration-[700ms] 
                text-transparent
                ${
                  bannerHover &&
                  `lg:-rotate-[3deg] lg:scale-[1.05] 
                welcome-graphic
                `
                }
            `}
            />
          )}
        </div>
      </div>

      {/* Two other cards:  */}
      {isProfileApproved(profile) && (
        <div
          className="w-full
          mt-6
          flex flex-col xl:flex-row gap-6
          overflow-hidden
          text-sm lg:text-base
      "
        >
          <ContainerCard>
            <div className="font-medium">Hey {name}!</div>

            <div className="mt-4 text-pry-lighter-green-100">
              Make sure you complete your onboarding as soon as possible to
              ensure that you’re ready to start{" "}
              {profile?.type === "cooperative"
                ? " registering members to your cooperative "
                : " managing your cooperatives "}
              with ThriftCorp.
              <br />
              <br /> If you have any issues or concerns, please reach us via any
              of our support channels.
            </div>

            <FromThriftCorpSign />
          </ContainerCard>

          <ContainerCard></ContainerCard>
        </div>
      )}
    </div>
  );
};

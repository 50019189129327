import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import empty_state from "../../../assets/images/empty_state_cooperatives.svg";
import { Button } from "../../../components/buttons/button";
import { ContainerCard } from "../../../components/cards/containerCard";
import { InformationCard } from "../../../components/cards/infoCard";
import { NumberCard } from "../../../components/cards/numberCard";
import { ClickDropDown } from "../../../components/dropdown/clickDropdown";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { TabSelector } from "../../../components/inputs/tabSelector";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { FailedDisbursementsModal } from "../../../components/modals/app_modals/failedDisbursementsModal";
import { RequestLoanModal } from "../../../components/modals/app_modals/requestLoanModal";
import { EmptyState } from "../../../components/states/emptyState";
import { LoanHistoryTable } from "../../../components/table/app_tables/loanHistoryTable";
import { useLayout } from "../../../customHooks/useLayout";
import { useLoans } from "../../../customHooks/useLoans";
import { usePermissions } from "../../../customHooks/usePermissions";
import { decodeLoanStatus } from "../../../helperFunctions";
import { permissionList } from "../../../utils/constants";

const defaultQuery = (id) => {
  return {
    coop: id,
    logs: true,
    text: undefined,
    status: undefined,
    limit: 10,
    offset: 0,
  };
};

export const CooperativeLoans = ({ singleCooperative, mode }) => {
  const [query, setQuery] = useState(defaultQuery(singleCooperative?.id));
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const { hasPermissionsFor } = usePermissions();

  const {
    fetchLoanInformation,
    fetchMyLoanHistory,
    fetchCooperativeLoanInformation,
    fetchCooperativeLoanHistory,
    fetchAllLoanStatuses,
    fetchFailedDisbursementLoans,
  } = useLoans();

  const loanStatuses = useQuery(["loanStatuses", {}], fetchAllLoanStatuses, {
    enabled: true,
  });

  const loanInformation = useQuery(
    ["loanInformation", { coop: singleCooperative?.id, logs: "false" }],
    fetchLoanInformation,
    { enabled: false }
  );

  const cooperativeLoanInformation = useQuery(
    [
      "cooperativeLoanInformation",
      { coop: singleCooperative?.id, logs: "false" },
    ],
    fetchCooperativeLoanInformation,
    { enabled: false }
  );

  const loanHistory = useQuery(["loanHistory", query], fetchMyLoanHistory, {
    enabled: false,
  });

  const cooperativeLoanHistory = useQuery(
    ["cooperativeLoanHistory", query],
    fetchCooperativeLoanHistory,
    {
      enabled: false,
    }
  );

  const failedDisbursementLoans = useQuery(
    [
      "failedDisbursementLoans",
      { coop: singleCooperative?.id, logs: "true", limit: 10, offset: 0 },
    ],
    fetchFailedDisbursementLoans,
    { enabled: false }
  );

  useEffect(() => {
    mode === "personal" && loanInformation?.refetch();
    mode === "admin" && cooperativeLoanInformation?.refetch();
    mode === "admin" &&
      hasPermissionsFor(permissionList.CAN_ADD_PAYMENT) &&
      failedDisbursementLoans?.refetch();

    setQuery(defaultQuery(singleCooperative?.id));
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    mode === "personal" && loanHistory?.refetch();
    mode === "admin" && cooperativeLoanHistory?.refetch();
    // eslint-disable-next-line
  }, [mode, query]);

  const refreshPage = () => {
    if (mode === "personal") {
      loanInformation?.refetch();
      loanHistory?.refetch();
    } else {
      cooperativeLoanInformation?.refetch();
      cooperativeLoanHistory?.refetch();
    }
  };

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  const handleRequestLoanStart = () => {
    setModal("requestLoanModal");
    setModalOpen(true);
  };

  const onboardingCompletionStatus = useSelector((state) => {
    return state?.auth?.onboardingCompletionStatus;
  });

  const { setPopupOpen } = useLayout();

  return !onboardingCompletionStatus?.isAllComplete && mode === "personal" ? (
    <ContainerCard className="!py-14">
      <EmptyState
        title={`Complete your profile to access loans`}
        titleClassName="!text-base"
        image={<img src={empty_state} alt="empty" />}
        actionText={"Proceed"}
        onClick={() => {
          setPopupOpen(true);
        }}
        actionButtonVariant="secondary"
      />
    </ContainerCard>
  ) : (
    <div className="w-full">
      {/* Card information and action */}
      {(mode === "admin" && cooperativeLoanInformation?.isFetching) ||
      (mode === "personal" &&
        (loanInformation?.isFetching || loanInformation?.isRefetching)) ? (
        <PageLoader />
      ) : (
        <div className="w-full flex flex-row flex-wrap gap-4 items-center">
          <NumberCard
            title={mode === "personal" ? `Loan Balance` : "Total Loan Balance"}
            value={
              mode === "personal"
                ? loanInformation?.data?.summary
                : cooperativeLoanInformation?.data?.summary
            }
            rightChildren={
              mode === "personal" ? (
                <div className="w-full flex flex-col gap-[10px]">
                  <Button
                    text="Request Loan"
                    onClick={handleRequestLoanStart}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm"
                    loading={false}
                    disabled={false}
                    icon={"plus"}
                    variation="primary-white"
                  />
                </div>
              ) : (
                <div
                  className={`flex flex-col gap-1 ${
                    !hasPermissionsFor(permissionList.CAN_ADD_PAYMENT) &&
                    "!hidden"
                  }`}
                >
                  <Button
                    text="Book a new Loan"
                    onClick={() => {
                      handleModalOpen("");
                    }}
                    type="button"
                    className="md:!py-[12px] w-full md:!max-w-[180px] !text-sm !hidden"
                    loading={false}
                    disabled={false}
                    icon={"plus"}
                    variation="secondary-light-3"
                  />
                </div>
              )
            }
          />
        </div>
      )}

      {/* History  */}

      {!loanInformation?.isFetching &&
        !cooperativeLoanInformation?.isFetching && (
          <div className="">
            {mode === "admin" &&
              failedDisbursementLoans?.data?.totalCount > 0 && (
                <InformationCard
                  info={
                    <span>
                      <span className="text-info-purple font-bold">
                        {failedDisbursementLoans?.data?.totalCount} Loans
                      </span>{" "}
                      failed disbursement.
                    </span>
                  }
                  size="mini"
                  variant="purple"
                  className={"mt-6 !items-center"}
                  actionElement={
                    <Button
                      text="View all"
                      onClick={() => {
                        handleModalOpen("failedDisbursementsModal");
                      }}
                      type="button"
                      className="!py-[8px] !px-[35px] !text-xs !font-medium"
                      loading={false}
                      disabled={false}
                      icon={"refresh"}
                      variation="primary-purple"
                    />
                  }
                />
              )}

            <div
              className="
              my-[24px] text-[16px] 
              font-medium flex gap-4 
              flex-wrap
              items-center justify-between
              "
            >
              <div>
                {mode === "personal"
                  ? "Loan History"
                  : "Cooperative Loan History"}
              </div>

              <div className="flex items-center gap-2 z-10 w-full md:w-fit">
                <FilterComponent
                  searchPlaceholder="Search member first name or last name"
                  hasExtraFilter={false}
                  size="small"
                  mainClassName="!w-full md:!w-[286px]"
                  className="!w-full md:!w-[286px] !py-2"
                  handleSearch={(e) => {
                    setQuery({ ...query, text: e, offset: 0 });
                  }}
                />

                <div className="">
                  <ClickDropDown
                    icon={"filter-icon"}
                    iconHeight={16}
                    iconWidth={16}
                    text={"Filter"}
                    className="bg-white px-5 py-2 border rounded-[4px]"
                    position="left"
                    loading={loanStatuses?.isFetching}
                    dropDownClassName="!text-sm !min-w-max md:!min-w-[300px]"
                    customDropDown={
                      <div className="p-4">
                        <TabSelector
                          className={""}
                          navs={loanStatuses?.data?.map((loanStatus) => {
                            return {
                              title: decodeLoanStatus(loanStatus?.code)?.text,
                              to: loanStatus?.code,
                            };
                          })}
                          initialActiveTab={null}
                          onClick={(selections) => {
                            setQuery({
                              ...query,
                              status:
                                selections?.length > 0
                                  ? `["${selections?.join(`","`)}"]`
                                  : undefined,
                              offset: 0,
                            });
                          }}
                          initialActiveTabs={query?.status
                            // eslint-disable-next-line
                            ?.replace(/[\[\]'""]/g, "")
                            ?.split(",")}
                          multiSelect
                          showSelections={false}
                          orientation="vertical"
                          tabClassName=""
                          activeTabClassName=""
                          showCancelOnTab
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>

            {loanHistory?.isFetching || cooperativeLoanHistory?.isFetching ? (
              <div className="mt-[24px]">
                <PageLoader />
              </div>
            ) : (
              <div>
                {(mode === "admin" &&
                  !cooperativeLoanHistory?.data?.data?.length) ||
                (mode === "personal" && !loanHistory?.data?.data?.length) ? (
                  <ContainerCard className="!py-12">
                    <EmptyState
                      title={
                        mode === "admin"
                          ? "No loans found"
                          : "You don’t have any loans yet"
                      }
                      titleClassName="!text-base"
                      image={<img src={empty_state} alt="empty" />}
                      actionText={
                        mode === "personal" && "+ Request for my first loan"
                      }
                      onClick={handleRequestLoanStart}
                      actionButtonVariant="secondary"
                    />
                  </ContainerCard>
                ) : (
                  <div className="">
                    {mode === "personal" ? (
                      <LoanHistoryTable
                        data={loanHistory?.data}
                        query={query}
                        setQuery={setQuery}
                        singleCooperative={singleCooperative}
                        mode={mode}
                        refreshPage={refreshPage}
                      />
                    ) : (
                      <LoanHistoryTable
                        data={cooperativeLoanHistory?.data}
                        query={query}
                        setQuery={setQuery}
                        singleCooperative={singleCooperative}
                        mode={mode}
                        refreshPage={refreshPage}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

      <RequestLoanModal
        modalOpen={modalOpen && modal === "requestLoanModal"}
        onModalClose={handleModalClose}
        refresh={refreshPage}
        loanConfigs={singleCooperative?.Settings?.loan}
        singleCooperative={singleCooperative}
      />

      <FailedDisbursementsModal
        isModalOpen={modalOpen && modal === "failedDisbursementsModal"}
        onModalClose={handleModalClose}
        singleCooperative={singleCooperative}
      />
    </div>
  );
};

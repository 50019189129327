import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../icons/icon";

export const WebsiteNavbar = ({ className }) => {
  return (
    <div
      className={`w-full flex items-center justify-between gap-4 ${className}`}
    >
      <div className="text-white max-w-[140px] width-lim:max-w-[163px]">
        <Icon svg={"tc-logo"} width={163} height={28} />
      </div>

      <div className="text-base flex items-center justify-center gap-5">
        <Link to={"/auth/sign-in"} className="text-white hidden md:flex">
          Sign In
        </Link>
        <div
          className="
            items-center justify-center gap-1.5 
            font-medium
            text-pry-dark-green-400
            px-5 py-2
            border border-white
            bg-white
            rounded-[40px]
            cursor-pointer
            hover:scale-[0.98]
            hidden md:flex
            transition-all duration-200
            "
        >
          <Link to={"/auth/sign-up"}>Get started</Link>
          <Icon
            svg={"left-arrow"}
            width={21}
            height={20}
            className={"rotate-180"}
          />
        </div>
        <Icon
          svg={"hamburger-icon"}
          width={24}
          height={24}
          className={"md:hidden !text-white"}
        />
      </div>
    </div>
  );
};

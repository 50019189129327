import React, { useEffect, useState } from "react";
import { TextLink } from "../buttons/textLink";
import { OtpMaxTime } from "../../utils/constants";

export const OtpCountdownTImer = ({ onClick = () => {}, text = "Resend" }) => {
  const [countValue, setCountValue] = useState(OtpMaxTime);

  useEffect(() => {
    const myTimeout =
      countValue > 0 &&
      setTimeout(() => {
        setCountValue(countValue - 1);
      }, 1000);

    return () => {
      clearTimeout(myTimeout);
    };
  });

  return (
    <span>
      <TextLink
        to=""
        text={text}
        className="!text-xs"
        disabled={countValue > 0}
        onClick={() => {
          onClick();
          setCountValue(OtpMaxTime);
        }}
      />
      {countValue > 0
        ? ` in ${countValue} ${countValue > 1 ? "secs" : "sec"}`
        : ""}
    </span>
  );
};

import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "..";
import { usePayment } from "../../../customHooks/usePayment";
import {
  decodePaymentAction,
  numberToString,
  stringToNumber,
} from "../../../helperFunctions";
import {
  addSavingsSchema,
  addSavingsSchemaStep2,
} from "../../../validationSchema";
import { InformationCard } from "../../cards/infoCard";
import { Icon } from "../../icons/icon";
import { TextInput } from "../../inputs/textInput";
import { PageLoader } from "../../loaders/pageLoader";
import { PaymentMethods } from "../../paymentMethods";

export const AddProductModal = ({
  isModalOpen,
  onModalClose = () => {},
  successCallback = () => {},
  singleCooperative,
  loanInfo = {},
  type = "savings",
  cooperative = {},
  callback = () => {} /**for when the modal closes either from success or failure */,
}) => {
  const [step, setStep] = useState(1);
  const [repaymentType, setRepaymentType] = useState(null);

  const { getPaymentLink } = usePayment();
  const profile = useSelector((state) => {
    return state?.auth?.profile;
  });

  const getValidationSchema = () => {
    if (type !== "loan_repayment" && step === 1) {
      return addSavingsSchema;
    } else if (type !== "loan_repayment" && step === 2) {
      return addSavingsSchemaStep2;
    } else if (
      type === "loan_repayment" &&
      repaymentType === "custom" &&
      step === 1
    ) {
      return addSavingsSchema;
    } else if (
      type === "loan_repayment" &&
      repaymentType === "custom" &&
      step === 2
    ) {
      return addSavingsSchemaStep2;
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isValid,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      amount: "",
      paymentMethod: "none",
    },
    validationSchema: getValidationSchema(),
    onSubmit: (data, { setSubmitting, resetForm }) => {
      if (step === 1) {
        setStep(step + 1);
        setSubmitting(false);
      } else {
        data?.paymentMethod === "paystack" &&
          getPaymentLink(
            {
              callbackurl: `${window.location.protocol}//${
                window.location.hostname
              }/app/success?coopName=${
                singleCooperative?.CoopName
              }&amount=${data?.amount?.replace(
                /,/g,
                ""
              )}&mode=default&message=Please_wait_while_we_process_your_payment..._🤗`,
              coop: singleCooperative?.id, //cooperative id
              type: decodePaymentAction(type)?.action,
              repaymenttype: type === "loan_repayment" && repaymentType, //only applicable to loans [full,monthly,custom]
              loanid: type === "loan_repayment" && loanInfo?.id, //only applicable to loans
              amount:
                data?.amount && stringToNumber(data?.amount?.replace(/,/g, "")),
              unit: type === "shares" && data?.amount?.replace(/,/g, ""), //only applicable to shares
              email: profile?.email, //user email
              id: singleCooperative?.id, //cooperative id
            },
            () => {
              setSubmitting(false);
            },
            (data) => {
              setSubmitting(false);
              successCallback(data?.data);
              setStep(1);
              resetForm();
            }
          );
      }
    },
  });

  const repaymentTypes = [
    {
      title: "Full Repayment",
      amount: "200000",
      value: "full",
    },
    {
      title: "Monthly Repayment",
      amount: "200000",
      value: "monthly",
    },
    {
      title: "Partial Repayment",
      amount: null,
      value: "custom",
    },
  ];

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        setStep(1);
        resetForm();
        callback();
      }}
      title={step === 1 ? decodePaymentAction(type)?.title : "Payment Methods"}
      topTitle
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons={step === 1}
      proceedButtonText="Proceed"
      onProceedClick={() => {
        handleSubmit();
      }}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={isSubmitting}
      isProceedDisabled={
        isSubmitting ||
        (type !== "loan_repayment" && !isValid) ||
        (type === "loan_repayment" && step === 1 && !repaymentType) ||
        (type === "loan_repayment" &&
          step === 1 &&
          repaymentType === "custom" &&
          !values?.amount) ||
        (type === "loan_repayment" &&
          step === 2 &&
          (!repaymentType || !values?.amount))
      }
    >
      {step === 1 && (
        <div>
          {/* Information card for shares purchase  */}
          {decodePaymentAction(type)?.action === "share" && (
            <InformationCard
              info={`1 share unit = ₦${Number(
                cooperative?.Settings?.share?.InitialSharePrice
              )?.toLocaleString()}`}
              className={"mb-4"}
              variant="purple"
              size="mini"
            />
          )}

          {/* Main body  */}

          {type === "loan_repayment" && (
            <div>
              <div className="text-base mb-4">
                Choose your repayment option:
              </div>
              <div className="flex flex-col gap-4">
                {repaymentTypes?.map((item, idx) => {
                  const key = `repaymentOption-${item?.value}-${idx}`;
                  return (
                    <div
                      className="flex items-center justify-between"
                      key={key}
                      onClick={() => {
                        setRepaymentType(item?.value);
                      }}
                    >
                      <div className="flex gap-2 items-center cursor-pointer">
                        <Icon
                          svg={
                            repaymentType === item?.value
                              ? "checkbox-active"
                              : "checkbox-inactive"
                          }
                          width={24}
                          height={24}
                          className={`transition-all duration-200 ${
                            repaymentType === item?.value &&
                            "text-pry-green-200"
                          }`}
                        />

                        <div className="font-medium">{item?.title}</div>
                      </div>

                      <div className="font-bold text-pry-green-200 text-xs hidden">
                        {item?.amount && `₦${numberToString(item?.amount)}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {(type !== "loan_repayment" ||
            (type === "loan_repayment" && repaymentType === "custom")) && (
            <TextInput
              type="text"
              name="amount"
              value={
                values?.amount?.replace(/,/g, "") !== ""
                  ? Number(values?.amount?.replace(/,/g, ""))?.toLocaleString()
                  : ""
              }
              placeholder={decodePaymentAction(type)?.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              autoComplete="off"
              errors={touched?.amount && errors?.amount && errors?.amount}
              className="!mt-4"
            />
          )}
        </div>
      )}

      {step === 2 && (
        <div>
          {isSubmitting ? (
            <div className="mt-12">
              <PageLoader />
            </div>
          ) : (
            <PaymentMethods
              setPaymentMethod={(val) => {
                setFieldValue("paymentMethod", val);
              }}
              handleProceed={() => {
                handleSubmit();
              }}
            />
          )}
        </div>
      )}
    </ModalComponent>
  );
};

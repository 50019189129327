import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoans } from "../../customHooks/useLoans";
import { getDateDifference } from "../../helperFunctions";
import { Button } from "../buttons/button";
import { ClickDropDown } from "../dropdown/clickDropdown";
import { Icon } from "../icons/icon";
import { ProfilePicture } from "../profile/profilePicture";

const NotificationsHeader = ({ data }) => {
  return (
    <div className="text-sm flex items-center gap-1 py-3 md:py-4 font-medium">
      <Icon svg={"notification-bell"} width={20} height={20} />
      <div className="text-sm font-bold">
        Notifications ({data?.data?.length ?? 0})
      </div>
    </div>
  );
};

export const ProfileNotificationComponent = () => {
  const navigate = useNavigate();

  const { fetchLoanGuarantorRequests } = useLoans();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const guarantorRequests = useQuery(
    ["guarantorRequests", { detail: true }],
    fetchLoanGuarantorRequests,
    {
      enabled: profile?.type === "member" ? true : false,
    }
  );

  return (
    <div className="flex items-center gap-3">
      {/* Notification and profile */}
      <div
        onClick={() => {
          navigate("settings");
        }}
      >
        <ProfilePicture />
      </div>

      <div
        className="
        relative
        border border-light-grey
        p-[6px] rounded-full
        cursor-pointer
        transition-all
      "
      >
        <ClickDropDown
          icon="notification-bell"
          iconHeight={20}
          iconWidth={20}
          className=""
          position="right"
          mobilePosition="left"
          loading={guarantorRequests?.isLoading}
          onDropdownOpen={() => {
            guarantorRequests?.refetch();
          }}
          options={
            !guarantorRequests?.data?.length
              ? [
                  {
                    title: <NotificationsHeader data={guarantorRequests} />,
                    onClick: () => {},
                    isClickable: false,
                    className: "!py-0",
                  },
                  {
                    title: (
                      <div className="text-sm text-center py-6 font-medium">
                        You don't have any new notifications
                      </div>
                    ),
                    onClick: () => {},
                    isClickable: false,
                  },
                ]
              : [
                  {
                    title: <NotificationsHeader data={guarantorRequests} />,
                    onClick: () => {},
                    isClickable: false,
                    className: "!py-0",
                  },
                  ...guarantorRequests?.data?.map((item, idx) => {
                    const key = `notification-item-${idx}`;

                    return {
                      title: (
                        <div
                          className="px-[4px] py-3 md:py-4 flex flex-col gap-[10px]"
                          key={key}
                        >
                          <div className="flex gap-4 justify-between text-sm md:!text-sm">
                            <div className="opacity-80 font-medium">{`${item?.MembershipLoanRequestID?.MemberID?.User?.FirstName} ${item?.MembershipLoanRequestID?.MemberID?.User?.LastName} has requested you to stand as guarantor for a loan`}</div>
                            <div className="min-w-max opacity-50">
                              {`${getDateDifference(
                                item?.DateAdded,
                                new Date()
                              )} ago`}
                            </div>
                          </div>

                          <div>
                            <Button
                              text={"View loan"}
                              onClick={() => {}}
                              type="button"
                              className="!text-xs !px-4 !py-2 !max-w-max"
                            />
                          </div>
                        </div>
                      ),
                      onClick: () => {
                        navigate("invitations/loan/guarantee");
                      },
                      isClickable: true,
                      className: "!py-0",
                    };
                  }),
                ]
          }
          dropDownClassName="w-[320px] md:w-[480px] max-h-[424px] overflow-y-scroll shadow-sm rounded-[8px] md:rounded-[16px]"
        />

        <div
          className={`
        absolute top-0 -right-1
        w-[12px] h-[12px]
        bg-red-200 
        text-white text-xs font-medium text-center
        rounded-full
        ${!guarantorRequests?.data?.length && "!hidden"}
        `}
        ></div>
      </div>
    </div>
  );
};

import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../icons/icon";

export const WebsiteAccordionTitle = ({ number, title }) => {
  return (
    <div className="flex gap-4 items-center justify-between">
      <div className="flex items-baseline justify-center gap-6 font-medium ">
        <div className="text-purple-200 text-sm">{number}</div>
        <div className="text-xl lg:text-2xl">{title}</div>
      </div>
      <div>
        <Icon
          svg="left-arrow"
          width={20}
          height={21}
          className={"rotate-180 text-purple-200"}
        />
      </div>
    </div>
  );
};

WebsiteAccordionTitle.propTypes = {
  number: PropTypes.any,
  title: PropTypes.any,
};

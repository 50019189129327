import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "../icons/icon";
import { MemberProfilePic } from "../profile/memberPicture";
import { YouCard } from "./youCard";

export const MemberCard = ({
  memberDetails,
  mode,
  onRemoveClick = () => {},
  onClick = () => {},
}) => {
  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  return (
    <div
      className="
        bg-white border w-full
        rounded-[8px]
        px-[24px] py-[8px]
        flex flex-row
        gap-8
        items-center justify-between
        transition-all duration-300
        group-[.name]:scale-[0.98]
        "
    >
      <div
        className="flex flex-row gap-[11px] items-center group cursor-pointer"
        onClick={() => {
          onClick(memberDetails);
        }}
      >
        <MemberProfilePic
          member={memberDetails}
          className="!w-[32px] !h-[32px] !text-xs !font-medium"
        />

        <div className="text-xs font-medium group-hover:text-pry-green-200 ">
          {memberDetails?.FirstName} {memberDetails?.LastName}
        </div>

        {mode === "admin" && (
          <div className="text-xs font-medium opacity-50">
            {memberDetails?.Email}
          </div>
        )}

        {profile?.Email === memberDetails?.Email && <YouCard />}
      </div>

      <div>
        {mode === "personal" ? (
          <div className="text-xs font-medium opacity-50">
            {/* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */}
            {/* {memberDetails?.Email} */}
            {/* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ Disabled feature ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */}
          </div>
        ) : (
          <div
            className={`
            flex flex-row gap-2 
            items-center cursor-pointer
            hover:text-red-200
            transition-all
            ${profile?.Email === memberDetails?.Email && "!hidden"}
            `}
            onClick={() => {
              onRemoveClick(memberDetails);
            }}
          >
            <Icon svg="remove-profile-icon" width={16} height={16} />
            <div className="text-xs font-medium">Remove Member</div>
          </div>
        )}
      </div>
    </div>
  );
};

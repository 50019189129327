import React, { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Icon } from "../icons/icon";
import { PageLoader } from "../loaders/pageLoader";

export const ClickDropDown = ({
  icon = "ellipses",
  text = "",
  iconWidth = 18,
  iconHeight = 4,
  className = "",
  position = "left",
  mobilePosition = "left",
  options = [], // {"title": "", "onClick": () => {}, isClickable: true}
  dropDownClassName = "",
  loading = false,
  onDropdownOpen = () => {},
  customDropDown,
}) => {
  const [visible, setVisible] = useState(false);

  mobilePosition = mobilePosition ? mobilePosition : position;

  useEffect(() => {
    visible && onDropdownOpen();
    // eslint-disable-next-line
  }, [visible]);

  return (
    <div
      className={`
        relative
        hide-scroll-bar
        ${className}
        `}
    >
      <div
        onClick={() => {
          setVisible(!visible);
        }}
        className="
        cursor-pointer
        flex gap-1 items-center
        "
      >
        <Icon svg={icon} width={iconWidth} height={iconHeight} />
        {text && <div className="font-medium text-xs">{text}</div>}
      </div>
      {visible && (
        <ClickAwayListener
          onClickAway={() => {
            setVisible(false);
          }}
        >
          <div
            className={`
                absolute top-[calc(100%+20px)]
                ani-drop
                bg-white
                border border-pry-dark-green-300/[15%]
                rounded-[8px]
                z-100
                overflow-hidden
                hide-scroll-bar
                ${position === "left" && "md:right-0"}
                ${position === "right" && "md:left-0"}
                ${mobilePosition === "left" && "right-0"}
                ${mobilePosition === "right" && "left-0"}
                ${dropDownClassName}
                `}
          >
            {!customDropDown &&
              !loading &&
              options?.map((option, idx) => {
                const key = `dropdownKey-${option?.title}-${idx}`;
                return (
                  option && (
                    <div
                      key={key}
                      onClick={() => {
                        option?.onClick();
                        option?.isClickable && setVisible(false);
                      }}
                      className={`
                    border-b last:border-b-0
                    px-4 py-3
                    ${
                      option?.isClickable &&
                      `hover:bg-light-green-bg-50
                        cursor-pointer`
                    }
                    transition-all duration-500
                    ${option?.className}
                `}
                    >
                      {option?.title}
                    </div>
                  )
                );
              })}

            {!loading && customDropDown && customDropDown}

            {loading && <PageLoader variant="spinner" width={20} height={20} />}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

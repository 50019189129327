import React from "react";
import { WebsiteButton } from "../buttons/websiteButton";

export const WebsiteCTASubSection = ({
  minititle,
  title,
  text,
  ctaVariant,
  ctaText,
  ctaTo,
  minititleClassName = "",
  titleClassName = "",
  textClassName = "",
  ctaClassName = "",
}) => {
  return (
    <div className="flex flex-col gap-6 lg:gap-8">
      <div
        className={`font-medium tracking-[2px] text-sm ${minititleClassName}`}
      >
        {minititle}
      </div>

      <div
        className={`mt-2 lg:mt-0 text-[32px] lg:text-4xl tracking-[-1px] leading-[44px] ${titleClassName}`}
      >
        {title}
      </div>

      <div className={`text-base lg:text-xl ${textClassName}`}>{text}</div>

      <WebsiteButton
        text={ctaText}
        variation={ctaVariant}
        to={ctaTo}
        className={`!px-6 !max-w-fit !py-[12px] lg:!py-[14px] ${ctaClassName}`}
      />
    </div>
  );
};

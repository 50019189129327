import React from "react";
import { mobileTabPadding } from "../../utils/constants";
import { Icon } from "../icons/icon";

export const WebsiteFooter = () => {
  return (
    <div
      className={`
      bg-pry-dark-green-200
      ${mobileTabPadding}
      py-[56px]
        `}
    >
      <div
        className="
        max-w-full md:max-w-max-1024 width-lim:max-w-website-max width-lim-xl:max-w-website-max-xl
        mx-auto
        text-white text-base
        "
      >
        <Icon svg={"tc-logo"} width={163} height={28} />

        <div
          className="
          mt-[56px] md:mt-[48px]
          flex flex-col lg:flex-row flex-wrap gap-4
          lg:items-center justify-between
        "
        >
          <div className="order-3 lg:order-1">
            © 2024 Thriftcorp. All rights reserved
          </div>
          <div className="order-2">Contact us: contact@thriftcorp.co</div>
          <div className="order-1 lg:order-3 flex flex-row items-center gap-2">
            <div>
              <Icon svg={"facebook-icon"} width={20} height={20} />
            </div>
            <div>
              <Icon svg={"twitter-icon"} width={20} height={20} />
            </div>
            <div>
              <Icon svg={"instagram-icon"} width={20} height={20} />
            </div>
            <div>
              <Icon svg={"linkedin-icon"} width={20} height={20} />
            </div>
            <div>@thriftcorp</div>
          </div>
        </div>
      </div>
    </div>
  );
};

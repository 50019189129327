import React from "react";
import { numberToString } from "../../helperFunctions";
import { Icon } from "../icons/icon";

export const NumberCard = ({
  title,
  value,
  leftChildrenExtra,
  rightChildren,
  className = "",
  iconClassName = "",
  titleClassName = "",
  valueClassName = "",
  icon = "coins",
}) => {
  return (
    <div
      className={`
        rounded-lg 
        border border-bg-pry-dark-green-300/10 
        bg-pry-green-200
        px-6 py-4
        text-white
        min-h-[120px]
        flex flex-row flex-wrap gap-4 md:gap-0
        items-center justify-between
        w-full max-w-[604px]
        ${className}
        `}
    >
      <div>
        <div className="flex flex-row flex-wrap gap-4 items-center">
          <div
            className={`p-1 bg-white w-fit rounded-[4px] text-pry-green-200 ${iconClassName}`}
          >
            <Icon svg={icon} width={24} height={24} className="" />
          </div>

          <div>
            <div className={`text-sm md:text-sm font-medium ${titleClassName}`}>
              {title}
            </div>
            <div
              className={`mt-1 text-xl md:text-2xl font-bold ${valueClassName}`}
            >
              ₦{numberToString(value ?? 0)}
            </div>
          </div>
        </div>

        {leftChildrenExtra && <div className="mt-2">{leftChildrenExtra}</div>}
      </div>
      <div className="w-full md:max-w-[180px]">{rightChildren}</div>
    </div>
  );
};

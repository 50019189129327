import PropTypes from "prop-types";
import React from "react";

export const WebsiteAccordion = ({ title, content, isOpen }) => {
  return (
    <div className="flex flex-col gap-6">
      <div>{title}</div>
      {isOpen && (
        <div className="text-lg text-pry-dark-green-300/70">{content}</div>
      )}
    </div>
  );
};

WebsiteAccordion.propTypes = {
  content: PropTypes.any,
  isOpen: PropTypes.any,
  title: PropTypes.any,
};

import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToastColor } from "./helperFunctions/nofitication";
import { store } from "./redux/store";
import { AppRoutes } from "./routes/routes";

function App() {
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]");

    if (el !== null) {
      let content = el.getAttribute("content");
      // eslint-disable-next-line
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }

      el.setAttribute("content", content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }

  return (
    <div>
      <ErrorBoundary
        FallbackComponent={({ error, resetErrorBoundary }) => {
          resetErrorBoundary();
          return <div>{error.message}</div>;
        }}
      >
        <Provider store={store}>
          <BrowserRouter>
            <AppRoutes />
            <ToastContainer
              className="!w-full !max-w-[360px] md:!max-w-[360px] !p-2 md:!p-0"
              toastClassName={({ type }) => ` 
            !flex !flex-row justify-between !gap-2
            !p-[16px] md:!p-[16px]
            !mb-2
            !rounded-[8px]
            border
            !min-h-[60px]
            ${getToastColor(type)}
            transition-all
            `}
              bodyClassName={`
            !w-full
            !flex !flex-row 
            !gap-8 
            !items-start
            `}
              closeButton={true}
              transition={Slide}
              rtl
            />
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;

import React from "react";

export const TagCard = ({ text, type, className }) => {
  return (
    <div
      className={`
            px-3 py-1 rounded-[4px]
            text-xs font-medium
            w-fit
            ${type === "success" && "text-pry-green-300 bg-light-green-bg-500"}
            ${type === "danger" && "text-red-200 bg-light-red-bg-50"}
            ${type === "info" && "text-info-purple bg-light-purple/50"}
            ${
              type === "pending" &&
              "text-warning-yellow bg-warning-yellow-bg-50"
            }
      ${className}
        `}
    >
      {text}
    </div>
  );
};

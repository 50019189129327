import React, { useState } from "react";
import { ModalComponent } from "..";
import { useMembership } from "../../../customHooks/useMembership";
import { InformationCard } from "../../cards/infoCard";
import { FileUploadInput } from "../../inputs/fileUploadInput";

export const UploadMembersModal = ({
  isModalOpen,
  onModalClose,
  singleCooperative,
  successCallback = () => {},
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const { downloadMembershipTemplate, doBulkMembershipupload } =
    useMembership();

  const handleSubmit = () => {
    setSubmitting(true);

    const formData = new FormData();

    formData.append("form", singleCooperative?.formdetail?.form?.id);
    formData.append("corp", singleCooperative?.id);
    formData.append("coop", singleCooperative?.id);
    formData.append("file", selectedFiles[0]);

    doBulkMembershipupload(
      formData,
      () => {
        setSubmitting(false);
      },
      () => {
        successCallback();
        setSubmitting(false);
        setSelectedFiles([]);
        onModalClose();
      }
    );
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        setSelectedFiles([]);
      }}
      title="Add members in bulk"
      subTitle={
        <InformationCard
          info={
            <div>
              <span
                className="underline text-info-purple font-medium cursor-pointer"
                onClick={() => {
                  downloadMembershipTemplate(
                    {
                      coop: singleCooperative?.id,
                      file: "csv",
                    },
                    `${singleCooperative?.CoopName}_membership_template`
                  );
                }}
              >
                Download
              </span>{" "}
              this excel file that corresponds with your cooperative’s
              membership form. If you haven’t created a membership form, kindly
              login to your cooperative’s Thriftcorp account and do so before
              proceeding.
            </div>
          }
          variant="purple"
          size="mini"
          className="w-full"
        />
      }
      subtitleContainerClassName="!max-w-full"
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons
      proceedButtonText="Proceed"
      onProceedClick={handleSubmit}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={submitting}
      isProceedDisabled={selectedFiles?.length < 1 || submitting}
    >
      <FileUploadInput
        handleChange={(data) => {
          setSelectedFiles(Object.values(data));
        }}
        selectedFiles={selectedFiles}
        setSelectedFiles={(data) => {
          setSelectedFiles(data);
        }}
        types="XLS, XLSX, CSV"
        accept=".xls,.xlsx,.csv"
      />
    </ModalComponent>
  );
};

import { useSelector } from "react-redux";

export const usePermissions = () => {
  const { myCooperativePermissions } = useSelector((state) => {
    return state.app;
  });

  const hasPermissionsFor = (permission) => {
    return myCooperativePermissions?.includes(permission) ?? false;
  };

  return { hasPermissionsFor };
};

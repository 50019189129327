import React from "react";
import { ModalComponent } from "..";
import { EmptyState } from "../../states/emptyState";

export const ImageViewModal = ({ modalOpen, onModalClose = () => {}, src }) => {
  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(extension)) {
      return "image";
    }
    if (["pdf"].includes(extension)) {
      return "pdf";
    }
    return "unknown";
  };

  const fileType = getFileType(src);

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={onModalClose}
      title={undefined}
      subTitle={undefined}
      childrenClassName="!mt-0"
      childrenContainerClassName="absolute inset-0 !mt-0 -z-[1]"
      modalClassName="!max-h-[90%] md:!max-w-[703px]"
      hasButtons={true}
      proceedButtonText="Full view"
      proceedButtonClassName="!max-w-[120px] !rounded-full"
      proceedButtonIcon={"expand-photo"}
      onProceedClick={() => {
        window.open(src, "_blank");
      }}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisable={false}
      buttonsContainerClassName="!justify-center"
    >
      <div className="w-full h-full relative flex items-center justify-center">
        {fileType === "image" && (
          <img
            src={src}
            className="!w-full !h-full overflow-scroll"
            alt="doc view"
          />
        )}
        {fileType === "pdf" && (
          <iframe
            src={src}
            title="PDF Viewer"
            style={{ width: "100%", height: "1000px" }}
          />
        )}
        {fileType === "unknown" && (
          <EmptyState
            title={"Nothing to see here"}
            subtitle={
              "Ooops! This could be due to an unsupported file type, or broken link."
            }
            className={"mt-32"}
          />
        )}
      </div>
    </ModalComponent>
  );
};

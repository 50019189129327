import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/buttons/backButton";
import { OnboardingLayout } from "../../../components/layouts/onboardingLayout";
import { ForgotPasswordCreate } from "./forgotPasswordCreateForm";
import { ForgotPasswordDetails } from "./forgotPasswordDetailsForm";

export const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("passwordResetEmail")) {
      setStep(() => 2);
    }
  }, []);

  return (
    <OnboardingLayout>
      <div className="w-full max-w-[327px]">
        {sessionStorage.getItem("user", "") !== "exists" && (
          <BackButton
            text={
              sessionStorage.getItem("passwordResetEmail") && step > 1
                ? "Forgot Password"
                : "Back"
            }
            onClick={() => {
              step > 1 ? setStep(step - 1) : navigate("/auth/sign-in");
            }}
          />
        )}
        {step === 1 && <ForgotPasswordDetails setStep={setStep} />}
        {step === 2 && <ForgotPasswordCreate setStep={setStep} />}
      </div>
    </OnboardingLayout>
  );
};

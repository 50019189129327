import { useFormik } from "formik";
import React, { useState } from "react";
import { ModalComponent } from "..";
import success from "../../../assets/images/success.svg";
import { useLoans } from "../../../customHooks/useLoans";
import {
  addLoanProductSchema,
  extraLoanProductConfigurationSchema,
} from "../../../validationSchema";
import { BackButton } from "../../buttons/backButton";
import { Icon } from "../../icons/icon";
import { Checkbox } from "../../inputs/checkbox";
import { TextInput } from "../../inputs/textInput";
import { ProgressTrail } from "../../progress/progressTrail";
import { EmptyState } from "../../states/emptyState";

// ______-------------_________-----LOAN INFORMATION----________------------------
const LoanInformation = ({ form }) => {
  return (
    <div className="w-full flex flex-col gap-4 hide-scroll-bar">
      <TextInput
        type="text"
        name={"loanName"}
        value={form?.values?.loanName ?? ""}
        placeholder="Enter loan name"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.loanName &&
          form?.touched?.loanName &&
          form?.errors?.loanName
        }
        inputClass=""
        className=""
        hasIcon={false}
        hasTitle
        title="Loan Name"
      />

      <TextInput
        type="textarea"
        name={"loanDescription"}
        value={form?.values?.loanDescription ?? ""}
        placeholder="Write something brief about the loan"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        hasIcon={false}
        errors={
          form?.errors?.loanDescription &&
          form?.touched?.loanDescription &&
          form?.errors?.loanDescription
        }
        inputClass=""
        className=""
        customIconPosition="left"
        hasTitle
        title="Loan Description"
        rows="3"
      />

      <TextInput
        type="text"
        name={"maxGuarantor"}
        value={form?.values?.maxGuarantor ?? ""}
        placeholder="Enter maximum number of guarantors"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.maxGuarantor &&
          form?.touched?.maxGuarantor &&
          form?.errors?.maxGuarantor
        }
        inputClass=""
        className=""
        customIconPosition="right"
        hasTitle
        hasIcon={false}
        title="Maximum Number of Guarantors"
      />

      <TextInput
        type="text"
        name={"minMonthJoined"}
        value={form?.values?.minMonthJoined ?? ""}
        placeholder="Enter number of months"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.minMonthJoined &&
          form?.touched?.minMonthJoined &&
          form?.errors?.minMonthJoined
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  "
          >
            Months
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Minimum Membership Months"
      />

      <TextInput
        type="text"
        name={"maxTenure"}
        value={form?.values?.maxTenure ?? ""}
        placeholder="Enter number of months"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.maxTenure &&
          form?.touched?.maxTenure &&
          form?.errors?.maxTenure
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  "
          >
            Months
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Maximum Loan Tenor"
      />
    </div>
  );
};

// ______-------------_________-----MORE CONFIGURATION----________------------------
const MoreConfiguration = ({ form }) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <TextInput
        type="text"
        name={"minLoanAmount"}
        value={
          form?.values?.minLoanAmount?.replace(/,/g, "") !== ""
            ? Number(
                form?.values?.minLoanAmount?.replace(/,/g, "")
              )?.toLocaleString()
            : ""
        }
        placeholder="Enter amount"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.minLoanAmount &&
          form?.touched?.minLoanAmount &&
          form?.errors?.minLoanAmount
        }
        inputClass=""
        className=""
        customIcon={
          <div>
            <Icon svg={"naira"} width={20} height={20} />
          </div>
        }
        customIconPosition="left"
        hasTitle
        title="Minimum Loan Amount"
      />
      <TextInput
        type="text"
        name={"maxXAmount"}
        value={form?.values?.maxXAmount ?? ""}
        placeholder="Enter multiplier"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.maxXAmount &&
          form?.touched?.maxXAmount &&
          form?.errors?.maxXAmount
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  !w-[160px] text-center
                  "
          >
            x of savings
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Maximum Loan Amount"
      />
      <TextInput
        type="text"
        name={"maxInterestRate"}
        value={form?.values?.maxInterestRate ?? ""}
        placeholder="Enter rate"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.maxInterestRate &&
          form?.touched?.maxInterestRate &&
          form?.errors?.maxInterestRate
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  "
          >
            %
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Interest Rate (%)"
      />
      <TextInput
        type="text"
        name={"penaltyFee"}
        value={form?.values?.penaltyFee ?? ""}
        placeholder="Enter fee"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.penaltyFee &&
          form?.touched?.penaltyFee &&
          form?.errors?.penaltyFee
        }
        inputClass=""
        className="!p-0 !pl-4"
        customIcon={
          <div
            className="
                  font-medium text-sm md:text-sm
                  py-[14px] px-[16px]
                  bg-white
                  border border-pry-dark-green-300/10
                  rounded-e-lg
                  "
          >
            %
          </div>
        }
        customIconPosition="right"
        hasTitle
        title="Penalty Fee (%)"
      />
      <TextInput
        type="text"
        name={"loanApplicationFee"}
        value={
          form?.values?.loanApplicationFee?.replace(/,/g, "") !== ""
            ? Number(
                form?.values?.loanApplicationFee?.replace(/,/g, "")
              )?.toLocaleString()
            : ""
        }
        placeholder="Enter amount"
        onBlur={form?.handleBlur}
        onChange={form?.handleChange}
        errors={
          form?.errors?.loanApplicationFee &&
          form?.touched?.loanApplicationFee &&
          form?.errors?.loanApplicationFee
        }
        inputClass=""
        className=""
        customIcon={
          <div>
            <Icon svg={"naira"} width={20} height={20} />
          </div>
        }
        customIconPosition="left"
        hasTitle
        title="Loan processing fee"
      />

      <div className="flex gap-2 items-center">
        <Checkbox
          initialChecked={form.values.multipleRunningLoan}
          onCheck={() => {
            form.setFieldValue("multipleRunningLoan", true);
          }}
          onUnCheck={() => {
            form.setFieldValue("multipleRunningLoan", false);
          }}
        />
        Allow members have multiple running loans?
      </div>
    </div>
  );
};

// ______-------------_________-----MAIN MODAL----________------------------

export const AddLoanProductModal = ({
  modalOpen,
  onModalClose = () => {},
  cooperativeConfiguration,
  mode = "create",
  selectedProduct,
  refetch = () => {},
}) => {
  const { addLoanProduct, editLoanProduct } = useLoans();

  const [showSuccess, setShowSuccess] = useState();
  const [step, setStep] = useState(1);
  const [finalPayload, setFinalPayload] = useState({});

  const steps = ["Loan Information", "More Configuration"];

  const loanInformationForm = useFormik({
    initialValues: {
      loanName: mode === "edit" ? selectedProduct?.LoanName : "",
      loanDescription: mode === "edit" ? selectedProduct?.LoanDescription : "",
      maxGuarantor: mode === "edit" ? selectedProduct?.MaxGuarantor : 0,
      minMonthJoined: mode === "edit" ? selectedProduct?.MinMonthJoined : 6,
      maxTenure: mode === "edit" ? selectedProduct?.MaxTenure : 6,
    },
    enableReinitialize: true,
    validationSchema: addLoanProductSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      setFinalPayload((initial) => {
        return { ...initial, ...data };
      });
      setStep(step + 1);
      setSubmitting(false);
    },
  });

  const moreConfigurationForm = useFormik({
    initialValues: {
      minLoanAmount: mode === "edit" ? String(selectedProduct?.MinAmount) : "",
      maxXAmount: mode === "edit" ? selectedProduct?.XAmount : "",
      maxInterestRate: mode === "edit" ? selectedProduct?.MaxInterestRate : "",
      penaltyFee: mode === "edit" ? selectedProduct?.PenaltyFee : "",
      loanApplicationFee:
        mode === "edit" && selectedProduct?.LoanApplicationFee
          ? selectedProduct?.LoanApplicationFee
          : "0",
      multipleRunningLoan:
        mode === "edit" ? selectedProduct?.MultipleRunningLoan : false,
    },
    enableReinitialize: true,
    validationSchema: extraLoanProductConfigurationSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      data.minLoanAmount = data?.minLoanAmount?.replace(/,/g, "");
      const payload = { ...finalPayload, ...data };
      mode === "create"
        ? addLoanProduct(
            payload,
            () => {
              setSubmitting(false);
            },
            () => {
              setSubmitting(false);
              setShowSuccess(true);
              handleReset();
              refetch();
            }
          )
        : editLoanProduct(
            { id: selectedProduct?.id, ...payload },
            () => {
              setSubmitting(false);
            },
            () => {
              setSubmitting(false);
              setShowSuccess(true);
              handleReset();
              refetch();
            }
          );
    },
  });

  const getProceedDisabled = () => {
    if (step === 1 && !loanInformationForm?.isValid) {
      return true;
    } else {
      return false;
    }
  };

  const handleReset = () => {
    loanInformationForm?.handleReset();
    moreConfigurationForm?.handleReset();
  };

  const handleClose = () => {
    onModalClose();
    setShowSuccess(false);
    setStep(1);
    handleReset();
  };

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={handleClose}
      topTitle
      title={mode === "edit" ? "Edit Loan Product" : "Add Loan Product"}
      subTitle=""
      modalClassName="!max-w-[424px]"
      childrenClassName={`!flex !flex-col ${
        showSuccess && `!justify-center`
      } !min-h-full`}
      proceedButtonText={
        step === 2 && mode === "create" ? "Add a loan product" : "Proceed"
      }
      hasButtons={!showSuccess}
      isProceedDisabled={
        getProceedDisabled() ||
        loanInformationForm?.isSubmitting ||
        moreConfigurationForm?.isSubmitting
      }
      isLoading={
        loanInformationForm?.isSubmitting || moreConfigurationForm?.isSubmitting
      }
      onProceedClick={() => {
        if (step === 1) {
          loanInformationForm?.handleSubmit();
        } else if (step === 2) {
          moreConfigurationForm?.handleSubmit();
        }
      }}
      hasCancelButton={false}
      cancelButtonText="Cancel"
      cancelButtonVariant="secondary"
      onCancelClick={() => {
        handleClose();
      }}
      modalStyle="side"
    >
      {showSuccess ? (
        <EmptyState
          image={<img src={success} alt="success" />}
          titleClassName="max-w-[267px]"
          title={
            mode === "create"
              ? `You’ve successfully added a loan product`
              : `Loan product edit completed successfully`
          }
          actionText={"View loan product"}
          actionButtonVariant="primary"
          onClick={() => {
            handleClose();
            cooperativeConfiguration?.refetch();
          }}
        />
      ) : (
        <form className="transition-all duration-500">
          {/* Back button */}
          {!showSuccess && step > 1 && (
            <BackButton
              text={"Previous"}
              onClick={() => step !== 1 && setStep(step - 1)}
              className="mb-5 !gap-1"
              backIcon="back-caret"
            />
          )}
          {/* ProgressTrail */}
          <div className="mb-6">
            <ProgressTrail steps={steps} currentStep={step} />
          </div>
          {/* Forms */}
          {step === 1 && <LoanInformation form={loanInformationForm} />}
          {step === 2 && <MoreConfiguration form={moreConfigurationForm} />}
        </form>
      )}
    </ModalComponent>
  );
};

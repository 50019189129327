import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/buttons/button";
import { TextLink } from "../../../components/buttons/textLink";
import { TextInput } from "../../../components/inputs/textInput";
import { HeaderSubheader } from "../../../components/layouts/headerSubheader";
import { OtpCountdownTImer } from "../../../components/timer/OtpCountdownTimer";
import { useAuth } from "../../../customHooks/useAuth";
import { createNewPasswordSchema } from "../../../validationSchema";

export const ForgotPasswordCreate = ({ setStep }) => {
  const { forgotPasswordChange, sendForgotPasswordCode } = useAuth();
  const navigate = useNavigate();

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      code: "",
      password: "",
      newPassword: "",
    },
    validationSchema: createNewPasswordSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        Email: sessionStorage.getItem("passwordResetEmail"),
        Code: data.code,
        Password: data.password,
      };

      forgotPasswordChange(
        payload,
        () => {
          setSubmitting(false);
        },
        () => {
          navigate("/auth/sign-in");
          sessionStorage.removeItem("passwordResetEmail");
          sessionStorage.removeItem("user");
        }
      );
    },
  });

  return (
    <div className="w-full">
      <HeaderSubheader
        header={
          sessionStorage.getItem("user", "") === "exists"
            ? "Hey there 👋🏾, let's set up your password"
            : "Create New Password"
        }
        subheader={
          sessionStorage.getItem("user", "") !== "exists" ? (
            <div>
              <span className="break-all">
                You requested a password reset for{" "}
                <span className="text-pry-dark-green-300/80 font-medium">
                  {sessionStorage.getItem("passwordResetEmail")}.
                </span>
              </span>{" "}
              If an account with this email exists, a password reset code will
              be sent to it.
            </div>
          ) : (
            <div>Kindly ensure you keep your password safe.</div>
          )
        }
        className="mt-7"
      />
      <form className="mt-6 flex flex-col gap-3" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name={"code"}
          value={values.code}
          placeholder="Code"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.code && touched.code && errors.code}
          hasIcon={false}
          autoComplete="off"
        />
        <TextInput
          type="password"
          name={"password"}
          value={values.password}
          placeholder="New password"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.password && touched.password && errors.password}
          hasIcon={false}
          autoComplete="off"
        />
        <TextInput
          type="password"
          name={"newPassword"}
          value={values.newPassword}
          placeholder="Confirm password"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.newPassword && touched.newPassword && errors.newPassword
          }
          hasIcon={false}
          autoComplete="off"
        />
        <div className="w-full text-right !text-xs text-grey-200">
          Didn’t get any code?{" "}
          <OtpCountdownTImer
            text="Resend Code"
            onClick={() => {
              sendForgotPasswordCode(
                { Email: sessionStorage.getItem("passwordResetEmail") },
                () => {},
                () => {}
              );
            }}
          />
        </div>
        <div className="w-full text-right mt-5 md:mt-3">
          <Button
            text="Create New Password"
            type="submit"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          />
        </div>
        <div className="w-full mt-1 !text-sm text-grey-400">
          I remember my password.{" "}
          <TextLink
            to="/auth/sign-in"
            text="Login"
            className=""
            onClick={() => {}}
          />
        </div>
      </form>
    </div>
  );
};

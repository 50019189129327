import { useDispatch } from "react-redux";
import { deleteRequest, getRequest, postRequest } from "../api/requestHelpers";
import { decodePaymentAction } from "../helperFunctions";
import { parseErrorMessage } from "../helperFunctions/auth";
import { doNotification } from "../helperFunctions/nofitication";
import { AuthSliceActions } from "../redux/features/auth/auth";
import { paymentSliceActions } from "../redux/features/payment/payment";

export const usePayment = () => {
  const dispatch = useDispatch();

  const fetchBankList = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Payment/Bank/list", params, true)
      .then((resp) => {
        const banks = resp?.data;

        dispatch(paymentSliceActions.bankListUpdated(banks));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const resolveAccountNumber = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    let accountName = undefined;
    await getRequest("Payment/Resolve/AccountNumber", params, true)
      .then((resp) => {
        const respData = resp?.data;
        accountName = respData?.account_name;

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
    return accountName;
  };

  const addCooperativeBankDetails = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Payment/BankDetail/Cooperative", payload, true)
      .then((resp) => {
        doNotification("Bank details updated successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const addMemberBankDetails = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Payment/BankDetail/Member", payload, true)
      .then((resp) => {
        doNotification("Bank details updated successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const deleteMemberBankDetail = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await deleteRequest("Payment/BankDetail/Member", payload, true)
      .then((resp) => {
        doNotification("Bank information removed successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const fetchMemberBankDetails = async (
    params,
    callback = () => {},
    successCallback = () => {},
    setLoading = () => {}
  ) => {
    await getRequest("Payment/BankDetail/Member", params, true)
      .then((resp) => {
        setLoading(true);

        const respData = resp?.data;

        dispatch(AuthSliceActions.memberBanks_updated(respData));

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const getRegistrationPaymentLink = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest("Payment/Paystack/View/Registration", params, true)
      .then((resp) => {
        const respData = resp?.data;

        // Call success callback
        successCallback(respData);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const verifyRegistrationPayment = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Payment/Paystack/View/Registration", payload, true)
      .then((resp) => {
        doNotification("Payment successful", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  /**Works for savings, deposits, tokenization, and loans */
  const getPaymentLink = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await getRequest(
      params?.type === "share" ? "Shares/Get/Payment" : "Payment/Get/link",
      params,
      true
    )
      .then((resp) => {
        const respData = resp?.data;

        // Call success callback
        successCallback(respData);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  /**Works for savings, deposits, and loans */
  const verifyPayment = async (
    payload,
    callback = () => {},
    successCallback = () => {},
    type = "savings"
  ) => {
    await postRequest(
      decodePaymentAction(type)?.paymentVerificationEndpoint,
      payload,
      true
    )
      .then((resp) => {
        const respData = resp?.data;

        doNotification("Payment successful", "success");

        // Call success callback
        successCallback(respData);
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    callback();
  };

  const fetchUserCards = async (
    params = {},
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Payment/CardDetail/Member", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        // Call success callback
        successCallback();

        response = data;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  const addNewCard = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await postRequest("Payment/CardDetail/Member", payload, true)
      .then((resp) => {
        doNotification("Card added successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const deleteCard = async (
    payload,
    callback = () => {},
    successCallback = () => {}
  ) => {
    await deleteRequest("Payment/CardDetail/Member", payload, true)
      .then((resp) => {
        doNotification("Card removed successfully", "success");

        // Call success callback
        successCallback();
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
      });

    // General callback
    callback();
  };

  const fetchCooperativeTransactionHistory = async (
    params,
    callback = () => {},
    successCallback = () => {}
  ) => {
    const paramsDestrct = params?.queryKey[1];

    let response;

    await getRequest("Payment/Wallet/Admin", paramsDestrct, true)
      .then((resp) => {
        const data = resp?.data;

        const result = {
          totalCount: data?.count,
          data: data?.results,
        };

        // Call success callback
        successCallback();

        response = result;
      })
      .catch((error) => {
        const errorMessage = parseErrorMessage(error?.response?.data);
        doNotification(errorMessage, "error");
        response = undefined;
      });

    callback();

    return Promise.resolve(response);
  };

  return {
    fetchBankList,
    resolveAccountNumber,
    addCooperativeBankDetails,
    addMemberBankDetails,
    deleteMemberBankDetail,
    fetchMemberBankDetails,
    getRegistrationPaymentLink,
    verifyRegistrationPayment,
    getPaymentLink,
    verifyPayment,
    fetchUserCards,
    addNewCard,
    deleteCard,
    fetchCooperativeTransactionHistory,
  };
};

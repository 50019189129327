import PropTypes from "prop-types";
import React from "react";
import { CooperativeProfilePic } from "../profile/cooperativePicture";
import { CooperativeMemberCount } from "./cooperativeMemberCount";

export const CooperativeCard = ({
  cooperative,
  onClick,
  variant = "default", // "default" | "modal-variant"
}) => {
  return (
    <div
      className={`
        flex flex-col
        border border-pry-dark-green-300/10
        rounded-lg
        !bg-white
        w-full max-w-[330px]
        h-[192px]
        overflow-hidden
        cursor-pointer
        hover:scale-95
        transition-all duration-300
        ${
          variant === "modal-variant" &&
          "hover:!scale-100 !border-none !rounded-none !h-full"
        }
        `}
      onClick={onClick}
    >
      <div
        className={`
        px-[24px] py-[16px]
        flex flex-row
        items-center
        gap-3
        bg-light-green-bg-300
        ${variant === "modal-variant" && "rounded-lg"}
        `}
      >
        <CooperativeProfilePic cooperative={cooperative} />

        <div
          className="
             overflow-hidden text-ellipsis
             flex-1
         "
        >
          <div
            className="
          w-full overflow-hidden 
          text-ellipsis whitespace-nowrap
          text-base font-medium text-pry-dark-green-100 
          capitalize
          "
          >
            {cooperative?.CoopName}
          </div>

          <CooperativeMemberCount cooperative={cooperative} />
        </div>
      </div>

      <div
        className={`
      h-full overflow-scroll
      py-4 px-6
      text-xs text-pry-dark-green-300/60
      ${variant === "modal-variant" && "!px-0 !py-6 !text-sm"}
      `}
      >
        {cooperative?.description ? cooperative?.description : "No description"}
      </div>
    </div>
  );
};

CooperativeCard.propTypes = {
  cooperative: PropTypes.shape({
    CoopName: PropTypes.any,
    description: PropTypes.any,
  }),
  onClick: PropTypes.any,
  variant: PropTypes.string,
};

import { doNotification } from "../helperFunctions/nofitication";

export const cooperativeSizes = [
  {
    text: "1-10",
    value: [1, 10],
  },
  {
    text: "11-30",
    value: [11, 30],
  },
  {
    text: "31-99",
    value: [31, 99],
  },
  {
    text: "100-499",
    value: [100, 499],
  },
  {
    text: "500+",
    value: [500],
  },
];

export const OtpMaxTime = 90; // Seconds

export const mobileTabPadding = "!px-6 md:!px-10 width-lim:!px-0";
export const mobileTabPaddingLeft =
  "!pl-6 !pr-6 md:!pl-10 md:!pr-10 lg:!pl-10 lg:!pr-0 width-lim:!pl-0 width-lim:!pr-0";
export const mobileTabPaddingRight =
  "!pr-6 !pl-6 md:!pr-10 md:!pl-10 lg:!pr-10 lg:!pl-0 width-lim:!pr-0 width-lim:!pl-0";

export const FE_STAGING_URL = "www.staging.thriftcorp.co";
export const FE_PROD_URL = "www.thriftcorp.co";
export const FE_APP_STAGING_URL = "www.app-staging.thriftcorp.co";
export const FE_APP_PROD_URL = "www.app.thriftcorp.co";

export const INACTIVITY_TIMEOUT = process.env.REACT_APP_INACTIVITY_TIMEOUT
  ? Number(process.env.REACT_APP_INACTIVITY_TIMEOUT)
  : 1000 * 60 * 5; // 5 minutes

export const callbackMap = {
  securityAutoLogOutCallback: () => {
    doNotification(
      "For your security, you have been logged out due to inactivity. Please log in again to continue.",
      "warning"
    );
  },
  expiredTokenAutoLogOutCallback: () => {
    doNotification(
      "Expired token. Please log in again to continue.",
      "warning"
    );
  },
};

export const FAILED_LOAN_STATUS = "failedDisbursement";

export const permissionList = {
  CAN_ADD_PAYMENT: "can.add.payment",
  CAN_ADD_MEMBER: "can.add.member",
  CAN_APPROVE_MEMBER: "can.approve.member",
  CAN_APPROVE_PAYMENT: "can.approve.payment",
  CAN_APPROVE_LOANS: "can.approve.loans",
};

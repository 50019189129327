import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "..";
import { useAuth } from "../../../customHooks/useAuth";
import { usePayment } from "../../../customHooks/usePayment";
import { CooperativeBankAccountSchema } from "../../../validationSchema";
import { InformationCard } from "../../cards/infoCard";
import { Dropdown } from "../../inputs/dropDown";
import { TextInput } from "../../inputs/textInput";

export const AddBankAccountModal = ({ modalOpen, onModalClose = () => {} }) => {
  const [bankOptions, setBankOptions] = useState([]);
  const [fetchingAccountName, setFetchingAccountName] = useState(false);
  const [fetchingBanks, setFetchingBanks] = useState(false);

  const {
    fetchBankList,
    resolveAccountNumber,
    addCooperativeBankDetails,
    addMemberBankDetails,
    fetchMemberBankDetails,
  } = usePayment();

  const { fetchUserProfile } = useAuth();

  const bankList = useSelector((state) => {
    return state.payment.bankList;
  });

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const handleSearch = (searchValue) => {
    const filteredList = bankList.filter((bank) =>
      bank?.name
        ?.toLowerCase()
        ?.trim()
        ?.includes(searchValue?.trim()?.toLowerCase())
    );
    const options = filteredList.map((bank) => {
      return {
        id: bank?.id,
        title: bank?.name,
        value: {
          bankCode: bank?.code,
          bankName: bank?.name,
        },
      };
    });
    setBankOptions(options);
  };

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      bank: undefined,
      accountNumber: "",
      accountName: "",
    },
    validationSchema: CooperativeBankAccountSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        bankCode: data?.bank?.value?.bankCode,
        bankName: data?.bank?.value?.bankName,
        accountNumber: data?.accountNumber,
        accouuntName: data?.accountName,
      };

      profile?.type === "cooperative"
        ? addCooperativeBankDetails(
            payload,
            () => {
              setSubmitting(false);
            },
            () => {
              fetchUserProfile();
              setSubmitting(false);
              resetForm();
              onModalClose();
            }
          )
        : addMemberBankDetails(
            payload,
            () => {
              setSubmitting(false);
            },
            () => {
              fetchMemberBankDetails();
              setSubmitting(false);
              resetForm();
              onModalClose();
            }
          );
    },
  });

  useEffect(() => {
    // Fetch bank list once ... on first screen render
    if (modalOpen) {
      setFetchingBanks(true);
      fetchBankList(undefined, () => {
        setFetchingBanks(false);
      });
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  //   Update options list whenever there's an update to bankList
  useEffect(() => {
    const options = bankList.map((bank) => {
      return {
        id: bank?.id,
        title: bank?.name,
        value: {
          bankCode: bank?.code,
          bankName: bank?.name,
        },
      };
    });
    setBankOptions(options);
  }, [bankList]);

  //   Retrieve account name when other info is valid
  useEffect(() => {
    if (
      !errors?.accountNumber &&
      !errors?.bank &&
      values?.accountNumber?.length === 10
    ) {
      setFetchingAccountName(true);
      resolveAccountNumber(
        {
          bankCode: values?.bank?.value?.bankCode,
          accountNumber: values?.accountNumber,
          accountName: values?.accountName,
        },
        () => {
          setFetchingAccountName(false);
        },
        () => {
          setFetchingAccountName(false);
        }
      ).then((resp) => {
        if (resp) {
          setFieldValue("accountName", resp);
        } else {
          setFieldValue("accountName", "");
        }
      });
    }

    if (values?.accountNumber?.length !== 10) {
      setFieldValue("accountName", "");
    }
    // eslint-disable-next-line
  }, [errors, values]);

  return (
    <ModalComponent
      isModalOpen={modalOpen}
      onModalClose={() => {
        onModalClose();
        resetForm();
      }}
      title="Add Bank Account"
      subTitle={undefined}
      modalClassName="!max-h-[480px]"
      childrenClassName="mt-[8px]"
      hasButtons
      proceedButtonText="Add account"
      onProceedClick={handleSubmit}
      isLoading={isSubmitting}
      isProceedDisabled={!isValid || isSubmitting}
    >
      <InformationCard
        info={
          profile?.type === "cooperative"
            ? "Kindly ensure you add the bank account that is tied to your cooperative financial activities"
            : "Kindly ensure you add your personal bank account."
        }
        size="mini"
        variant="purple"
      />

      <form className="mt-4 flex flex-col gap-[12px]" onSubmit={handleSubmit}>
        <Dropdown
          value={values?.bank}
          options={bankOptions}
          resetOptionsList={() => {
            handleSearch("");
          }}
          placeholder={
            profile?.type === "cooperative"
              ? "Cooperative Bank Name"
              : "Bank Name"
          }
          onChange={(val) => {
            setFieldValue("bank", val);
          }}
          onBlur={handleBlur("bank")}
          hasIcon={false}
          errors={touched?.bank && errors?.bank && errors?.bank}
          dropdownClassName="!h-[150px]"
          onSearch={handleSearch}
          isSearchable
          loading={fetchingBanks}
        />

        <TextInput
          type="text"
          name="accountNumber"
          value={values?.accountNumber}
          placeholder={
            profile?.type === "cooperative"
              ? "Cooperative Account Number"
              : "Account Number"
          }
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          errors={
            touched?.accountNumber &&
            errors?.accountNumber &&
            errors?.accountNumber
          }
          autoComplete="off"
          disabled={fetchingAccountName}
        />

        {!errors?.accountNumber && !errors?.bank && (
          <TextInput
            name="accountName"
            type="text"
            value={
              fetchingAccountName
                ? "Retrieving account name..."
                : values?.accountName
            }
            placeholder={
              values?.accountName
                ? "Cooperative Account Name"
                : "Failed to resolve account number"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            hasIcon={false}
            errors={undefined}
            inputClass={`
            text-pry-green-200
            font-medium ${
              !values?.accountName && "placeholder:!text-error-200"
            }`}
            className={`!py-2 ani-drop ${
              !values?.accountName && !fetchingAccountName && "!bg-light-red-bg"
            }`}
            readOnly={true}
            disabled={true}
          />
        )}
      </form>
    </ModalComponent>
  );
};

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

export const TextLink = ({
  text,
  to,
  className,
  onClick = () => [],
  disabled,
  ...props
}) => {
  return (
    <Link
      to={to}
      className={`text-pry-green-200 font-medium 
      hover:text-pry-dark-green-100
      no-underline
      ${className}
      ${
        disabled &&
        "hover:!cursor-not-allowed text-pry-green-200/50 hover:text-pry-green-200/50 "
      }
      transition-all
      `}
      {...props}
      onClick={!disabled && onClick}
    >
      {text}
    </Link>
  );
};

TextLink.propTypes = {
  className: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.any,
  to: PropTypes.any,
};

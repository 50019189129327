import React from "react";

export const PageTitle = ({ text, subtext = "", className = "" }) => {
  return (
    <div>
      <div
        className={`
      text-base md:text-2xl
      font-medium
      ${className}
      `}
      >
        {text}
      </div>
      {subtext && (
        <div className="mt-2 text-sm md:text-base text-pry-dark-green-300/60">
          {subtext}
        </div>
      )}
    </div>
  );
};

import PropTypes from "prop-types";
import React from "react";
import { Oval } from "react-loader-spinner";

export const PageLoader = ({
  width = 30,
  height = 30,
  className = "",
  variant = "logo", //spinner, logo
  spinnerColor = "#02291C",
  secondaryColor = "white",
}) => {
  return (
    <div
      className={`w-full flex min-h-[300px] h-full items-center justify-center ${className}`}
    >
      <div className="relative p-1.5 bg-transparent rounded-full">
        {variant === "logo" ? (
          <svg
            width={width}
            height={height}
            viewBox="0 0 370 256"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="!overflow-visible relative scale-[0.8] md:scale-100"
          >
            <path
              d="M173.268 231.063C173.15 231.376 173.018 231.688 172.886 232.004C172.674 231.741 172.445 231.49 172.243 231.209C172.587 231.177 172.929 231.129 173.268 231.063Z"
              fill="#02291C"
              className="!overflow-visible"
            />
            <path
              d="M308.682 214.339C260.853 253.283 201.247 259.504 179.232 239.315C179.58 239.003 179.928 238.704 180.227 238.436C182.784 238.485 184.685 238.502 184.685 238.502C191.943 238.603 199.544 235.763 206.929 230.514C211.375 230.138 215.787 229.443 220.133 228.434C243.46 223.159 269.282 209.679 292.864 190.48C304.235 181.287 314.653 170.977 323.963 159.705C332.765 148.942 339.847 137.863 344.785 127C354.323 106.038 354.701 88.5397 345.877 77.7113L345.648 77.4299C336.775 66.8257 319.619 63.7482 297.344 68.8085C282.336 72.207 266.307 78.9951 250.493 88.5177C250.145 86.0601 249.749 83.6157 249.335 81.2197C265.004 72.0443 280.883 65.4716 295.821 62.1039C320.974 56.3886 340.714 60.3893 351.233 73.3632C351.893 74.1722 362.456 88.1221 362.456 88.1221C383.56 120.642 358.702 173.633 308.682 214.339Z"
              fill="#02291C"
              className="coin-3 !overflow-visible"
            />
            <path
              d="M173.268 231.063C173.15 231.376 173.018 231.688 172.886 232.004C172.674 231.741 172.445 231.49 172.243 231.209C172.587 231.177 172.929 231.129 173.268 231.063Z"
              fill="#02291C"
              className="!overflow-visible"
            />
            <path
              d="M252.605 123.052C251.98 172.178 230.599 213.728 206.929 230.518C199.544 235.768 191.943 238.608 184.685 238.507C184.685 238.507 182.784 238.507 180.227 238.441C181.547 237.267 182.454 236.475 182.454 236.475C184.746 234.43 186.714 232.047 188.29 229.41C189.994 226.561 191.352 223.519 192.335 220.349C197.506 215.214 202.334 208.365 206.648 199.928C217.554 178.667 223.768 150.248 224.165 119.851C224.165 118.858 224.165 117.886 224.165 116.897C224.165 113.673 224.098 110.469 223.966 107.286C223.852 104.446 223.689 101.641 223.456 98.867C221.695 76.4452 216.63 55.8347 208.699 39.3437C198.716 18.5925 185.513 7.03431 171.53 6.88923C171.41 6.88002 171.289 6.88002 171.169 6.88923C157.318 6.88923 143.983 18.0825 133.556 38.4029C128.252 48.7345 124.071 60.7543 121.114 73.8777C119.093 72.3917 117.078 70.9717 115.062 69.6C118.086 56.9558 122.254 45.3668 127.425 35.2638C139.19 12.31 154.924 -0.241792 171.649 1.16855e-05L172.489 0.0307867C172.771 0.0307867 173.053 0.0307867 173.317 0.0659582C177.912 0.268194 190.156 0.879299 190.156 0.879299C219.904 4.49317 241.435 36.9301 249.335 81.2198C249.749 83.6158 250.145 86.0558 250.493 88.5178C252.067 99.9595 252.773 111.504 252.605 123.052Z"
              fill="#02291C"
              className="coin-2 !overflow-visible"
            />
            <path
              d="M183.911 150.424C182.885 148.01 181.776 145.588 180.57 143.174C173.846 129.495 165.315 116.778 155.206 105.365C144.978 93.6994 133.551 83.1398 121.114 73.8601C119.093 72.3741 117.078 70.954 115.062 69.5823C82.409 47.5122 49.1092 40.9571 26.8168 56.6744C26.8168 56.6744 13.283 67.7666 12.4863 68.4612C-0.0704218 79.4875 -3.27893 99.3154 3.41537 124.208C9.89401 148.274 24.9155 174.508 45.7378 198.068C66.5601 221.629 90.7538 239.795 113.856 249.203C137.759 258.95 157.833 258.203 170.377 247.124C172.577 245.193 176.455 241.76 179.232 239.315C179.58 239.003 179.928 238.704 180.227 238.436C181.547 237.262 182.454 236.471 182.454 236.471C184.747 234.425 186.714 232.043 188.29 229.406C189.993 226.558 191.351 223.518 192.335 220.349C197.907 202.72 195.328 176.882 183.911 150.424ZM175.35 223.268C174.94 225.934 174.242 228.548 173.268 231.063C173.15 231.376 173.018 231.688 172.886 232.004C171.948 234.258 170.715 236.378 169.219 238.309C168.287 239.554 167.235 240.704 166.077 241.742C165.998 241.808 165.914 241.892 165.813 241.975C155.338 251.208 137.803 251.516 116.453 242.855C94.3232 233.82 71.0406 216.305 50.9093 193.522C30.778 170.74 16.2846 145.487 10.0569 122.423C4.14158 100.388 6.53586 83.1541 16.7864 73.8601C16.8691 73.7711 16.9573 73.6874 17.0504 73.6095C27.5254 64.3769 45.06 64.0692 66.4105 72.7302C77.4664 77.2365 88.8569 83.8751 99.926 92.2284C103.33 94.7871 106.711 97.5099 110.071 100.397C112.236 102.249 114.386 104.175 116.519 106.174C121.8 111.093 126.981 116.409 131.954 122.041C145.866 137.789 157.089 154.706 164.756 171.281C166.178 174.38 167.485 177.436 168.656 180.513C170.25 184.656 171.635 188.876 172.806 193.157C175.896 204.65 176.719 214.836 175.35 223.268Z"
              fill="#02291C"
              className="coin-1 !overflow-visible"
            />
            <path
              d="M173.268 231.063C173.15 231.376 173.018 231.688 172.886 232.004C172.674 231.741 172.445 231.49 172.243 231.209C172.587 231.177 172.929 231.129 173.268 231.063Z"
              fill="#02291C"
              className="!overflow-visible"
            />
            <path
              d="M173.268 231.063C173.15 231.376 173.018 231.688 172.886 232.004C172.674 231.741 172.445 231.49 172.243 231.209C172.587 231.177 172.929 231.129 173.268 231.063Z"
              fill="#02291C"
              className="!overflow-visible"
            />
          </svg>
        ) : (
          <Oval
            visible={true}
            height={width}
            width={height}
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            color={spinnerColor}
            secondaryColor={secondaryColor}
            strokeWidth={3}
            strokeWidthSecondary={3}
          />
        )}

        <div className="absolute inset-0 animate-puls borde border-dashe p-3 rounded-full border-pry-dark-green-300"></div>
      </div>
    </div>
  );
};

PageLoader.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  secondaryColor: PropTypes.string,
  spinnerColor: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.number,
};

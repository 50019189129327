import { Approvals } from "../pages/app/approvals";
import { SingleCooperativeApproval } from "../pages/app/approvals/singleCooperativeApproval";
import { JoinCooperative } from "../pages/app/cooperatives/joinCooperative";
import { Discover } from "../pages/app/discover";
import { Invitation } from "../pages/app/invitation";
import { LoanGuarantorInvitation } from "../pages/app/invitation/loanGuarantorInvite";
import { Members } from "../pages/app/members";
import { MyCooperatives } from "../pages/app/mycooperatives";
import { SingleMyCooperative } from "../pages/app/mycooperatives/singleCooperative";
import { Overview } from "../pages/app/overview/overview";
import { Settings } from "../pages/app/settings";
import { ConfigurationsEdit } from "../pages/app/settings/configurations/configurationsEdit";
import { MembershipSettingsEdit } from "../pages/app/settings/membershipSettings/membershipSettingsEdit";
import { ProfileEdit } from "../pages/app/settings/profileSettings/profileEdit";
import { SuccessPage } from "../pages/app/success";
import { Wallet } from "../pages/app/wallet";
import { ForgotPassword } from "../pages/auth/forgotPassword/forgotPassword";
import { SignIn } from "../pages/auth/signIn";
import { SignUp } from "../pages/auth/signup/signUp";
import { Error404Page } from "../pages/error/error404Page";
import { LandingPage } from "../pages/website/landing-page/landingPage";

export const mainRoutes = [
  {
    route: "", // Url route
    element: <LandingPage />, // Component to render
    isPrivate: false, // Does accessing this route require authentication?
    profileAvailable: "all", // Is this route accessible by just member profile accounts, cooperative profile accounts, or both
  },

  {
    route: "auth/sign-in", // Url route
    element: <SignIn />, // Component to render
    isPrivate: false, // Does accessing this route require authentication?
    profileAvailable: "all", // Is this route accessible by just member profile accounts, cooperative profile accounts, or both
  },

  {
    route: "auth/forgot-password",
    element: <ForgotPassword />,
    isPrivate: false,
    profileAvailable: "all",
  },

  {
    route: "auth/sign-up/:type",
    element: <SignUp />,
    isPrivate: false,
    profileAvailable: "all",
  },

  {
    route: "auth/sign-up",
    element: <SignUp />,
    isPrivate: false,
    profileAvailable: "all",
  },

  {
    route: "app/success",
    element: <SuccessPage />,
    isPrivate: false,
    profileAvailable: "all",
  },

  {
    route: "overview",
    element: <Overview title="Overview" />,
    isPrivate: true,
    profileAvailable: "all",
  },

  {
    route: "members",
    element: <Members title="Overview" />,
    isPrivate: true,
    profileAvailable: "cooperative",
  },

  {
    route: "discover",
    element: <Discover />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "settings",
    element: <Settings />,
    isPrivate: true,
    profileAvailable: "all",
  },

  {
    route: "settings/profile/edit",
    element: <ProfileEdit />,
    isPrivate: true,
    profileAvailable: "all",
  },

  {
    route: "settings/configurations/edit",
    element: <ConfigurationsEdit />,
    isPrivate: true,
    profileAvailable: "cooperative",
  },

  {
    route: "settings/membership-settings/edit",
    element: <MembershipSettingsEdit />,
    isPrivate: true,
    profileAvailable: "cooperative",
  },

  {
    route: "cooperative/:id/join",
    element: <JoinCooperative />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "wallet",
    element: <Wallet />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "invitations",
    element: <Invitation />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "invitations/loan/guarantee",
    element: <LoanGuarantorInvitation />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "approvals",
    element: <Approvals />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "approvals/:id",
    element: <SingleCooperativeApproval />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "my-cooperatives",
    element: <MyCooperatives />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "my-cooperatives/:id",
    element: <SingleMyCooperative />,
    isPrivate: true,
    profileAvailable: "member",
  },

  {
    route: "*",
    element: <Error404Page />,
    isPrivate: false,
    profileAvailable: "all",
  },
];

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useApprovals } from "../../customHooks/useApprovals";
import { useAuth } from "../../customHooks/useAuth";
import { useMembership } from "../../customHooks/useMembership";
import { isProfileApproved } from "../../helperFunctions";
import { Icon } from "../icons/icon";
import { EmptySideNavFeaturesState } from "../states/emptySidenavFeatures";
import { navs } from "./navs";
import { ProfileNotificationComponent } from "./profileNotificationComp";

export const SideNav = ({ navOpen, setNavOpen }) => {
  const navigate = useNavigate();

  const { logoutUser } = useAuth();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const { invitations, approvalSummary } = useSelector((state) => {
    return state.app;
  });

  const { fetchInvitations } = useMembership();
  const { fetchApprovalsSummary } = useApprovals();
  const { fetchUserProfile } = useAuth();

  useEffect(() => {
    if (profile?.type === "member") {
      fetchInvitations();
      fetchApprovalsSummary();
      fetchUserProfile();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="
    bg-white 
    h-full overflow-x-visible
    w-full
    border-l lg:border-r border-light-grey
    p-6
    flex flex-col items-center
    transition-all
    "
    >
      {/* Top stuff */}
      <div
        className="
        flex gap-3 items-center
        w-full
        pb-10
        justify-between
      "
      >
        {/* Logo */}
        <div
          className={`transition-all delay-200 duration-500 cursor-pointer ${
            navOpen
              ? "translate-x-0 opacity-100"
              : "-translate-x-8 lg:translate-x-0 opacity-0 lg:opacity-100"
          }
          flex flex-row items-center justify-between w-full
          `}
        >
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            className="md:hidden"
            onClick={() => {
              navigate("/app/overview");
            }}
          />
          <img
            src="/assets/images/logo_desktop.svg"
            alt="logo"
            className="hidden md:flex"
            onClick={() => {
              navigate("/app/overview");
            }}
          />
          <div onClick={() => setNavOpen(false)} className="md:hidden">
            <Icon svg="cancel-icon" width={24} height={24} />
          </div>
        </div>

        <div className="hidden md:flex">
          <ProfileNotificationComponent />
        </div>
      </div>

      {/* Menus */}
      <div className="w-full flex flex-col gap-4">
        {navs.map(({ title, icon, to, profileAvailable, hasCount }, idx) => {
          const key = `nav-item-${idx}`;
          if (!approvalSummary?.Official && to?.includes("approval")) {
            // If user is not an official in any cooperative, hide approvals menu
            return undefined;
          }

          // Check If the account profile type matches the allowed type for the route
          if (
            profile?.type === profileAvailable ||
            profileAvailable === "all"
          ) {
            // Another check if cooperative profile types have been aprroved, to enable access to features
            if (!isProfileApproved(profile) && to !== "/app/overview") {
              return undefined;
            }

            return (
              <NavLink
                key={key}
                to={to}
                onClick={() => setNavOpen(false)}
                className={({ isActive }) => {
                  const activeClassName = isActive
                    ? "!text-pry-green-200 !bg-light-green-bg-200 font-medium"
                    : "";
                  return `
                        ${activeClassName}
                        group
                        flex items-center gap-3
                        rounded-lg
                        py-3 px-4
                        text-sm
                        hover:text-pry-green-200 hover:font-medium
                        hover:bg-light-grey-bg
                        font-medium
                        transition-all
                        `;
                }}
              >
                <div className="">
                  <Icon
                    svg={icon}
                    width={20}
                    height={20}
                    className="
                        lg:group-hover:scale-[0.9]
                        lg:group-hover:text-pry-green-200
                        transition-all duration-[700ms]
                        "
                  />
                </div>
                {title}
                {hasCount && (
                  <div
                    className={`
                  bg-info-purple text-white
                  p-1 rounded-full
                  text-xs
                  h-[20px] min-w-[20px]
                  flex items-center justify-center
                  font-medium
                  ${
                    to?.includes("invitation") &&
                    !invitations?.length &&
                    "!hidden"
                  }
                  ${
                    to?.includes("approval") &&
                    !approvalSummary?.totalapprovalCount &&
                    "!hidden"
                  }
                `}
                  >
                    {to?.includes("invitation") && invitations?.length}
                    {to?.includes("approval") &&
                      approvalSummary?.totalapprovalCount}
                  </div>
                )}
              </NavLink>
            );
          } else {
            return undefined;
          }
        })}

        {!isProfileApproved(profile) && <EmptySideNavFeaturesState />}
      </div>

      <div
        className="
        w-full h-full flex flex-col 
        justify-end
        "
      >
        <div
          className="
          flex gap-3 items-center text-sm
          font-medium
          text-red-200
          cursor-pointer
          py-3 px-4
          rounded-lg
          hover:bg-lighter-red-bg
          group
          mb-0 md:mb-0
          transition-all
          "
          onClick={logoutUser}
        >
          <Icon
            svg={"exit"}
            width={20}
            height={20}
            className="
                        lg:group-hover:scale-[0.9]
                        transition-all duration-[700ms]
                        "
          />
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";

const NavItem = ({ parentRef, children, onClick = () => {}, className }) => {
  const ref = useRef();

  return (
    <div
      ref={ref}
      onClick={() => {
        const relativeLeft =
          ref.current.getBoundingClientRect().left -
          parentRef.current.getBoundingClientRect().left +
          parentRef.current.scrollLeft;

        // const relativeRight =
        //   parentRef.current.getBoundingClientRect().right +
        //   parentRef.current.scrollLeft -
        //   ref.current.getBoundingClientRect().right;

        parentRef.current.scrollLeft = relativeLeft - 32;

        onClick();
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export const TabNav = ({
  navs = [],
  className,
  initialActiveTab = "",
  variant = "nav", // nav | switcher
}) => {
  const [activeTab, setActiveTab] = useState(
    initialActiveTab === "" ? navs[0]?.to : initialActiveTab
  );

  const navRef = useRef();

  useEffect(() => {
    initialActiveTab !== "" && setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  return (
    <div
      className={`
      flex flex-row overflow-x-scroll
      hide-scroll-bar
      gap-3 md:gap-4
      ${variant === "switcher" && "p-1 rounded-[4px] bg-white border !gap-2"}
      transition-all duration-1000
      ${className}
      !scroll-smooth
    `}
      ref={navRef}
    >
      {navs
        .filter((item) => item !== undefined)
        .map(({ title, to, onClick, hasCount, count }, i) => {
          const key = `TabNav-${i}-${title}`;

          return (
            <NavItem
              parentRef={navRef}
              key={key}
              className={`
            min-w-max ${variant === "nav" ? "md:flex-1" : "flex-1"}
            text-center
            font-medium
            text-base md:text-base
            cursor-pointer transition-all duration-500
            ${
              variant === "nav" && activeTab === to
                ? "text-pry-green-200 font-medium"
                : "text-pry-dark-green-400/40 hover:scale-95"
            }

            ${
              variant === "switcher" && activeTab === to
                ? "bg-pry-dark-green-100 text-white"
                : "text-pry-dark-green-400/60 hover:scale-95"
            }
            relative
            ${variant === "nav" && "p-1"}
            ${
              variant === "switcher" &&
              "px-[32px] py-1 rounded-[4px] !font-medium justify-center"
            }
            flex gap-1 items-center 
            `}
              onClick={(e) => {
                setActiveTab(to);
                onClick(to);
              }}
            >
              {title}
              {hasCount && (
                <div
                  className={`
                transition-all duration-500
                text-xs px-1 min-h-[20px] min-w-[20px]
                rounded-full
                flex items-center justify-center
                text-pry-dark-green-300/30
                bg-pry-dark-green-300/10
              ${
                variant === "nav" &&
                activeTab === to &&
                "!bg-pry-green-200 !text-white !font-medium"
              }
  
              ${
                variant === "switcher" &&
                activeTab === to &&
                "!text-pry-green-200 !bg-white"
              }
            `}
                >
                  {count}
                </div>
              )}
              {variant === "nav" && (
                <div
                  className={`
              absolute w-0 left-0 right-full bottom-0
              transition-all duration-500 
              border-b-2 border-transparent
            ${
              activeTab === to &&
              "!border-pry-green-200 !right-full !w-full rounded-full"
            }`}
                ></div>
              )}
            </NavItem>
          );
        })}
    </div>
  );
};

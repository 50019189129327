import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import empty_state_cooperative from "../../../assets/images/empty_state_cooperatives.svg";
import { ContainerCard } from "../../../components/cards/containerCard";
import { MyCooperativeCard } from "../../../components/cards/myCooperativeCard";
import { FilterComponent } from "../../../components/inputs/filterComponent";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { AwaitingMembershipApprovalModal } from "../../../components/modals/app_modals/awaitingMembershipApprovalModal";
import { Pagination } from "../../../components/pagination/pagination";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useApprovals } from "../../../customHooks/useApprovals";
import { useCooperative } from "../../../customHooks/useCooperative";
import { useMembership } from "../../../customHooks/useMembership";

export const MyCooperatives = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    offset: 0,
    limit: 10,
    text: null,
  });
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(undefined);

  const { fetchMyCooperatives } = useCooperative();
  const { fetchApprovalsSummary } = useApprovals();
  const { fetchInvitations } = useMembership();

  const { fetchingMyCooperatives, myCooperatives } = useSelector((state) => {
    return state?.app;
  });

  const handleModalOpen = (modalName) => {
    setModal(modalName);
    setModalOpen(true);
  };

  useEffect(() => {
    fetchMyCooperatives(query);
    fetchApprovalsSummary();
    fetchInvitations();
    // eslint-disable-next-line
  }, [query]);

  return (
    <div className="w-full pb-52">
      <PageTitle
        text="My Cooperatives"
        subtext="Manage your cooperatives all in one place"
      />

      <div
        className="
        mt-6
        "
      >
        <FilterComponent
          searchPlaceholder="Search any cooperative name"
          hasExtraFilter={false}
          handleSearch={(e) => {
            setQuery({ ...query, text: e, offset: 0 });
          }}
        />
      </div>

      {fetchingMyCooperatives && (
        <ContainerCard className="!p-[48px] border-none !h-full !bg-transparent">
          <PageLoader />
        </ContainerCard>
      )}

      {!fetchingMyCooperatives &&
        (myCooperatives?.data?.length < 1 || !myCooperatives?.data) && (
          <ContainerCard className="mt-8 !py-14">
            <EmptyState
              image={<img src={empty_state_cooperative} alt="empty_state" />}
              title="You have not joined any cooperative yet."
              actionText="Discover a cooperative"
              onClick={() => {
                navigate("/app/discover");
              }}
            />
          </ContainerCard>
        )}

      {!fetchingMyCooperatives &&
        (myCooperatives?.data?.length >= 1 || myCooperatives?.data) && (
          <div className="flex flex-col gap-6 mt-6">
            <div className="flex flex-col gap-3 md:gap-2">
              {myCooperatives?.data?.map((coopData, idx) => {
                const key = `myCooperativeData-${idx}`;
                return (
                  <MyCooperativeCard
                    cooperativeData={coopData}
                    key={key}
                    setSelectedData={setSelectedData}
                    handleModalOpen={() =>
                      handleModalOpen("awaitingMembershipApprovalModal")
                    }
                  />
                );
              })}
            </div>

            <Pagination
              limit={query?.limit}
              totalCount={myCooperatives?.totalCount}
              otherQueryParams={query}
              onPageChange={(e) => setQuery(e)}
            />
          </div>
        )}

      <AwaitingMembershipApprovalModal
        modalOpen={modalOpen && modal === "awaitingMembershipApprovalModal"}
        onModalClose={() => {
          setModal("");
          setModalOpen(false);
        }}
        selectedData={selectedData}
      />
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: [],
  cooperativeOfficials: [],
  membershipForm: [],
  formFieldTypes: [],
  membershipFee: "",
};

const cooperativeSlice = createSlice({
  name: "cooperative",
  initialState,
  reducers: {
    /**Handles cooperative roles update */
    rolesUpdated: (state, action) => {
      state.roles = action.payload;
    },
    /**Handles cooperative officials update */
    officialsUpdated: (state, action) => {
      state.cooperativeOfficials = action.payload;
    },
    /**Handles membership form update */
    membershipFormUpdated: (state, action) => {
      state.membershipForm = action.payload;
    },
    /**Handles membership form field types update */
    membershipFormFieldTypesUpdated: (state, action) => {
      state.formFieldTypes = action.payload;
    },
    /**Handles membership fee update */
    membershipFeeUpdated: (state, action) => {
      state.membershipFee = action.payload;
    },
    reset: (state, action) => {
      state = initialState;
    },
  },
});

export const cooperativeSliceReducer = cooperativeSlice.reducer;
export const cooperativeSliceActions = cooperativeSlice.actions;

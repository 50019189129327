import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import empty_state_invitation from "../../../assets/images/empty_state_invite.svg";
import { ContainerCard } from "../../../components/cards/containerCard";
import { InvitationCard } from "../../../components/cards/invitationCard";
import { PageLoader } from "../../../components/loaders/pageLoader";
import { DeclineInvitationModal } from "../../../components/modals/app_modals/declineInvitationModal";
import { EmptyState } from "../../../components/states/emptyState";
import { PageTitle } from "../../../components/typography/pageTitle";
import { useMembership } from "../../../customHooks/useMembership";

export const Invitation = () => {
  const { invitations, fetchingInvitations } = useSelector((state) => {
    return state.app;
  });

  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState({});

  const { fetchInvitations } = useMembership();

  useEffect(
    () => {
      fetchInvitations();
    },
    // eslint-disable-next-line
    []
  );

  const handleModalOpen = (name) => {
    setModal(name);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModal("");
    setModalOpen(false);
  };

  return (
    <div className="w-full">
      <PageTitle
        text="Invitations"
        subtext="Manage all your cooperative membership invitations in one place"
      />

      <div className="mt-6 ">
        {fetchingInvitations ? (
          <PageLoader />
        ) : (
          <div className="flex flex-col gap-4">
            {!invitations?.length && (
              <ContainerCard className=" !py-14">
                <EmptyState
                  image={<img src={empty_state_invitation} alt="empty_state" />}
                  title="You have no invitations"
                  subtitle="All your invitations will be displayed here whenever you are invited to join any cooperative"
                />
              </ContainerCard>
            )}

            {invitations?.length > 0 &&
              invitations?.map((invitation, idx) => {
                const key = `invitation-${idx}`;
                return (
                  <InvitationCard
                    key={key}
                    invitation={invitation}
                    handleModalOpen={handleModalOpen}
                    setSelected={setSelectedInvitation}
                  />
                );
              })}
          </div>
        )}
      </div>

      <DeclineInvitationModal
        isModalOpen={modalOpen && modal === "rejectInvitationModal"}
        onModalClose={handleModalClose}
        cooperative={selectedInvitation?.Cooperative}
        invitation={selectedInvitation}
      />
    </div>
  );
};

import { useFormik } from "formik";
import React from "react";
import { Button } from "../../../components/buttons/button";
import { TextLink } from "../../../components/buttons/textLink";
import { TextInput } from "../../../components/inputs/textInput";
import { HeaderSubheader } from "../../../components/layouts/headerSubheader";
import { useAuth } from "../../../customHooks/useAuth";
import { memberSignUpSchema } from "../../../validationSchema";

export const SignUpMember = ({ setStep }) => {
  const { registerMember } = useAuth();

  const {
    values,
    errors,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: memberSignUpSchema,
    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        FName: data.firstName,
        LName: data.lastName,
        Email: data.email,
        Password: data.password,
      };

      registerMember(
        payload,
        () => {
          // General callback function
          setSubmitting(false);
        },
        () => {
          // onSuccess callback function
          // To persist the account validation flow, in case it wasn't completed initially
          const accountConfirmation = {
            email: data.email,
            isConfirmed: false,
          };
          localStorage.setItem(
            "accountConfirmation",
            JSON.stringify(accountConfirmation)
          );
          // On success callback: Go to next step -> confirm email page
          setStep("confirm-mail");
        }
      );
    },
  });

  return (
    <div className="w-full">
      <HeaderSubheader
        header="Setup your account"
        subheader="Kindly provide the information below and we’re good to go."
      />
      <form className="mt-6 flex flex-col gap-3" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name={"firstName"}
          value={values.firstName}
          placeholder="First name"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.firstName && touched.firstName && errors.firstName}
          hasIcon={false}
        />
        <TextInput
          type="text"
          name={"lastName"}
          value={values.lastName}
          placeholder="Last name"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.lastName && touched.lastName && errors.lastName}
          hasIcon={false}
        />
        <TextInput
          type="text"
          name={"email"}
          value={values.email}
          placeholder="Email address"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email && errors.email}
          hasIcon={false}
        />
        <TextInput
          type="password"
          name={"password"}
          value={values.password}
          placeholder="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.password && touched.password && errors.password}
          hasIcon={false}
        />

        <div className="w-full text-right mt-5 md:mt-3">
          <Button
            text="Create Account"
            onClick={() => {}}
            type="submit"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          />
        </div>

        <div className="w-full mt-1 !text-sm text-grey-400">
          Already have an account?{" "}
          <TextLink
            to="/auth/sign-in"
            text="Login"
            className=""
            onClick={() => {}}
          />
        </div>
      </form>
    </div>
  );
};

export const PROD_API = "https://api.thriftcorp.co/api/v1";
export const STAGING_API = "https://sandbox-api.thriftcorp.co/api/v1";
export const DEV_API = "https://sandbox-api.thriftcorp.co/api/v1";
// export const DEV_API = "https://api.thriftcorp.co/api/v1";

export const checkContain = (link) => {
  if (window.location.href.includes) {
    return window.location.href.includes(link);
  } else {
    return window.location.href.contains(link);
  }
};

export const getApiUrl = () => {
  const BASE_URL = process.env.REACT_APP_BE_BASE_URL;

  if (!BASE_URL) {
    if (checkContain("app.thriftcorp.co")) {
      return PROD_API; // Prod API
    } else if (checkContain("staging.thriftcorp.co")) {
      return STAGING_API; // Staging API
    } else if (checkContain("thriftcorp.co")) {
      return PROD_API; // PROD API
    } else if (checkContain("localhost")) {
      return STAGING_API; // Dev API
    } else {
      return DEV_API; // Dev API
    }
  }

  return BASE_URL;
};

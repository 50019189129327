import React from "react";

export const LoanProductCard = ({ productName, creationDate, onClick }) => {
  const date = new Date(creationDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div
      className="
            p-4 rounded-lg 
            min-h-[112px] 
            w-full max-w-[173px]
            bg-light-green-bg border-pry-dark-green-300/10
            flex flex-col justify-between
            gap-2 cursor-pointer
            transition-all duration-200
            hover:scale-95
            "
      onClick={onClick}
    >
      <div className="text-sm font-bold text-pry-green-200">
        {productName ?? "Null"}
      </div>
      <div className="w-full font-medium flex justify-between text-xs text-pry-dark-green-300/60">
        <div>Created:</div>
        <div className="text-pry-dark-green-300/70">
          {date?.getDate()} {months[date?.getMonth()]}, {date?.getFullYear()}
        </div>
      </div>
    </div>
  );
};

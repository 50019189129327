import React from "react";
import { decodeUrl } from "../../helperFunctions";

export const MemberProfilePic = ({ member, className }) => {
  const colors = [
    "!bg-[#F1D7FD] !border-[#F1D7FD]",
    // "!bg-[#ed6354] !border-[#ed6354] !text-white",
    // "!bg-[#979aa5] !border-[#979aa5] !text-white",
    // "!bg-[#94d2f8ff] !border-[#94d2f8ff] !text-white",
    // "!bg-[#efde9dff] !border-[#efde9dff]",
    "!bg-[#F1D7FD] !border-[#F1D7FD]",
  ];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div
      className={`
            w-[72px] h-[72px]
            flex items-center justify-center
            ${
              !member?.Picture &&
              `
            text-[16px] font-bold uppercase
            ${randomColor}
            `
            }
            rounded-full overflow-hidden
            ${className} border
            object-cover
            `}
    >
      {member?.Picture ? (
        <img
          src={decodeUrl(member?.Picture)}
          alt="member_Picture"
          className="object-cover w-full h-full"
        />
      ) : (
        `${member?.FirstName?.slice(0, 1)}${member?.LastName?.slice(0, 1)}`
      )}
    </div>
  );
};

import React, { useState } from "react";
import { Icon } from "../icons/icon";
import { ErrorText } from "../typography/errorText";

export const TextInput = ({
  type = "text",
  name,
  value,
  placeholder = "Placeholder",
  onChange,
  onBlur,
  hasIcon = true,
  iconSvg,
  errors,
  rows = "3",
  inputClass = "",
  className = "",
  containerClassName = "",
  customIcon = undefined,
  customIconPosition = "left",
  hasTitle = false,
  title = "",
  autoComplete = "off",
  mobileScaleDown = false,
  ...inputProps
}) => {
  const [viewPassword, setViewPassword] = useState(false);

  const getIcon = () => {
    if (type === "text") {
      return "sms";
    } else if (type === "password") {
      return "lock";
    } else {
      return iconSvg;
    }
  };
  const icon = getIcon();

  const inputClassName = `
  bg-transparent w-full
  outline-none focus:outline-none
  placeholder:text-pry-dark-green-300/50
  autofill:focus:bg-transparent autofill:bg-transparent
  ${inputClass}
  ${mobileScaleDown && "text-sm"}
  `;

  return (
    <div className={containerClassName}>
      {hasTitle && (
        <div
          className={`
            ${mobileScaleDown ? "text-xs md:text-sm" : "text-sm"}
            text-pry-dark-green-300/80 font-medium
            mb-1
            `}
        >
          {title}
        </div>
      )}

      <div
        className={`w-full
          px-[17px] py-[14px]
          flex flew-row items-center gap-4
          text-sm font-medium
          bg-light-green-bg
          border rounded-lg border-transparent
          focus-within:border-pry-green-200
          ${errors && "focus-within:!border-red-200 !bg-light-red-bg"}
          transition-all
          ${className}
          `}
      >
        {hasIcon && !customIcon && (
          <Icon svg={icon} width={20} height={20} className="w-[24px]" />
        )}
        {customIcon && customIconPosition === "left" ? customIcon : undefined}
        {type !== "textarea" ? (
          <input
            type={viewPassword ? "text" : type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            className={inputClassName}
            autoComplete={autoComplete}
            {...inputProps}
          />
        ) : (
          <textarea
            type={viewPassword ? "text" : type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            rows={rows}
            className={`${inputClassName} resize-none`}
          />
        )}
        {type === "password" && (
          <Icon
            svg={viewPassword ? "hide" : "view"}
            width={20}
            height={20}
            className={`
            w-[24px] 
            cursor-pointer
            transition-all
            duration-500
            ${viewPassword && "rotate-[180deg]"}
            `}
            onClick={() => {
              setViewPassword(!viewPassword);
            }}
          />
        )}
        {customIcon && customIconPosition === "right" ? customIcon : undefined}
      </div>

      {errors && <ErrorText text={errors} />}
    </div>
  );
};

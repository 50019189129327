import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decodeUrl } from "../../helperFunctions";
import { ActionCard } from "../cards/actionCard";
import { Icon } from "../icons/icon";

export const OnboardingChecklist = ({ setPopupOpen, onboardingCompletion }) => {
  const navigate = useNavigate();

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  const checklist = [
    {
      svg: "send-colored",
      title: "Getting Started",
      text: `Give us some information about ${
        profile?.type === "cooperative" ? "your cooperative" : "yourself"
      }.`,
      buttonText: onboardingCompletion?.isGetStartedStarted
        ? "Continue"
        : "Start",
      isCompleted: onboardingCompletion?.isGetStartedComplete,
      ActionClick: () => {
        setPopupOpen(false);
        navigate("/app/settings?tab=profile");
      },
      showFor: "all",
    },
    {
      svg: "people-colored",
      title: "Invite cooperative officials",
      text: "Send an invite to the emails of your cooperative officials.",
      buttonText: onboardingCompletion?.isOfficialsStarted
        ? "Continue"
        : "Start",
      isCompleted: onboardingCompletion?.isOfficialsComplete,
      ActionClick: () => {
        setPopupOpen(false);
        navigate("/app/settings?tab=manage_officials");
      },
      showFor: "cooperative",
    },
    {
      svg: "bank-colored",
      title: `Add ${
        profile?.type === "cooperative" ? "cooperative" : "your"
      } bank details`,
      text: `Fill in your ${
        profile?.type === "cooperative" ? "current cooperative" : ""
      } bank details`,
      buttonText: "Start",
      isCompleted: onboardingCompletion?.isBankDetailsComplete,
      ActionClick: () => {
        setPopupOpen(false);
        navigate("/app/settings?tab=bank_account");
      },
      showFor: "all",
    },
    {
      svg: "document-colored",
      title: "Create membership form",
      text: "Setup the information you need from your members",
      buttonText: "Start",
      isCompleted: onboardingCompletion?.isMembershipFormComplete,
      ActionClick: () => {
        setPopupOpen(false);
        navigate("/app/settings?tab=membership_settings");
      },
      showFor: "cooperative",
    },
  ];
  return (
    <div
      className="h-full w-full 
      md:rounded-t-[8px] md:rounded-lg overflow-scroll
    flex flex-col
    "
    >
      {/* Green area */}
      <div className="bg-pry-dark-green-100">
        <div
          className="w-full flex items-center justify-between 
        px-8 py-6
        "
        >
          <div
            className="text-white text-sm md:text-base
           font-bold"
          >
            Onboarding Checklist
          </div>
          <Icon
            svg="cancel-icon"
            width={24}
            height={24}
            className="hover:cursor-pointer text-white"
            onClick={() => {
              setPopupOpen(false);
            }}
          />
        </div>
        <div
          className="px-8 py-6 
        border-t border-pry-green-200
        text-white
        flex items-center justify-between
        gap-4
        "
        >
          <div className="w-full max-w-[194px] md:max-w-[354px]">
            <div className="font-bold text-base md:text-2xl">
              Hello 👋🏼, welcome to ThriftCorp
            </div>
            <div className="mt-4 flex gap-2 items-center">
              <Icon
                svg="info-icon"
                width={24}
                height={24}
                className="w-full max-w-[24px] text-white hidden md:flex"
              />
              <div className="text-xs md:text-base font-medium">
                Kindly complete the following steps to fully activate your
                account
              </div>
            </div>
          </div>

          <div
            className={`
          bg-light-green-bg w-[93px] h-[93px] md:w-[127px] md:h-[127px]
          rounded-full
          flex items-center justify-center
          md:px-[18px]
          ${profile?.logo && "!bg-transparent !px-0"}
          cursor-pointer
          overflow-hidden
          px-2
          `}
            onClick={() => {
              setPopupOpen(false);
              navigate("/app/settings?tab=profile");
            }}
          >
            {profile?.logo || profile?.Picture ? (
              <img
                src={`${decodeUrl(profile?.logo ?? profile?.Picture)}`}
                alt="logo"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex flex-col items-center justify-center">
                <Icon
                  svg="gallery-icon-2"
                  className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
                />
                <div className="text-xs md:text-sm text-pry-green-200 text-center pt-1 font-medium">
                  {profile?.type === "member"
                    ? "Add Your Picture"
                    : "Add Your Logo"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* White area */}
      <div
        className="
        bg-white h-full min-h-[100px] overflow-scroll
        py-6 px-4 md:px-8
        flex flex-col
        gap-[10px]
      "
      >
        {checklist
          .sort((item1, item2) => {
            if (item1?.isCompleted === item2?.isCompleted) {
              return 0;
            } else if (item1?.isCompleted) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((item, i) => {
            const key = `checklist-option-${i}`;
            return item.showFor === profile?.type || item.showFor === "all" ? (
              <div
                key={key}
                className={!item.isCompleted ? "min-h-[108px]" : ""}
              >
                <ActionCard
                  svg={item.svg}
                  title={item.title}
                  text={item.text}
                  buttonText={item.buttonText}
                  isCompleted={item.isCompleted}
                  className={!item.isCompleted ? "!h-full" : ""}
                  onActionClick={item.ActionClick}
                />
              </div>
            ) : undefined;
          })}
      </div>
    </div>
  );
};

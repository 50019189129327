import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ModalComponent } from "..";
import { useCooperative } from "../../../customHooks/useCooperative";
import { decodePaymentAction, numberToString } from "../../../helperFunctions";
import { Button } from "../../buttons/button";
import { TagCard } from "../../cards/tagCard";
import { FilterComponent } from "../../inputs/filterComponent";
import { PageLoader } from "../../loaders/pageLoader";
import { Pagination } from "../../pagination/pagination";
import { TableComponent } from "../../table/table";
import { TimeText } from "../../typography/timeText";
import { TransactionDetailsModal } from "./transactionDetailsModal";

export const AdminViewProductHistoryModal = ({
  isModalOpen,
  onModalClose,
  singleCooperative = {},
  type = "savings",
}) => {
  const defaultQuery = {
    coop: singleCooperative?.id,
    logs: true,
    text: "",
    limit: 10,
    offset: 0,
  };

  const [query, setQuery] = useState(defaultQuery);
  const [modal, setModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);

  const { fetchCooperativeProductHistory } = useCooperative();

  const productHistory = useQuery(
    [`fetchCooperativeProductHistory${type}`, query, type],
    fetchCooperativeProductHistory,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    isModalOpen && productHistory?.refetch();
    // eslint-disable-next-line
  }, [isModalOpen, query]);

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        setQuery(defaultQuery);
      }}
      title={`Members' ${decodePaymentAction(type)?.subject} History`}
      topTitle
      subTitle=""
      childrenClassName=""
      modalClassName="!max-h-[572px] !max-w-[1038px]"
      hasButtons={false}
      proceedButtonText=""
      onProceedClick={() => {}}
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={false}
      isProceedDisabled={false}
      childrenContainerClassName="!mt-0"
    >
      <div className="mb-6 flex items-center justify-between flex-wrap gap-2">
        <FilterComponent
          searchPlaceholder={"Search member name, email"}
          hasExtraFilter={false}
          handleSearch={(search) => {
            setQuery({ ...query, text: search, limit: 10, offset: 0 });
          }}
          mainClassName="!w-full md:!max-w-[286px]"
          className={"!w-full md:!w-[286px] !py-2"}
          size="small"
        />

        <Button
          onClick={() => {}}
          text="Export"
          type="button"
          className="md:!py-[8px] w-full md:!max-w-[140px] !text-sm"
          loading={false}
          disabled={
            !productHistory?.data?.data ||
            productHistory?.data?.data?.length === 0 ||
            productHistory?.data?.data?.length === undefined
          }
          icon={"export-icon"}
          variation="primary"
        />
      </div>
      {productHistory?.isLoading ? (
        <PageLoader />
      ) : (
        <div className="w-full">
          <div className="w-full overflow-x-auto">
            <TableComponent
              className=""
              headers={decodePaymentAction(type)?.adminHistoryTableHeaders}
              rowClassName="md:!py-4"
              isRowClickable
              onRowClick={(data) => {
                setSelectedDataId(data);
                setModal("transactionDetailsModal");
                setModalOpen(true);
              }}
              rows={productHistory?.data?.data?.map((item, idx) => {
                const key = `schedule-${idx}`;
                return type === "shares"
                  ? {
                      id: item?.installment_number,
                      items: [
                        `${item?.MembershipSharesWalletID?.MemberID?.User?.FirstName} ${item?.MembershipSharesWalletID?.MemberID?.User?.LastName}`,
                        `${numberToString(item?.Unit)} unit${
                          item?.Unit > 1 ? "s" : ""
                        }`,
                        `₦${numberToString(item?.Amount)}`,
                        <TagCard
                          text={item?.inAppPayment ? "Online" : "Upload"}
                          type={item?.inAppPayment ? "success" : "info"}
                        />,
                        <TimeText
                          timeString={item?.PaymentDateAdded}
                          key={`${key}-paymentDate`}
                        />,
                      ],
                    }
                  : {
                      id: item?.installment_number,
                      items: [
                        `${
                          item?.MembershipDepositoryID?.MemberID?.User
                            ?.FirstName ??
                          item?.MembershipWalletID?.MemberID?.User?.FirstName
                        } ${
                          item?.MembershipDepositoryID?.MemberID?.User
                            ?.LastName ??
                          item?.MembershipWalletID?.MemberID?.User?.LastName
                        }`,
                        `₦${numberToString(item?.Amount)}`,

                        <TagCard
                          text={item?.inAppPayment ? "Online" : "Upload"}
                          type={item?.inAppPayment ? "success" : "info"}
                        />,
                        <TimeText
                          timeString={item?.PaymentDateAdded}
                          key={`${key}-paymentDate`}
                        />,
                      ],
                    };
              })}
            />
          </div>

          <div className="w-full mt-4">
            <Pagination
              limit={query?.limit}
              totalCount={productHistory?.data?.totalCount}
              otherQueryParams={query}
              onPageChange={(e) => setQuery(e)}
            />
          </div>

          <TransactionDetailsModal
            isModalOpen={modalOpen && modal === "transactionDetailsModal"}
            onModalClose={() => {
              setSelectedDataId(null);
              setModal("");
              setModalOpen(false);
            }}
            transaction={productHistory?.data?.data[selectedDataId]}
          />
        </div>
      )}
    </ModalComponent>
  );
};

import PropTypes from "prop-types";
import React from "react";

export const HeaderSubheader = ({ header, subheader, className }) => {
  return (
    <div className={`${className}`}>
      <div
        className="
        text-2xl text-pry-dark-green-300
        font-bold
      "
      >
        {header}
      </div>
      <div
        className="
        mt-1 md:mt-2
        text-sm text-pry-dark-green-300/60
        font-normal
      "
      >
        {subheader}
      </div>
    </div>
  );
};

HeaderSubheader.propTypes = {
  className: PropTypes.any,
  header: PropTypes.any,
  subheader: PropTypes.any,
};

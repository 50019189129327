import React from "react";

export const SimpleCard = ({
  text,
  subtext,
  growAnimate,
  onClick,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={`
    bg-light-green-bg
    rounded-lg
    border border-transparent
    px-[24px] pt-[24px] pb-[40px]
    ${onClick && growAnimate && "md:hover:scale-[1.02]"}
    ${
      onClick &&
      "md:hover:cursor-pointer md:hover:border md:hover:border-pry-green-200"
    }
    ${className}
    transition-all duration-300
    `}
    >
      <div
        className="
      text-pry-green-200 font-medium text-[20px]
      pb-2
      "
      >
        {text}
      </div>
      <div
        className="
      text-pry-dark-green-300/80 text-[14px]
      "
      >
        {subtext}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { decodeUrl } from "../../helperFunctions";

export const ProfilePicture = ({ className, tempSrc }) => {
  const [profileBgColor, setProfileBgColor] = useState("");
  const [image, setImage] = useState("");

  const profile = useSelector((state) => {
    return state.auth.profile;
  });

  useEffect(() => {
    const colors = [
      "!bg-[#F1D7FD] !border-[#F1D7FD]",
      //   "!bg-[#ed6354] !border-[#ed6354] !text-white",
      //   "!bg-[#979aa5] !border-[#979aa5] !text-white",
      //   "!bg-[#94d2f8ff] !border-[#94d2f8ff] !text-white",
      //   "!bg-[#efde9dff] !border-[#efde9dff]",
      "!bg-[#F1D7FD] !border-[#F1D7FD]",
    ];
    const random = Math.floor(Math.random() * colors.length);
    setProfileBgColor(colors[random]);
  }, []);

  useEffect(() => {
    if (profile?.type === "cooperative") {
      setImage(profile?.logo);
    } else {
      setImage(profile?.Picture);
    }
  }, [profile]);

  return (
    <div
      className={`
            relative
            w-[32px] h-[32px]
            border border-light-grey
            rounded-full
            hover:cursor-pointer
            transition-all
            ${!image && !tempSrc && profileBgColor}
            text-pry-dark-green-400
            text-xs
            ${className}
            `}
    >
      {image || tempSrc ? (
        <img
          src={tempSrc ? tempSrc : `${decodeUrl(image)}`}
          alt="profile"
          className="
            w-full h-full 
            object-cover
            rounded-full
            "
        />
      ) : (
        <div
          className="w-full h-full 
              flex items-center justify-center
              text-center font-medium
              uppercase
              "
        >
          {profile?.type === "member"
            ? `${profile?.FirstName?.slice(0, 1)}${profile?.LastName?.slice(
                0,
                1
              )}`
            : profile?.CoopName?.slice(0, 1)}
        </div>
      )}
    </div>
  );
};

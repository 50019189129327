import React, { useEffect, useState } from "react";
import { ModalComponent } from "..";
import { useSavings } from "../../../customHooks/useSavings";
import { InformationCard } from "../../cards/infoCard";
import { Dropdown } from "../../inputs/dropDown";
import { FileUploadInput } from "../../inputs/fileUploadInput";

const defaultIdentifierSelection = {
  id: "Email",
  title: "Email",
  value: "email",
};

const otherdefaultIdentifiers = [
  {
    id: "phone",
    title: "Phone number",
    value: "phone",
  },
];

export const UploadMemberSavingsModal = ({
  isModalOpen,
  onModalClose,
  singleCooperative,
  successCallback = () => {},
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [identifierOptions, setIdentifierOptions] = useState([
    defaultIdentifierSelection,
  ]);
  const [selectedIdentifier, setSelectedIdentifier] = useState(
    defaultIdentifierSelection
  );

  const { downloadBulkSavingsTemplate, doBulkSavingsUpload } = useSavings();

  const handleSubmit = () => {
    setSubmitting(true);

    const formData = new FormData();

    formData.append("form", singleCooperative?.formdetail?.form?.id);
    formData.append("corp", singleCooperative?.id);
    formData.append("coop", singleCooperative?.id);
    formData.append("file", selectedFiles[0]);
    formData.append("unique", selectedIdentifier.value);

    doBulkSavingsUpload(
      formData,
      () => {
        setSubmitting(false);
      },
      (data) => {
        setSubmitting(false);
        setSelectedFiles([]);
        onModalClose();

        const respData = {
          hasError: data?.numberError > 0,
          fileBase64: data?.file,
        };
        successCallback(respData);
      }
    );
  };

  useEffect(() => {
    const customFields = singleCooperative?.formdetail?.Field
      ? singleCooperative?.formdetail?.Field?.map((field) => {
          return {
            id: field?.question,
            title: field?.question,
            value: field?.question,
          };
        })
      : [];

    isModalOpen &&
      setIdentifierOptions([
        {
          id: "Email",
          title: "Email",
          value: "Email",
        },
        ...otherdefaultIdentifiers,
        ...customFields,
      ]);
  }, [isModalOpen, singleCooperative]);

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      onModalClose={() => {
        onModalClose();
        setSelectedFiles([]);
      }}
      topTitle
      title="Add savings in bulk"
      subTitle={
        <InformationCard
          info={
            <div>
              <span
                className="underline text-info-purple font-medium cursor-pointer"
                onClick={() => {
                  downloadBulkSavingsTemplate(
                    {
                      coop: singleCooperative?.id,
                      file: "csv",
                      unique: selectedIdentifier.value,
                    },
                    `${singleCooperative?.CoopName}_savings_upload_template`
                  );
                }}
              >
                Download
              </span>{" "}
              this excel file and kindly, fill accordingly. Once that is done,
              you can upload the file here.
            </div>
          }
          variant="purple"
          size="mini"
        />
      }
      childrenClassName="!mt-[16px]"
      modalClassName="!max-h-[490px]"
      hasButtons
      proceedButtonText="Submit for approval"
      onProceedClick={handleSubmit}
      proceedButtonVariant="primary"
      hasCancelButton={false}
      cancelButtonText=""
      cancelButtonVariant=""
      onCancelClick={() => {}}
      isLoading={submitting}
      isProceedDisabled={selectedFiles?.length < 1 || submitting}
    >
      <div className="flex flex-col gap-4">
        <Dropdown
          title="Select a unique identifier for members"
          hasTitle
          value={selectedIdentifier}
          options={identifierOptions}
          resetOptionsList={() => {}}
          placeholder={"Identifier"}
          onChange={(val) => {
            setSelectedIdentifier(val);
          }}
          onBlur={() => {}}
          hasIcon={false}
          dropdownClassName="!h-[150px]"
          onSearch={() => {}}
          isSearchable={false}
          loading={false}
        />

        <FileUploadInput
          handleChange={(data) => {
            setSelectedFiles(Object.values(data));
          }}
          selectedFiles={selectedFiles}
          setSelectedFiles={(data) => {
            setSelectedFiles(data);
          }}
          types="XLS, XLSX, CSV"
          accept=".xls,.xlsx,.csv"
        />
      </div>
    </ModalComponent>
  );
};

import React from "react";
import { toast } from "react-toastify";
import { ToastIcon } from "../../components/icons/toastIcon";

const ToastMessage = ({ title, message }) => {
  return (
    <div>
      <div
        className="
      text-pry-dark-green-400 text-base
      font-medium
      "
      >
        {title}
      </div>
      <div
        className="
      text-sm text-pry-dark-green-400/60
      "
      >
        {message}
      </div>
    </div>
  );
};

export const getToastColor = (type) => {
  switch (type) {
    case "success":
      return "border-pry-dark-green-300/20 bg-light-green-bg-400";
    case "error":
      return "border-error-200/20 bg-light-red-bg-50";
    case "warning":
      return "border-warning-yellow/20 bg-warning-yellow-bg-50";
    default:
      return "border-info-purple/20 bg-info-purple-bg";
  }
};

export const doNotification = (message, type = "") => {
  const toastConfig = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    icon: ({ type }) => {
      return <ToastIcon type={type} />;
    },
  };

  switch (type) {
    case "success":
      toast.success(<ToastMessage title="Success" message={message} />, {
        ...toastConfig,
      });
      break;
    case "error":
      toast.error(<ToastMessage title="Error" message={message} />, {
        ...toastConfig,
      });
      break;
    case "warning":
      toast.warning(<ToastMessage title="Warning" message={message} />, {
        ...toastConfig,
      });
      break;
    default:
      toast.info(<ToastMessage title="Information" message={message} />, {
        ...toastConfig,
      });
  }
};
